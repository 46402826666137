import * as React from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { NonAuthRoutes } from 'presentation/routes/routes';
import { Link, useLocation } from 'react-router-dom';
import st from '../Auth.module.scss';
import { HeaderBarSmall } from 'presentation/views/components/AppBar/AppBarSmall';
import { useViewModels } from 'presentation/view-model/ViewModelProvider';

const EmailConfirmation = () => {
    const intl = useIntl();

    const query = new URLSearchParams(useLocation().search);
    const queryToken = query?.get('confirmationCode');

    const {
        authViewModel: {
            emailConfirmationSubmitError,
            isEmailConfirmationSubmission,
            isEmailConfirmationSubmissionIsSync,
            onEmailConfirmation,
        },
        userViewModel,
    } = useViewModels();

    React.useEffect(() => {
        onEmailConfirmation(queryToken);
    }, [onEmailConfirmation, queryToken]);

    return (
        <div className={st.auth}>
            <HeaderBarSmall bgColor="#09299C" staticBarIsTransparent className={st.header} />

            <div className={st.auth_container}>
                <Grid container component="main" sx={{ minHeight: '100vh' }}>
                    <Grid item xs={12} md={5} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                            sx={[
                                {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    maxWidth: 480,
                                },
                                (theme) => ({
                                    [theme.breakpoints.down('md')]: {
                                        maxWidth: 'none',
                                    },
                                }),
                            ]}
                        >
                            <Grid container spacing={6}>
                                {isEmailConfirmationSubmission ? (
                                    <>
                                        {isEmailConfirmationSubmissionIsSync && (
                                            <Grid item xs={12}>
                                                <Typography variant="t3" mb={4} className="error">
                                                    {emailConfirmationSubmitError.length < 1
                                                        ? intl.formatMessage({ id: 'auth.newPassword.notVerifyToken' })
                                                        : emailConfirmationSubmitError}
                                                </Typography>
                                            </Grid>
                                        )}

                                        <Grid item xs={12}>
                                            <Link to={`${userViewModel.locale && userViewModel.locale !== 'de' ? "/" + userViewModel.locale : ''}${NonAuthRoutes.signin}`}>
                                                <Button variant="contained" color="secondary" autoFocus sx={{ width: '100%' }}>
                                                    {intl.formatMessage({ id: 'ok' })}
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={12}>
                                            <Typography
                                                component="h1"
                                                variant="h4"
                                                sx={{ m: 0 }}
                                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.emailConfirmation.title' }) }}
                                                gutterBottom
                                            ></Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography
                                                component="p"
                                                variant="body1"
                                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.emailConfirmation.description' }) }}
                                            ></Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Link to={`${userViewModel.locale && userViewModel.locale !== 'de' ? "/" + userViewModel.locale : ''}${NonAuthRoutes.signin}`}>
                                                <Button variant="contained" color="secondary" autoFocus sx={{ width: '100%' }}>
                                                    {intl.formatMessage({ id: 'auth.emailConfirmation.button' })}
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6} className={st.media}>
                        <svg className={st.media_circle_1} viewBox="0 0 577 577" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity=".32" cx="288.293" cy="288.294" r="287.5" transform="rotate(45 288.293 288.294)" stroke="#fff" fill="none" />
                        </svg>
                        <svg className={st.media_circle_2} viewBox="0 0 468 545" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.32" cx="180" cy="288.294" r="287.5" transform="rotate(45 180 288.294)" stroke="#fff" fill="none" />
                        </svg>
                        <div className={st.media__picture}>
                            <img src={`/images/Auth/forgot.png`} alt="forgot password" />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default observer(EmailConfirmation);

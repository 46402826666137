// @ts-nocheck
import React, { useEffect, useState } from "react";

// api config
import "data/settings/axios";

// routing
import { Switch, Route, Redirect } from "react-router-dom";
import { AuthRoutes, NonAuthRoutes } from "presentation/routes/routes";

//  stores
import { observer } from "mobx-react";

// views (pages)
import Contacts from "./Public/Contacts";

//  styles
import "presentation/views/assets/styles/global/index.scss";
import Auth from "./Public/Auth";
import Doctors from "./Public/Doctors";
import Faq from "./Public/Faq";
import FaqId from "./Public/FaqId/FaqId";
import Home from "./Public/Home";
import News from "./Public/News";
import NewsId from "./Public/NewsId";
import { useViewModels } from "presentation/view-model/ViewModelProvider";
import AuthRoute from "presentation/routes/AuthRoute";
import Cabinet from "./Private/Cabinet/Cabinet";
import PrivacyPolicy from "./Public/PrivacyPolicy";
import TermsOfUse from "./Public/TermsOfUse";
import Specialities from "./Public/Specialities";
import { UserRoles } from "types";
import About from "./Public/About";
import ForDoctors from "./Public/ForDoctors";
import UserEdit from "./Private/UserEdit";

// Localization
import { IntlProvider } from "react-intl";
import Germany from "lang/de.json";
import English from "lang/en.json";
import ErrorPage from "./Public/ErrorPage";
import DoctorId from "./Public/DoctorId/DoctorId";
import PatientId from "./Public/PatientId/PatientId";
import Preloader from "./components/Preloader";
import EmailConfirmation from "./Public/Auth/EmailConfirmation";
import AuthenticationEmulate from "./Public/Auth/AuthenticationEmulate/AuthenticationEmulate";
import { Helmet } from 'react-helmet-async';


const App = () => {
    const {
        authViewModel: { getUserAppAccess, isSync, isUserAuthorized, isDoctor },
        userViewModel,
        doctorProfileViewModel,
        patientProfileViewModel,
    } = useViewModels();

    const [i18nStrings, setI18nStrings]: any = useState(Germany);
    const intlProvider = new IntlProvider({ 'en': English, 'de': Germany });

    useEffect(() => {
        getUserAppAccess();
    }, [getUserAppAccess]);

    useEffect(() => {
        if (isUserAuthorized) {
            if (!userViewModel.identifier) {
                userViewModel.loadUser().then(() => {
                    if (isDoctor) {
                        doctorProfileViewModel.loadProfile();
                    } else {
                        patientProfileViewModel.loadPatientProfile();
                    }
                });
            } else {
                if (isDoctor) {
                    doctorProfileViewModel.loadProfile();
                } else {
                    patientProfileViewModel.loadPatientProfile();
                }
            }
        }
    }, [userViewModel, isUserAuthorized, doctorProfileViewModel, patientProfileViewModel, isDoctor, userViewModel.locale]);

    useEffect(() => {
        let isMounted = true;
        if (userViewModel.locale === "de") {
            if (isMounted) {
                setI18nStrings(Germany);
            }
        } else {
            if (isMounted) {
                setI18nStrings(English);
            }
        }
        return () => { isMounted = false };
    }, [userViewModel.locale]);

    const RouteDistributor = ({children, ...rest}) => {
        return (
            <Route
                {...rest}
                render={({ match, location }) =>
                    ([NonAuthRoutes.auth, NonAuthRoutes.authLocale].includes(match.path) ?
                            <>{children}</>
                        :
                        match.params?.locale ?
                            userViewModel.locale === "de" ?
                                <Redirect to={(match.url).slice(match.params.locale.length + 1)} />
                                :
                                <>{children}</>
                            :
                            userViewModel.locale !== "de" ?
                                <Redirect to={`/${userViewModel.locale}${match.url}`} />
                                :
                                <>{children}</>
                    )
                }
            />
        );
    }

    // if (isSync || userViewModel.isSync) return <LinearProgress />;
    if (isSync
        || userViewModel.isSync
        || patientProfileViewModel.isSync
        || doctorProfileViewModel.isSync
    ) {
        return <Preloader />;
    }

    return (
        <IntlProvider messages={i18nStrings} locale={userViewModel.locale} defaultLocale="de">
            <Helmet>
                <title>{intlProvider.props[userViewModel.locale]["app.title"]}</title>
                <meta name="description" content={intlProvider.props[userViewModel.locale]["app.description"]} />
                {/*<link rel="canonical" href={process.env.REACT_APP_API_ADDRESS} />*/}
            </Helmet>

            <Switch>
                <Route path={NonAuthRoutes.homeLocale} exact>
                    {userViewModel.locale === 'de' ? <Redirect to={NonAuthRoutes.home} /> : <Home />}
                </Route>
                <Route path="/" exact>
                    {userViewModel.locale !== 'de' ? <Redirect to={`/${userViewModel.locale}/`} /> : <Home />}
                </Route>

                <RouteDistributor exact path={[NonAuthRoutes.news, NonAuthRoutes.newsLocale]}>
                    <News />
                </RouteDistributor>

                <RouteDistributor exact path={[NonAuthRoutes.newsId, NonAuthRoutes.newsIdLocale]}>
                    <NewsId />
                </RouteDistributor>

                {/*<Route path={NonAuthRoutes.auth} component={Auth} />*/}

                <RouteDistributor path={[NonAuthRoutes.auth, NonAuthRoutes.authLocale]}>
                    <Auth />
                </RouteDistributor>

                <RouteDistributor exact path={[NonAuthRoutes.doctor, NonAuthRoutes.doctorLocale]}>
                    <DoctorId />
                </RouteDistributor>

                <RouteDistributor exact path={[NonAuthRoutes.doctors, NonAuthRoutes.doctorsLocale]}>
                    <Doctors />
                </RouteDistributor>

                <RouteDistributor exact path={[NonAuthRoutes.faq, NonAuthRoutes.faqLocale]}>
                    <Faq />
                </RouteDistributor>

                <RouteDistributor exact path={[NonAuthRoutes.faqId, NonAuthRoutes.faqIdLocale]}>
                    <FaqId />
                </RouteDistributor>

                <RouteDistributor exact path={[NonAuthRoutes.contacts, NonAuthRoutes.contactsLocale]}>
                    <Contacts />
                </RouteDistributor>

                <RouteDistributor exact path={[NonAuthRoutes.forDoctors, NonAuthRoutes.forDoctorsLocale]}>
                    <ForDoctors />
                </RouteDistributor>

                <RouteDistributor exact path={[NonAuthRoutes.about, NonAuthRoutes.aboutLocale]}>
                    <About />
                </RouteDistributor>

                <RouteDistributor exact path={[NonAuthRoutes.privacyPolicy, NonAuthRoutes.privacyPolicyLocale]}>
                    <PrivacyPolicy />
                </RouteDistributor>

                <RouteDistributor exact path={[NonAuthRoutes.termsOfUse, NonAuthRoutes.termsOfUseLocale]}>
                    <TermsOfUse />
                </RouteDistributor>

                <RouteDistributor exact path={[NonAuthRoutes.specialities, NonAuthRoutes.specialitiesLocale]}>
                    <Specialities />
                </RouteDistributor>

                <RouteDistributor exact path={[AuthRoutes.patient, AuthRoutes.patientLocale]}>
                    <PatientId />
                </RouteDistributor>

                <AuthRoute Component={Cabinet} path={AuthRoutes.cabinet} requiredRoles={[UserRoles.doctor, UserRoles.patient]} />
                <AuthRoute Component={Cabinet} path={AuthRoutes.cabinetLocale} requiredRoles={[UserRoles.doctor, UserRoles.patient]} />

                <AuthRoute exact Component={UserEdit} path={AuthRoutes.doctorEdit} requiredRoles={[UserRoles.doctor]} />
                <AuthRoute Component={UserEdit} path={AuthRoutes.doctorEditLocale} requiredRoles={[UserRoles.doctor]} />

                <AuthRoute exact Component={UserEdit} path={AuthRoutes.patientEdit} requiredRoles={[UserRoles.patient]} />
                <AuthRoute Component={UserEdit} path={AuthRoutes.patientEditLocale} requiredRoles={[UserRoles.patient]} />

                <AuthRoute Component={AuthenticationEmulate} path={AuthRoutes.authenticationEmulate} requiredRoles={[]} />
                <AuthRoute Component={AuthenticationEmulate} path={AuthRoutes.authenticationEmulateLocale} requiredRoles={[]} />

                <Route path={NonAuthRoutes.emailConfirmation} component={EmailConfirmation} exact />

                <Route path="*" component={ErrorPage} exact />
            </Switch>
        </IntlProvider>
    );
};

export default observer(App);

// @ts-nocheck
import React, {useEffect} from "react";
import { useIntl } from "react-intl";
import { Field, FormikProvider, useFormik } from "formik";
import Fade from '@mui/material/Fade';

// components
import {
    Autocomplete,
    Dialog,
    Divider,
    IconButton,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useViewModels } from "presentation/view-model/ViewModelProvider";
import st from "./security.module.scss";
import stylePopup from "presentation/views/components/Popup/popup.module.scss";
import {observer} from "mobx-react";
import { ReactComponent as CrossIcon } from "presentation/views//assets/images/icons/cross_14_2.svg";
import cleanDeep from "clean-deep";
import ReactPhoneInput from 'react-phone-input-material-ui';
import { tools } from "../../../../helpers/tools";
import isEmail from "validator/lib/isEmail";
// import isMobilePhone from "validator/lib/isMobilePhone";
import classNames from "classnames";


const Security: React.FC = () => {
    const intl = useIntl();
    const {
        authViewModel: { userOptions, getUserAccount, editUserAccount, onClickChangePassword, onClickDeleteAccount },
        userViewModel,
        uIViewModel: {
            setSuccessAlertContent,
            setErrorAlertContent,
            setErrorAlertContentTime,
            alertContentTime
        },
    } = useViewModels();
    const userId = userViewModel.identifier;
    const localeOptions = [
        { localeValue: "de", title: "Deutsch" },
        { localeValue: "en", title: intl.formatMessage({id: 'locale.en'}) },
    ];

    useEffect(() => {
        getUserAccount(userId);
    }, [getUserAccount, userId]);

    const validateChangePassword = (values: any): object => {
        const errors: any = {
            currentPassword: (() => {
                if (!values.currentPassword) return intl.formatMessage({id: 'required'});
            })(),
            newPassword: (() => {
                if (!values.newPassword) return intl.formatMessage({id: 'required'});
                if (values.confirmNewPassword && values.confirmNewPassword !== values.newPassword) {
                    return intl.formatMessage({id: 'errors.passwordNotMatch'});
                }
            })(),
            confirmNewPassword: (() => {
                if (!values.confirmNewPassword) return "required";
                if (values.confirmNewPassword !== values.newPassword) {
                    return intl.formatMessage({id: 'errors.passwordNotMatch'});
                }
            })(),
        };

        return cleanDeep(errors);
    };

    const validateEditAccount = (values: any) => {
        const errors: any = {};

        if (!values.login) {
            errors.login = intl.formatMessage({id: 'required'});
        }

        /*if (values.phone && !isMobilePhone(values.phone)) {
            errors.phone = intl.formatMessage({id: "errors.phone"});
        }*/

        if (values.email && !isEmail(values.email)) {
            errors.email = intl.formatMessage({id: "errors.email"});
        }

        return errors;
    }

    const accountForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            login: userOptions.login ?? '',
            phone: userOptions.phone ?? '',
            email: userOptions.email ?? '',
            defaultLocale: userOptions.defaultLocale ?? null,
        },
        validate: validateEditAccount,
        onSubmit: (values, { setErrors }) => {
            setSuccessAlertContent('', true);
            setErrorAlertContent('', true);

            editUserAccount(userId, {
                login: values.login,
                phone: values.phone,
                email: values.email,
                defaultLocale: values.defaultLocale,
            })
                .then(() => {
                    setSuccessAlertContent(intl.formatMessage({ id: "successSave" }));
                })
                .catch((errors) => {
                    console.log('errors', errors);
                    setErrorAlertContent(intl.formatMessage({ id: "errorSave" }));
                });
        },
    });

    const form = useFormik({
        initialValues: { currentPassword: "", newPassword: "", confirmNewPassword: "" },
        validate: validateChangePassword,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values, { setErrors }) => {
            setSuccessAlertContent('', true);
            setErrorAlertContent('', true);

            onClickChangePassword(values.currentPassword, values.newPassword, values.confirmNewPassword)
                .then(() => {
                    setSuccessAlertContent(intl.formatMessage({ id: "successSave" }));
                })
                .catch(error => {
                    if (typeof error === "string") {
                        setErrorAlertContentTime(error, alertContentTime);
                    } else if (tools.isObject(error)) {
                        let errors = {};
                        for (let key in error) {
                            if (key in form.values) {
                                errors[key] = error[key][0];
                            }

                        }
                        setErrors(errors);
                    } else {
                        console.log({error});
                    }
                });
        },
    });

    const phoneChange = (phone: number) => {
        accountForm.setFieldValue("phone", phone);
    }

    const phoneFocusChange = (e: any, phone: number) => {
        if (accountForm.values.phone?.length <= 1) {
            accountForm.setFieldValue("phone", phone.dialCode);
        }
    }

    const validateDeleteAccount = (values: any): object => {
        const errors: any = {
            currentPassword: (() => {
                if (!values.currentPassword) return intl.formatMessage({id: 'required'});
            })(),
        };

        return cleanDeep(errors);
    };

    const deleteAccountForm = useFormik({
        initialValues: { currentPassword: "" },
        validate: validateDeleteAccount,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values, { setErrors }) => {
            onClickDeleteAccount(values.currentPassword)
                .catch(error => {
                if (typeof error === "string") {
                    setErrorAlertContentTime(error, alertContentTime);
                } else if (tools.isObject(error)) {
                    let errors = {};
                    for (let key in error) {
                        if (key in form.values) {
                            errors[key] = error[key][0];
                        }
                    }
                    setErrors(errors);
                } else {
                    console.log({error});
                }
            });
        },
    });

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Typography variant="h4" sx={{ mb: 8 }}
                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.account.title" }) }}
            />
{/*            <pre style={{color: "saddlebrown", }}>{// @ts-ignore
                JSON.stringify(accountForm.values, 0, 2)}</pre>*/}
{/*            <pre style={{color: "saddlebrown", }}>{// @ts-ignore
                JSON.stringify(form.values, 0, 2)}</pre>*/}

            <FormikProvider value={accountForm}>
                <Box
                    component="form"
                    noValidate
                    onSubmit={accountForm.handleSubmit}
                    onChange={(event: any) => {
                        if (accountForm.errors) {
                            accountForm.setFieldError(event.target.name, "");
                        }
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{ mb: 4 }}
                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.security.personal.title" }) }}
                    />
                    <Grid container spacing={4} sx={{ mb: 4 }}>
                        {/*<Grid item xs={12}>
                            <Field
                                name="login"
                                children={(fieldProps: any) => (
                                    <TextField
                                        {...fieldProps.field}
                                        required
                                        fullWidth
                                        type="text"
                                        variant="standard"
                                        label={<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.account.login" }) }} />}
                                        error={!accountForm.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={!accountForm.isValid && fieldProps.meta.error}
                                    />
                                )}
                            />
                        </Grid>*/}

                        <Grid item xs={12}>
                            <Field
                                name="phone"
                                children={(fieldProps: any) => (
                                    <ReactPhoneInput
                                        {...fieldProps.field}
                                        value={accountForm.values.phone}
                                        className={classNames(st.phone, accountForm.values.phone && accountForm.values.phone.length >= 1 ? st.filled : "")}
                                        onChange={phoneChange}
                                        onFocus={phoneFocusChange}
                                        disableCountryGuess={true}
                                        inputProps={{
                                            id: "phone",
                                            margin: "normal",
                                            inputMode: 'numeric',
                                            variant: "standard",
                                            autoComplete: "phone",
                                            error: !accountForm.isValid && Boolean(fieldProps.meta.error),
                                            helperText: !accountForm.isValid && fieldProps.meta.error,
                                            label: `${intl.formatMessage({ id: "cabinet.account.phoneNumber" }) }`,
                                        }}
                                        name="phone"
                                        prefix=''
                                        disableDropdown
                                        preserveOrder={['country', 'preferredCountries']}
                                        defaultErrorMessage={accountForm.phone && !accountForm.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        error={accountForm.phone && !accountForm.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={accountForm.phone && !accountForm.isValid && fieldProps.meta.error}
                                        component={TextField}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Field
                                name="email"
                                children={(fieldProps: any) => (
                                    <TextField
                                        {...fieldProps.field}
                                        fullWidth
                                        type="email"
                                        label={<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.account.email" }) }} />}
                                        variant="standard"
                                        error={!accountForm.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={!accountForm.isValid && fieldProps.meta.error}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                blurOnSelect={true}
                                getOptionLabel={(option) => (option.title ? option.title : "")}
                                isOptionEqualToValue={(option, value) => value.title === "" || option.title === value.title}
                                options={localeOptions}
                                value={localeOptions[localeOptions.findIndex(item => item.localeValue === userOptions?.defaultLocale)] ?? null}
                                onChange={(e, value) => {
                                    accountForm.setFieldValue("defaultLocale", value?.localeValue ?? null);
                                    userOptions.defaultLocale = value?.localeValue ?? null;
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="defaultLocale"
                                        fullWidth
                                        label={intl.formatMessage({ id: "defaultLocale" })}
                                        type="text"
                                        variant="standard"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Button type="submit" variant="contained">
                        <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.security.password.submit" }) }} />
                    </Button>
                </Box>
            </FormikProvider>

            <Divider sx={{ mt: {xs: 4, md: 8}, mb: {xs: 4, md: 8} }} />

            <FormikProvider value={form}>
                <Box
                    component="form"
                    noValidate
                    onSubmit={form.handleSubmit}
                    onChange={(event: any) => {
                        if (form.errors) {
                            form.setFieldError(event.target.name, "");
                        }
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{ mb: 4 }}
                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.security.password.title" }) }}
                    />

                    <Grid container spacing={4} sx={{ mb: 4 }}>
                        <Grid item xs={12}>
                            <Field
                                name="currentPassword"

                                children={(fieldProps: any) => (
                                    <TextField
                                        {...fieldProps.field}
                                        required
                                        fullWidth
                                        type="password"
                                        variant="standard"
                                        label={<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.security.password.current" }) }} />}
                                        error={!form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={!form.isValid && fieldProps.meta.error}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Field
                                name="newPassword"
                                children={(fieldProps: any) => (
                                    <TextField
                                        {...fieldProps.field}
                                        required
                                        fullWidth
                                        type="password"
                                        label={<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.security.password.new" }) }} />}
                                        variant="standard"
                                        error={!form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={!form.isValid && fieldProps.meta.error}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Field
                                name="confirmNewPassword"
                                children={(fieldProps: any) => (
                                    <TextField
                                        {...fieldProps.field}
                                        required
                                        fullWidth
                                        type="password"
                                        label={<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.security.password.confirm" }) }} />}
                                        variant="standard"
                                        error={!form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={!form.isValid && fieldProps.meta.error}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Button type="submit" variant="contained">
                        <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.security.password.submit" }) }} />
                    </Button>

                    <Divider sx={{ mt: {xs: 4, md: 8}, mb: {xs: 4, md: 8} }} />

                    <Typography
                        variant="h5"
                        gutterBottom
                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.security.delete.title" }) }}
                    />

                    <Typography
                        variant="body1"
                        sx={{ mb: 4 }}
                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.security.delete.description" }) }}
                    />
                    <Button type="button" variant="contained" color="error" onClick={handleClickOpen}>
                        <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.security.delete.submit" }) }} />
                    </Button>
                </Box>
            </FormikProvider>

            <Dialog TransitionComponent={Fade} open={open} onClose={handleClose} className={stylePopup.popup} scroll="body">
                <div className={stylePopup.container}>
                    <IconButton className={stylePopup.close} onClick={handleClose}>
                        <CrossIcon />
                    </IconButton>

                    <FormikProvider value={deleteAccountForm}>
                        <Box component="form" noValidate onSubmit={deleteAccountForm.handleSubmit}>
                            <Typography
                                variant="h5"
                                className={stylePopup.title}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.security.delete.popup.title" }) }}
                            />

                            <Typography
                                variant="p5"
                                sx={{ mb: 2 }}
                                className={stylePopup.text}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.security.delete.popup.description" }) }}
                            />

                            <Field
                                name="currentPassword"
                                children={(fieldProps: any) => (
                                    <TextField
                                        {...fieldProps.field}
                                        required
                                        fullWidth
                                        type="password"
                                        label={<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.security.delete.popup.password" }) }} />}
                                        variant="standard"
                                        error={!deleteAccountForm.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={!deleteAccountForm.isValid && fieldProps.meta.error}
                                    />
                                )}
                            />

                            <Grid container spacing={2} pt={{ xs: 2, sm: 4, md: 6 }}>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        sx={{ width: "100%" }}
                                        variant="contained"
                                        color="error"
                                        type="submit"
                                    >
                                        <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.security.delete.popup.submit" }) }} />
                                    </Button>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Button
                                        sx={{ width: "100%" }}
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleClose}
                                        >
                                        <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cancel" }) }} />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </FormikProvider>
                </div>
            </Dialog>
        </div>
    );
};

export default observer(Security);

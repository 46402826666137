import { makeAutoObservable } from "mobx";

export default class SearchBySpecialtiesModel {
    public availableLetters: string[];
    public specialties: object;

    constructor() {
        makeAutoObservable(this);
        this.availableLetters = [];
        this.specialties = {};
    }

    updateSpecialtiesByletter = (letter: string, specialties: object) => {
        (this.specialties as any)[letter] = specialties;
    };

    resetSpecialties = () => this.specialties = {};

    updateAvailableLetters = (letters: string[]) => {
        this.availableLetters = letters;
    };
}

import NewsModel from "domain/entity/news/NewsModel";
import NewsRepository from "domain/repository/news/NewsRepository";
import { ValidationResult } from "types";
import { SubmissionError } from "../CustomErrors";

export default class ManageNewsUseCase {
    private model: NewsModel;
    private repository: NewsRepository;

    constructor(newsModel: NewsModel, repository: NewsRepository) {
        this.model = newsModel;
        this.repository = repository;
    }

    validate = (letter: string): ValidationResult => {
        const errors: any = {
            letter: (() => {
                if (!letter) return "required";
            })(),
        };

        const isValid = Object.values(errors).filter((error) => !!error).length <= 0;

        return {
            errors: errors,
            isValid: isValid,
        };
    };

    loadNewsByParams = (params: any): Promise<void> => {
        return this.repository
            .load(params)
            .then((news) => {
                this.model.updateSearchResults(news);
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error.validationErrors, error.data.message));
            });
    };

    loadOtherNews = (id: string): Promise<void> => {
        return this.repository.loadOtherNews(id)
            .then((news) => {
                this.model.updateOtherNews(news);
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error.validationErrors, error.data.message));
            });
    };

    loadAvailableYears = (): Promise<number[]> => {
        if (this.model.availableYears.length) {
            return Promise.resolve(this.model.availableYears);
        } else {
            return this.repository
                .loadAvailableYears()
                .then((availableYears) => {
                    this.model.updateAvailableYears(availableYears);
                    return availableYears;
                })
                .catch((error) => {
                    return Promise.reject(new SubmissionError(error.validationErrors, error.data.message));
                });

        }
    };

    loadFreshNews = (): Promise<void> => {
        return this.loadAvailableYears()
            .then((availableYears) => {
                this.loadNewsByParams({ year:  Math.max.apply(Math, availableYears?.map((year) => year)) });
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error.validationErrors, error.data.message));
            });
    };

    loadPostById = (id: string): Promise<void> => {
        let findingNews = undefined;
        const currentLocale = localStorage.getItem("locale") ?? process.env.REACT_APP_DEFAULT_LOCALE;
        if (this.model.locale === currentLocale) {
            findingNews = this.model.searchResults.find(item => item.id === id);
        } else {
            this.model.resetPosts();
            // @ts-ignore
            this.model.locale = currentLocale;
        }

        if (findingNews !== undefined) {
            this.model.addNewsPost(findingNews);
            return Promise.resolve();
        } else {
            return this.repository
                .loadPostById(id)
                .then((post) => {
                    this.model.addNewsPost(post);
                })
                .catch((error) => {
                    return Promise.reject(new SubmissionError(error.validationErrors, error.data.message));
                });
        }
    };

}

import axios from "axios";


class SpecialitiesApi {
    getSpecialities(): Promise<[]> {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + `api/specialities?perPage=500`,
        }).then((doctors: any) => {
            return doctors.data;
        }).catch((error) => {
            console.log({error});
            return Promise.reject(error);
        });
    }

    getSpecialitiesForSearch(): Promise<[]> {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + `api/specialities?perPage=5&doctorsCount=DESC`,
        }).then((doctors: any) => {
            return doctors.data;
        }).catch((error) => {
            console.log({error});
            return Promise.reject(error);
        });
    }

}

export default new SpecialitiesApi();

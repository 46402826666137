import React, { useCallback, useRef, useState } from 'react';
import { TextField, Box, ButtonBase, IconButton } from '@mui/material';
import st from './FileInput.module.scss';
import { defaultTheme } from 'presentation/views/layouts/themes/primaryMuiTheme';
import { ReactComponent as FileIcon } from '../../assets/images/icons/file.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/cross-empty.svg';
import { useViewModels } from 'presentation/view-model/ViewModelProvider';

interface FileInputProps {
    label: string | React.ReactNode;
    placeholder?: string;
    acceptedExtensions?: string;
    handleChange?: (event: InputEvent, info: any) => void;
    error?: {
        message: string;
    };
}

const FileInput: React.FC<FileInputProps> = ({ placeholder, acceptedExtensions, label, handleChange, error, ...props }) => {
    const { mediaViewModel } = useViewModels();
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const [attachment, setAttachment] = useState<{ name?: string }>({ name: 'Load File' });
    const [isDisabled, setIsDisabled] = useState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const reader = new FileReader();

    const handleClick = useCallback(() => {
        hiddenFileInput.current?.click();
    }, []);

    const handleDelete = useCallback((event) => {
        event.stopPropagation();
        setIsDisabled(false);

        reader.abort();

        if (hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current.value = '';
        }
        setAttachment({ name: 'Load File' });
    }, [reader]);

    const handleChangeFile = useCallback(
        (event) => {
            const target = event.target;
            const file = target.files[0];
            let fileInfo = null;
            setIsDisabled(true);

            setAttachment(file);

            reader.readAsDataURL(file);

            reader.onload = () => {
                console.log(reader);
                fileInfo = mediaViewModel.addMedia(reader.result);
            };

            reader.onerror = () => {
                console.log(reader.error);
            };

            if (!!handleChange) {
                handleChange(event, fileInfo);
            }
        },
        [handleChange, mediaViewModel, reader]
    );

    return (
        <Box position="relative" className={st.box} height={70} color={!!error ? defaultTheme.palette.error.main : defaultTheme.palette.primary.contrastText}>
            <TextField
                variant="standard"
                className={st.field}
                fullWidth
                label={label}
                value={attachment?.name || ''}
                error={!!error}
                helperText={error?.message || ' '}
            />
            <ButtonBase className={st.button} component="label" onClick={handleClick} disabled={isDisabled}>
                {hiddenFileInput.current?.value ? (
                    <IconButton className={st.button__deleteicon}>
                        <CloseIcon height="12px" onClick={handleDelete} />
                    </IconButton>
                ) : (
                    <FileIcon className={st.button__fileicon} />
                )}
            </ButtonBase>
            <input
                className={st.input}
                ref={hiddenFileInput}
                type="file"
                disabled={isDisabled}
                accept={acceptedExtensions}
                placeholder={placeholder}
                hidden
                onChange={handleChangeFile}
                {...props}
            />
        </Box>
    );
};

export default FileInput;

import { computed, makeAutoObservable, action } from "mobx";
import UserModel from "domain/entity/user/UserModel";
import LoadUserUseCase from "domain/interactors/user/LoadUserUseCase";
import validator from "validator";
import cleanDeep from "clean-deep";

// @TODO (separate this to different stores)
export default class UserViewModel {
    public userModel: UserModel;
    private loadUserUseCase: LoadUserUseCase;
    public isSync: boolean;
    public locale: string;

    constructor(userModel: UserModel, loadUserUseCase: LoadUserUseCase) {
        makeAutoObservable(this);
        this.userModel = userModel;
        this.loadUserUseCase = loadUserUseCase;
        // @ts-ignore
        this.locale = localStorage.getItem('locale') ?? process.env.REACT_APP_DEFAULT_LOCALE;
        this.isSync = false;
    }

    @computed get fullName(): string {
        return this.userModel.email;
    }

    @computed get identifier(): string {
        return this.userModel.id;
    }

    @action loadUser = (): Promise<any> => {
        this.isSync = true;
        return this.loadUserUseCase.start().finally(
            action(() => {
                if (this.userModel?.defaultLocale) {
                    this.changeLocale(this.userModel.defaultLocale);
                }
                this.isSync = false;
            })
        );
    };

    @action changeLocale = (locale: string) => {
        window.history.pushState({}, "", (locale === "de" ? window.location.pathname.slice(3) : ("/" + locale + window.location.pathname)) + (window.location.search ? window.location.search : ""));
        this.locale = locale;
        localStorage.setItem('locale', locale);
    };

    @computed localeUrl = (value: any) => {
        return ((this.locale && this.locale !== process.env.REACT_APP_DEFAULT_LOCALE ? "/" + this.locale : '') + value);
    }

    public validateAccount = (values: any): object => {
        const errors: any = {
            email: (() => {
                if (!validator.isEmail(values.email)) return "Invalid email";
            })()
        };
        // убираем пустые поля
        return cleanDeep(errors);
    };

}

// @ts-nocheck
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { TextField, Typography, Grid, Autocomplete, Box } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { useViewModels } from "presentation/view-model/ViewModelProvider";
import SpecialitiesViewModel from "../../../../../../view-model/specialities/SpecialitiesViewModel";
import { UserSaveButtons } from "../../../../../components/UserSaveButtons";
import st from "./specialities.module.scss";
import { NonAuthRoutes } from "presentation/routes/routes";


const Specialities: React.FC = ({ changeDetector, setTab, congratulationsDetector }) => {
    const intl = useIntl();
    const {
        doctorProfileViewModel: { options, onSaveProfileButtonClick },
        uIViewModel: { setSuccessAlertContent, setErrorAlertContent, },
        userViewModel: { locale },
    } = useViewModels();
    const [saveAndNext, setSaveAndNext] = useState(false);

    const [specialitiesOptions, setSpecialitiesOptions] = useState([]);
    let [selectedValues, setSelectedValues] = useState(options.specialities);

    const changeForm = useFormik({
        initialValues: {
            specialities: (options?.specialities.length ? options.specialities.map(item => `api/specialities/${item.id}`) : []),
        },

        onSubmit: (values, { setErrors }) => {
            setSuccessAlertContent('', true);
            setErrorAlertContent('', true);

            onSaveProfileButtonClick(values)
                .then((result) => {
                    if (!options.active && result.active) {
                        congratulationsDetector();
                    }

                    if (saveAndNext) {
                        setSaveAndNext(false);
                        setTab('saveAndNext', '', false, true);
                    }
                    changeDetector(false);
                    setSuccessAlertContent(intl.formatMessage({id: "successSave"}));
                })
                .catch((error) => {
                    console.log(error);
                    setErrorAlertContent(intl.formatMessage({id: "errorSave"}));
                    if (error.errors) {
                        setErrors(error.errors);
                    }
                });
        },
    });

    useEffect(() => {
        let isMounted = true;

        if (SpecialitiesViewModel.specialities.length === 0 || SpecialitiesViewModel.locale !== locale) {
            SpecialitiesViewModel.getSpecialitiesPromise()
                .then(data => {
                    if (isMounted) {
                        const selectSpecialitiesIds = selectedValues.map(item => item.id);
                        const localeSelectSpecialities = data.items.filter(item => selectSpecialitiesIds.includes(item.id));
                        SpecialitiesViewModel.specialities = data.items;
                        setSpecialitiesOptions(data.items);
                        setSelectedValues(localeSelectSpecialities);
                        SpecialitiesViewModel.locale = locale;
                    }
                });
        } else {
            if (isMounted) {
                setSpecialitiesOptions(SpecialitiesViewModel.specialities);
            }
        }
        return () => { isMounted = false };
        // eslint-disable-next-line
    }, [locale]);

    return (
        <FormikProvider value={changeForm}>
            <Box component="form" noValidate onSubmit={changeForm.handleSubmit}>
                <Typography
                    variant="h5"
                    sx={{ mb: 5 }}
                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.profile.doctor.specialties.title" }) }}
                />

                <Grid container spacing={4} sx={{mb: 4}}>
                    <Grid item xs={12} lg={9}>
                        <Autocomplete
                            multiple
                            className={st.autocomplete_withButtons}
                            filterSelectedOptions
                            getOptionLabel={option => (option?.name ? option.name : "")}
                            isOptionEqualToValue={(option, value) => value.name === "" || option.name === value.name}
                            options={specialitiesOptions}
                            noOptionsText={
                                <Typography variant="body1">
                                    {intl.formatMessage({ id: "cabinet.profile.doctor.specialties.contacts" })}
                                    <Link to={(locale && locale !== 'de' ? '/' + locale : '') + NonAuthRoutes.contacts} className={st.link} target="_blank" rel="noopener noreferrer">
                                        {intl.formatMessage({ id: "cabinet.profile.doctor.specialties.contacts.link" })}
                                    </Link>
                                </Typography>
                            }
                            value={selectedValues}
                            onChange={(e, newValue) => {
                                changeDetector(true);
                                changeForm.setFieldValue(`specialities`, newValue.map(item => `api/specialities/${item.id}`));
                                setSelectedValues(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label={intl.formatMessage({id: "cabinet.profile.doctor.specialties.specialty"})}
                                    type="text"
                                    variant="standard"
                                />
                            )}
                        />
                    </Grid>
                    {/* <Grid item xs={12} lg={9}>
                        <Typography variant="body1">
                            {intl.formatMessage({ id: "cabinet.profile.doctor.specialties.contacts" })}
                            <Link to={(locale && locale !== 'de' ? '/' + locale : '') + NonAuthRoutes.contacts} className={st.link} target="_blank" rel="noopener noreferrer">
                                {intl.formatMessage({ id: "cabinet.profile.doctor.specialties.contacts.link" })}
                            </Link>
                        </Typography>
                    </Grid> */}
                </Grid>

                <UserSaveButtons setSaveAndNext={setSaveAndNext} />
            </Box>

            {/*<pre style={{color: "saddlebrown", overflow: 'hidden', width: '100%'}}>{JSON.stringify(changeForm.values, 0, 2)}</pre>*/}
        </FormikProvider>
    );
};

export default observer(Specialities);

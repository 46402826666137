import React from "react";
import st from "./newsPreview.module.scss";

interface NewsPreviewProps {
    date: string;
    title: string;
}

export const NewsPreview: React.FC<NewsPreviewProps> = ({ date, title }) => {
    return (
        <div className={st.preview}>
            <div className={st.date}>{date}</div>
            <div className={st.title}>{title}</div>
        </div>
    );
};

// @ts-nocheck
import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";
import { observer } from "mobx-react";
import { Box, Grid, Button, ClickAwayListener, IconButton, TextField, Typography, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import TooltipedCard from "presentation/views/components/TooltipedCard";
import EntityInfo from "presentation/views/components/EntityInfo";
import { defaultTheme } from "presentation/views/layouts/themes/primaryMuiTheme";
import { useViewModels } from "presentation/view-model/ViewModelProvider";
import { Link } from 'react-router-dom';
import { AuthRoutes, NonAuthRoutes } from 'presentation/routes/routes';
import {ConditionalWrapper} from "../ConditionalWrapper";

import stDashboard from "../../Private/Cabinet/Common/Dashboard/dashboard.module.scss";
import st from "./appointmentList.module.scss";
import { ReactComponent as MoreIcon } from "presentation/views/assets/images/icons/more.svg";
import { ReactComponent as PhoneIcon } from "presentation/views/assets/images/icons/phone_18b.svg";
import axios from "axios";
import { Popup } from "../Popup";
import { Field, FormikProvider, useFormik } from "formik";
import { UserRoles } from "../../../../types";
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/arrow.svg";
import getAge from "../../helpers/getAge";
import stylePopup from "presentation/views/components/Popup/popup.module.scss";
import classNames from "classnames";

const AppointmentList = ({
         appointmentsType,
         pageType = string,
         activeAppointmentCard,
         setActiveAppointmentCard,
         setTypeActiveAppointmentCard = (Function | null),
     }) => {
    const intl = useIntl();
    const {
        uIViewModel: { openPopup, closePopup }, appointmentViewModel, authViewModel,
        userViewModel: {locale},
    } = useViewModels();
    const [successCancelAppointment, setSuccessCancelAppointment] = useState(false);
    const [cardMenuIndexActive, setCardMenuIndexActive] = useState<number | null>(null);
    const [cardChangeStatusActive, setCardChangeStatusActive] = useState<any>(null);
    const [cardChangeStatusType, setCardChangeStatusType] = useState<string>('canceled');
    const typeAppointmentCard = (authViewModel.userRoles[0] === UserRoles.doctor) ? "patient" : "doctor";
    const typeAppointmentsNoTitle = appointmentsType === "upcomingAppointments" ? "noUpcoming" : "noPast";
    const appointmentsList = ['dashboard', 'patientId'].includes(pageType) ? appointmentViewModel[appointmentsType].slice(0, 3) : appointmentViewModel[appointmentsType];
    const cancelInputCustom = useRef(null);

    const handleCardMenuClickAway = (event: MouseEvent | TouchEvent) => {
        const activeCard: HTMLElement | null = document.querySelector(`.cardMenu_${cardMenuIndexActive}`);

        if (!activeCard?.contains(event.target as Node)) {
            setCardMenuIndexActive(null);
        }
    };

    const cancelAppointment = useFormik({
        enableReinitialize: true,
        validate: appointmentViewModel.validatePutCancelAppointment,
        initialValues: {
            appointmentId: (cardChangeStatusActive?.id ?? ""),
            cancellationReason: "",
        },
        onSubmit: async (values, { setErrors }) => {
            appointmentViewModel.putStatusChangeAppointment(values.appointmentId, 'cancel', 'canceled', values.cancellationReason)
                .then((e) => {
                    setSuccessCancelAppointment(true);
                });
        },
    });

    const completeAppointment = useFormik({
        enableReinitialize: true,
        validate: appointmentViewModel.validatePutCompleteAppointment,
        initialValues: {
            appointmentId: (cardChangeStatusActive?.id ?? ""),
        },
        onSubmit: async (values, { setErrors }) => {
            appointmentViewModel.putStatusChangeAppointment(values.appointmentId, 'complete', 'completed')
                .then((e) => {
                    setSuccessCancelAppointment(true);
                });
        },
    });

    function formatHumanDateTime(date: string, time: string) {
        const dateHuman = new Date(date);
        const timeHuman = new Date(time);

        const monthDayUtc = dateHuman.getUTCDate();
        dateHuman.setUTCDate(monthDayUtc);

        const weekday = intl.formatDate(dateHuman, { weekday: "short" });
        const monthDay = intl.formatDate(dateHuman, { month: "long", day: "numeric" });
        const hour = timeHuman.getUTCHours();
        const min = timeHuman.getUTCMinutes();

        const dateFormatted = `${weekday}, ${monthDay}`;
        const timeFormatted = `${hour}:${(min < 10 ? "0" : "")}${min}`;

        return intl.formatMessage({ id: "cabinet.appointments.dateTime" }, { date: dateFormatted, time: timeFormatted });
    }

    return (
        <>
            {appointmentsType === "upcomingAppointments" &&
                <Popup>
                    {successCancelAppointment ?
                        <>
                            <Typography variant="h5" className={stylePopup.title}>
                                {intl.formatMessage({ id: `appointments.${cardChangeStatusType}.successTitle` })}
                            </Typography>

                            <Typography variant="p5" className={stylePopup.text}>
                                {intl.formatMessage({ id: `appointments.${cardChangeStatusType}.successSubtitle` })}
                            </Typography>

                            <Button type="button" color="secondary" sx={{ mt: {xs: 2, sm: 4, md: 6}, width: "100%" }} variant="contained"
                                    onClick={closePopup}
                            >
                                { intl.formatMessage({ id: "ok" }) }
                            </Button>
                        </>
                        :
                        <>
                            <Typography variant="h5" className={stylePopup.title}>
                                {intl.formatMessage({ id: `appointments.${cardChangeStatusType}.title` })}
                            </Typography>

                            {cardChangeStatusType === 'completed' ?
                                <FormikProvider value={completeAppointment}>
                                    <Box component="form" onSubmit={completeAppointment.handleSubmit}>
                                        <Button type="submit" color="secondary" sx={{ mt: {xs: 2, sm: 4, md: 6}, width: "100%" }} variant="contained">
                                            {intl.formatMessage({ id: "submit" })}
                                        </Button>
                                    </Box>
                                </FormikProvider>
                                :
                                <>
                                    <Typography variant="p5" className={stylePopup.text} sx={{ mb: 4 }}>
                                        {intl.formatMessage({ id: `appointments.${cardChangeStatusType}.subtitle` })}
                                    </Typography>

                                    <RadioGroup aria-label="cancellation Reason" className={st.radio_group}>
                                        {typeAppointmentCard === "patient" ?
                                            <>
                                                <FormControlLabel
                                                    className={st.reason_cancellation}
                                                    value={intl.formatMessage({ id: "cabinet.appointments.cancel.variant_7" })}
                                                    control={<Radio
                                                        onChange={(e) => cancelAppointment.setFieldValue("cancellationReason", e.currentTarget.value)}
                                                        value={intl.formatMessage({ id: "cabinet.appointments.cancel.variant_7" })} />}
                                                    label={
                                                        <span>{intl.formatMessage({ id: "cabinet.appointments.cancel.variant_7" })}</span>
                                                    }
                                                />
                                                <FormControlLabel
                                                    className={st.reason_cancellation}
                                                    value={intl.formatMessage({ id: "cabinet.appointments.cancel.variant_6" })}
                                                    control={<Radio
                                                        onChange={(e) => cancelAppointment.setFieldValue("cancellationReason", e.currentTarget.value)}
                                                        value={intl.formatMessage({ id: "cabinet.appointments.cancel.variant_6" })} />}
                                                    label={
                                                        <span>{intl.formatMessage({ id: "cabinet.appointments.cancel.variant_6" })}</span>
                                                    }
                                                />
                                            </>
                                            :
                                            <>
                                                <FormControlLabel
                                                    className={st.reason_cancellation}
                                                    value={intl.formatMessage({ id: "cabinet.appointments.cancel.variant_1" })}
                                                    control={<Radio
                                                        onChange={(e) => cancelAppointment.setFieldValue("cancellationReason", e.currentTarget.value)}
                                                        value={intl.formatMessage({ id: "cabinet.appointments.cancel.variant_1" })} />}
                                                    label={
                                                        <span>{intl.formatMessage({ id: "cabinet.appointments.cancel.variant_1" })}</span>
                                                    }
                                                />
                                                <FormControlLabel
                                                    className={st.reason_cancellation}
                                                    value={intl.formatMessage({ id: "cabinet.appointments.cancel.variant_2" })}
                                                    control={<Radio
                                                        onChange={(e) => cancelAppointment.setFieldValue("cancellationReason", e.currentTarget.value)}
                                                        value={intl.formatMessage({ id: "cabinet.appointments.cancel.variant_2" })} />}
                                                    label={
                                                        <span>{intl.formatMessage({ id: "cabinet.appointments.cancel.variant_2" })}</span>
                                                    }
                                                />

                                                <FormControlLabel
                                                    className={st.reason_cancellation}
                                                    value={intl.formatMessage({ id: "cabinet.appointments.cancel.variant_3" })}
                                                    control={<Radio
                                                        onChange={(e) => cancelAppointment.setFieldValue("cancellationReason", e.currentTarget.value)}
                                                        value={intl.formatMessage({ id: "cabinet.appointments.cancel.variant_3" })} />}
                                                    label={
                                                        <span>{intl.formatMessage({ id: "cabinet.appointments.cancel.variant_3" })}</span>
                                                    }
                                                />

                                                <FormControlLabel
                                                    className={st.reason_cancellation}
                                                    value={intl.formatMessage({ id: "cabinet.appointments.cancel.variant_5" })}
                                                    control={<Radio
                                                        onChange={(e) => cancelAppointment.setFieldValue("cancellationReason", e.currentTarget.value)}
                                                        value={intl.formatMessage({ id: "cabinet.appointments.cancel.variant_5" })} />}
                                                    label={
                                                        <span>{intl.formatMessage({ id: "cabinet.appointments.cancel.variant_5" })}</span>
                                                    }
                                                />

                                                <FormControlLabel
                                                    className={st.reason_cancellation}
                                                    value={intl.formatMessage({ id: "cabinet.appointments.cancel.variant_6" })}
                                                    control={<Radio
                                                        onChange={(e) => cancelAppointment.setFieldValue("cancellationReason", e.currentTarget.value)}
                                                        value={intl.formatMessage({ id: "cabinet.appointments.cancel.variant_6" })} />}
                                                    label={
                                                        <span>{intl.formatMessage({ id: "cabinet.appointments.cancel.variant_6" })}</span>
                                                    }
                                                />
                                            </>
                                        }
                                        <FormControlLabel
                                            className={st.reason_cancellation}
                                            value={intl.formatMessage({ id: "cabinet.appointments.cancel.variant_4" })}
                                            onClick={() => {
                                                cancelAppointment.setFieldValue("cancellationReason", "");
                                                cancelInputCustom.current?.focus();
                                            }}
                                            control={<Radio />}
                                            label={
                                                <span>{intl.formatMessage({ id: "cabinet.appointments.cancel.variant_4" })}</span>
                                            }
                                        />
                                    </RadioGroup>

                                    <FormikProvider value={cancelAppointment}>
                                        <Box component="form" onSubmit={cancelAppointment.handleSubmit}>
                                            <Field
                                                name="cancellationReason"
                                                children={(fieldProps: any) => (
                                                    <TextField
                                                        {...fieldProps.field}
                                                        inputRef={cancelInputCustom}
                                                        fullWidth
                                                        label={intl.formatMessage({ id: "cabinet.appointments.cancel.reason" })}
                                                        type="text"
                                                        variant="standard"
                                                        helperText={cancelAppointment.touched.cancellationReason && !cancelAppointment.isValid && fieldProps.meta.error}
                                                        error={cancelAppointment.touched.cancellationReason && !cancelAppointment.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                    />
                                                )}
                                            />

                                            <Button type="submit" color="secondary" sx={{ mt: {xs: 2, sm: 4, md: 6}, width: "100%" }}
                                                    disabled={cancelAppointment.values.cancellationReason.length === 0} variant="contained">
                                                {intl.formatMessage({ id: "submit" })}
                                            </Button>
                                        </Box>
                                    </FormikProvider>
                                </>
                            }

                            {/*<pre style={{color: "black", fontSize: '18px', marginTop: '10%'}}>{JSON.stringify(cardChangeStatusType === 'completed' ? completeAppointment.values : cancelAppointment.values, 0, 2)}</pre>*/}
                        </>
                    }
                </Popup>
            }

            {activeAppointmentCard ?
                <>
                    <div className={stDashboard.back_to_dashboard} onClick={() => setActiveAppointmentCard(null)}>
                        <ArrowIcon />
                        {intl.formatMessage({id: "back"})}
                    </div>

                    <div className={st.active_card}>
                        <div className={st.active_card_top}>
                            <ConditionalWrapper
                                condition={typeAppointmentCard === 'doctor'}
                                wrapper={children => <Link to={`${NonAuthRoutes.doctors}/${activeAppointmentCard[typeAppointmentCard].id}`}>{children}</Link>}
                            >
                                <EntityInfo
                                    noHover={true}
                                    fio={activeAppointmentCard[typeAppointmentCard].profile?.firstName && activeAppointmentCard[typeAppointmentCard].profile.firstName.charAt(0) +
                                    (activeAppointmentCard[typeAppointmentCard].profile?.lastName && activeAppointmentCard[typeAppointmentCard].profile.lastName.charAt(0))}
                                    avatarSize={64}
                                    pictureUrl={activeAppointmentCard[typeAppointmentCard].profile?.avatar?.contentUrl !== undefined ?
                                        axios.defaults.baseURL + activeAppointmentCard[typeAppointmentCard].profile.avatar.contentUrl
                                        : ""
                                    }
                                >
                                    <Typography variant="t3" color={defaultTheme.palette.primary.dark} className={st.active_card_text_item}>
                                        {typeAppointmentCard === 'patient' ?
                                            activeAppointmentCard[typeAppointmentCard].profile?.firstName + ' ' + activeAppointmentCard[typeAppointmentCard].profile?.lastName
                                            :
                                            (activeAppointmentCard[typeAppointmentCard]?.title ? activeAppointmentCard[typeAppointmentCard].title + ' ' : '') +
                                            (activeAppointmentCard[typeAppointmentCard]?.profile?.firstName ? activeAppointmentCard[typeAppointmentCard].profile.firstName + ' ' : '') +
                                            (activeAppointmentCard[typeAppointmentCard]?.profile?.lastName ? activeAppointmentCard[typeAppointmentCard].profile.lastName : '') +
                                            (activeAppointmentCard[typeAppointmentCard]?.mCredential ? ', ' + activeAppointmentCard[typeAppointmentCard].mCredential : '')
                                        }
                                    </Typography>

                                    <Typography variant="t3s" color={defaultTheme.palette.primary.light1}>
                                        {typeAppointmentCard === 'patient' ?
                                            getAge(activeAppointmentCard[typeAppointmentCard].profile?.birthDate) + ' ' + intl.formatMessage({ id: "age" }) + ' ' +  activeAppointmentCard[typeAppointmentCard].profile?.gender
                                        :
                                            `${(activeAppointmentCard[typeAppointmentCard].specialities?.length && (activeAppointmentCard[typeAppointmentCard].specialities[0].name ?? "")) ?? ''}`
                                        }
                                    </Typography>

                                    {typeAppointmentCard === 'patient' ?
                                        <div className={st.active_card_phone}>
                                            <Typography variant="t2news" color={defaultTheme.palette.primary.light}>{intl.formatMessage({ id: "phone" })}</Typography>&nbsp;
                                            <Typography variant="t2news">{activeAppointmentCard.patientPhoneNumber ?? ''}</Typography>
                                        </div>
                                        :
                                        <Typography variant="t3s" color={defaultTheme.palette.secondary.main}>
                                            {activeAppointmentCard[typeAppointmentCard].hourCost ?? ''}
                                        </Typography>
                                    }
                                </EntityInfo>
                            </ConditionalWrapper>

                            {appointmentsType === "upcomingAppointments" ?
                                <div className={st.buttons}>
                                    <ClickAwayListener onClickAway={(e: MouseEvent | TouchEvent) => handleCardMenuClickAway(e)}>
                                        <div className={`cardMenu_1000 ${st.card_menu} ${1000 === cardMenuIndexActive ? st.card_menu_active : ""}`}
                                             onClick={() => (1000 === cardMenuIndexActive ? setCardMenuIndexActive(null) : setCardMenuIndexActive(1000))}
                                        >
                                            <IconButton>
                                                <MoreIcon height="16px" />
                                            </IconButton>

                                            <div className={classNames(st.cancel_appointment,
                                                (activeAppointmentCard.appointmentByCall && typeAppointmentCard === "patient" ? st.cancel_with_button : '')
                                            )}>
                                                <div
                                                    onClick={() => {
                                                        setCardChangeStatusActive(activeAppointmentCard);
                                                        setSuccessCancelAppointment(false);
                                                        openPopup();
                                                    }}
                                                >
                                                    {intl.formatMessage({ id: "cabinet.appointments.cancel" })}
                                                </div>
                                            </div>
                                        </div>
                                    </ClickAwayListener>

                                    {activeAppointmentCard.appointmentByCall && typeAppointmentCard === "patient" &&
                                        <Button variant="contained" size="small" color="secondary"
                                                className={st.card_menu_complete}
                                                sx={{mr: 2.5}}
                                                onClick={() => {
                                                    setCardChangeStatusActive(activeAppointmentCard);
                                                    setSuccessCancelAppointment(false);
                                                    openPopup();
                                                }}
                                        >
                                            {intl.formatMessage({ id: "cabinet.appointments.complete" })}
                                        </Button>
                                    }
                                </div>
                                :
                                <Typography variant="button" sx={{ textTransform: "capitalize" }} color={activeAppointmentCard.status === 'canceled' ? defaultTheme.palette.primary.error : defaultTheme.palette.action.disabled}>
                                    {activeAppointmentCard.status !== 'new' ?
                                        intl.formatMessage({ id: `appointments.${activeAppointmentCard.status}` })
                                        : activeAppointmentCard.status}
                                </Typography>

                            }
                        </div>

                        <div className={st.active_card_bottom}>
                            <div className={st.active_card_bottom_item}>
                                <Typography variant="label" color={defaultTheme.palette.primary.light1}>
                                    {intl.formatMessage({ id: "cabinet.appointments.card.date" })}
                                </Typography>

                                <Typography variant="t3" color={defaultTheme.palette.primary.dark}>
                                    {activeAppointmentCard.appointmentByCall ?
                                        <>
                                            {formatHumanDateTime(activeAppointmentCard.createdAt, activeAppointmentCard.createdAt)}
                                            <div style={{marginTop: 3}}>({intl.formatMessage({ id: "appointmentByCall" })})</div>
                                        </>
                                        :
                                        formatHumanDateTime(activeAppointmentCard.date, activeAppointmentCard.time)
                                    }
                                </Typography>
                            </div>

                            <div className={st.active_card_bottom_item}>
                                <Typography variant="label" color={defaultTheme.palette.primary.light1}>
                                    {intl.formatMessage({ id: "cabinet.appointments.card.reason" })}
                                </Typography>

                                <Typography variant="t3" color={defaultTheme.palette.primary.dark}>
                                    {activeAppointmentCard.reason}
                                </Typography>
                            </div>
                        </div>

                        <div className={st.active_card_bottom}>
                            {activeAppointmentCard.cancellationReason && (
                                <div className={st.active_card_bottom_item}>
                                    <Typography variant="label" color={defaultTheme.palette.primary.light1}>
                                        {intl.formatMessage({ id: "cabinet.appointments.card.cancellation" })}
                                    </Typography>

                                    <Typography variant="t3" color={defaultTheme.palette.primary.dark}>
                                        {activeAppointmentCard.cancellationReason}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </div>

                    <Typography variant="inputText" sx={{mt: {xs: 3, md: 6}, display: 'block'}}>
                        <a className={st.active_card_link} target="_blank" rel="noopener noreferrer"
                           href={`https://www.google.com/maps/place/${activeAppointmentCard?.clinicLocation}`}>Link on Google Maps</a>
                    </Typography>
                </>
                :
                appointmentViewModel[appointmentsType].length > 0 ?
                    <>
                        {appointmentsList.map((item, index) => (
                            <TooltipedCard
                                key={item.id}
                                variant="bordered"
                                tooltip={
                                    appointmentsType === "upcomingAppointments" ?
                                        <div className={st.buttons}>
                                            <ClickAwayListener onClickAway={(e: MouseEvent | TouchEvent) => handleCardMenuClickAway(e)}>
                                                <div
                                                    className={`cardMenu_${index} ${st.card_menu} ${index === cardMenuIndexActive ? st.card_menu_active : ""}`}
                                                    onClick={() => (index === cardMenuIndexActive ? setCardMenuIndexActive(null) : setCardMenuIndexActive(index))}
                                                >
                                                    <IconButton>
                                                        <MoreIcon height="16px" />
                                                    </IconButton>

                                                    <div className={st.cancel_appointment}>
                                                        <div
                                                            onClick={() => {
                                                                setCardChangeStatusActive(item);
                                                                setSuccessCancelAppointment(false);
                                                                setCardChangeStatusType("canceled");
                                                                const checkedOption = document.querySelector(".cancellationReason:checked");
                                                                checkedOption && (checkedOption.checked = false);
                                                                openPopup();
                                                            }}
                                                        >
                                                            {intl.formatMessage({ id: "cabinet.appointments.cancel" })}
                                                        </div>


                                                    </div>

                                                </div>
                                            </ClickAwayListener>

                                            {item.appointmentByCall &&
                                                <div className={st.appointment_complete}>
                                                    {typeAppointmentCard === "patient" &&
                                                        <Button variant="contained" size="small" color="secondary"
                                                                className={st.card_menu_complete}
                                                                sx={{mr: 2.5}}
                                                                onClick={() => {
                                                                    setCardChangeStatusActive(item);
                                                                    setSuccessCancelAppointment(false);
                                                                    setCardChangeStatusType("completed");
                                                                    openPopup();
                                                                }}
                                                        >
                                                            {intl.formatMessage({ id: "cabinet.appointments.complete" })}
                                                        </Button>
                                                    }

                                                    <PhoneIcon className={st.card_menu_icon} />
                                                </div>
                                            }
                                        </div>
                                        : ""
                                }
                            >
                                <div
                                    className={st.tooltiped_item}
                                    onClick={() => {
                                        setActiveAppointmentCard(appointmentViewModel[appointmentsType][index]);
                                        setTypeActiveAppointmentCard && setTypeActiveAppointmentCard(appointmentsType);
                                    }}>
                                    <EntityInfo
                                        fio={item[typeAppointmentCard].profile?.firstName && item[typeAppointmentCard].profile.firstName.charAt(0) +
                                        (item[typeAppointmentCard].profile?.lastName && item[typeAppointmentCard].profile.lastName.charAt(0))}
                                        avatarSize={64}
                                        pictureUrl={item[typeAppointmentCard].profile?.avatar?.contentUrl !== undefined ?
                                            axios.defaults.baseURL + item[typeAppointmentCard].profile.avatar.contentUrl
                                            : ""
                                        }>
                                        <Typography variant="t3" color={defaultTheme.palette.primary.dark}>
                                            {item.appointmentByCall ?
                                                intl.formatMessage({id: "appointmentByCall"})
                                                :
                                                formatHumanDateTime(item.date, item.time)
                                            }

                                            {item.status !== "new" && (
                                                <div className={classNames(st.tooltiped_status, item.status === 'canceled' ? 'error' : '')}>
                                                    {intl.formatMessage({ id: `appointments.${item.status}` })}
                                                </div>
                                            )}
                                        </Typography>

                                        <Typography variant="t3s" color={defaultTheme.palette.primary.light1}>
                                            {typeAppointmentCard === 'patient' ?
                                                item[typeAppointmentCard].profile?.firstName + ' ' + item[typeAppointmentCard].profile?.lastName +
                                                (item.patientPhoneNumber ? `, ${intl.formatMessage({ id: "phone" }).toLowerCase()} ${item.patientPhoneNumber}` : '')
                                                :
                                                item[typeAppointmentCard].label
                                            }
                                        </Typography>

                                        <Typography variant="t2news" color={defaultTheme.palette.primary.dark}>
                                            {item.reason}
                                        </Typography>
                                    </EntityInfo>
                                </div>
                            </TooltipedCard>
                        ))}

                        {['dashboard', 'patientId'].includes(pageType) &&
                            <Grid item xs={12} mt={{ xs: 2, sm: 3, md: 4, lg: 5 }}>
                                <Link to={(locale && locale !== 'de' ? '/' + locale : '') + AuthRoutes.bookingAppointments}>
                                    <Button color="secondary" variant="contained">
                                        {intl.formatMessage({ id: 'cabinet.appointments.all' })}
                                    </Button>
                                </Link>
                            </Grid>
                        }
                    </>
                    :
                    <Grid container>
                        <Grid item xs={12}>
                            <div
                                className={st.empty_dashboard}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `cabinet.appointments.${typeAppointmentsNoTitle}` }) }}
                            />
                        </Grid>

                        {pageType === 'patientId' &&
                            <Grid item xs={12} mt={5}>
                                <Link to={(locale && locale !== 'de' ? '/' + locale : '') + NonAuthRoutes.doctors}>
                                    <Button variant="contained" color="secondary">
                                        {intl.formatMessage({ id: 'cabinet.appointments.new' })}
                                    </Button>
                                </Link>
                            </Grid>
                        }
                    </Grid>
                }
        </>
    );
};

export default observer(AppointmentList);

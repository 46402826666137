import React from "react";
import st from "./spinner.module.scss";


export const Spinner = () => {
    return (
        <div className={st.container}>
            &nbsp;
            <svg className={st.spinner} viewBox="0 0 50 50">
                <circle className={st.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
            </svg>
        </div>

    );
};

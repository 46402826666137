// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { defaultTheme } from 'presentation/views/layouts/themes/primaryMuiTheme';
import st from './scheduleSettings.module.scss';
import stUserEdit from '../../../../../Private/UserEdit/userEdit.module.scss';
import { ReactComponent as PlusIcon } from 'presentation/views/assets/images/icons/plus.svg';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EntityInfo from 'presentation/views/components/EntityInfo';
import { FormControlLabel, Typography, Button, MenuItem, Grid, FormControl, Switch, TextField, IconButton } from '@mui/material';
import { useViewModels } from 'presentation/view-model/ViewModelProvider';
import classNames from 'classnames';
import { configure } from "mobx";

configure({
    enforceActions: "never"
});


const ScheduleSettings = ({ changeDetector, setTab, congratulationsDetector }) => {
    const intl = useIntl();
    const {
        scheduleViewModel,
        userViewModel: { identifier, locale },
        doctorProfileViewModel,
        uIViewModel: { setSuccessAlertContent, setErrorAlertContent },
    } = useViewModels();
    const doctorId = identifier;
    const user = doctorProfileViewModel.model.options;

    const workingDays = [
        {
            name: locale === 'de' ? 'Mon' : 'Mon',
            day: 1,
            isWorking: true,
        },
        {
            name: locale === 'de' ? 'Die' : 'Tue',
            day: 2,
            isWorking: false,
        },
        {
            name: locale === 'de' ? 'Mit' : 'Wed',
            day: 3,
            isWorking: true,
        },
        {
            name: locale === 'de' ? 'Don' : 'Thu',
            day: 4,
            isWorking: false,
        },
        {
            name: locale === 'de' ? 'Fre' : 'Fri',
            day: 5,
            isWorking: true,
        },
        {
            name: locale === 'de' ? 'Sam' : 'Sat',
            day: 6,
            isWorking: false,
        },
        {
            name: locale === 'de' ? 'Son' : 'Sun',
            day: 7,
            isWorking: false,
        },
    ];

    const handleIsByCallChange = (event: any, newValue: any) => {
        changeDetector(true);
        scheduleViewModel.scheduleSettings.appointmentByCall = newValue;
    };

    const handleIsNoOrdination = (event: any, newValue: any) => {
        changeDetector(true);
        scheduleViewModel.scheduleSettings.noOrdination = newValue;
    };

    const generateTimes = () => {
        let times = [];
        for (let i = 8; i <= 23; i++) {
            if (i < 10) {
                times.push(`0${i}:00`);
            } else {
                times.push(`${i}:00`);
            }

            if (i === 23) {
                break;
            }

            let startTimeStr: any = times[times.length - 1].split(':')[0];

            for (let j = 1; j <= 5; j++) {
                times.push(`${startTimeStr}:${j}0`);
            }
        }

        return times;
    };

    const addBreak = () => {
        changeDetector(true);
        if (scheduleViewModel.scheduleSettings.workingTimeSettingsCommon) {
            scheduleViewModel.scheduleSettings.workingTimeSettingsCommon.breaks.push({
                timeFrom: '08:00',
                timeTill: '08:00',
            });
        }
    };

    const removeBreak = (breakIndex: number) => {
        changeDetector(true);
        if (scheduleViewModel.scheduleSettings.workingTimeSettingsCommon) {
            scheduleViewModel.scheduleSettings.workingTimeSettingsCommon.breaks.splice(breakIndex, 1);
        }
    };

    const handleWorkingDaysChange = (day: String) => {
        changeDetector(true);
        const workingDaysCopy = [...workingDays];

        const changedDay = workingDaysCopy.find(({ name }) => name === day);

        if (!changedDay) {
            return;
        }

        let index = scheduleViewModel.scheduleSettings.workingDays.findIndex((num) => num === changedDay.day);
        if (index >= 0) {
            scheduleViewModel.scheduleSettings.workingDays.splice(index, 1);
        } else {
            scheduleViewModel.scheduleSettings.workingDays.push(changedDay.day);
        }
    };

    useEffect(() => {
        scheduleViewModel.getDoctorScheduleSettings(doctorId);
    }, [scheduleViewModel, doctorId]);

    const saveScheduleSettings = (next = false) => {
        const userIsActive = user.active;
        setSuccessAlertContent('', true);
        setErrorAlertContent('', true);

        scheduleViewModel
            .saveDoctorScheduleSettings()
            .then((result) => {
                if (!userIsActive && result.options.active) {
                    congratulationsDetector();
                }

                if (next) {
                    setTab('saveAndNext', '', false, true);
                }

                changeDetector(false);
                setSuccessAlertContent(intl.formatMessage({ id: 'successSave' }));
            })
            .catch((error) => {
                console.log(error);
                setErrorAlertContent(intl.formatMessage({ id: 'errorSave' }));
                if (error.errors) {
                    setErrors(error.errors);
                }
            });
    };

    const save = () => {
        saveScheduleSettings();
    };

    const saveAndNext = () => {
        saveScheduleSettings(true);
    };

    const CustomSelect = ({ value, label, id, onChange, endAdornment, startAdornment, children }) => {
        const [open, setOpen] = useState(false);

        const handleClose = () => {
            setOpen(false);
        };

        const handleOpen = () => {
            setOpen(true);
        };

        window.addEventListener('scroll', () => {
            if (open) {
                handleClose();
            }
        });

        return (
            <FormControl variant="standard" sx={{ width: '100%' }}>
                <TextField
                    select
                    SelectProps={{
                        open: open,
                        onClose: handleClose,
                        onOpen: handleOpen,
                        MenuProps: { disableScrollLock: true },
                    }}
                    value={value}
                    label={label}
                    id={id}
                    onChange={onChange}
                    variant="standard"
                    InputProps={{
                        startAdornment: startAdornment ? (
                            <label htmlFor={id}>
                                <b>{intl.formatMessage({ id: `cabinet.schedule.${startAdornment}` })}</b>
                            </label>
                        ) : (
                            false
                        ),
                        endAdornment: endAdornment ? (
                            <IconButton onClick={() => setOpen(!open)} className={st.time_select_icon}>
                                <ArrowDropDownIcon />
                            </IconButton>
                        ) : (
                            false
                        ),
                    }}
                    className={st.time_select}
                >
                    {children}
                </TextField>
            </FormControl>
        );
    };

    return (
        <section className={st.section}>
            <div className={st.section__title_wrapper}>
                <Typography variant="h5" sx={{ mb: 5 }}>
                    {intl.formatMessage({ id: 'cabinet.schedule.settings' })}
                </Typography>
            </div>

            <Grid item xs={12} lg={9}>
                <Grid container columnSpacing={14}>
                    {user?.experiences[0] && (
                        <Grid item xs={12} md={6}>
                            <div className={st.hospital}>
                                <EntityInfo avatarSize={80} pictureUrl="" fio={user?.experiences[0]?.clinic?.title}>
                                    <Typography variant="t3">{user?.experiences[0]?.clinic?.title}</Typography>

                                    <div className={st.hospital__location}>
                                        <Typography color={defaultTheme.palette.primary.light} variant="t3s">
                                            {user?.experiences?.length ?
                                                (user.experiences[0].country ? user.experiences[0].country + ', ' : '') +
                                                (user.experiences[0].city ? user.experiences[0].city + ', ' : '') +
                                                (user.experiences[0].clinic?.location ? user.experiences[0].clinic?.location + ', ' : '') +
                                                (user.experiences[0].zipCode ? user.experiences[0].zipCode : '')
                                                : ""
                                            }
                                        </Typography>
                                    </div>
                                </EntityInfo>
                            </div>
                        </Grid>
                    )}

                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            label={
                                <Typography
                                    variant="inputText"
                                    className="form_control_label_switch__label">
                                    {intl.formatMessage({ id: "cabinet.schedule.byCall" })}
                                </Typography>
                            }
                            className={classNames('form_control_label_switch', st.switcher,
                                (scheduleViewModel.scheduleSettings.noOrdination ? "disabled" : '')
                            )}
                            control={<Switch checked={scheduleViewModel.scheduleSettings.appointmentByCall} onChange={handleIsByCallChange} />}
                        />

                        <FormControlLabel
                            label={
                                <Typography
                                    variant="inputText"
                                    className="form_control_label_switch__label">
                                    {intl.formatMessage({ id: "cabinet.schedule.noOrdination" })}
                                </Typography>
                            }
                            className={classNames('form_control_label_switch', st.switcher,
                                (scheduleViewModel.scheduleSettings.appointmentByCall ? "disabled" : '')
                            )}
                            control={<Switch checked={scheduleViewModel.scheduleSettings.noOrdination ?? false} onChange={handleIsNoOrdination} />}
                        />
                    </Grid>
                </Grid>

                <div className={(scheduleViewModel.scheduleSettings.appointmentByCall || scheduleViewModel.scheduleSettings.noOrdination) ? "disabled" : ''}>
                    <Grid container columnSpacing={14}>
                        <Grid item xs={12} md={6}>
                            <Typography
                                className={st.h6}
                                sx={{ marginBottom: 4 }}
                                dangerouslySetInnerHTML={{ __html: `1. ${intl.formatMessage({ id: 'cabinet.schedule.selectDays' })}` }}
                            />

                            <div className={st.days}>
                                {workingDays?.map(({ name, day, isWorking }) => (
                                    <div
                                    key={name}
                                    className={classNames(st.days__day, scheduleViewModel.isWorkingDay(day) && st.days__day_working)}
                                    onClick={() => handleWorkingDaysChange(name)}
                                    >
                                        <Typography
                                            color={
                                                scheduleViewModel.isWorkingDay(day)
                                                    ? defaultTheme.palette.neutral.main
                                                    : defaultTheme.palette.secondary.main
                                            }
                                            variant="t3s"
                                        >
                                            {name}
                                        </Typography>
                                    </div>
                                ))}
                            </div>
                        </Grid>
                    </Grid>

                    <hr className={st.underline} />

                    <Grid container columnSpacing={14}>
                        <Grid item xs={12} md={6}>
                            <Typography
                                className={st.h6}
                                sx={{ marginBottom: 4 }}
                                dangerouslySetInnerHTML={{ __html: `2. ${intl.formatMessage({ id: 'cabinet.schedule.selectTime' })}` }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container rowSpacing={4} columnSpacing={14}>
                        <Grid item xs={12} md={6} mb={{ md: 4 }}>
                            <Grid container columnSpacing={2} sx={{ alignItems: 'flex-end' }}>
                                <Grid item xs={6}>
                                    <CustomSelect
                                        label={intl.formatMessage({ id: 'cabinet.schedule.workingTime' })}
                                        value={scheduleViewModel.scheduleSettings.workingTimeSettingsCommon?.workingTimeStart}
                                        id="common-working-hours-from"
                                        onChange={(e: any) => {
                                            changeDetector(true);
                                            if (scheduleViewModel.scheduleSettings.workingTimeSettingsCommon) {
                                                scheduleViewModel.scheduleSettings.workingTimeSettingsCommon.workingTimeStart = e.target.value;
                                            }
                                        }}
                                        variant="standard"
                                        startAdornment="from"
                                        endAdornment
                                    >
                                        {generateTimes()?.map((time, i) => (
                                            <MenuItem key={time + i} value={time}>
                                                {time}
                                            </MenuItem>
                                        ))}
                                    </CustomSelect>
                                </Grid>

                                <Grid item xs={6}>
                                    <CustomSelect
                                        value={scheduleViewModel.scheduleSettings.workingTimeSettingsCommon?.workingTimeEnd}
                                        id="common-working-hours-to"
                                        onChange={(e: any) => {
                                            changeDetector(true);
                                            if (scheduleViewModel.scheduleSettings.workingTimeSettingsCommon) {
                                                scheduleViewModel.scheduleSettings.workingTimeSettingsCommon.workingTimeEnd = e.target.value;
                                            }
                                        }}
                                        variant="standard"
                                        startAdornment="to"
                                        endAdornment
                                    >
                                        {generateTimes()?.map((time, i) => (
                                            <MenuItem key={time + i + '2'} value={time}>
                                                {time}
                                            </MenuItem>
                                        ))}
                                    </CustomSelect>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6} mb={{ xs: 3, md: 0 }}>
                            <CustomSelect
                                value={scheduleViewModel.scheduleSettings.workingTimeSettingsCommon?.appointmentInterval}
                                label={intl.formatMessage({ id: 'cabinet.schedule.interval' })}
                                id="interval-label"
                                onChange={(e: any) => {
                                    changeDetector(true);
                                    if (scheduleViewModel.scheduleSettings.workingTimeSettingsCommon) {
                                        scheduleViewModel.scheduleSettings.workingTimeSettingsCommon.appointmentInterval = parseInt(e.target.value);
                                    }
                                }}
                                endAdornment
                            >
                                <MenuItem value="10">10</MenuItem>
                                <MenuItem value="20">20</MenuItem>
                                <MenuItem value="30">30</MenuItem>
                                <MenuItem value="60">60</MenuItem>
                            </CustomSelect>
                        </Grid>

                        {scheduleViewModel.scheduleSettings?.workingTimeSettingsCommon?.breaks?.map(({ timeFrom, timeTill }, breakIndex) => (
                            <React.Fragment key={breakIndex + 'workingTimes'}>
                                <Grid item xs={12} md={6}>
                                    <Grid container columnSpacing={2} sx={{ alignItems: 'flex-end' }}>
                                        <Grid item xs={6}>
                                            <CustomSelect
                                                select
                                                label={intl.formatMessage({ id: 'cabinet.schedule.break' })}
                                                value={timeFrom}
                                                id={`break-from-${breakIndex}`}
                                                onChange={(e: any) => {
                                                    changeDetector(true);
                                                    if (scheduleViewModel.scheduleSettings.workingTimeSettingsCommon) {
                                                        scheduleViewModel.scheduleSettings.workingTimeSettingsCommon.breaks[breakIndex].timeFrom =
                                                            e.target.value;
                                                    }
                                                }}
                                                startAdornment="from"
                                                endAdornment
                                            >
                                                {generateTimes()?.map((time, i) => (
                                                    <MenuItem key={time + i + '3'} value={time}>
                                                        {time}
                                                    </MenuItem>
                                                ))}
                                            </CustomSelect>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <CustomSelect
                                                value={timeTill}
                                                id={`break-to-${breakIndex}`}
                                                onChange={(e: any) => {
                                                    changeDetector(true);
                                                    if (scheduleViewModel.scheduleSettings.workingTimeSettingsCommon) {
                                                        // action(() => {
                                                        scheduleViewModel.scheduleSettings.workingTimeSettingsCommon.breaks[breakIndex].timeTill =
                                                            e.target.value;
                                                        // })
                                                    }
                                                }}
                                                startAdornment="to"
                                                endAdornment
                                            >
                                                {generateTimes()?.map((time, i) => (
                                                    <MenuItem key={time + i + '4'} value={time}>
                                                        {time}
                                                    </MenuItem>
                                                ))}
                                            </CustomSelect>
                                        </Grid>
                                        <Typography
                                            variant="button"
                                            onClick={() => removeBreak(breakIndex)}
                                            className={st.removeBreak}
                                            sx={{ width: { xs: '100%', sm: 'auto' } }}
                                            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cabinet.schedule.removeBreak' }) }}
                                        />
                                    </Grid>
                                </Grid>

                                {breakIndex + 1 === scheduleViewModel.scheduleSettings.workingTimeSettingsCommon?.breaks.length ? (
                                    <Grid item xs={12} md={6}>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            startIcon={<PlusIcon width={14} height={14} />}
                                            className={classNames(st.button, st.button_icon)}
                                            onClick={() => addBreak()}
                                            sx={{ width: { xs: '100%', sm: 'auto' } }}
                                        >
                                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cabinet.schedule.addBreak' }) }} />
                                        </Button>
                                    </Grid>
                                ) : (
                                    ''
                                )}
                            </React.Fragment>
                        ))}
                        {scheduleViewModel?.scheduleSettings?.workingTimeSettingsCommon &&
                        scheduleViewModel.scheduleSettings.workingTimeSettingsCommon.breaks?.length < 1 ? (
                            <Grid item xs={12} md={6}>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    startIcon={<PlusIcon width={14} height={14} />}
                                    className={classNames(st.button, st.button_icon)}
                                    onClick={() => addBreak()}
                                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                                >
                                    <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cabinet.schedule.addBreak' }) }} />
                                </Button>
                            </Grid>
                        ) : (
                            ''
                        )}
                    </Grid>
                </div>

            </Grid>

            <div className={stUserEdit.save_buttons}>
                <Button type="button" color="secondary" variant="contained" onClick={save}>
                    {intl.formatMessage({ id: 'cabinet.profile.doctor.submit' })}
                </Button>

                <Button type="button" color="secondary" variant="contained" onClick={saveAndNext}>
                    {intl.formatMessage({ id: 'saveAndNext' })}
                </Button>
            </div>

            {/*<pre style={{color: "saddlebrown", }}>{JSON.stringify(scheduleViewModel.scheduleSettings, 0, 2)}</pre>*/}
        </section>
    );
};

export default observer(ScheduleSettings);

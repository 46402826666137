export const tools = {
    objectToArray: (value: any) => {
        if (typeof value === "object" &&
            !Array.isArray(value) &&
            value !== null) {
            return Object.values(value);
        }
        return value;
    },

    isObject: (value: any) => {
        return typeof value === "object" && !Array.isArray(value) && value !== null;
    },

};

export const stringAvatar = (name: string) => {
    if (name.indexOf(" ") < 0 || name.length < 1)  {
        return {children: name};
    }

    return {
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
};

import FaqModel from "domain/entity/faq/FaqModel";
import ManageFaqUseCase from "domain/interactors/faq/ManageFaqUseCase";
import {makeAutoObservable} from "mobx";
import CategorizedQuestion from "../../../domain/entity/faq/structures/CategorizedQuestion";

export default class FaqViewModel {
    public faqModel: FaqModel;
    private manageFaqUseCase: ManageFaqUseCase;
    public forDoctorsFaqs: Array<CategorizedQuestion>;

    constructor(faqModel: FaqModel, manageFaqUseCase: ManageFaqUseCase) {
        makeAutoObservable(this);
        this.faqModel = faqModel;
        this.manageFaqUseCase = manageFaqUseCase;
        this.forDoctorsFaqs = [];
    }

    public loadPopularQustions = () => {
        this.manageFaqUseCase.loadPopularQustions();
    };

    public loadCategories = () => {
        this.manageFaqUseCase.loadCategories();
    };

    public loadQuestionsByCategory = (category: string) => {
        this.manageFaqUseCase.loadQuestionsByCategory(category);
    };

    public loadQuestionsForDoctorsCategory = () => {
        this.manageFaqUseCase.loadQuestionsForDoctorsCategory();
    };
}

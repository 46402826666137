import SubscriptionRepository, { SubscriptionResult } from "../../domain/repository/subscription/SubscriptionRepository";
import axios from "axios";

export default class SubscriptionApi implements SubscriptionRepository {
    postSubscription(name: string, email: string): Promise<SubscriptionResult> {
        return axios({
            method: "POST",
            url: "api/subscriptions",
            data: {name, email},
        })
            .then((result: any) => {
                return {
                    status: true
                };
            })
            .catch((e) => {
                console.log({ e });
                return Promise.reject(e);
            });
    }
}

// @ts-nocheck
import React, { useState } from "react";
import { useIntl, } from "react-intl";
import { Link, withRouter } from "react-router-dom";
import docPage from "../../../views/Public/DoctorId/doctorId.module.scss";
import st from "./doctorPreview.module.scss";
import stylePopup from "../Popup/popup.module.scss";
import classNames from "classnames";

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Navigation, Pagination } from "swiper";
import { Button, Checkbox, FormControlLabel, IconButton, TextField, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { ReactComponent as AngleBracket } from "../../assets/images/icons/angle-bracket.svg";
import { ReactComponent as AngleBracketPrev } from "../../assets/images/icons/angle-bracket-prev.svg";
import { ReactComponent as AngleBracketNext } from "../../assets/images/icons/angle-bracket-next.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/icons/cross_14_2.svg";
import { ReactComponent as QuestionIcon } from "../../assets/images/icons/question.svg";
import { Field, FormikProvider, useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import { DoctorPreviewProfile } from "../DoctorPreviewProfile";
import { useViewModels } from "presentation/view-model/ViewModelProvider";
import DoctorsViewModel from "../../../view-model/doctors/DoctorsViewModel";
import { NonAuthRoutes, AuthRoutes } from "../../../routes/routes";
import { observer } from "mobx-react";
import Fade from '@mui/material/Fade';
import ReactPhoneInput from "react-phone-input-material-ui";
// import isMobilePhone from "validator/lib/isMobilePhone";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ConditionalWrapper } from "../ConditionalWrapper";
import { useHistory  } from "react-router-dom";


interface doctorPreviewProps {
    previewIndex: number;
    mobileIs?: boolean;
    doctor: any;
    isDoctorPage?: boolean;
    isMyPage?: boolean;
    viewAsPatient?: boolean;
}


// export const DoctorPreview: React.FC<doctorPreviewProps> = withRouter(((
export const DoctorPreview: React.FC<doctorPreviewProps> = withRouter(observer((
    {
        mobileIs,
        previewIndex,
        doctor,
        isDoctorPage,
        isMyPage,
        viewAsPatient,
    }) => {
    const intl = useIntl();
    const history = useHistory();
    const {
        appointmentViewModel,
        authViewModel: { isUserAuthorized, isDoctor, onClickSignOut },
        userViewModel: { userModel, locale, localeUrl },
        connectionViewModel,
        uIViewModel: { tabletIs },
        patientProfileViewModel: {patientProfile},
        doctorProfileViewModel: {model: { options: myProfile }},
    } = useViewModels();
    const scheduleLimit = mobileIs ? 8 : 10;
    const [swiperDaysActiveIndex, setSwiperDaysActiveIndex] = useState(0);
    const [scheduleFullShow, setScheduleFullShow] = useState(false);
    const [handleSlideTo, setHandleSlideTo] = useState<Swiper | null>(null);
    const [bookingPopupIsOpen, setBookingPopupIsOpen] = useState(false);
    const [canSubmit, setCanSubmit] = useState(true);
    const [bookingTime, setBookingTime] = useState('');
    const [bookingDate, setBookingDate] = useState('');
    const [successAppointment, setSuccessAppointment] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [loadingMoreSchedule, setLoadingMoreSchedule] = useState(false);
    const [openingAppointmentItem, setOpeningAppointmentItem] = useState(null);
    const appointmentByCall = !!doctor.scheduleSettings.appointmentByCall;
    const noOrdination = !!doctor.scheduleSettings.noOrdination;
    const [openTooltip, setOpenTooltip] = React.useState(false);

    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };

    const handleTooltipOpen = () => {
        setOpenTooltip(true);
    };


    const handleDisableConnect = () => {
        history.push(localeUrl(`${NonAuthRoutes.doctors}/${myProfile.id}/edit?tab=personal`));
    };

    function formatHumanDate(date: string) {
        const day = intl.formatDate(new Date(date), { day: 'numeric' });
        const month = intl.formatDate(new Date(date), { month: 'long' });
        const year = intl.formatDate(new Date(date), { year: 'numeric' });

        return `${month} ${day}, ${year}`;
    }

    function formatHumanWeekday(date: string) {
        const day = intl.formatDate(new Date(date), { day: 'numeric' });
        const month = intl.formatDate(new Date(date), { month: 'short' });
        // const month = intl.formatDate(new Date(date), { month: mobileIs ? 'long' : 'short' });

        return `${month}, ${day}`;
    }

    const sendConnection = () => {
        connectionViewModel.postConnection(`api/doctors/${doctor.id}`)
            .then((response) => {
                DoctorsViewModel.updateCurrentDoctor({ ...doctor, connectionStatus: response.status })
            });
    }

    const askToApproveSkills = () => {
        connectionViewModel.getAskToApproveSkillsPromise(doctor.id)
            .then((response) => {
                DoctorsViewModel.updateCurrentDoctor({ ...doctor, waitForApproveSkills: true })
            });
    }

    const validatePostBookingAppointment = (values: any) => {
        const errors: any = {};

        if (!values.doctorIri.length) {
            errors.doctorIri = 'Required';
        }

        if (appointmentByCall) {
            const phone = values.phoneNumber.trim();

            if (!phone) {
                errors.phoneNumber = intl.formatMessage({id: "required"});
            }
            /*if (phone && !isMobilePhone(phone)) {
                errors.phoneNumber = intl.formatMessage({id: "errors.phone"});
            }*/
        } else {
            if (!values.date.length) {
                errors.date = 'Required';
            }
            if (!values.time.length) {
                errors.time = 'Required';
            }
            if (!values.reason.length) {
                errors.reason = 'Required';
            }
        }
        console.log('errors', errors);

        return errors;
    }

    let initialValues = {reason: ""};
    if (appointmentByCall) {
        initialValues = {...initialValues, phoneNumber: userModel?.fullOptions?.phone ?? ""}
    } else {
        initialValues = {...initialValues, date: "", time: "",}

    }

    const bookAppointment = useFormik({
        enableReinitialize: true,
        validate: validatePostBookingAppointment,
        initialValues: {
            ...initialValues,
            doctorIri: `/api/doctors/${doctor.id}`,
        },
        onSubmit: async (values, { setErrors }) => {
            if (appointmentByCall) {
                appointmentViewModel.postBookingAppointmentByCall(values.doctorIri, values.phoneNumber, values.reason)
                    .then((e) => {
                        setSuccessAppointment(true);
                    });
            } else {
                appointmentViewModel.postBookingAppointment(values.doctorIri, values.date, values.time, values.reason)
                    .then((e) => {
                        setSuccessAppointment(true);
                        if (openingAppointmentItem) {
                            // remove booking time from schedule
                            const day = Object.keys(openingAppointmentItem)[0];
                            doctor.scheduleItems[day].availableScheduleItems.splice(openingAppointmentItem[day], 1);
                        }
                    });
            }
        },
    });

    const phoneChange = (phoneNumber: number) => {
        bookAppointment.setFieldValue("phoneNumber", phoneNumber);
    }

    const phoneFocusChange = (e: any, phoneNumber: number) => {
        if (bookAppointment.values.phoneNumber?.length <= 1) {
            bookAppointment.setFieldValue("phoneNumber", phoneNumber.dialCode);
        }
    }

    const getNextAvailableDay = (index, array) => {
        const remnantArray = array.slice(index + 1);
        const nextAvailableDay = remnantArray.find(item2 => item2.workingDay === true && item2.availableScheduleItems.length);

        if (nextAvailableDay) {
            const nextAvailableDayIndexSlice = remnantArray.findIndex(item2 => item2.workingDay === true && item2.availableScheduleItems.length);
            const nextAvailableDayDate = nextAvailableDay.date;
            const nextAvailableDayIndex = nextAvailableDayIndexSlice + index;

            return (
                <Button
                    onClick={() => {
                        if (handleSlideTo !== null) {
                            handleSlideTo.slideTo(nextAvailableDayIndex + 1);
                        }
                    }}
                    className={classNames("btn--trans", st.btn_next_availability)}
                    color="secondary"
                    sx={{ width: "100%", padding: 0 }} size="small" variant="outlined"
                >
                    {intl.formatMessage({ id: "doctors.preview.nextAvailable" }, { day: formatHumanWeekday(nextAvailableDayDate) }) }
                    <AngleBracketNext className={st.next_available_day} />
                </Button>
            )
        } else {
            return (<div className={st.swiper_schedule__no_available_days}>{intl.formatMessage({ id: "doctors.preview.notAvailable" })}</div>)
        }
    }

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            padding: "16px",
            borderRadius: "4px",
            width: "100%",
            maxWidth: "295px",
            fontWeight: 300,
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "-0.02em",
            color: "rgba(5, 20, 75, 0.64)",
            background: "#fff",
            boxShadow: "0px 5px 5px -3px rgba(5, 20, 75, 0.2), 0px 8px 10px 1px rgba(5, 20, 75, 0.08), 0px 3px 14px 2px rgba(5, 20, 75, 0.04)",
        },
    }));

    return (
        <div className={classNames(st.container, (isDoctorPage ? st.doctor_page : ''))}>
            <Dialog
                TransitionComponent={Fade}
                onClose={() => setBookingPopupIsOpen(false)}
                maxWidth={false}
                open={bookingPopupIsOpen}
                scroll="body"
                className={stylePopup.popup}
            >
                <div className={stylePopup.container}>
                    <IconButton className={stylePopup.close} onClick={() => setBookingPopupIsOpen(false)}>
                        <CrossIcon />
                    </IconButton>
                    {successAppointment ?
                        <>
                            <Typography
                                variant="h5"
                                className={stylePopup.title}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "doctors.preview.title" }) }}
                            />
                            <div className={stylePopup.text}
                                 dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "doctors.preview.success" }) }}></div>
                            <Button color="secondary" fullWidth variant="contained" onClick={() => setBookingPopupIsOpen(false)} sx={{ width: "100%", mt: { xs: 2, sm: 4, md: 6 } }}>
                                {intl.formatMessage({ id: "ok" })}
                            </Button>
                        </>
                        :
                        <>
                            <Typography
                                className={stylePopup.title} variant="h5"
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "doctors.preview.title" }) }}
                            />

                            <div className={st.popup_book_appointment__doctor_preview}>
                                <DoctorPreviewProfile doctor={doctor} isDoctorPage={isDoctorPage} />
                            </div>

                            {(!isUserAuthorized || isDoctor || (patientProfile?.id && !patientProfile?.active)) ?
                                <>
                                    <Typography variant="p5" className={stylePopup.text}>
                                        {isDoctor ?
                                            intl.formatMessage({ id: "invitationToRegister.doctor" })
                                            :
                                            isUserAuthorized ?
                                                intl.formatMessage({ id: "invitationToFill" })
                                                :
                                                intl.formatMessage({ id: "invitationToRegister" })
                                        }
                                    </Typography>

                                    <Grid container spacing={2} pt={{ xs: 2, sm: 4, md: 6 }}>
                                        {(!isUserAuthorized || isDoctor) &&
                                        <Grid item xs={12} sm={6}>
                                            <Link to={(locale && locale !== 'de' ? '/' + locale : '') + NonAuthRoutes.signup + '?type=patient'}
                                                  onClick={isDoctor ? onClickSignOut : undefined}
                                            >
                                                <Button type="button" variant="contained" color="secondary">
                                                    {isDoctor ?
                                                        intl.formatMessage({ id: "logoutSignUp" })
                                                        :
                                                        intl.formatMessage({ id: "auth.signup.button" })
                                                    }
                                                </Button>
                                            </Link>
                                        </Grid>
                                        }

                                        <Grid item xs={12} sm={(!isUserAuthorized || isDoctor) ? 6 : 12}>
                                            <Link to={(locale && locale !== 'de' ? '/' + locale : '') + ((!isUserAuthorized || isDoctor) ?  NonAuthRoutes.signin : `/patients/${userModel.id}/edit?tab=personal`)}
                                                  onClick={isDoctor ? onClickSignOut : undefined}
                                            >
                                                <Button type="button" variant="contained" color="secondary" className={(isUserAuthorized && !isDoctor) ? 'width-100' : ''}>
                                                    {isDoctor ?
                                                        intl.formatMessage({ id: "logoutSignIn" })
                                                        :
                                                        isUserAuthorized ?
                                                            intl.formatMessage({ id: "editProfile" })
                                                            :
                                                            intl.formatMessage({ id: "auth.signin.signIn" })
                                                    }
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                <FormikProvider value={bookAppointment}>
                                    <Box component="form" onSubmit={bookAppointment.handleSubmit}>
                                        {/*<pre style={{color: "saddlebrown", overflow: 'hidden', width: '100%'}}>{JSON.stringify(bookAppointment.values, 0, 2)}</pre>*/}
                                        {appointmentByCall &&
                                            <Field
                                                name="phoneNumber"
                                                children={(fieldProps: any) => (
                                                    <ReactPhoneInput
                                                        {...fieldProps.field}
                                                        value={bookAppointment.values.phoneNumber}
                                                        className={classNames(st.phone, bookAppointment.values.phoneNumber && bookAppointment.values.phoneNumber.length >= 1 ? st.filled : "")}
                                                        onChange={phoneChange}
                                                        onFocus={phoneFocusChange}
                                                        inputProps={{
                                                            id: "phoneNumber",
                                                            margin: "normal",
                                                            inputMode: 'numeric',
                                                            variant: "standard",
                                                            autoComplete: "phoneNumber",
                                                            error: bookAppointment.touched.phoneNumber && !bookAppointment.isValid && Boolean(fieldProps.meta.error),
                                                            helperText: bookAppointment.touched.phoneNumber && !bookAppointment.isValid && fieldProps.meta.error,
                                                            label: `${intl.formatMessage({ id: "cabinet.account.phoneNumber" })}`,
                                                        }}
                                                        prefix=''
                                                        disableDropdown
                                                        country={locale || "de"}
                                                        preserveOrder={['country', 'preferredCountries']}
                                                        component={TextField}
                                                    />
                                                )}
                                            />
                                        }

                                        <div className={st.popup_book_appointment__date}>
                                            <div className={st.popup_book_appointment__date_title}>
                                                {intl.formatMessage({ id: "doctors.preview.date" })}
                                            </div>

                                            <div>{` ${bookingDate ? formatHumanDate(bookingDate) : ""} ${bookingTime}`}</div>
                                        </div>

                                        <Field
                                            name="reason"
                                            children={(fieldProps: any) => (
                                                <TextField
                                                    {...fieldProps.field}
                                                    className={st.popup_book_appointment__reason_label}
                                                    fullWidth
                                                    label={<span dangerouslySetInnerHTML={{ __html: intl.formatMessage(
                                                        { id: (appointmentByCall ? "cabinet.appointments.card.reason" : "doctors.preview.reason") }) }}></span>}
                                                    type="text"
                                                    variant="standard"
                                                    helperText={bookAppointment.touched.reason && !bookAppointment.isValid && fieldProps.meta.error}
                                                    error={bookAppointment.touched.reason && !bookAppointment.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                />
                                            )}
                                        />

                                        <FormControlLabel
                                            className={st.popup_book_appointment__privacy}
                                            control={
                                                <Checkbox
                                                    checkedIcon={<span />}
                                                    icon={<span />}
                                                    defaultChecked
                                                    onChange={(value: any) => {
                                                        setCanSubmit(value.target.checked);
                                                    }}
                                                />
                                            }
                                            label={
                                                <span className={st.terms}>
                                                <span
                                                    dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "doctors.preview.terms.read" })} ` }} />

                                                <Link
                                                    to={NonAuthRoutes.termsOfUse}
                                                    className={st.terms__link}
                                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "doctors.preview.terms.terms" }) }}
                                                />

                                                <span
                                                    dangerouslySetInnerHTML={{ __html: ` ${intl.formatMessage({ id: "doctors.preview.terms.and" })} ` }} />

                                                <Link
                                                    to={NonAuthRoutes.privacyPolicy}
                                                    className={st.terms__link}
                                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "doctors.preview.terms.privacy" }) }}
                                                />.
                                            </span>
                                            }
                                        />

                                        <Grid container spacing={2} pt={{ xs: 2, sm: 4, md: 6 }}>
                                            <Grid item xs={12} sm={6}>
                                                <Button variant="outlined" color="secondary" onClick={() => setBookingPopupIsOpen(false)} sx={{ width: "100%" }}>
                                                    {intl.formatMessage({ id: "doctors.preview.cancelButton" })}
                                                </Button>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Button type="submit" color="secondary" disabled={!canSubmit} variant="contained" sx={{ width: "100%" }}>
                                                    {intl.formatMessage({ id: "doctors.preview.confirmButton" })}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </FormikProvider>
                            }
                        </>
                    }
                </div>
            </Dialog>

            <div className={st.doctor_info_preview}>
                <DoctorPreviewProfile doctor={doctor} isDoctorPage={isDoctorPage} viewAsPatient={viewAsPatient} />
                {isDoctorPage && doctor?.profile?.about &&
                <div className={st.doctor_page__doc_description}>
                    <div className={st.doctor_page__sub_title}>
                        {intl.formatMessage({id: 'app.footer.menu.about'})}
                        {viewAsPatient && (
                            <Link to={`${(locale && locale !== 'de' ? '/' + locale : '')}/doctors/${doctor.id}/edit?tab=personal`} className={docPage.edit}>{intl.formatMessage({ id: "edit" })}</Link>
                        )}
                    </div>

                    {
                        doctor?.profile?.about?.length > 360 ? (
                            <Typography component="div" variant="p5" className={st.doctor_page__about}>
                                {showMore ? (
                                    doctor.profile.about.split("\n").map((i, key) => {
                                        return (
                                            <p key={key}>
                                                {i}{key === doctor.profile.about.split("\n").length - 1 ? <span className={st.doctor_page__show_button} onClick={() => setShowMore(!showMore)}>Show less</span> : ""}
                                            </p>
                                        );
                                    })
                                ) : (
                                    doctor.profile.about.substring(0, 360).split("\n").map((i, key) => {
                                        if (doctor.profile.about.substring(0, 360).split("\n").length >= 2) {
                                            return (
                                                <p key={key}>
                                                    {i}{key >= 2 ? "..." : ""} {key >= 2 ? <span className={st.doctor_page__show_button} onClick={() => setShowMore(!showMore)}>Show more</span> : ""}
                                                </p>
                                            );
                                        }

                                        return (
                                            <p key={key}>
                                                {i.substring(0, 360)}... {<span className={st.doctor_page__show_button} onClick={() => setShowMore(!showMore)}>Show more</span>}
                                            </p>
                                        );
                                    })
                                )}
                            </Typography>
                        ) : (
                            <Typography component="div" variant="p5" className={st.doctor_page__about}>
                                {doctor.profile.about.split("\n").map((i, key) => {
                                    return <p key={key}>{i}</p>;
                                })}
                            </Typography>
                        )
                    }
                </div>
                }
            </div>

            <div className={st.block_right}>
                <div className={isDoctorPage ? st.schedule_on_doc_page : ''}>
                    {isDoctorPage &&
                    <div className={st.doctor_page__schedule_header}>
                        <div className={st.doctor_page__sub_title}>
                            {intl.formatMessage({ id: (noOrdination ? "bookingAppointment" : "cabinet.schedule.title") })}
                        </div>
                        {viewAsPatient && (
                            <Link to={`${(locale && locale !== 'de' ? '/' + locale : '')}/doctors/${doctor.id}/edit?tab=schedule-settings`}
                                  className={docPage.edit}>{intl.formatMessage({ id: "edit" })}</Link>
                        )}
                    </div>
                    }

                    {!appointmentByCall && !noOrdination &&
                    <div className={st.swiper_schedule__days}>
                        <div className={st.swiper_schedule__prev}>
                            <Button
                                className={classNames(`btn--circle--sm btn--trans swiper-schedule-prev-${previewIndex}`)}
                                size="small"
                                color="primary"
                                variant="circle">
                                <AngleBracketPrev />
                            </Button>
                        </div>

                        <div className={classNames(`swiper swiper-days-${previewIndex}`)}>
                            <div className="swiper-wrapper"></div>
                        </div>

                        <div className={st.swiper_schedule__next}>
                            <Button
                                className={classNames(`btn--circle--sm btn--trans swiper-schedule-next-${previewIndex}`)}
                                size="small"
                                disabled={false}
                                variant="circle">
                                <AngleBracketNext />
                            </Button>
                        </div>
                    </div>
                    }

                    <div className={classNames(`swiper-schedule-${previewIndex} ${st.swiper_schedule__timing}`)}>
                        {(appointmentByCall || noOrdination) ?
                            (isDoctorPage && noOrdination ?
                                <Typography variant="p5">{intl.formatMessage({id: 'noOrdinationText'})}</Typography>
                                :
                                <ConditionalWrapper
                                    condition={noOrdination}
                                    wrapper={children => <Link to={localeUrl(`${NonAuthRoutes.doctors}/${doctor.id}`)}>{children}</Link>}
                                >
                                    <div className={st.swiper_schedule__appointment_by_call}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            sx={{ width: "100%" }}
                                            size="small"
                                            onClick={() => {
                                                if (appointmentByCall) {
                                                    setSuccessAppointment(false);
                                                    setBookingPopupIsOpen(true);
                                                }
                                            }}
                                        >
                                            {intl.formatMessage({ id: (appointmentByCall ? 'cabinet.schedule.byCall' : 'profile') })}
                                        </Button>

                                        {!tabletIs ? (
                                            <HtmlTooltip
                                                placement="left-end"
                                                title={
                                                    <React.Fragment>
                                                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Explicabo, illum!
                                                    </React.Fragment>
                                                }
                                            >
                                                <div className={st.swiper_schedule__appointment_by_call_question}>
                                                    <QuestionIcon />
                                                </div>
                                            </HtmlTooltip>
                                        ) : (
                                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                                <div>
                                                    <HtmlTooltip
                                                        onClose={handleTooltipClose}
                                                        open={openTooltip}
                                                        disableFocusListener
                                                        disableHoverListener
                                                        disableTouchListener
                                                        title={
                                                            <React.Fragment>
                                                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Explicabo, illum!
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <div onClick={handleTooltipOpen} className={st.swiper_schedule__appointment_by_call_question}>
                                                            <QuestionIcon />
                                                        </div>
                                                    </HtmlTooltip>
                                                </div>
                                            </ClickAwayListener>
                                        )}
                                    </div>
                                </ConditionalWrapper>)
                            // wrapper={children => <Link to={`${NonAuthRoutes.doctors}/${activeAppointmentCard[typeAppointmentCard].id}`}>{children}</Link>}
                            /*<div className={st.swiper_schedule__appointment_by_call}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{ width: "100%" }}
                                    size="small"
                                    onClick={() => {
                                        if (appointmentByCall) {
                                            setSuccessAppointment(false);
                                            setBookingPopupIsOpen(true);
                                        }
                                    }}
                                >
                                    {intl.formatMessage({id: (appointmentByCall ? 'cabinet.schedule.byCall' : 'profile')})}
                                </Button>

                                {!tabletIs ? (
                                    <HtmlTooltip
                                        placement="left-end"
                                        title={
                                            <React.Fragment>
                                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Explicabo, illum!
                                            </React.Fragment>
                                        }
                                    >
                                        <div className={st.swiper_schedule__appointment_by_call_question}>
                                            <QuestionIcon />
                                        </div>
                                    </HtmlTooltip>
                                ):(
                                    <ClickAwayListener onClickAway={handleTooltipClose}>
                                        <div>
                                        <Tooltip
                                            onClose={handleTooltipClose}
                                            open={openTooltip}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title={
                                                <React.Fragment>
                                                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Explicabo, illum!
                                                </React.Fragment>
                                            }
                                        >
                                            <div onClick={handleTooltipOpen} className={st.swiper_schedule__appointment_by_call_question}>
                                                <QuestionIcon />
                                            </div>
                                        </Tooltip>
                                        </div>
                                    </ClickAwayListener>
                                )}
                            </div>*/
                            :
                            <Swiper
                                slidesPerView={1}
                                allowTouchMove={!appointmentByCall && !noOrdination}
                                speed={400}
                                autoHeight={!!isDoctorPage}
                                spaceBetween={16}
                                onSlideChange={(e) => {
                                    setSwiperDaysActiveIndex(e.activeIndex);
                                    if (doctor.scheduleItems.length - e.activeIndex < 7) {
                                        // Get more schedule
                                        const date = new Date(doctor.scheduleItems[doctor.scheduleItems.length - 1].date);
                                        const from = new Date(date.setDate(date.getDate() + 1)).toISOString().slice(0, 10);
                                        const till = new Date(date.setDate(date.getDate() + 7)).toISOString().slice(0, 10);
                                        if (!loadingMoreSchedule) {
                                            setLoadingMoreSchedule(true);
                                            DoctorsViewModel.getDoctorWithSchedule(doctor.id, from, till, true, isDoctorPage)
                                                .finally(() => {
                                                    setLoadingMoreSchedule(false)
                                                });
                                        }
                                    }
                                }}
                                onSwiper={(swiper) => {
                                    setHandleSlideTo(swiper);
                                }}
                                modules={[Pagination, Navigation]}
                                navigation={{
                                    prevEl: `.swiper-schedule-prev-${previewIndex}`,
                                    nextEl: `.swiper-schedule-next-${previewIndex}`,
                                }}
                                pagination={{
                                    el: `.swiper-days-${previewIndex} .swiper-wrapper`,
                                    clickable: true,
                                    type: "bullets",
                                    dynamicBullets: true,
                                    dynamicMainBullets: 3,
                                    renderBullet: function(index, className) {
                                        return (`<div class="${classNames(className, st.swiper_schedule__slide, st.swiper_schedule__slide_day, (swiperDaysActiveIndex === index && st.swiper_schedule__slide_active))}">
                                            ${formatHumanWeekday(doctor?.scheduleItems?.[index].date)}
                                        </div>`)

                                    },
                                }}
                            >
                                {doctor?.scheduleItems?.map((item, indexDay) => {
                                    return (
                                        <SwiperSlide className={classNames('swiper-slide')} key={previewIndex + '_d' + indexDay}>
                                            {item.workingDay && item.availableScheduleItems.length ?
                                                <div className={classNames(`${st.swiper_schedule__slide}
                                                        ${scheduleFullShow ? st.swiper_schedule__slide_full : ""}
                                                        ${appointmentByCall ? st.swiper_schedule__slide_by_call : ""}
                                                    `)}
                                                >

                                                    {item.availableScheduleItems.map((value, indexTime) => {// iterate schedule day
                                                        return (
                                                            <React.Fragment key={previewIndex + '_d' + indexDay + '_t' + indexTime}>
                                                                {(!isDoctorPage && (indexTime + 1 === scheduleLimit) && item.availableScheduleItems.length > scheduleLimit) &&
                                                                <div className={classNames(`${st.swiper_schedule__timing_item}
                                                                            ${st.swiper_schedule__timing_item_switcher}
                                                                            ${scheduleFullShow ? st.swiper_schedule__timing_item_switcher_open : ''}`)}
                                                                     onClick={() => setScheduleFullShow(!scheduleFullShow)}
                                                                >
                                                                    <AngleBracket />
                                                                </div>
                                                                }
                                                                <div className={st.swiper_schedule__timing_item}
                                                                     onClick={() => {
                                                                         setSuccessAppointment(false);
                                                                         setBookingDate(item.date);
                                                                         setBookingTime(value.time);
                                                                         bookAppointment.setFieldValue('date', item.date);
                                                                         bookAppointment.setFieldValue('time', value.time);
                                                                         setBookingPopupIsOpen(true);
                                                                         setOpeningAppointmentItem({ [indexDay]: indexTime });
                                                                     }}
                                                                >{value.time}</div>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </div>
                                                :
                                                getNextAvailableDay(indexDay, doctor.scheduleItems)
                                            }
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        }
                    </div>
                </div>

                {isDoctor && isDoctorPage && !isMyPage &&
                    <>
                        <Button id="connect" className={st.connect__button} color="secondary" variant="contained"
                               onClick={
                                   !myProfile.active ?
                                       handleDisableConnect
                                       :
                                       doctor.connectionStatus === "existing" ?
                                           askToApproveSkills
                                           :
                                           (doctor.connectionStatus !== "pending" ?
                                                   sendConnection
                                                   :
                                                   undefined
                                           )
                               }
                               disabled={doctor.connectionStatus === "pending" || doctor.waitForApproveSkills}
                        >
                            {!myProfile.active ?
                                intl.formatMessage({ id: "connectNotEnable" })
                                :
                                doctor.connectionStatus === "existing" ?
                                    intl.formatMessage({ id: doctor.waitForApproveSkills ? "cabinet.connection.askedApprove" : "askToApprove" })
                                    :
                                    (doctor.connectionStatus === "pending" ?
                                            intl.formatMessage({ id: "cabinet.profile.doctor.connection.connectionRequested" })
                                            :
                                            intl.formatMessage({ id: "cabinet.profile.doctor.connection.connect" })
                                    )
                            }
                        </Button>

                        {myProfile.active && doctor.connectionStatus === "pending" &&
                            <Link to={`${localeUrl(AuthRoutes.connections)}?type=${doctor.connectionSender === doctor.id ? 2 : 3}`}>
                                <Button className={st.connect__button} variant="outlined" color="secondary">
                                    {intl.formatMessage({id: "manageConnections"})}
                                </Button>
                            </Link>
                        }
                    </>
                }
            </div>
        </div>
    );
}));

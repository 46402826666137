import ScheduleRepository from "../../repository/schedule/ScheduleRepository";
import {ScheduleDay} from "../../entity/schedule/structures/ScheduleDayStructure";
import {SubmissionError} from "../CustomErrors";
import {ScheduleSettings} from "../../entity/schedule/structures/ScheduleSettingsStructure";

export default class ManageScheduleUseCase {
    private scheduleRepository: ScheduleRepository;

    constructor(scheduleRepository: ScheduleRepository) {
        this.scheduleRepository = scheduleRepository;
    }

    getDoctorSchedule = (id: string, from: string, till: string) => {
        return this.scheduleRepository
            .getDoctorSchedule(id, from, till)
            .then((scheduleDays: [ScheduleDay[], []]) => {
                return scheduleDays;
            })
    };

    changeAppointmentByCall = (appointmentByCall: boolean): Promise<ScheduleSettings> => {
        return this.scheduleRepository
            .changeAppointmentByCall(appointmentByCall)
            .then((object) => {
                return Promise.resolve(object);
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error.validationErrors, (error?.data?.message ? error.data.message : (error?.message ? error.message : 'Unknown error'))));
            });
    }

    getDoctorScheduleSettings = (id: string): Promise<ScheduleSettings> => {
        return this.scheduleRepository
            .getDoctorScheduleSettings(id)
            .then((object) => {
                return Promise.resolve(object);
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error.validationErrors, (error?.data?.message ? error.data.message : (error?.message ? error.message : 'Unknown error'))));
            });
    }

    saveDoctorScheduleSettings = (scheduleSettings: any): Promise<ScheduleSettings> => {
        return this.scheduleRepository
            .saveDoctorScheduleSettings(scheduleSettings)
            .then((object) => {
                return Promise.resolve(object);
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error.validationErrors, (error?.data?.message ? error.data.message : (error?.message ? error.message : 'Unknown error'))));
            });
    }
}

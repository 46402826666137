// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useViewModels } from "presentation/view-model/ViewModelProvider";
import DoctorsViewModel from "../../../view-model/doctors/DoctorsViewModel";
import DoctorExperienceViewModel from "../../../view-model/doctorExperience/DoctorExperienceViewModel";
import LanguagesViewModel from "../../../view-model/languages/LanguagesViewModel";
import SpecialitiesViewModel from "../../../view-model/specialities/SpecialitiesViewModel";

import st from "./doctorsSearch.module.scss";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import { AutocompleteRenderInputParams, Button, TextField, ToggleButtonGroup, ToggleButton, Input } from "@mui/material";
/* Date block
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { MobileDatePicker as FormikDatePicker } from "formik-mui-lab";
import { LocaleMap } from "../../../../types";
*/

import { Autocomplete as AutocompleteFormik } from "formik-mui";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search.svg";
import { ReactComponent as ExpandIcon } from "../../assets/images/icons/expand.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/icons/cross_14_2.svg";
import { ReactComponent as FiltersIcon } from "../../assets/images/icons/filters.svg";
import { ReactComponent as CheckerIcon } from "../../assets/images/icons/checker.svg";
import { ReactComponent as InsurancePlusIcon } from '../../assets/images/icons/insurance-1.svg';
import { ReactComponent as InsuranceSchieldIcon } from '../../assets/images/icons/insurance-2.svg';
import { withRouter, useLocation } from "react-router-dom";
import { NonAuthRoutes } from "../../../routes/routes";

interface doctorsSearchProps {
    close?: Function;
    isHomePage?: boolean;
}


export const DoctorsSearch: React.FC<doctorsSearchProps> = withRouter((
    {
        close,
        isHomePage,
        history,
    }) => {
    const intl = useIntl();
    const routLocation = useLocation();
    const {insuranceViewModel: {types}, userViewModel: { locale }, uIViewModel: { tabletIs }} = useViewModels();
    // const [heroPickerOpen, setHeroPickerOpen] = useState(false);
    const insurancesOptions = [
        { insuranceValue: types[0], title: intl.formatMessage({ id: "gesetzlichType" }), icon: <InsurancePlusIcon /> },
        { insuranceValue: types[1], title: intl.formatMessage({ id: "privatType" }), icon: <InsuranceSchieldIcon /> },
    ];
    const filterGenderOptions = [
        { genderValue: 'male', title: intl.formatMessage({ id: "app.doctorSearch.filter.gender.male" }) },
        { genderValue: 'female', title: intl.formatMessage({ id: "app.doctorSearch.filter.gender.female" }) },
        { genderValue: 'others', title: intl.formatMessage({ id: "app.doctorSearch.filter.gender.others" }) },
    ];
    const [experienceOptions, setExperienceOptions] = useState([]);
    const [showClearIcon, setShowClearIcon] = useState(false); // For label smooth animation
    const [noExperienceOptionsText, setNoExperienceOptionsText] = useState(intl.formatMessage({ id: "app.doctorSearch.filter.tip.location" }));
    const [startExperienceRequest, setStartExperienceRequest] = useState(0);
    const [languagesOptions, setLanguagesOptions] = useState([]);
    const [mobileFilters, setMobileFilters] = useState(false);
    const contentEl = React.useRef(null);

    const handleLocationChange = (e: React.SyntheticEvent) => {
        const searchValue = e.target.value.trim();
        if (searchValue.length > 2 && (Date.now() - startExperienceRequest > 300)) {
            setStartExperienceRequest(Date.now());

            DoctorExperienceViewModel.getDoctorExperiencePromise(searchValue).then(data => {
                setNoExperienceOptionsText(intl.formatMessage({ id: "tip.noResults" }));
                let filteredExperience = new Set();
                const regexp = new RegExp( searchValue, 'i' )
                const fieldForSearch = ['country', 'city', 'state', 'zipCode', ]

                for (let item of data.items) {
                    fieldForSearch.forEach(value => {
                        if (regexp.test(item[value])) {
                            filteredExperience.add(item[value]);
                        }
                    });
                }

                if (filteredExperience.size) {
                    setExperienceOptions(Array.from(filteredExperience));
                }
            });
        }
    }

    useEffect(() => {
        let isMounted = true;

        if (!routLocation?.state?.search && isMounted) {
            DoctorsViewModel.setFilterOrSort('reset');
        }

        return () => { isMounted = false };
    }, [routLocation]);

    useEffect(() => {
        let isMounted = true;

        if (isHomePage) {
            if (SpecialitiesViewModel.specialitiesForSearch.length === 0 || SpecialitiesViewModel.locale !== locale) {
                SpecialitiesViewModel.getSpecialitiesForSearch(locale);
            }
        } else {
            if (LanguagesViewModel.languages.length === 0 || LanguagesViewModel.languages.length > 99) {
                LanguagesViewModel.getLanguagesPromise('doctors.active=true')
                    .then(data => {
                        LanguagesViewModel.languages = data.items;

                        if (isMounted) {
                            setLanguagesOptions(data.items);
                        }
                    });
            } else {
                setLanguagesOptions(LanguagesViewModel.languages);
            }

        }

        return () => { isMounted = false };
    }, [isHomePage, locale]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                firstSmartInput: DoctorsViewModel.searchParameters.firstSmartInput,
                location: DoctorsViewModel.searchParameters.locationSmartInput ? DoctorsViewModel.searchParameters.locationSmartInput : null,
                insuranceType: DoctorsViewModel.searchParameters.insuranceType ? DoctorsViewModel.searchParameters.insuranceType : [],
                // date: '',
                // gender: '', // for Select (not Autocomplete)
                gender: null,
                spokenLanguages: null,
            }}

            onSubmit={(values, { setSubmitting }) => {
                if (isHomePage) {
                    history.push({
                        pathname: (locale && locale !== 'de' ? '/' + locale : '') + NonAuthRoutes.doctors,
                        state: { search: 1 }
                    });
                } else {
                    DoctorsViewModel.setFilterOrSort('firstSmartInput', values.firstSmartInput, true);
                    setSubmitting(false);
                }
            }}
        >
            {(props) => (
                <Form className={st.search_form}>
                    <div className={classNames("search-bar", st.search_bar)}>
                        <div className={classNames(st.input_box, st.doctor)}>
                            <Field
                                id="firstSmartInput"
                                name="firstSmartInput"
                                type="text"
                                className={st.input}
                                fullWidth={true}
                            >
                                {({field, form, meta}) => <Input
                                    className={st.input_query}
                                    variant="standard"
                                    placeholder={intl.formatMessage({ id: "app.doctorSearch.label.query.placeholder" })}
                                    onChange={
                                        (e) => {
                                            // props.handleChange(e);
                                            props.setFieldValue('firstSmartInput', e.currentTarget.value);
                                            DoctorsViewModel.setFilterOrSort('firstSmartInput', e.currentTarget.value ?? '');
                                        }}
                                />}
                            </Field>

                            <label htmlFor="firstSmartInput" className={classNames(st.label, st.label_active)}>
                                {intl.formatMessage({ id: "app.doctorSearch.label.query" })}
                            </label>
                        </div>

                        <div className={classNames(st.input_box, st.location)}>
                            <Field
                                blurOnSelect={true}
                                id="searchBarLocation"
                                noOptionsText={noExperienceOptionsText}
                                onChange={
                                    (e, newValue) => {
                                        setShowClearIcon(prevState => !prevState);
                                        props.setFieldValue('location', newValue);
                                        DoctorsViewModel.setFilterOrSort('locationSmartInput', newValue ?? '', Boolean(!isHomePage));
                                    }}
                                className={classNames(st.input, st.input_autocomplete)}
                                disablePortal
                                popupIcon={false}
                                clearIcon={(props.values.location || showClearIcon) ? <CrossIcon /> : false}
                                name="location"
                                filterSelectedOptions
                                component={AutocompleteFormik}
                                options={experienceOptions}
                                getOptionLabel={(option: any) => option}
                                fullWidth={true}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <TextField
                                        {...params}
                                        className={st.filters_bar__select}
                                        variant="standard"
                                        placeholder={intl.formatMessage({ id: "app.doctorSearch.label.city.placeholder" })}
                                        onChange={handleLocationChange}
                                    />
                                )}
                            />

                            <label htmlFor="searchBarLocation" className={classNames(st.label, st.label_for_select, st.label_active)}>
                                {intl.formatMessage({ id: "app.doctorSearch.label.city" })}
                            </label>
                        </div>

                        <div className={classNames(st.input_box, st.insurance)}>
                            <label htmlFor="searchBarInsurance" className={classNames(st.label, st.label_for_select, st.label_active)}>
                                {intl.formatMessage({ id: "app.doctorSearch.label.insurance" })}
                            </label>

                            <ToggleButtonGroup
                                aria-label="search bar insurance"
                                className={st.radio_group}
                                value={props.values.insuranceType}
                                onChange={(e, newValue) => {
                                    setShowClearIcon(prevState => !prevState);
                                    props.setFieldValue('insuranceType', newValue ?? []);
                                    DoctorsViewModel.setFilterOrSort('insuranceType', newValue ?? [], Boolean(!isHomePage));
                                }}
                            >
                                {insurancesOptions?.map((item, index) => {
                                    return (
                                        <ToggleButton
                                            key={index + "searchBarInsurance"}
                                            className={classNames(st.radio_group__item, item.insuranceValue)}
                                            value={item.insuranceValue}
                                        >
                                            <div className={st.radio_group__item_icon}>
                                                {item.icon}
                                            </div>
                                            <span>{item.title}</span>
                                        </ToggleButton>
                                    );
                                })}
                              </ToggleButtonGroup>
                        </div>

                        <div className={st.button}>
                            <Button type="submit" color="secondary" variant="circle" size="medium">
                                <SearchIcon />
                            </Button>
                        </div>
                    </div>

                    {isHomePage ?
                        <div className={st.search_tags}>
                            <div className={st.search_tags_container}>
                                {SpecialitiesViewModel.specialitiesForSearch.map((item, index) => (
                                    <div key={index} className={st.search_tag} onClick={() => {
                                        DoctorsViewModel.setFilterOrSort('firstSmartInput', item.name);
                                        history.push({
                                            pathname: (locale && locale !== 'de' ? '/' + locale : '') + NonAuthRoutes.doctors,
                                            state: { search: 1 }
                                        });
                                    }}>
                                        {item.name}
                                    </div>
                                ))}
                            </div>

                            <div className={st.proposal}>
                                <div className={st.help_with_diagnosis}>{intl.formatMessage({ id: "app.doctorSearch.diagnosis" })}</div>

                                <div className={st.proposal_checker}>
                                    {<CheckerIcon />}
                                    <span>{intl.formatMessage({ id: "app.doctorSearch.symptomChecker" })}</span>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            {tabletIs ? (
                                <>
                                    <div className={st.mobile_filters_header}>
                                        <div className={st.mobile_filters_title} onClick={() => setMobileFilters(!mobileFilters)}>
                                            <FiltersIcon className={(props.values.gender || props.values.spokenLanguages) ? st.mobile_filters_active : ''} />
                                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.doctorSearch.filters" }) }} />
                                            <ExpandIcon className={classNames(st.mobile_filters_expand, mobileFilters ? st.mobile_filters_expand_active : "")} />
                                        </div>
                                    </div>
                                    <div
                                        className={classNames(st.mobile_filters_body, mobileFilters ? st.mobile_filters_body_active : '')}
                                        ref={contentEl}
                                        style={mobileFilters ? { maxHeight: contentEl.current.scrollHeight } : { maxHeight: '0px' }}
                                    >
                                        <div className={classNames(st.input_box, st.gender)}>
                                            <Field
                                                blurOnSelect={true}
                                                id="genderBarLocation"
                                                onChange={
                                                    (e, newValue) => {
                                                        props.setFieldValue('gender', newValue);
                                                        DoctorsViewModel.setFilterOrSort('profile.gender', newValue?.genderValue ?? '', true);
                                                    }}
                                                className={classNames(st.input, st.input_autocomplete)}
                                                disablePortal
                                                popupIcon={!props.values.gender ? <ExpandIcon /> : false}
                                                clearIcon={props.values.gender ? <CrossIcon /> : false}
                                                name="gender"
                                                component={AutocompleteFormik}
                                                options={filterGenderOptions}
                                                getOptionLabel={(option: any) => option.title}
                                                isOptionEqualToValue={(option, value) => option.title === value.title}
                                                fullWidth={true}
                                                renderInput={(params: AutocompleteRenderInputParams) => (
                                                    <TextField
                                                        {...params}
                                                        className={st.filters_bar__select}
                                                        variant="standard"
                                                        placeholder={intl.formatMessage({ id: "app.doctorSearch.filter.gender.placeholder" })}
                                                    />
                                                )}
                                            />

                                            <label htmlFor="genderBarLocation" className={classNames(st.label, st.label_for_select, st.label_active)}>
                                                {intl.formatMessage({ id: "app.doctorSearch.filter.gender.placeholder" })}
                                            </label>
                                        </div>
                                        <div className={classNames(st.input_box, st.spokenLanguages)}>
                                            <Field
                                                blurOnSelect={true}
                                                id="spokenLanguagesBarLocation"
                                                onChange={
                                                    (e, newValue) => {
                                                        props.setFieldValue('spokenLanguages', newValue);
                                                        DoctorsViewModel.setFilterOrSort('profile.spokenLanguages', newValue?.spokenLanguagesValue ?? '', true);
                                                    }}
                                                className={classNames(st.input, st.input_autocomplete)}
                                                disablePortal
                                                popupIcon={!props.values.spokenLanguages ? <ExpandIcon /> : false}
                                                clearIcon={props.values.spokenLanguages ? <CrossIcon /> : false}
                                                name="spokenLanguages"
                                                component={AutocompleteFormik}
                                                options={languagesOptions}
                                                getOptionLabel={option => (option.title ? option.title : "")}
                                                isOptionEqualToValue={(option, value) => value.title === "" || option.title === value.title}
                                                fullWidth={true}
                                                renderInput={(params: AutocompleteRenderInputParams) => (
                                                    <TextField
                                                        {...params}
                                                        className={st.filters_bar__select}
                                                        variant="standard"
                                                        placeholder={intl.formatMessage({ id: "app.doctorSearch.filter.spokenLanguages" })}
                                                    />
                                                )}
                                            />

                                            <label htmlFor="genderBarLocation" className={classNames(st.label, st.label_for_select, st.label_active)}>
                                                {intl.formatMessage({ id: "app.doctorSearch.filter.spokenLanguages" })}
                                            </label>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={classNames(st.input_box, st.gender)}>
                                        <Field
                                            blurOnSelect={true}
                                            id="genderBarLocation"
                                            onChange={
                                                (e, newValue) => {
                                                    props.setFieldValue('gender', newValue);
                                                    DoctorsViewModel.setFilterOrSort('profile.gender', newValue?.genderValue ?? '', true);
                                                }}
                                            className={classNames(st.input, st.input_autocomplete)}
                                            disablePortal
                                            popupIcon={!props.values.gender ? <ExpandIcon /> : false}
                                            clearIcon={props.values.gender ? <CrossIcon /> : false}
                                            name="gender"
                                            component={AutocompleteFormik}
                                            options={filterGenderOptions}
                                            getOptionLabel={(option: any) => option.title}
                                            isOptionEqualToValue={(option, value) => option.title === value.title}
                                            fullWidth={true}
                                            renderInput={(params: AutocompleteRenderInputParams) => (
                                                <TextField
                                                    {...params}
                                                    className={st.filters_bar__select}
                                                    variant="standard"
                                                    placeholder={intl.formatMessage({ id: "app.doctorSearch.filter.gender.placeholder" })}
                                                />
                                            )}
                                        />

                                        <label htmlFor="genderBarLocation" className={classNames(st.label, st.label_for_select, st.label_active)}>
                                            {intl.formatMessage({ id: "app.doctorSearch.filter.gender.placeholder" })}
                                        </label>
                                    </div>
                                    <div className={classNames(st.input_box, st.spokenLanguages)}>
                                        <Field
                                            blurOnSelect={true}
                                            id="spokenLanguagesBarLocation"
                                            onChange={
                                                (e, newValue) => {
                                                    props.setFieldValue('spokenLanguages', newValue);
                                                    DoctorsViewModel.setFilterOrSort('profile.spokenLanguages', newValue?.spokenLanguagesValue ?? '', true);
                                                }}
                                            className={classNames(st.input, st.input_autocomplete)}
                                            disablePortal
                                            popupIcon={!props.values.spokenLanguages ? <ExpandIcon /> : false}
                                            clearIcon={props.values.spokenLanguages ? <CrossIcon /> : false}
                                            name="spokenLanguages"
                                            component={AutocompleteFormik}
                                            options={languagesOptions}
                                            getOptionLabel={option => (option.title ? option.title : "")}
                                            isOptionEqualToValue={(option, value) => value.title === "" || option.title === value.title}
                                            fullWidth={true}
                                            renderInput={(params: AutocompleteRenderInputParams) => (
                                                <TextField
                                                    {...params}
                                                    className={st.filters_bar__select}
                                                    variant="standard"
                                                    placeholder={intl.formatMessage({ id: "app.doctorSearch.filter.spokenLanguages" })}
                                                />
                                            )}
                                        />

                                        <label htmlFor="genderBarLocation" className={classNames(st.label, st.label_for_select, st.label_active)}>
                                            {intl.formatMessage({ id: "app.doctorSearch.filter.spokenLanguages" })}
                                        </label>
                                    </div>
                                </>
                            )}
                            <div className={st.filters_bar}>
                                <div className={classNames("filters-bar", st.filters_bar__panel)}>
                                    <div className={classNames(st.filters_bar__card, `${props.values.gender ? st.card_active : ""}`)}>
                                        <Field
                                            blurOnSelect={true}
                                            onChange={
                                                (e, newValue) => {
                                                props.setFieldValue('gender', newValue);
                                                DoctorsViewModel.setFilterOrSort('profile.gender', newValue?.genderValue ?? '', true);
                                            }}
                                            disablePortal
                                            popupIcon={!props.values.gender ? <ExpandIcon /> : false}
                                            clearIcon={props.values.gender ? <CrossIcon /> : false}
                                            name="gender"
                                            id="mobileGender"
                                            component={AutocompleteFormik}
                                            options={filterGenderOptions}
                                            getOptionLabel={(option: any) => option.title}
                                            isOptionEqualToValue={(option, value) => option.title === value.title}
                                            fullWidth={true}
                                            renderInput={(params: AutocompleteRenderInputParams) => (
                                                <TextField
                                                {...params}
                                                    name="gender"
                                                    className={st.filters_bar__select}
                                                    variant="standard"
                                                    placeholder={intl.formatMessage({ id: "app.doctorSearch.filter.gender.placeholder" })}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className={classNames(st.filters_bar__card, `${props.values.spokenLanguages ? st.card_active : ""}`)}>
                                        <Field
                                            blurOnSelect={true}
                                            onChange={
                                                (e, newValue) => {
                                                    DoctorsViewModel.setFilterOrSort('spokenLanguages', newValue?.id ?? '', true);
                                                    props.setFieldValue('spokenLanguages', newValue);
                                                }
                                            }
                                            disablePortal
                                            popupIcon={!props.values.spokenLanguages ? <ExpandIcon /> : false}
                                            clearIcon={props.values.spokenLanguages ? <CrossIcon /> : false}
                                            name="spokenLanguages"
                                            component={AutocompleteFormik}
                                            options={languagesOptions}
                                            getOptionLabel={option => (option.title ? option.title : "")}
                                            isOptionEqualToValue={(option, value) => value.title === "" || option.title === value.title}
                                            fullWidth={true}
                                            renderInput={(params: AutocompleteRenderInputParams) => (
                                                <TextField
                                                    {...params}
                                                    name="spokenLanguages"
                                                    className={st.filters_bar__select}
                                                    sx={{minWidth: '180px'}}
                                                    variant="standard"
                                                    placeholder={intl.formatMessage({ id: "app.doctorSearch.filter.spokenLanguages" })}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className={st.filters_bar__reset} onClick={() => {
                                    props.handleReset();
                                    props.values.insuranceType = null;
                                    props.values.gender = null;
                                    props.values.location = null;
                                    props.values.spokenLanguages = '';
                                    DoctorsViewModel.setFilterOrSort('reset', true, true);
                                }}>
                                    <CrossIcon />
                                    <div>{intl.formatMessage({ id: "app.doctorSearch.reset" })}</div>
                                </div>
                            </div>

                            <div
                                className={st.filters_bar__reset_mobile}
                                onClick={() => {
                                    props.handleReset();
                                    props.values.insuranceType = null;
                                    props.values.gender = null;
                                    props.values.location = null;
                                    props.values.spokenLanguages = '';
                                    DoctorsViewModel.setFilterOrSort('reset', true, true);
                                }}
                            >
                                <CrossIcon />
                                <div>{intl.formatMessage({ id: "app.doctorSearch.reset" })}</div>
                            </div>
                        </>
                    }

                    <div className={st.mobile_button_search}>
                        <Button type="submit" fullWidth color="secondary" variant="contained" onClick={close && (() => close())}>
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.doctorSearch.search" }) }} />
                        </Button>
                    </div>
                    {/*<pre style={{color: "saddlebrown", display: "flex", justifyContent: "flex-end",}}>{JSON.stringify(props.values, 0, 2)}</pre>*/}
                </Form>
            )}
        </Formik>
    );
});

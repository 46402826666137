// @ts-nocheck
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { action } from "mobx";
import { Link } from 'react-router-dom';
import { Button, Typography } from "@mui/material";
import TooltipedCard from "presentation/views/components/TooltipedCard";
import EntityInfo from "presentation/views/components/EntityInfo";
import { defaultTheme } from "presentation/views/layouts/themes/primaryMuiTheme";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import { useViewModels } from "presentation/view-model/ViewModelProvider";

import cn from "classnames/bind";
import st from "../Dashboard/dashboard.module.scss";
import axios from "axios";
import { Connection } from "../../../../../../domain/entity/connections/structures/ConnectionStructure";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { Popup } from "../../../../components/Popup";
import { NonAuthRoutes } from "../../../../../routes/routes";
import stylePopup from "presentation/views/components/Popup/popup.module.scss";

const Connections = () => {
    const intl = useIntl();
    const query = new URLSearchParams(useLocation().search);
    const {
        uIViewModel: { openPopup, closePopup, setSuccessAlertContent, setErrorAlertContent },
        connectionViewModel,
        userViewModel,
    } = useViewModels();
    const [connectionType, setConnectionType] = useState('1');
    const [changeConnectionStatus, setChangeConnectionStatus] = useState<'cancel' | 'reject' | 'accept' | ''>('');
    const [changeConnectionId, setChangeConnectionId] = useState<string>('');
    const userId = userViewModel.identifier;

    const resolveFullNameWithWhomConnection = (connection: Connection, onlyFirstLetters?: boolean): any  => {
        let fullName = '';
        [connection.sender, connection.recipient].forEach((user) => {
            if (user.id !== userId) {
                if (onlyFirstLetters) {
                        fullName = (user.profile?.firstName ?? '').charAt(0) + (user.profile?.lastName ?? '').charAt(0);
                } else {
                    fullName = user.label;
                }
            }
        })

        return fullName;
    }

    const resolveAvatarWithWhomConnection = (connection: Connection): any  => {
        let avatar = null;
        [connection.sender, connection.recipient].forEach((user) => {
            if (user.id !== userId) {
                avatar = user.profile?.avatar?.contentUrl ? (axios.defaults.baseURL + user.profile?.avatar?.contentUrl) : '';
            }
        })

        return avatar;
    }

    const handleChange = (event: any, newValue: any) => {
        window.history.pushState({}, '', `${window.location.pathname}?type=${newValue}`);
        setConnectionType(newValue);
    };

    const handleStatusButtonClick = (connectionId: string, status: ('cancel' | 'reject' | 'accept')) => {
        setChangeConnectionId(connectionId);
        setChangeConnectionStatus(status);
        action(() => {
                connectionViewModel.complete.changeConnectionStatus = false;
                connectionViewModel.errors.changeConnectionStatus = '';
            }
        )

        openPopup();
    }

    const handleApproveSkillClick = (connection: any) => {
        let resolvedDoctorId = '';
        if (connection.recipient.id === userId) {
            resolvedDoctorId = connection.sender.id;
        } else if (connection.sender.id === userId) {
            resolvedDoctorId = connection.recipient.id;
        }

        setSuccessAlertContent('', true);
        setErrorAlertContent('', true);

        connectionViewModel.askToApproveSkills(connection, resolvedDoctorId)
            .then((data) => {
                setSuccessAlertContent(intl.formatMessage({id: "requestSent"}));
            })
            .catch((error) => {
                setErrorAlertContent(intl.formatMessage({id: "errorPosting"}));
            });
    }

    const handleSubmit = () => {
        connectionViewModel.changeConnectionStatus(changeConnectionId, changeConnectionStatus);
    }

    useEffect(() => {
        connectionViewModel.getMyConnections();
        connectionViewModel.getConnectionsRequests();
        connectionViewModel.getOutgoingConnectionsRequests();
    }, [connectionViewModel]);

    const queryTypeConnection = query.get("type");
    useEffect(() => {
        // Connections types: 1 - exists; 2 - requests; 3 - outgoing
        if ((typeof queryTypeConnection === 'string') && ['1', '2', '3'].includes(queryTypeConnection)) {
            if (connectionType !== queryTypeConnection) {
                setConnectionType(queryTypeConnection);
            }
        }
    }, [queryTypeConnection]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <section className={st.section}>
            <Popup>
                <Typography
                    variant="h5"
                    className={stylePopup.title}
                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.changeConnectionStatus.title" }, {status: changeConnectionStatus}) }}
                />

                {connectionViewModel.complete.changeConnectionStatus ?
                    <>
                        <Typography variant="p5" className={stylePopup.text}>
                            {intl.formatMessage({ id: `cabinet.connection.complete.${changeConnectionStatus}` })}
                        </Typography>

                        <Button type="button" color="secondary" sx={{ mt: 6, width: "100%" }} variant="contained" onClick={closePopup}>
                            {intl.formatMessage({ id: "ok" })}
                        </Button>
                    </>
                    :
                    <>
                        <Typography variant="p5" className={stylePopup.text}>
                            {intl.formatMessage({ id: "cabinet.connection.confirm" })}
                        </Typography>

                        <Button type="submit" color="secondary" sx={{ mt: {xs: 2, sm: 4, md: 6}, width: "100%" }} variant="contained" onClick={handleSubmit}>
                            {intl.formatMessage({ id: "submit" })}
                        </Button>

                        {connectionViewModel.errors &&
                            <Typography color={defaultTheme.palette.primary.error} sx={{position: "absolute", bottom: "30px",}}>{connectionViewModel.errors.changeConnectionStatus}</Typography>
                        }
                    </>
                }

            </Popup>

            <Typography variant="h5" className={cn(st.section__title, st.section__title_isLg)}>
                {intl.formatMessage({id: "cabinet.connection.title"})}
            </Typography>

            <TabContext value={connectionType}>
                <Tabs value={connectionType} onChange={handleChange} aria-label="connections tabs buttons" variant="scrollable" scrollButtons={false}>
                    <Tab label={intl.formatMessage({id: "cabinet.connection.tabs.connections"})} value="1" sx={{ paddingLeft: 0 }} />
                    <Tab label={intl.formatMessage({id: "cabinet.connection.tabs.requests"})} value="2" />
                    <Tab label={intl.formatMessage({id: "cabinet.connection.tabs.outgoing"})} value="3" />
                </Tabs>

                <TabPanel value="1" sx={{ padding: 0 }}>
                    {connectionViewModel.myConnections.length > 0 ?
                        connectionViewModel.myConnections?.map((item, index) => (
                            <TooltipedCard
                                key={item.id}
                                variant="bordered"
                                buttons={
                                    item.approvedSkillsCount ?
                                        <Typography variant="t2news">{intl.formatMessage({id: "skillsApproven"},
                                            {count: item.approvedSkillsCount, label: resolveFullNameWithWhomConnection(item)})}
                                        </Typography>
                                        :
                                        <Button disabled={item.askedToApproveSkills} color={"secondary"}
                                                variant={item.askedToApproveSkills ? "outlined" : "contained"}
                                                onClick={() => handleApproveSkillClick(item)}
                                        >
                                            {intl.formatMessage({ id: item.askedToApproveSkills ? "cabinet.connection.askedApprove" : "cabinet.connection.askApprove" })}
                                        </Button>
                                }
                                tooltip={""}
                            >
                                <Link className={st.connection_item} to={NonAuthRoutes.doctors + '/' + (item.sender.id !== userId ? item.sender.id : item.recipient.id)}>
                                    <EntityInfo
                                        fio={resolveFullNameWithWhomConnection(item, true)}
                                        avatarSize={64}
                                        pictureUrl={resolveAvatarWithWhomConnection(item)}>
                                        <Typography variant="t3">
                                            {resolveFullNameWithWhomConnection(item)}
                                        </Typography>

                                        <Typography variant="t2news">
                                            {intl.formatMessage({id: "cabinet.connection.text.existing"})} {item.beautyCreatedAt}
                                        </Typography>
                                    </EntityInfo>
                                </Link>
                            </TooltipedCard>
                        ))
                        :
                        <div className={st.empty_dashboard}>You don’t have existing connections.</div>
                    }
                </TabPanel>

                <TabPanel value="2" sx={{ padding: 0 }}>
                    {connectionViewModel.connectionsRequests.length > 0 ?
                        connectionViewModel.connectionsRequests?.map((item, index) => (
                            <TooltipedCard
                                key={item.id}
                                variant="bordered"
                                buttons={
                                    <div className={st.buttons__container}>
                                        <Button fullWidth color="secondary" variant="outlined"
                                                onClick={() => handleStatusButtonClick(item.id, "reject")}
                                        >
                                            {intl.formatMessage({ id: "cabinet.connection.reject" })}
                                        </Button>

                                        <Button fullWidth color="secondary" variant="contained" sx={{ marginLeft: "16px" }}
                                                onClick={() => handleStatusButtonClick(item.id, "accept")}
                                        >
                                            {intl.formatMessage({ id: "cabinet.connection.accept" })}
                                        </Button>
                                    </div>
                                }
                            >
                                <Link className={st.connection_item} to={NonAuthRoutes.doctors + '/' + (item.sender.id !== userId ? item.sender.id : item.recipient.id)}>
                                    <EntityInfo
                                        fio={item.sender.profile?.firstName && item.sender.profile.firstName.charAt(0) +
                                            ((item.sender.profile?.lastName && item.sender.profile.lastName.charAt(0)) ?? '')}
                                        avatarSize={63}
                                        pictureUrl={item.sender.profile?.avatar?.contentUrl !== undefined ?
                                            axios.defaults.baseURL + item.sender.profile.avatar.contentUrl
                                            : ''
                                        }>
                                        <Typography variant="t3">
                                            {item.sender.label}
                                        </Typography>

                                        <Typography variant="t2news">
                                            {intl.formatMessage({id: "cabinet.connection.text.requests"})} {item.beautyCreatedAt}
                                        </Typography>
                                    </EntityInfo>
                                </Link>
                            </TooltipedCard>
                        ))
                        :
                        <div className={st.empty_dashboard}>
                            {intl.formatMessage({id: "cabinet.connection.emptyList.requests"})}
                        </div>
                    }
                </TabPanel>

                <TabPanel value="3" sx={{ padding: 0 }}>
                    {connectionViewModel.outgoingConnectionsRequests.length > 0 ?
                        connectionViewModel.outgoingConnectionsRequests?.map((item, index) => (
                            <TooltipedCard
                                key={item.id}
                                variant="bordered"
                                buttons={
                                    <Button className="btn--short" color="secondary" variant="outlined"
                                        onClick={() => handleStatusButtonClick(item.id, 'cancel')}
                                    >
                                        {intl.formatMessage({ id: "cancel" })}
                                    </Button>
                                }
                            >
                                <Link className={st.connection_item} to={NonAuthRoutes.doctors + '/' + (item.sender.id !== userId ? item.sender.id : item.recipient.id)}>
                                    <EntityInfo
                                        fio={item.recipient.profile?.firstName && item.recipient.profile.firstName.charAt(0) +
                                        (item.recipient.profile?.lastName ? item.recipient.profile.lastName.charAt(0) : '')}
                                        avatarSize={63}
                                        pictureUrl={item.recipient.profile?.avatar?.contentUrl !== undefined ?
                                            axios.defaults.baseURL + item.recipient.profile.avatar.contentUrl
                                            : ''
                                        }>
                                        <Typography variant="t3">
                                            {item.recipient.label}
                                        </Typography>

                                        <Typography variant="t2news">
                                            {intl.formatMessage({id: "cabinet.connection.text.outgoing"})} {item.beautyCreatedAt}
                                        </Typography>
                                    </EntityInfo>
                                </Link>
                            </TooltipedCard>
                        ))
                        :
                        <div className={st.empty_dashboard}>
                            {intl.formatMessage({id: "cabinet.connection.emptyList.outgoing"})}
                        </div>
                    }
                </TabPanel>
            </TabContext>
        </section>
    );
};

export default observer(Connections);

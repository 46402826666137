import LanguagesApi from "../../../data/languages/LanguagesApi";
import {makeAutoObservable} from "mobx";

class LanguagesViewModel {
    public languages = [];

    constructor() {
        makeAutoObservable(this);
    }

    getLanguagesPromise = (param = undefined) => {
        return LanguagesApi.getLanguages(param);
    }

}

export default new LanguagesViewModel();

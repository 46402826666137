import axios from "axios";
import qs from "qs";

import SingleNews from "domain/entity/news/structures/SingleNews";
import NewsRepository from "domain/repository/news/NewsRepository";

export default class NewsApi implements NewsRepository {
    load = (searchParams: any): Promise<SingleNews[]> => {
        const queryString = qs.stringify(searchParams, {
            allowDots: true,
            encode: false,
        });

        return axios({
            method: "GET",
            url: axios.defaults.baseURL + `api/news?${queryString}`,
        }).then((serverNews: any) => {
            return serverNews.data.items.map((serverPost: any) => {
            return {
                    ...serverPost,
                    active: serverPost["active"],
                    content: serverPost["content"],
                    picture: serverPost["picture"]?.contentUrl || "",
                    title: serverPost["title"],
                    id: serverPost["id"],
                    createdAt: serverPost["createdAt"]?.slice(0, 10),
                };
            });
        });
    };

    loadAvailableYears = (): Promise<number[]> => {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + `api/news/available-years`,
        }).then((serverAvailableYears: any) => {
            return serverAvailableYears.data.map((obj: any) => obj.year);
        });
    };

    loadOtherNews = (id: string): Promise<SingleNews[]> => {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + `api/news/${id}/other-news`,
        }).then((serverNews: any) => {
            return serverNews.data.map((serverPost: any) => {
                return {
                    ...serverPost,
                    active: serverPost["active"],
                    content: serverPost["content"],
                    picture: serverPost["picture"]?.contentUrl || "",
                    title: serverPost["title"],
                    id: serverPost["id"],
                    createdAt: serverPost["createdAt"]?.slice(0, 10),
                };
            });
        });
    };

    loadPostById = (id: string): Promise<SingleNews> => {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + `api/news/${id}`,
        }).then(({ data }: any) => {
            return {
                ...data,
                active: data["active"],
                content: data["content"],
                picture: data["picture"]?.contentUrl || "",
                title: data["title"],
                id: data["id"],
                createdAt: data["createdAt"],
            };
        });
    };
}

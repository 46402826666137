// @ts-nocheck
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { TextField, Typography, Grid, Box } from "@mui/material";
import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import { useViewModels } from "presentation/view-model/ViewModelProvider";

import styles from "../../doctorProfileEdit.module.scss";
import { ReactComponent as CrossIcon } from "../../../../../assets/images/icons/cross_14_2.svg";
import { ReactComponent as PlusIcon } from "../../../../../assets/images/icons/plus.svg";
import cleanDeep from "clean-deep";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { UserSaveButtons } from "../../../../../components/UserSaveButtons";


const Publications: React.FC = ({ changeDetector, setTab }) => {
    const intl = useIntl();
    const {
        doctorProfileViewModel: { options, onSaveProfileButtonClick },
        uIViewModel: { setSuccessAlertContent, setErrorAlertContent, },
    } = useViewModels();
    const [saveAndNext, setSaveAndNext] = useState(false);
    const initialValues = {
        id: null,
        title: "",
        link: "",
        year: '',
    };

    const validate = (values: any) => {
        const errors: any = {publications: {}};

        values.publications.forEach((item, index) => {
            if (!item.title.trim()) {
                errors.publications[index] = {title: "Required"};
            }
        });

        if (Object.keys(cleanDeep(errors)).length > 0) {
            setSaveAndNext(false);
        }

        return cleanDeep(errors);
    }

    const changeForm = useFormik({
        validate: validate,
        initialValues: {
            publications: (() => {
                if (options.publications.length === 0) {
                    return [initialValues];
                } else {
                    return (options.publications.map(value => {
                        return {
                            id: `api/doctor_publications/${value.id}`,
                            title: value.title ?? '',
                            link: value.link ?? '',
                            year: value.year ?? '',
                        }
                    }));
                }
            })(),
        },

        onSubmit: (values, { setErrors }) => {
            setSuccessAlertContent('', true);
            setErrorAlertContent('', true);

            onSaveProfileButtonClick(values)
                .then(() => {
                    if (saveAndNext) {
                        setSaveAndNext(false);
                        setTab('saveAndNext', '', false, true);
                    }
                    changeDetector(false);
                    setSuccessAlertContent(intl.formatMessage({id: "successSave"}));
                })
                .catch((error) => {
                    console.log(error);
                    setErrorAlertContent(intl.formatMessage({id: "errorSave"}));
                    if (error.errors) {
                        setErrors(error.errors);
                    }
                });
        },
    });

    return (
        <FormikProvider value={changeForm}>
            <Box component="form" noValidate onSubmit={changeForm.handleSubmit}>
                <Typography variant="h5" sx={{ mb: 5 }}
                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.profile.doctor.publications.title" }) }}
                />

                <FieldArray
                    name="publications"
                    render={(arrayHelpers) => (
                        <>
                            <Grid container spacing={4} sx={{ mb: {xs: 4, sm: 6,} }}>
                                {changeForm.values.publications?.map((publication, i) => (
                                    <Grid key={i} item xs={12} className={styles.container_universal}>
                                        <div className={styles.button_delete_universal}>
                                            <div className={styles.button_delete} role="button"
                                                 onClick={() => {
                                                     arrayHelpers.remove(i);
                                                 }}
                                            >
                                                <CrossIcon />

                                                <span
                                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.profile.doctor.education.delete" }) }} />
                                            </div>
                                        </div>

                                        <div className={styles.main_block_universal}>
                                            <Field
                                                name={`publications.[${i}].title`}
                                                children={(fieldProps: any) => (
                                                    <TextField
                                                        {...fieldProps.field}
                                                        onChange={(e) => {
                                                            changeForm.handleChange(e);
                                                            changeDetector(true);
                                                        }}
                                                        fullWidth
                                                        label={intl.formatMessage({id: "cabinet.profile.doctor.publications.publication"})}
                                                        type="text"
                                                        variant="standard"
                                                        error={changeForm.touched.publications?.[i]?.title && !changeForm.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                        helperText={changeForm.touched.publications?.[i]?.title && !changeForm.isValid && fieldProps.meta.error}
                                                    />
                                                )}
                                            />

                                            <Box sx={{ "> div": { width: {xs: '100%', sm: 'auto'} } }}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        name={`publications.[${i}].year`}
                                                        disableCloseOnSelect={false}
                                                        views={['year']}
                                                        label={intl.formatMessage({id: 'cabinet.profile.doctor.publications.year'})}
                                                        minDate={new Date('1970-01-01')}
                                                        maxDate={new Date()}
                                                        value={publication.year ? publication.year : null}
                                                        onChange={newValue => {
                                                            changeForm.setFieldValue(`publications[${i}].year`, newValue);
                                                            changeDetector(true);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} helperText={null} />}
                                                    />
                                                </LocalizationProvider>
                                            </Box>

                                            <Field
                                                name={`publications.[${i}].link`}
                                                children={(fieldProps: any) => (
                                                    <TextField
                                                        {...fieldProps.field}
                                                        onChange={(e) => {
                                                            changeForm.handleChange(e);
                                                            changeDetector(true);
                                                        }}
                                                        fullWidth
                                                        label={intl.formatMessage({id: "cabinet.profile.doctor.publications.link"})}
                                                        type="text"
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>

                            <div className={styles.button_add} role="button" onClick={() => {
                                    arrayHelpers.push(initialValues);
                                }}
                            >
                                <PlusIcon />

                                <span>{intl.formatMessage({ id: "cabinet.profile.doctor.publications.addPublication" })}</span>
                            </div>
                        </>
                    )}
                />

                <UserSaveButtons setSaveAndNext={setSaveAndNext} />
            </Box>
        </FormikProvider>
    );
};

export default (Publications);

import React from "react";
import st from "./plus.module.scss";
import classNames from "classnames";

interface plusProps {
    className?: string;
}

export const Plus: React.FC<plusProps> = ({className}) => {
    return (
        <div className={classNames(st.plus, className)}></div>
    );
};

// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
    RadioGroup,
    Autocomplete,
    TextField,
    Typography,
    Avatar,
    Box,
    FormControl,
    FormControlLabel,
    Radio,
    Grid,
    // Tab,
    FormHelperText,
} from "@mui/material";
import { FormikProvider, Field, useFormik } from "formik";
import primaryMuiTheme, { defaultTheme } from "presentation/views/layouts/themes/primaryMuiTheme";
import { useViewModels } from "presentation/view-model/ViewModelProvider";

import st from "./personalInformation.module.scss";
import axios from "axios";
import mediaObjectApi from "../../../../../../../data/mediaObject/mediaObjectApi";
// import { TabContext, TabList, TabPanel } from "@mui/lab";
import { observer } from "mobx-react";
import LanguagesViewModel from "../../../../../../view-model/languages/LanguagesViewModel";
import classNames from "classnames";
import { UserSaveButtons } from "../../../../../components/UserSaveButtons";
import { Spinner } from "../../../../../components/Spinner";


const PersonalInformation: React.FC = ({ changeDetector, setTab, congratulationsDetector }) => {
    const intl = useIntl();
    const {
        doctorProfileViewModel: { options: doctorOptions, onSaveProfileButtonClick, model },
        patientProfileViewModel: { patientProfile, updatePatient },
        patientProfileViewModel,
        authViewModel: { isDoctor },
        uIViewModel: { setSuccessAlertContent, setErrorAlertContent, }
    } = useViewModels();
    // const [value, setValue] = useState("id-0");
    const [saveAndNext, setSaveAndNext] = useState(false);
    const [spinnerShow, setSpinnerShow] = useState(false);
    const [languagesOptions, setLanguagesOptions] = useState([]);
    const options = isDoctor ? doctorOptions : patientProfile;

    /*const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };*/

    useEffect(() => {
        let isMounted = true;

        if (LanguagesViewModel.languages.length < 100) {
            LanguagesViewModel.getLanguagesPromise()
                .then(data => {
                    if (isMounted) {
                        LanguagesViewModel.languages = data.items;
                        setLanguagesOptions(data.items);
                    }
                });
        } else {
            if (isMounted) {
                setLanguagesOptions(LanguagesViewModel.languages);
            }
        }

        return () => { isMounted = false };
    }, []);

/*    const translation = [
        {
            key: "key-0",
            name: intl.formatMessage({ id: "cabinet.profile.doctor.personalInfo.about.translation.en" }),
            id: "id-0",
        },
        {
            key: "key-1",
            name: intl.formatMessage({ id: "cabinet.profile.doctor.personalInfo.about.translation.de" }),
            id: "id-1",
        },
        {
            key: "key-2",
            name: intl.formatMessage({ id: "cabinet.profile.doctor.personalInfo.about.translation.es" }),
            id: "id-2",
        },
    ];*/

    const validate = (values: any) => {
        // Adding fields here, also add them to the fill check when uploading the avatar.
        const errors: any = {};

        if (!values.firstName.trim()) {
            errors.firstName = "Required";
        }

        if (!values.lastName.trim()) {
            errors.lastName = "Required";
        }

        if (!values.gender) {
            errors.gender = "Required";
        }

        if (values.spokenLanguages.length === 0) {
            errors.spokenLanguages = "Required";
        }

        if (isDoctor) {
            if (!values.about.trim()) {
                errors.about = "Required";
            }
        }

        if (Object.keys(errors).length > 0) {
            setSaveAndNext(false);
        }

        return errors;
    }

    let initialValues = {
        id: options.profileId,
        avatarId: (options.avatarId ? `api/media_objects/${options.avatarId}` : null),
        firstName: options.firstName ?? '',
        lastName: options.lastName ?? '',
        gender: options.gender ?? null,
        spokenLanguages: (options?.spokenLanguages?.length ? options.spokenLanguages.map(item => `api/languages/${item.id}`) : []),
    };

    if (isDoctor) {
        initialValues.about = options.about ?? '';
        initialValues.title = options.title ?? '';
        initialValues.mCredential = options.mCredential ?? '';
    }

    const changeForm = useFormik({
        validate: validate,
        initialValues: initialValues,

        onSubmit: (values, { setErrors }) => {
            setSpinnerShow(true);
            setSuccessAlertContent('', true);
            setErrorAlertContent('', true);

            if (!isDoctor) {
                const profile = (values["firstName"] ?
                    {
                        id: (values.id ? `api/profiles/${values.id}` : null),
                        avatar: values.avatarId,
                        gender: values.gender,
                        lastName: values.lastName,
                        birthDate: values.birthDate,
                        firstName: values.firstName,
                    }
                    : undefined);
                values = {profile, spokenLanguages: values.spokenLanguages}
            }

            (isDoctor ? onSaveProfileButtonClick : updatePatient)(values)
                .then((result) => {
                    if (!options.active && result.active) {
                        congratulationsDetector();
                    }

                    if (saveAndNext) {
                        setSaveAndNext(false);
                        setTab('saveAndNext', '', false, true);
                    }
                    changeDetector(false);
                    setSuccessAlertContent(intl.formatMessage({id: "successSave"}));
                    setSpinnerShow(false);
                })
                .catch((error) => {
                    console.log(error);
                    setErrorAlertContent(intl.formatMessage({id: "errorSave"}));
                    setSpinnerShow(false);
                    if (error.errors) {
                        setErrors(error.errors);
                    }
                });
        },
    });

    const uploadAvatar = (e) => {
        setSuccessAlertContent('', true);
        setErrorAlertContent('', true);

        mediaObjectApi.loadMedia(e.target.files[0])
            .then((response) => {
                if (response.status === 201 && response.data.contentUrl) {
                    changeForm.setFieldValue('avatarId', `api/media_objects/${response.data.id}`);

                    if (changeForm.values.firstName
                        && changeForm.values.lastName
                        && (changeForm.values.spokenLanguages.length > 0)
                        && changeForm.values.gender
                        && (!isDoctor || changeForm.values.about)
                    ) {
                        changeForm.submitForm()
                            .then(() => {
                                setSuccessAlertContent(intl.formatMessage({ id: "successSave" }));
                            })
                            .catch((error) => {
                                console.log(error);
                                setErrorAlertContent(intl.formatMessage({ id: "errorSave" }));
                                if (error.errors) {
                                    setErrors(error.errors);
                                }
                            });
                    } else {
                        changeDetector(true);
                        options.avatar = response.data.contentUrl;
                    }
                } else {
                    console.log(response);
                }
            })
            .catch(error => {
                console.log(error);
                setErrorAlertContent(intl.formatMessage({ id: "errorSave" }));
            });
    }

    const deleteAvatar = () => {
        setSuccessAlertContent('', true);
        setErrorAlertContent('', true);

        changeForm.values.avatarId &&
        mediaObjectApi.deleteAvatar(changeForm.values.id)
            .then((response) => {
                if (response.status === 204) {
                    changeForm.setFieldValue('avatarId',  null);
                    const newData = { ...options, avatarId: null, avatar: null };

                    if (isDoctor) {
                        model.updateFromJson(newData)
                    } else {
                        patientProfileViewModel.updatePatientFromJson(newData);
                    }

                    setSuccessAlertContent(intl.formatMessage({ id: "successSave" }));
                } else {
                    console.log(response);
                    setErrorAlertContent(intl.formatMessage({ id: "errorSave" }));
                }
            })
            .catch(error => {
                console.log(error);
                setErrorAlertContent(intl.formatMessage({ id: "errorSave" }));
            });
    }

    return (
        <FormikProvider value={changeForm}>
            <Box component="form" noValidate onSubmit={changeForm.handleSubmit} className="form-loader">
                {spinnerShow && <Spinner />}

                <Typography variant="h5" sx={{ mb: 5 }}
                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.profile.doctor.personalInfo.title" }) }}
                />

                <Grid container spacing={4} sx={{ mb: 5 }}>
                    <Grid item xs={12} md={9}>
                        <Grid container alignItems="center">
                            <div className={st.avatar}>
                                {options?.avatar ? (
                                    <Avatar
                                        src={(axios.defaults.baseURL + options.avatar)}
                                        alt={`${options.firstName} ${options.lastName}`}
                                        sx={{
                                            ...primaryMuiTheme.typography.h3,
                                            width: { xs: "117px", sm: "192px" },
                                            height: { xs: "117px", sm: "192px" },
                                        }}
                                    />
                                ) : (
                                    <Avatar
                                        sx={{
                                            ...primaryMuiTheme.typography.h3,
                                            bgcolor: "rgba(0, 185, 255, 1)",
                                            width: { xs: "117px", sm: "192px" },
                                            height: { xs: "117px", sm: "192px" },
                                        }}
                                    >
                                        {options?.firstName?.slice(0, 1)}
                                        {options?.lastName?.slice(0, 1)}
                                    </Avatar>
                                )}

                            </div>

                            <Grid item color="primary" alignItems="center">
                                <Box sx={{ mb: 1 }}>
                                    <label>
                                        <input id="icon-button-file" type="file" style={{ display: "none" }} onChange={uploadAvatar}/>

                                        <Typography className="transition-def" variant="button" color={defaultTheme.palette.secondary.main}
                                                    sx={[{ textDecoration: "underline", cursor: "pointer", "&:hover": { color: '#9146FF'} }]}>
                                            {intl.formatMessage({ id: "cabinet.profile.doctor.personalInfo.changePhoto" })}
                                        </Typography>
                                    </label>
                                </Box>

                                <Typography variant="button" color={defaultTheme.palette.primary.light1} className="transition-def"
                                            sx={[{ textDecoration: "underline", cursor: "pointer", "&:hover": { color: '#9146FF'} }]}
                                            onClick={deleteAvatar}
                                >
                                    {intl.formatMessage({ id: "cabinet.profile.doctor.personalInfo.deletePhoto" })}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={4} sx={{ mb: 2.5 }}>
                    <Grid item xs={12} lg={9}>
                        <Grid container spacing={4}>
                            {isDoctor &&
                                <Grid item xs={12} md={6}>
                                    <Field
                                        name="title"
                                        children={(fieldProps: any) => (
                                            <TextField
                                                {...fieldProps.field}
                                                onChange={(e) => {
                                                    changeForm.handleChange(e);
                                                    changeDetector(true);
                                                }}
                                                fullWidth
                                                label={intl.formatMessage({ id: "cabinet.profile.doctor.title" })}
                                                type="text"
                                                variant="standard"
                                                error={changeForm.touched.title && !changeForm.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                helperText={changeForm.touched.title && !changeForm.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />
                                </Grid>
                            }

                            <Grid item xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="firstName"
                                            children={(fieldProps: any) => (
                                                <TextField
                                                    {...fieldProps.field}
                                                    onChange={(e) => {
                                                        changeForm.handleChange(e);
                                                        changeDetector(true);
                                                    }}
                                                    fullWidth
                                                    label={intl.formatMessage({id: "cabinet.profile.doctor.personalInfo.firstName"})}
                                                    type="text"
                                                    variant="standard"
                                                    error={changeForm.touched.firstName && !changeForm.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                    helperText={changeForm.touched.firstName && !changeForm.isValid && fieldProps.meta.error}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="lastName"
                                            children={(fieldProps: any) => (
                                                <TextField
                                                    {...fieldProps.field}
                                                    onChange={(e) => {
                                                        changeForm.handleChange(e);
                                                        changeDetector(true);
                                                    }}
                                                    fullWidth
                                                    label={intl.formatMessage({id: "cabinet.profile.doctor.personalInfo.lastName"})}
                                                    type="text"
                                                    variant="standard"
                                                    error={changeForm.touched.lastName && !changeForm.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                    helperText={changeForm.touched.lastName && !changeForm.isValid && fieldProps.meta.error}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {isDoctor &&
                                <>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="mCredential"
                                            children={(fieldProps: any) => (
                                                <TextField
                                                    {...fieldProps.field}
                                                    onChange={(e) => {
                                                        changeForm.handleChange(e);
                                                        changeDetector(true);
                                                    }}
                                                    fullWidth
                                                    label={intl.formatMessage({ id: "cabinet.profile.doctor.mCredential" })}
                                                    type="text"
                                                    variant="standard"
                                                    error={changeForm.touched.mCredential && !changeForm.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                    helperText={changeForm.touched.mCredential && !changeForm.isValid && fieldProps.meta.error}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={st.credential}>
                                            <span>{intl.formatMessage({ id: "userCard.personalCredential" })}:&nbsp;</span>
                                            {changeForm.values.title + " "
                                            + changeForm.values.firstName + " "
                                            + changeForm.values.lastName +
                                            (changeForm.values.mCredential ? ", " + changeForm.values.mCredential : "")}
                                        </div>
                                    </Grid>
                                </>
                            }

                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <Field
                                        name="gender"
                                        children={(fieldProps: any) => (
                                            <>
                                                <RadioGroup {...fieldProps.field} aria-label="gender" defaultValue="female"
                                                            className={classNames(st.radio_group, !changeForm.isValid && ("gender" in changeForm.errors) ? st.error : '')}
                                                            onChange={(e) => {
                                                                changeForm.handleChange(e);
                                                                changeDetector(true);
                                                            }}
                                                >
                                                    <FormControlLabel value="male" control={<Radio />}
                                                        label={intl.formatMessage({ id: "cabinet.profile.doctor.personalInfo.gender.male" })}
                                                    />

                                                    <FormControlLabel value="female" control={<Radio />}
                                                        label={intl.formatMessage({ id: "cabinet.profile.doctor.personalInfo.gender.female" })}
                                                    />

                                                    <FormControlLabel value="others" control={<Radio />}
                                                        label={intl.formatMessage({ id: "cabinet.profile.doctor.personalInfo.gender.other" })}
                                                    />
                                                </RadioGroup>

                                                <FormHelperText sx={{ml: 0}}
                                                    error={changeForm.touched.gender && !changeForm.isValid ? ('gender' in changeForm.errors) : undefined}
                                                >
                                                    {changeForm.touched.gender && !changeForm.isValid && changeForm.errors.gender}
                                                </FormHelperText>
                                            </>
                                        )}
                                    />
                                </FormControl>
                            </Grid>

                            {isDoctor &&
                                <Grid item xs={12}>
{/*                                    <TabContext value={value}>
                                        <Typography variant="label" color={defaultTheme.palette.primary.light1}>
                                            {intl.formatMessage({ id: "cabinet.profile.doctor.personalInfo.about.translation" })}
                                        </Typography>

                                        <TabList onChange={handleChange} aria-label="Personal translation about tabs">
                                            {translation.map(({ key, name, id }) => (
                                                <Tab
                                                    key={key}
                                                    value={id}
                                                    sx={{ paddingLeft: 0 }}
                                                    label={<span dangerouslySetInnerHTML={{ __html: name }} />}
                                                />
                                            ))}
                                        </TabList>

                                        {translation.map(({ key, id }) => (
                                            <TabPanel key={key} value={id} sx={{ padding: 0 }}>*/}
                                                <Field
                                                    name="about"
                                                    children={(fieldProps: any) => (
                                                        <TextField
                                                            {...fieldProps.field}
                                                            className={st.about}
                                                            onChange={(e) => {
                                                                changeForm.handleChange(e);
                                                                changeDetector(true);
                                                            }}
                                                            fullWidth
                                                            label={intl.formatMessage({ id: "cabinet.profile.doctor.personalInfo.about" })}
                                                            type="text"
                                                            variant="standard"
                                                            multiline
                                                            maxRows={6}
                                                            error={changeForm.touched.about && !changeForm.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                            helperText={changeForm.touched.about && !changeForm.isValid && fieldProps.meta.error}
                                                        />
                                                    )}
                                                />
{/*                                            </TabPanel>
                                        ))}
                                    </TabContext>*/}
                                </Grid>
                            }

                            <Grid item xs={12}>
                                <Autocomplete
                                    name="spokenLanguages"
                                    multiple
                                    filterSelectedOptions
                                    className={st.autocomplete_withButtons}
                                    getOptionLabel={option => (option?.title ? option.title : "")}
                                    isOptionEqualToValue={(option, value) => value.title === "" || option.title === value.title}
                                    options={languagesOptions}
                                    value={options.spokenLanguages ?? []}
                                    onChange={(e, newValue) => {
                                        changeForm.setFieldValue(`spokenLanguages`, newValue.map(item => `api/languages/${item.id}`));
                                        options.spokenLanguages = newValue;
                                        changeDetector(true);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            label={intl.formatMessage({id: "cabinet.profile.doctor.languages.title"})}
                                            type="text"
                                            variant="standard"
                                            error={changeForm.touched.spokenLanguages && !changeForm.isValid ? ('spokenLanguages' in changeForm.errors) : undefined}
                                            helperText={changeForm.touched.spokenLanguages && !changeForm.isValid && changeForm.errors.spokenLanguages}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <UserSaveButtons setSaveAndNext={setSaveAndNext} />
            </Box>
            {/*<pre style={{color: "saddlebrown", }}>{JSON.stringify(changeForm.values, 0, 2)}</pre>*/}
        </FormikProvider>
    );
};

export default observer(PersonalInformation);

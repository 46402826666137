import { action, makeAutoObservable } from "mobx";
import SingleNews from "./structures/SingleNews";

export default class NewsModel {
    public news: SingleNews[]; // for Single news
    public otherNews: SingleNews[];
    public searchResults: SingleNews[]; // for List news
    public availableYears: number[];
    public locale: string;

    constructor() {
        makeAutoObservable(this);
        this.news = [];
        this.otherNews = [];
        this.searchResults = [];
        this.availableYears = [];
        // @ts-ignore
        this.locale = localStorage.getItem('locale') ?? process.env.REACT_APP_DEFAULT_LOCALE;
    }

    @action addNewsPost = (post: SingleNews) => {
        this.news.push(post);
    };

    @action resetPosts = () => {
        this.news = [];
    };

    @action deletePost = (postId: string) => {
        const indexToDelete = this.news.findIndex((currentPost) => currentPost.id === postId);
        this.news.splice(indexToDelete);
    };

    @action getPostById = (id: string): SingleNews => {
        return this.news.filter((currentId) => currentId.id === id)[0];
    };

    @action updateOtherNews = (posts: SingleNews[]) => {
        this.otherNews = posts;
    };

    @action updateSearchResults = (posts: SingleNews[]) => {
        this.searchResults = posts;
    };

    @action updateAvailableYears = (newYearsList: number[]) => {
        this.availableYears = newYearsList;
    };
}

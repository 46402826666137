// @ts-nocheck
import { LocaleMap } from "../../types";

const availableLocales = Object.keys(LocaleMap).join('|');

enum AuthRoutes {
    cabinet = "/cabinet",
    cabinetLocale = `/:locale(${availableLocales})/cabinet`,

    dashboard = "/cabinet/dashboard",
    dashboardLocale = `/:locale(${availableLocales})/cabinet/dashboard`,

    referral = "/cabinet/referral",
    referralLocale = `/:locale(${availableLocales})/cabinet/referral`,

    accountSettings = "/cabinet/account-settings",
    accountSettingsLocale = `/:locale(${availableLocales})/cabinet/account-settings`,

    bookingAppointments = "/cabinet/booking-appointments",
    bookingAppointmentsLocale = `/:locale(${availableLocales})/cabinet/booking-appointments`,

    schedule = "/cabinet/schedule",
    scheduleLocale = `/:locale(${availableLocales})/cabinet/schedule`,

    connections = "/cabinet/connections",
    connectionsLocale = `/:locale(${availableLocales})/cabinet/connections`,

    patients = "/cabinet/patients",
    patientsLocale = `/:locale(${availableLocales})/cabinet/patients`,

    patient = "/patients/:id",
    patientLocale = `/:locale(${availableLocales})/patients/:id`,

    team = "/cabinet/team",
    teamLocale = `/:locale(${availableLocales})/cabinet/team`,

    doctorEdit = "/doctors/:id/edit",
    doctorEditLocale = `/:locale(${availableLocales})/doctors/:id/edit`,

    patientEdit = "/patients/:id/edit",
    patientEditLocale = `/:locale(${availableLocales})/patients/:id/edit`,

    authenticationEmulate = "/authentication-emulate",
    authenticationEmulateLocale = `/:locale(${availableLocales})/authentication-emulate`,
}

enum NonAuthRoutes {
    home = "/",
    homeLocale = `/(${availableLocales})/`,

    contacts = "/contact",
    contactsLocale = `/:locale(${availableLocales})/contact`,

    about = "/about",
    aboutLocale = `/:locale(${availableLocales})/about`,

    doctors = "/doctors",
    doctorsLocale = `/:locale(${availableLocales})/doctors`,

    doctor = "/doctors/:id",
    doctorLocale = `/:locale(${availableLocales})/doctors/:id`,

    forDoctors = "/for-doctors",
    forDoctorsLocale = `/:locale(${availableLocales})/for-doctors`,

    faq = "/faq",
    faqLocale = `/:locale(${availableLocales})/faq`,

    faqId = "/faq/:id",
    faqIdLocale = `/:locale(${availableLocales})/faq/:id`,

    news = "/news",
    newsLocale = `/:locale(${availableLocales})/news`,

    newsId = "/news/:id",
    newsIdLocale = `/:locale(${availableLocales})/news/:id`,

    specialities = "/specialities",
    specialitiesLocale = `/:locale(${availableLocales})/specialities`,

    privacyPolicy = "/privacy-policy",
    privacyPolicyLocale = `/:locale(${availableLocales})/privacy-policy`,

    termsOfUse = "/terms-of-use",
    termsOfUseLocale = `/:locale(${availableLocales})/terms-of-use`,

    auth = "/auth",
    authLocale = `/:locale(${availableLocales})/auth`,

    signin = "/auth/signin",
    signinLocale = `/:locale(${availableLocales})/auth/signin`,

    signup = "/auth/signup",
    signupLocale = `/:locale(${availableLocales})/auth/signup`,

    newPassword = "/auth/new-password",
    newPasswordLocale = `/:locale(${availableLocales})/auth/new-password`,

    forgotPassword = "/auth/forgot-password",
    forgotPasswordLocale = `/:locale(${availableLocales})/auth/forgot-password`,

    passwordReset = "/auth/reset",
    passwordResetLocale = `/:locale(${availableLocales})/auth/reset`,

    emailConfirmation = "/email-confirmation",
}

export { AuthRoutes, NonAuthRoutes };

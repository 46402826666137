import { action, computed, makeAutoObservable } from "mobx";
import { UserRoles } from "types";
import cleanDeep from "clean-deep";
import validator from "validator";
import AccountStructure from "../user/structures/AccountStructure";
import UserModel from "../user/UserModel";

export default class AuthModel {
    private isAuthorized: boolean;
    private roles: UserRoles[];
    private userModel: UserModel;
    public accountOptions: AccountStructure;

    public constructor(userModel: UserModel) {
        makeAutoObservable(this);
        this.isAuthorized = false;
        this.roles = [];
        this.userModel = userModel;
        this.accountOptions = this.defaultAccountOptions;
    }

    get defaultAccountOptions(): AccountStructure {
        return {
            email: "",
            login: "",
            phone: "",
            roles: [],
            defaultLocale: ""
        };
    }

    @action public updateAccountFromJson(accountJson: AccountStructure) {
        this.accountOptions = accountJson;
    }

    @computed get accountAsJson(): AccountStructure {
        return this.accountOptions;
    }

    @action public onSignedIn(roles: UserRoles[]) {
        this.isAuthorized = true;
        this.roles = roles;
    }

    @action public onLogout() {
        this.isAuthorized = false;
        this.roles = [];
        // @ts-ignore
        this.userModel.update({id: '' });
    }

    public validateSignUp = (values: any): object => {
        const errors: any = {
            email: (() => {
                if (!values.email) return "required";
                if (!validator.isEmail(values.email)) return "Invalid email";
            })(),
            password: (() => {
                if (!values.password) return "required";
                if (values.password.length < 6) return "at least 6 symbols";
                if (values.confirm_password && values.confirm_password !== values.password) return "password does not match";
            })(),
            confirm_password: (() => {
                if (!values.confirm_password) return "required";
                if (values.confirm_password !== values.password) return "password does not match";
            })(),
            type: (() => {
                if (!values.type) return "required";
                if (!["patient", "doctor"].includes(values.type)) return "Invalid role";
            })(),
        };
        // убираем пустые поля
        const cleanedErrors = cleanDeep(errors);
        return cleanedErrors;
    };

    public validateSignIn = (values: any): object => {
        const errors: any = {
            email: (() => {
                if (!values.email) return "required";
                if (!validator.isEmail(values.email)) return "Invalid email";
            })(),
            password: (() => {
                if (!values.password) return "required";
            })(),
        };
        // убираем пустые поля
        const cleanedErrors = cleanDeep(errors);
        return cleanedErrors;
    };

    public validateChangePassword = (values: any): object => {
        const errors: any = {
            currentPassword: (() => {
                if (!values.currentPassword) return "required";
            })(),
            newPassword: (() => {
                if (!values.newPassword) return "required";
                if (values.confirmNewPassword && values.confirmNewPassword !== values.newPassword) return "password does not match";
            })(),
            confirmNewPassword: (() => {
                if (!values.confirmNewPassword) return "required";
                if (values.confirmNewPassword !== values.newPassword) return "password does not match";
            })(),
        };

        const cleanedErrors = cleanDeep(errors);
        return cleanedErrors;
    };

    public validateEditAccount = (values: any): object => {
        const errors: any = {
            login: (() => {
                if (!values.login) return "required";
            })(),
            email: (() => {
                if (!validator.isEmail(values.email)) return "Invalid email";
            })(),
        };

        const cleanedErrors = cleanDeep(errors);
        return cleanedErrors;
    };

    public validateDeleteAccount = (values: any): object => {
        const errors: any = {
            currentPassword: (() => {
                if (!values.currentPassword) return "required";
            })(),
        };

        const cleanedErrors = cleanDeep(errors);
        return cleanedErrors;
    };

    public validateForgotPass = (values: any): object => {
        const errors: any = {
            email: (() => {
                if (!values.email) return "required";
                if (!validator.isEmail(values.email)) return "Invalid email";
            })(),
        };

        const cleanedErrors = cleanDeep(errors);
        return cleanedErrors;
    };

    public validateNewPass = (values: any): object => {
        const errors: any = {
            newPassword: (() => {
                if (!values.newPassword) return "required";
                if (values.confirmNewPassword && values.confirmNewPassword !== values.newPassword) return "password does not match";
            })(),
            confirmNewPassword: (() => {
                if (!values.confirmNewPassword) return "required";
                if (values.confirmNewPassword !== values.newPassword) return "password does not match";
            })(),
        };

        const cleanedErrors = cleanDeep(errors);
        return cleanedErrors;
    };

    @computed public get isUserAuthorized(): boolean {
        return this.isAuthorized;
    }
    @computed public get userRoles(): UserRoles[] {
        return this.roles;
    }
}

// import parseIRI from "data/utils/parseIRI";
import { DoctorProfileOptions } from "domain/entity/profile/doctor/structures/DoctorProfileStructure";

const checkOnObject = (value: any) => {
    if (typeof value === 'object' &&
        !Array.isArray(value) &&
        value !== null) {
        return Object.values(value);
    }
    return value;
}

const compareBooleanField = (sortingField: string, a: any, b: any) => {
    if (a[sortingField] && !b[sortingField]) return -1;
    if (a[sortingField] === b[sortingField]) return 0;
    if (!a[sortingField] && b[sortingField]) return 1;
}


class DoctorProfileService {
    sortByBooleanField = (value: any, sortingField: string) => {
        if (value.length) {
            value.sort(compareBooleanField.bind(null, sortingField));
        }

        return value;
    }

    serialize = (uglyProfile: any): DoctorProfileOptions => {
        return {
            ...uglyProfile,
            address: uglyProfile["address"],
            profileId: uglyProfile["profile"]?.["id"],
            workEmail: uglyProfile["workEmail"],
            phoneNumber: uglyProfile["phoneNumber"],
            about: uglyProfile["profile"]?.["about"],
            gender: uglyProfile["profile"]?.["gender"],
            birthDate: uglyProfile["profile"]?.["birthDate"],
            lastName: uglyProfile["profile"]?.["lastName"] || "",
            firstName: uglyProfile["profile"]?.["firstName"] || "",
            avatar: uglyProfile["profile"]?.["avatar"]?.["contentUrl"],
            avatarId: uglyProfile["profile"]?.["avatar"]?.["id"],
            scheduleSettings: uglyProfile["scheduleSettings"],
            spokenLanguages: checkOnObject(uglyProfile["spokenLanguages"]),
            insurances: checkOnObject(uglyProfile["insurances"]),
            experiences: this.sortByBooleanField(checkOnObject(uglyProfile["experiences"]), 'currentWork'),
            specialities: checkOnObject(uglyProfile["specialities"]),
            certifications: checkOnObject(uglyProfile["certifications"]),
            educations: this.sortByBooleanField(checkOnObject(uglyProfile["educations"]), 'stillStudying')?.map((education: any) => {
                return {
                    ...education,
                    graduationYear: !education.graduationYear ? null : new Date(education.graduationYear, 1, 1),
                };
            }),
            awards: checkOnObject(uglyProfile["awards"])?.map((award: any) => {
                return {
                    ...award,
                    year: !award.year ? null : new Date(award.year, 1, 1),
                };
            }),
            publications: checkOnObject(uglyProfile["publications"])?.map((item: any) => {
                return {
                    ...item,
                    year: !item.year ? null : new Date(item.year, 1, 1),
                };
            }),
        };
    };

    serializeDoctorClinicsSchedules = (uglySchedules: any) => {
        return uglySchedules.map((uglySchedule: any) => {
            return {};
        });
    };

    deserialize = (ugly: any): object => {
        return {
            instructionIsOpen: ugly["instructionIsOpen"] ?? undefined,
            address: ugly["address"],
            title: ugly["title"],
            mCredential: ugly["mCredential"],
            workEmail: ugly["workEmail"],
            insurances: ugly["insurances"],
            experiences: ugly["experiences"]?.map((experience: any) => {
                return {
                    ...experience,
                    startOfWork: experience.startOfWork ? experience.startOfWork: null,
                    endOfWork: experience.endOfWork ? experience.endOfWork : null,
                };
            }),
            phoneNumber: ugly["phoneNumber"],
            specialities: ugly["specialities"],
            certifications: ugly["certifications"],
            spokenLanguages: ugly["spokenLanguages"],
            enablePrivateInsurance: ugly["enablePrivateInsurance"],
            profile: (
                ugly["firstName"] ?
                {
                    id: (ugly["id"]? `api/profiles/${ugly["id"]}` : null),
                    avatar: ugly["avatarId"],
                    gender: ugly["gender"],
                    lastName: ugly["lastName"],
                    birthDate: ugly["birthDate"],
                    firstName: ugly["firstName"],
                    about: ugly["about"],
                } : undefined),
            educations: ugly["educations"]?.map((education: any) => {
                return {
                    ...education,
                    graduationYear: education.graduationYear ? education.graduationYear.getFullYear() : null,
                    speciality: education.speciality ? `api/specialities/${education.speciality.id}` : null,
                };
            }),
            awards: ugly["awards"]?.map((award: any) => {
                return {
                    ...award,
                    year: award.year ? award.year.getFullYear() : null,
                };
            }),
            publications: ugly["publications"]?.map((item: any) => {
                return {
                    ...item,
                    year: item.year ? item.year.getFullYear() : null,
                };
            }),
        };
    };
}
// export { convertObjToIRIString };
export default new DoctorProfileService();

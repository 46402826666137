import MedicalCredentialsRepository from "../../repository/medical_credentials/MedicalCredentialsRepository";
import {MedicalCredential} from "../../entity/medical_credentials/structures/MedicalCredentialStructure";

export default class LoadMedicalCredentialsUseCase {
    private medicalCredentialsRepository: MedicalCredentialsRepository;

    constructor(medicalCredentialsRepository: MedicalCredentialsRepository) {
        this.medicalCredentialsRepository = medicalCredentialsRepository;
    }

    getMedicalCredentials = () => {
        return this.medicalCredentialsRepository
            .getMedicalCredentials()
            .then((medicalCredentials: [MedicalCredential[], []]) => {
                return medicalCredentials;
            })
    }
}

// @ts-nocheck
import React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useViewModels } from 'presentation/view-model/ViewModelProvider';
import LandingLayout from 'presentation/views/layouts/landing/LandingLayout';
import { Link, useParams } from 'react-router-dom';
import { Avatar } from '@mui/material';
import AppointmentList from 'presentation/views/components/AppointmentList/AppointmentList';
import { Appointment } from 'domain/entity/appointment/structures/AppointmentStructure';
import classNames from 'classnames';

// styles
import st from './patientId.module.scss';
import { useIntl } from 'react-intl';
import { Grid, Typography } from '@mui/material';
import primaryMuiTheme, { defaultTheme } from '../../layouts/themes/primaryMuiTheme';
import axios from 'axios';
import getAge from '../../helpers/getAge';

import { ReactComponent as LocationIcon } from '../../assets/images/icons/location_20.svg';
import { ReactComponent as PhoneIcon } from '../../assets/images/icons/phone_20.svg';
import { ReactComponent as MailIcon } from '../../assets/images/icons/mail_20.svg';
import EntityInfo from 'presentation/views/components/EntityInfo';
import ReactPhoneInput from 'react-phone-input-material-ui';
import TextField from "@mui/material/TextField";


const PatientId: React.FC = () => {
    const intl = useIntl();
    const { id } = useParams();
    const {
        patientProfileViewModel: {patientProfile},
        userViewModel: {identifier, locale},
        appointmentViewModel
    } = useViewModels();

    const [activeAppointmentCard, setActiveAppointmentCard] = React.useState<Appointment | null>(null);
    const [typeActiveAppointmentCard, setTypeActiveAppointmentCard] = React.useState('upcomingAppointments');

    React.useEffect(() => {
        if (!appointmentViewModel.upcomingAppointments.length) {
            appointmentViewModel.getUpcomingAppointments();
        }
    }, [appointmentViewModel]);

    return (
        <LandingLayout>
            {id === identifier ? (
                <div className={st.main}>
                    <div className={st.main_info}>
                        <Grid container rowSpacing={{ xs: 3, md: 3 }} className={st.personal_info}>
                            <Grid item xs={12} mb={-0.5} sx={{ display: { xs: 'block', sm: 'none' } }}>
                                <div className={st.breadcrumbs}>{intl.formatMessage({ id: 'userPage.see' })}</div>
                            </Grid>

                            <Grid item xs={12} sm={3.7} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'start' } }}>
                                {patientProfile?.avatar ?
                                    <Avatar
                                        src={axios.defaults.baseURL + patientProfile.avatar}
                                        className={st.avatar}
                                        alt={`${patientProfile.firstName} ${patientProfile.lastName}`}
                                    />
                                :
                                    <Avatar sx={{
                                        ...primaryMuiTheme.typography.h3,
                                        bgcolor: "rgba(0, 185, 255, 1)",
                                        width: { xs: "156px", sm: "192px" },
                                        height: { xs: "156px", sm: "192px" },
                                    }}
                                    >
                                        {patientProfile?.firstName?.slice(0, 1)}
                                        {patientProfile?.lastName?.slice(0, 1)}
                                    </Avatar>
                                }
                            </Grid>

                            <Grid item xs={12} sm={8.3} pl={{ sm: 2, md: 3 }}>
                                <Grid container>
                                    <Grid item xs={12} mb={{ sm: 2, md: 4 }} sx={{ display: { xs: 'none', sm: 'block' } }}>
                                        <div className={st.breadcrumbs}>{intl.formatMessage({ id: 'userPage.see' })}</div>
                                    </Grid>

                                    <Grid item xs={12} mb={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
                                        <Link to={`${locale && locale !== 'de' ? '/' + locale : ''}/patients/${id}/edit?tab=personal`} className={st.edit}>
                                            {intl.formatMessage({ id: 'edit' })}
                                        </Link>
                                    </Grid>

                                    <Grid item xs={12} mb={{ xs: 1.5, sm: 2 }}>
                                        <Typography variant="h5">
                                            {intl.formatMessage(
                                                { id: `cabinet.dashboard.patient.card.name` },
                                                { name: `${patientProfile.firstName} ${patientProfile.lastName}` }
                                            )}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="t2" color={defaultTheme.palette.primary.light}>
                                            {`${
                                                patientProfile.birthDate
                                                    ? getAge(patientProfile.birthDate) + ' ' + intl.formatMessage({ id: 'age' })
                                                    : ''
                                            } ${patientProfile.gender}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                    <div className={st.additional_info}>
                        <div className={st.additional_card}>
                            <div className={st.card_top}>
                                <Typography variant="h6" className={st.card_title}>
                                    {intl.formatMessage({ id: 'userPage.insurancePlans' })}
                                </Typography>

                                <Link to={`${locale && locale !== 'de' ? '/' + locale : ''}/patients/${id}/edit?tab=insurance`} className={st.edit}>
                                    {intl.formatMessage({ id: 'edit' })}
                                </Link>
                            </div>

                            <div className={st.card_body}>
                                {patientProfile?.insurances?.map((item, i) => {
                                    return (
                                        <div key={item.country + i + "insurance"} className={st.insurance_item}>
                                            <EntityInfo
                                                noHover={true}
                                                avatarSize={48}
                                                fio={item.country}
                                                imgContain
                                                className={st.insurance_item_border}
                                                bgcolor="#fff"
                                                pictureUrl={item.logo.contentUrl ? axios.defaults.baseURL + item.logo.contentUrl : ""}
                                            >
                                                <Typography variant="p5">{item.title}</Typography>
                                            </EntityInfo>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className={st.additional_card}>
                            <div className={st.card_top}>
                                <Typography variant="h6" className={st.card_title}>
                                    {intl.formatMessage({ id: 'userPage.myContacts' })}
                                </Typography>

                                <Link to={`${locale && locale !== 'de' ? '/' + locale : ''}/patients/${id}/edit?tab=contacts`} className={st.edit}>
                                    {intl.formatMessage({ id: 'edit' })}
                                </Link>
                            </div>

                            <Grid container spacing={3} className={st.card_body}>
                                {patientProfile.phone && (
                                    <Grid item xs={12} className={st.card_item}>
                                        <PhoneIcon />
                                        <Typography variant="p5">
                                            <ReactPhoneInput
                                                readOnly
                                                value={patientProfile.phone}
                                                className={classNames(st.card_item__phone, st.phone)}
                                                inputProps={{
                                                    readOnly: true,
                                                    id: "phoneNumber",
                                                    margin: "normal",
                                                    inputMode: 'numeric',
                                                    variant: "standard",
                                                    autoComplete: "phoneNumber",
                                                    label: "",
                                                }}
                                                name="phoneNumber"
                                                prefix=''
                                                disableDropdown
                                                country={locale || "de"}
                                                preserveOrder={['country', 'preferredCountries']}
                                                component={TextField}
                                            />
                                        </Typography>
                                    </Grid>
                                )}
                                {patientProfile.workEmail && (
                                    <Grid item xs={12} className={st.card_item}>
                                        <MailIcon />
                                        <Typography variant="p5">{patientProfile.workEmail}</Typography>
                                    </Grid>
                                )}
                                {patientProfile.address && (
                                    <Grid item xs={12} className={st.card_item}>
                                        <LocationIcon />
                                        <Typography variant="p5">{patientProfile.address}</Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                    </div>

                    <div className={st.other_info}>
                        <div className={st.appointments}>
                            <div className={st.card_top}>
                                <Typography variant="h6" className={st.card_title}>
                                    {intl.formatMessage({ id: 'userPage.appointments' })}
                                </Typography>
                            </div>

                            {(activeAppointmentCard === null || typeActiveAppointmentCard === 'upcomingAppointments') && (
                                <AppointmentList
                                    appointmentsType="upcomingAppointments"
                                    pageType="patientId"
                                    activeAppointmentCard={activeAppointmentCard}
                                    setActiveAppointmentCard={setActiveAppointmentCard}
                                    setTypeActiveAppointmentCard={setTypeActiveAppointmentCard}
                                />
                            )}
                        </div>
                        {console.log(toJS(patientProfile.spokenLanguages))}
                        {patientProfile?.spokenLanguages?.length > 0 &&
                            <div className={st.languages}>
                                <div className={st.card_top}>
                                    <Typography variant="h6" className={st.card_title}>
                                        <span>{intl.formatMessage({ id: 'userPage.languages' })}</span>

                                        <Link to={`${locale && locale !== 'de' ? '/' + locale : ''}/patients/${id}/edit?tab=languages`} className={st.edit}>
                                            {intl.formatMessage({ id: 'edit' })}
                                        </Link>
                                    </Typography>
                                </div>

                                <div className={classNames(st.card_body, st.languages_grid)}>
                                    {patientProfile.spokenLanguages?.map((item, i) => {
                                        return (
                                            <div key={i} className={st.languages_item}>
                                                <Typography variant="p5">{item.title}</Typography>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            ) : (
                <p>It is not your User page.</p>
            )}
        </LandingLayout>
    );
};

export default observer(PatientId);

import axios from "axios";
import doctorProfileService from "../profile/doctor/services/DoctorProfileService";


class DoctorsApi {
    getDoctorsWithSchedule(pageNumber: number, searchParameters?: string): Promise<[]> {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + `api/doctors?scheduleItemsExtended=1&skillApproves=DESC&perPage=8&page=${pageNumber}${searchParameters ? '&' + searchParameters : ''}`,
        }).then((doctors: any) => {
            return doctors.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    getOwnDoctors(): Promise<any> {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + 'api/doctors?own=true&skillApproves=DESC',
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    getDoctorWithSchedule(doctorId: string, from: string, till: string): Promise<[]> {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + `api/doctors/${doctorId}?scheduleItemsExtended=1${from ? '&from=' + from: ''}${from ? '&till=' + till : ''}`,
        }).then((result: any) => {
            result.data.experiences = doctorProfileService.sortByBooleanField(result.data.experiences, 'currentWork');
            result.data.educations = doctorProfileService.sortByBooleanField(result.data.educations, 'stillStudying');
            return result.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    getDoctorSpecialities(doctorId: string): Promise<[]> {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + `api/doctors/${doctorId}/specialities-approves`,
        }).then((doctors: any) => {
            return doctors.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    approveSkill(speciality: string, doctor: string): Promise<any> {
        return axios({
            method: "POST",
            url: axios.defaults.baseURL + `api/approved-skills`,
            data: {
                speciality,
                doctor
            },
        }).then((response: any) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    removeApproveSkill(speciality: string, doctor: string): Promise<any> {
        return axios({
            method: "POST",
            url: axios.defaults.baseURL + `api/remove-approved-skills`,
            data: {
                speciality,
                doctor
            },
        }).then((response: any) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }
}

export default new DoctorsApi();

import axios from "axios";
import * as qs from 'qs';

import FaqRepository from "domain/repository/faq/FaqRepository";

export default class FaqApi implements FaqRepository {
    loadCategories = () => {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + `api/faq_categories`,
        }).then((result: any) => {
            return result.data.items.map((category: any) => {
                return {
                    id: category['id'],
                    name: category['name'],
                }
            });
        });
    };

    loadPopularQustions = () => {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + `api/faqs?page=1&perPage=4&viewsCount=DESC`,
        }).then((result: any) => {
            return result.data.items.map((question: any) => {
                return {
                    title: question['title'],
                    answer: question['answer'],
                    order: question['order']

                }
            });
        });
    };

    loadQuestionsByCategory = (category: string) => {
        const queryString = qs.stringify({ category: category, viewsCount: 'DESC' }, { allowDots: true, encode: false, });

        return axios({
            method: "GET",
            url: axios.defaults.baseURL + `api/faqs?${queryString}`,
        }).then((result: any) => {
            return result.data.items.map((question: any) => {
                return {
                    category: question['category'].name,
                    title: question['title'],
                    answer: question['answer'],
                }
            });
        });
    };

    loadQuestionsForDoctorsCategory = () => {
        const queryString = qs.stringify({ viewsCount: 'DESC', 'category.name': 'static' }, { allowDots: true, encode: false, });

        return axios({
            method: "GET",
            url: axios.defaults.baseURL + `api/faqs?${queryString}`,
        }).then((result: any) => {
            return result.data.items.map((question: any) => {
                return {
                    category: question['category'].name,
                    title: question['title'],
                    answer: question['answer'],
                }
            });
        });
    };
}

import { action, computed, makeAutoObservable, toJS } from "mobx";
import AuthModel from "domain/entity/auth/AuthModel";
// import SocialApi from "data/auth/SocialApi";

// @TODO to AuthUseCases
import LoginUseCase from "domain/interactors/auth/LoginUseCase";
import LogoutUseCase from "domain/interactors/auth/LogoutUseCase";
import RegisterUseCase from "domain/interactors/auth/RegisterUseCase";
import GetUserAppAccessUseCase from "domain/interactors/auth/GetUserAppAccessUseCase";
import { SubmissionError, ValidationError } from "domain/interactors/CustomErrors";
import { UserRoles } from "types";
import ChangeUserPasswordUseCase from "domain/interactors/auth/ChangePasswordUseCase";
import DeleteAccountUseCase from "domain/interactors/auth/DeleteAccountUseCase";
import ForgotPasswordUseCase from "domain/interactors/auth/ForgotPasswordUseCase";
import NewPasswordUseCase from "domain/interactors/auth/NewPasswordUseCase";
import VerifyTokenUseCase from "domain/interactors/auth/VerifyTokenUseCase";

// import { UserRoles } from "../.././../types";
import ManageAccountUseCase from "../../../domain/interactors/user/ManageAccountUseCase";
import AuthApi from "data/auth/AuthApi";
import errorParse from "../../views/helpers/errorParse";

// @TODO (separate this to different stores)
export default class AuthViewModel {
    public isShowError: boolean;
    public signUpSubmitError: string;
    public signInSubmitError: string;
    public forgotPassSubmitError: string;
    public newPassSubmitError: string;
    public verifyTokenSubmitError: string;
    public emailConfirmationSubmitError: string;
    public successSignUpIsActive: boolean;
    public successForgotPassIsActive: boolean;
    public successNewPassIsActive: boolean;
    public errorSignUpIsActive: boolean;
    public isSignInSubmissionErrorVisible: boolean;
    public isSignUpSubmissionErrorVisible: boolean;
    public isForgotPassSubmissionErrorVisible: boolean;
    public isNewPassSubmissionErrorVisible: boolean;
    public isVerifyTokenSubmission: boolean;
    public isVerifyTokenSubmissionIsSync: boolean;
    public isEmailConfirmationSubmission: boolean;
    public isEmailConfirmationSubmissionIsSync: boolean;
    public isSync: boolean;
    private loginUseCase: LoginUseCase;
    private logoutUseCase: LogoutUseCase;
    private registerUseCase: RegisterUseCase;
    public getUserAppAccessUseCase: GetUserAppAccessUseCase;
    private changeUserPasswordUseCase: ChangeUserPasswordUseCase;
    private deleteAccountUseCase: DeleteAccountUseCase;
    private forgotPasswordUseCase: ForgotPasswordUseCase;
    private newPasswordUseCase: NewPasswordUseCase;
    private verifyTokenUseCase: VerifyTokenUseCase;
    private manageAccountUseCase: ManageAccountUseCase;

    private authModel: AuthModel;

    public user: any;

    constructor(
        loginUseCase: LoginUseCase,
        registeruseCase: RegisterUseCase,
        logoutUseCase: LogoutUseCase,
        getUserAppAccessUseCase: GetUserAppAccessUseCase,
        changeUserPasswordUseCase: ChangeUserPasswordUseCase,
        deleteAccountUseCase: DeleteAccountUseCase,
        forgotPasswordUseCase: ForgotPasswordUseCase,
        newPasswordUseCase: NewPasswordUseCase,
        verifyTokenUseCase: VerifyTokenUseCase,
        manageAccountUseCase: ManageAccountUseCase,
        authModel: AuthModel
    ) {
        makeAutoObservable(this);
        this.isShowError = false;
        this.signUpSubmitError = "";
        this.signInSubmitError = "";
        this.forgotPassSubmitError = "";
        this.newPassSubmitError = "";
        this.verifyTokenSubmitError = "";
        this.emailConfirmationSubmitError = "";
        this.isSignInSubmissionErrorVisible = false;
        this.isSignUpSubmissionErrorVisible = false;
        this.isForgotPassSubmissionErrorVisible = false;
        this.isNewPassSubmissionErrorVisible = false;
        this.isVerifyTokenSubmission = true;
        this.isVerifyTokenSubmissionIsSync = false;
        this.isEmailConfirmationSubmission = true;
        this.isEmailConfirmationSubmissionIsSync = false;

        this.successSignUpIsActive = false;
        this.errorSignUpIsActive = false;
        this.successForgotPassIsActive = false;
        this.successNewPassIsActive = false;
        this.isSync = true;

        // use cases
        this.loginUseCase = loginUseCase;
        this.logoutUseCase = logoutUseCase;
        this.registerUseCase = registeruseCase;
        this.getUserAppAccessUseCase = getUserAppAccessUseCase;
        this.changeUserPasswordUseCase = changeUserPasswordUseCase;
        this.deleteAccountUseCase = deleteAccountUseCase;
        this.forgotPasswordUseCase = forgotPasswordUseCase;
        this.newPasswordUseCase = newPasswordUseCase;
        this.verifyTokenUseCase = verifyTokenUseCase;
        this.manageAccountUseCase = manageAccountUseCase;
        this.authModel = authModel;

        // this.runInterceptor();
    }

    public validateSignUpForm = (values: any): object => {
        return this.authModel.validateSignUp(values);
    };

    public validateSignInForm = (values: any): object => {
        return this.authModel.validateSignIn(values);
    };

    public validateChangePasswordForm = (values: any): object => {
        return this.authModel.validateChangePassword(values);
    };

    public validateEditAccount = (values: any): object => {
        return this.authModel.validateEditAccount(values);
    };

    public validateDeleteAccountForm = (values: any): object => {
        return this.authModel.validateDeleteAccount(values);
    };

    public validateForgotPassForm = (values: any): object => {
        return this.authModel.validateForgotPass(values);
    };

    public validateNewPassForm = (values: any): object => {
        console.log(values)
        return this.authModel.validateNewPass(values);
    };

    public saveReferralCode = (referralCode: string) => {
        localStorage.setItem('referralCode', referralCode);
    }

    @action public onClickSignIn = (email: string, password: string) => {
        this.isSignInSubmissionErrorVisible = false;
        this.isShowError = false;

        return this.loginUseCase
            .loginUser(email, password)
            .finally(
                action(() => {
                    this.isSync = false;
                })
            );
    };

    onClickFaceBookLogin = async (facebookToken: string, userType: string | null) => {
        const referralCode = localStorage.getItem('referralCode');

        // return SocialApi
        return this.loginUseCase
            .facebookLogin(facebookToken, userType, referralCode)
            .then(
                action((data) => {
                    console.log(data)
                    this.showSuccessSignUp();
                })
            )
            .catch(
                action((error) => {
                    if (error.data.message.indexOf('Validation failed') > -1) {
                        return 'need type';
                    }

                    if (error instanceof SubmissionError) {
                        const submissionError = error as SubmissionError;
                        this.signUpSubmitError = submissionError.errorDescription;
                        this.isSignUpSubmissionErrorVisible = true;

                        return submissionError.errors;
                    }
                })
            )
            .finally(
                action(() => {
                    this.isSync = false;
                })
            );
    };

    onClickGoogleLogin = async (googleToken: string, userType: string | null) => {
        const referralCode = localStorage.getItem('referralCode');

        return this.loginUseCase
            .googleLogin(googleToken, userType, referralCode)
            .then((data) => {
                console.log(data)

                this.showSuccessSignUp();
            })
            .catch(
                action((error) => {
                    console.log(error);
                    if (error.data.message.indexOf('Validation failed') > -1) {
                        return 'need type';
                    }

                    if (error instanceof SubmissionError) {
                        const submissionError = error as SubmissionError;
                        this.signUpSubmitError = submissionError.errorDescription;
                        this.isSignUpSubmissionErrorVisible = true;

                        return submissionError.errors;
                    }
                })
            );
    };

    @action public onClickSignUp = (type: string, email: string, password: string, confirm_password: string): Promise<any> => {
        this.isShowError = false;

        const referralCode = localStorage.getItem('referralCode');

        return this.registerUseCase
            .register(type, email, password, confirm_password, referralCode)
            .then(
                action(() => {
                    this.showSuccessSignUp();
                })
            )
            .finally(
                action(() => {
                    this.isSync = false;
                })
            );
    };

    @action public onClickSignOut = () => {
        this.logoutUseCase.logout();
    };

    public onClickChangePassword = (currentPassword: string, newPassword: string, confirmNewPassword: string) => {
        return this.changeUserPasswordUseCase
            .start(currentPassword, newPassword, confirmNewPassword)
            .then()
            .catch(error => Promise.reject(errorParse(error)));
    };

    public onClickDeleteAccount = (currentPassword: string) => {
        return this.deleteAccountUseCase
            .start(currentPassword)
            .then(() => window.location.reload())
            .catch(error => Promise.reject(errorParse(error)));
    };

    public onClickForgotPassword = (email: string) => {
        return this.forgotPasswordUseCase
            .start(email)
            .then(() => {
                this.showSuccessForgotPass();
            })
            .catch(
                action((error) => {
                    if (error instanceof SubmissionError) {
                        const submissionError = error as SubmissionError;
                        this.forgotPassSubmitError = submissionError.errorDescription;
                        this.isForgotPassSubmissionErrorVisible = true;

                        return Promise.reject(submissionError.errorDescription);
                    }
                    if (error instanceof ValidationError) {
                        const validationError = error as ValidationError;
                        return validationError.errors;
                    }

                    return error;
                })
            );
    };

    public onClickNewPassword = (newPassword: string, token: string | null) => {
        return this.newPasswordUseCase
            .start(newPassword, token)
            .then(() => {
                this.showSuccessNewPass();
            })
            .catch(
                action((error) => {
                    if (error instanceof SubmissionError) {
                        const submissionError = error as SubmissionError;
                        this.newPassSubmitError = submissionError.errorDescription;
                        this.isNewPassSubmissionErrorVisible = true;

                        return Promise.reject(submissionError.errorDescription);
                    }
                    if (error instanceof ValidationError) {
                        const validationError = error as ValidationError;
                        return validationError.errors;
                    }

                    return error;
                })
            );
    };

    public onVerifyToken = (token: string | null) => {
        return this.verifyTokenUseCase
            .start(token)
            .then(() => {
                this.isVerifyTokenSubmission = false;
                this.isVerifyTokenSubmissionIsSync = true;
                console.log('token is verified')
            })
            .catch(
                action((error) => {
                    if (error instanceof SubmissionError) {
                        const submissionError = error as SubmissionError;
                        this.verifyTokenSubmitError = submissionError.errorDescription;
                        this.isVerifyTokenSubmission = true;
                        this.isVerifyTokenSubmissionIsSync = true;

                        return submissionError.errors;
                    }

                    if (error instanceof ValidationError) {
                        const validationError = error as ValidationError;
                        return validationError.errors;
                    }

                    return error;
                })
            );
    };

    public onEmailConfirmation = (code: string | null) => {
        return new AuthApi()
            .emailConfirmation(code)
            .then(() => {
                this.isEmailConfirmationSubmission = false;
                this.isEmailConfirmationSubmissionIsSync = true;
                console.log('code is verified')
            })
            .catch(
                action((error) => {
                    console.log(error)
                    this.emailConfirmationSubmitError = error;
                    this.isEmailConfirmationSubmission = true;
                    this.isEmailConfirmationSubmissionIsSync = true;

                    return error;
                })
            );
    };

    @computed public get isUserAuthorized(): boolean {
        return this.authModel.isUserAuthorized;
    }

    @computed public get userRoles(): UserRoles[] {
        return this.authModel.userRoles;
    }

    @computed public get isDoctor(): boolean {
        return !!this.userRoles.filter((userRole) => userRole === UserRoles.doctor)[0];
    }

    @action getUserAppAccess = () => {
        this.isSync = true;

        this.getUserAppAccessUseCase.start().finally(
            action(() => {
                this.isSync = false;
            })
        );
    };

    @action public showSuccessSignUp = () => {
        this.successSignUpIsActive = true;
    };
    @action public hideSuccessSignUp = () => {
        this.successSignUpIsActive = false;
    };

    @action public showSuccessForgotPass = () => {
        this.successForgotPassIsActive = true;
    };
    @action public hideSuccessForgotPass = () => {
        this.successForgotPassIsActive = false;
    };

    @action public showSuccessNewPass = () => {
        this.successNewPassIsActive = true;
    };
    @action public hideSuccessNewPass = () => {
        this.successNewPassIsActive = false;
    };

    @action public showErrorSignUp = () => {
        this.errorSignUpIsActive = true;
    };
    @action public hideErrorSignUp = () => {
        this.errorSignUpIsActive = false;
    };

    editUserAccount = (id: string, userAccount: any) => {
        return this.manageAccountUseCase
            .editUserAccount(id, userAccount)
            .then((response) => {
                return Promise.resolve(response);
            })
    }

    getUserAccount = (id: string) => {
        this.manageAccountUseCase
            .getUserAccount(id)
            .then((user: any) => {
                // @ts-ignore
                this.user = user;
                this.authModel.updateAccountFromJson({
                    login: user['login'],
                    email: user['email'],
                    phone: user['phone'],
                    roles: user['roles'],
                    defaultLocale: user['defaultLocale']
                });
            });
    }

    @computed get userOptions(): any {
        return toJS(this.authModel.accountAsJson);
    }
}

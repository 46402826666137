import React from "react";
import { ViewModelsProvider } from "presentation/view-model/ViewModelProvider";
import ReactDOM from "react-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import primaryMuiTheme from "presentation/views/layouts/themes/primaryMuiTheme";
import { StyledEngineProvider } from "@mui/material/styles";

import App from "presentation/views/App";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./presentation/views/components/ScrollToTop/ScrollToTop";
import { HelmetProvider } from "react-helmet-async";

// ReactDOM.hydrate(
ReactDOM.render(
    <ViewModelsProvider>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={primaryMuiTheme}>
                <CssBaseline />
                <Router>
                    <ScrollToTop />
                    <HelmetProvider>
                        <App />
                    </HelmetProvider>
                </Router>
            </ThemeProvider>
        </StyledEngineProvider>
    </ViewModelsProvider>,
    document.getElementById("root")
);

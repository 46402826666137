// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { useViewModels } from "../../../../view-model/ViewModelProvider";

import stDashboard from "../../Cabinet/Common/Dashboard/dashboard.module.scss"
import st from "../Doctor/doctorProfileEdit.module.scss";
import classNames from "classnames";

import { ReactComponent as ArrowIcon } from "../../../assets/images/icons/arrow.svg";
import { ReactComponent as CheckedIcon2 } from "../../../assets/images/icons/checked_2.svg";
import { Tab, Grid, Typography, Button } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PersonalInformation from "../Doctor/Common/PersonalInformation";
import Insurance from "../Doctor/Common/Insurance";
import { defaultTheme } from "../../../layouts/themes/primaryMuiTheme";
import ContactsUser from "./ContactsUser";
import { Popup } from "../../../components/Popup";
import { observer } from "mobx-react";
import { NonAuthRoutes } from "../../../../routes/routes";
import stylePopup from "presentation/views/components/Popup/popup.module.scss";


const PatientProfileEdit = () => {
    const intl = useIntl();
    const query = new URLSearchParams(useLocation().search);
    const {
        userViewModel: {identifier, locale},
        uIViewModel: { tabletIs, openPopup, closePopup },
        patientProfileViewModel: {patientProfile: user}
    } = useViewModels();
    const [tab, setTab] = useState('personal');
    const [pendingTab, setPendingTab] = useState('');
    const [changeDetect, setChangeDetect] = useState(false);
    const [congratulationsDetect, setCongratulationsDetect] = useState(false);

    const handleChange = (event: (React.SyntheticEvent | string), newValue: string, unconditionalRedirect = false, setNextTab = false) => {
        if (setNextTab) {
            const nextTabIndex = tabsLinks.findIndex(item => item.value === tab) + 1;
            newValue = tabsLinks[nextTabIndex].value;
        }

        if (!unconditionalRedirect && !setNextTab) {
            setPendingTab(newValue);
        }

        if (!setNextTab && changeDetect && !unconditionalRedirect) {
            openPopup();
        } else {
            window.history.pushState({}, '', `${window.location.pathname}?tab=${newValue}`);
            setTab(newValue);
        }
    };

    const handleCongratulationsDetect = () => {
        setCongratulationsDetect(true);
        openPopup();
    }

    const resetCongratulations = () => {
        setCongratulationsDetect(false);
    }


    const tabsLinks = [
        {
            id: "id-0",
            name: intl.formatMessage({ id: "cabinet.profile.doctor.personalInfo.title" }),
            value: "personal",
            complete: !!user.firstName,
            required: true,
        },
        {
            id: "id-1",
            name: intl.formatMessage({ id: "cabinet.profile.doctor.insurance.title" }),
            value: "insurance",
            complete: !!user.insurances.length,
            required: false,
        },
        {
            id: "id-2",
            name: intl.formatMessage({ id: "app.header.menu.contacts" }),
            value: "contacts",
            complete: !!user.phone,
            required: false,
        },
    ];

    useEffect(() => {
        let isMounted = true;

        let queryTab = query.get("tab");

        if ((typeof queryTab === 'string')) {
            if (!tabsLinks.find(item => item.value === queryTab)) {
                queryTab = 'personal';
            }

            if (isMounted && tab !== queryTab) {
                setTab(queryTab);
            }
        }

        return () => { isMounted = false };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Popup callback={resetCongratulations}>
                {congratulationsDetect ?
                    <>
                        <Typography variant="h5" className={stylePopup.title}>{intl.formatMessage({ id: "congratulationsTitle" })}</Typography>

                        <Typography variant="p5" className={stylePopup.text}>{intl.formatMessage({ id: "congratulationsTextPatient" })}</Typography>

                        <Grid container spacing={2} pt={{ xs: 2, sm: 4, md: 6 }}>
                            <Grid item xs={12} sm={6}>
                                <Link to={`${locale && locale !== 'de' ? '/' + locale : ''}${NonAuthRoutes.doctors}`} sx={{ width: "100%" }}>
                                    <Button type="button" color="secondary" variant="contained"
                                            onClick={(e) => {
                                                closePopup();
                                                setCongratulationsDetect(false);
                                            }}
                                    >
                                        {intl.formatMessage({ id: "doctors.preview.confirmButton" })}
                                    </Button>
                                </Link>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Button type="button" color="secondary" variant="outlined" sx={{ width: "100%" }}
                                        onClick={() => {
                                            closePopup();
                                            setCongratulationsDetect(false);
                                        }}
                                >
                                    {intl.formatMessage({ id: "close" })}
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                    :
                    <>
                        <Typography variant="h5" mb={4} className={stylePopup.title}>{intl.formatMessage({ id: "leavingAlertTitle" })}</Typography>

                        <Typography variant="p5" className={stylePopup.text}>{intl.formatMessage({id: "leavingAlert"})}</Typography>

                        <Grid container spacing={2} pt={{ xs: 2, sm: 4, md: 6 }}>
                            <Grid item xs={12} sm={6}>
                                <Button type="button" color="secondary" variant="contained" sx={{ width: "100%" }}
                                        onClick={(e) => {
                                            setChangeDetect(false);
                                            closePopup();
                                            handleChange(e, pendingTab, true);
                                        }}
                                >
                                    {intl.formatMessage({ id: "ok" })}
                                </Button>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Button type="button" color="secondary" variant="outlined" onClick={closePopup} sx={{ width: "100%" }}>
                                    {intl.formatMessage({ id: "cancel" })}
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }
            </Popup>

            <Link to={`${locale && locale !== 'de' ? '/' + locale : ''}${user.active ? '/patients/' + identifier : '/cabinet/dashboard'}`} className={st.back_to_dashboard}>
                <ArrowIcon />
                {intl.formatMessage({id: "back"})}
            </Link>

            <Grid container>
                <TabContext value={tab}>
                    {tabletIs && (
                        <Grid item xs={12}>
                            <TabList
                                variant="scrollable"
                                textColor="secondary"
                                onChange={handleChange}
                                aria-label="Horizontal tabs menu"
                                classes={{ root: st.tabs_block }}
                                sx={{ background: defaultTheme.palette.secondary.light2 }}
                            >
                                {tabsLinks.map(({ id, name, value, complete, required }) => (
                                    <Tab
                                        key={id}
                                        classes={{
                                            root: st.tab_item,
                                            selected: st.tab_item_active,
                                        }}
                                        value={value}
                                        label={
                                            <div className={classNames(stDashboard.details_item, st.tab_item_label, (complete ? st.tab_item_label_complete : ''))}>
                                                <div className={classNames(stDashboard.details_item_check, st.tab_item_label_icon)}><CheckedIcon2/></div>
                                                <div className={st.tab_item_title}>{name}{required && '*'}</div>
                                            </div>
                                        }
                                    />
                                ))}
                            </TabList>
                        </Grid>
                    )}

                    <Grid item xs={12} md={8} lg={9}>
                        <TabPanel value="personal" sx={{ padding: 0 }}>
                            <PersonalInformation changeDetector={setChangeDetect} setTab={handleChange} congratulationsDetector={handleCongratulationsDetect} />
                        </TabPanel>

                        <TabPanel value="insurance" sx={{ padding: 0 }}>
                            <Insurance changeDetector={setChangeDetect} setTab={handleChange} />
                        </TabPanel>

                        <TabPanel value="contacts" sx={{ padding: 0 }}>
                            <ContactsUser changeDetector={setChangeDetect} setTab={handleChange} />
                        </TabPanel>
                    </Grid>

                    {!tabletIs && (
                        <Grid item sx={{ ml: "auto", width: '288px' }}>
                            <TabList
                                orientation="vertical"
                                variant="scrollable"
                                textColor="secondary"
                                onChange={handleChange}
                                aria-label="Vertical tabs menu"
                                classes={{ root: st.tabs_block }}
                                sx={{ background: defaultTheme.palette.secondary.light2 }}
                            >
                                {tabsLinks.map(({ id, name, value, complete, required }) => (
                                    <Tab
                                        key={id}
                                        classes={{
                                            root: st.tab_item,
                                            selected: st.tab_item_active,
                                        }}
                                        sx={[
                                            { alignItems: "flex-start" },
                                            (theme) => ({
                                                ...theme.typography.t3,
                                                color: theme.palette.primary.main,
                                            }),
                                        ]}
                                        value={value}
                                        label={
                                            <div className={classNames(stDashboard.details_item, st.tab_item_label, (complete ? st.tab_item_label_complete : ''))}>
                                                <div className={classNames(stDashboard.details_item_check, st.tab_item_label_icon)}><CheckedIcon2/></div>
                                                <div className={st.tab_item_title}>{name}{required && '*'}</div>
                                            </div>
                                        }
                                    />
                                ))}
                            </TabList>
                        </Grid>
                    )}
                </TabContext>
            </Grid>
        </>
    );
};

export default observer(PatientProfileEdit);

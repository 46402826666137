// @ts-nocheck
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { TextField, Typography, Grid, Box } from "@mui/material";
import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import { useViewModels } from "presentation/view-model/ViewModelProvider";

import styles from "../../doctorProfileEdit.module.scss";
import { ReactComponent as PlusIcon } from "../../../../../assets/images/icons/plus.svg";
import FileInput from "presentation/views/components/FileInput";
import axios from "axios";
import { ReactComponent as CrossIcon } from "../../../../../assets/images/icons/cross_14_2.svg";
import mediaObjectApi from "../../../../../../../data/mediaObject/mediaObjectApi";
import cleanDeep from "clean-deep";
import { observer } from "mobx-react";
import { UserSaveButtons } from "../../../../../components/UserSaveButtons";


const Certifications: React.FC = ({ changeDetector, setTab }) => {
    const intl = useIntl();
    const {
        doctorProfileViewModel: { options, onSaveProfileButtonClick, model },
        uIViewModel: { setSuccessAlertContent, setErrorAlertContent, },
    } = useViewModels();
    const [saveAndNext, setSaveAndNext] = useState(false);
    const initialValues = {
        id: null,
        file: null,
        title: '',
    };

    const validateCertifications = (values: any) => {
        const errors: any = {certifications: {}};

        values.certifications.forEach((item, index) => {
            if (!item.title.trim()) {
                errors.certifications[index] = {title: "Required"};
            }
        });

        if (Object.keys(cleanDeep(errors)).length > 0) {
            setSaveAndNext(false);
        }

        return cleanDeep(errors);
    }

    const changeForm = useFormik({
        validate: validateCertifications,
        initialValues: {
            certifications: (() => {
                if (options.certifications.length === 0) {
                    return [initialValues];
                } else {
                    return (options.certifications.map(value => {
                        return {
                            id: `api/doctor_certifications/${value.id}`,
                            file: (value.file?.id  ? `api/media_objects/${value.file.id}` : null),
                            title: value.title ?? '',
                        }
                    }));
                }
            })(),
        },

        onSubmit: (values, { setErrors }) => {
            setSuccessAlertContent('', true);
            setErrorAlertContent('', true);

            onSaveProfileButtonClick(values)
                .then(() => {
                    if (saveAndNext) {
                        setSaveAndNext(false);
                        setTab('saveAndNext', '', false, true);
                    }
                    changeDetector(false);
                    setSuccessAlertContent(intl.formatMessage({id: "successSave"}));
                })
                .catch((error) => {
                    console.log(error);
                    setErrorAlertContent(intl.formatMessage({id: "errorSave"}));
                    if (error.errors) {
                        setErrors(error.errors);
                    }
                });
        },
    });

    const uploadFile = (e, index) => {
        mediaObjectApi.loadMedia(e.target.files[0])
            .then((response) => {
                if (response.status === 201 && response.data.contentUrl) {
                    changeForm.values.certifications[index].file = `api/media_objects/${response.data.id}`;

                    changeForm.submitForm().then(() => {
                        setSuccessAlertContent(intl.formatMessage({ id: "successSave" }));
                    })
                        .catch((error) => {
                            console.log(error);
                            setErrorAlertContent(intl.formatMessage({ id: "errorSave" }));
                            if (error.errors) {
                                setErrors(error.errors);
                            }
                        });
                } else {
                    console.log(response);
                }
            })
            .catch(error => {
                console.log(error);
                setErrorAlertContent(intl.formatMessage({ id: "errorSave" }));
            });
    }

    const deleteFile = (mediaObjectIri) => {
        mediaObjectApi.deleteMedia(mediaObjectIri)
            .then((response) => {
                if (response.status === 204) {
                    const deleteFileIndex = options.certifications.findIndex(item => item.file?.id === mediaObjectIri.replace('api/media_objects/', ''));

                    options.certifications[deleteFileIndex].file = null;
                    changeForm.setFieldValue(`certifications[${deleteFileIndex}].file`, null);
                    model.updateFromJson(options);
                    setSuccessAlertContent(intl.formatMessage({ id: "successSave" }));
                } else {
                    console.log(response);
                    setErrorAlertContent(intl.formatMessage({ id: "errorSave" }));
                }
            })
            .catch(error => {
                console.log(error);
                setErrorAlertContent(intl.formatMessage({ id: "errorSave" }));
            });
    }

    return (
        <FormikProvider value={changeForm}>
            <Box component="form" noValidate onSubmit={changeForm.handleSubmit}>
                <Typography
                    variant="h5"
                    sx={{ mb: 5 }}
                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.profile.doctor.certifications.title" }) }}
                />

                <FieldArray
                    name="certifications"
                    render={(arrayHelpers) => (
                        <>
                            <Grid container spacing={6} sx={{ mb: 4 }}>
                                {changeForm.values.certifications?.map((certification, i) => (
                                    <Grid item key={i} xs={12} lg={9}>
                                        <Box className={styles.with_cross_inside}>
                                            <Field
                                                name={`certifications.[${i}].title`}
                                                children={(fieldProps: any) => (
                                                    <TextField
                                                        {...fieldProps.field}
                                                        onChange={(e) => {
                                                            changeForm.handleChange(e);
                                                            changeDetector(true);
                                                        }}
                                                        fullWidth
                                                        label={intl.formatMessage({id: "cabinet.profile.doctor.certifications.certification"})}
                                                        type="text"
                                                        variant="standard"
                                                        error={changeForm.touched.certifications?.[i]?.title && !changeForm.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                        helperText={changeForm.touched.certifications?.[i]?.title && !changeForm.isValid && fieldProps.meta.error}
                                                    />
                                                )}
                                            />

                                            <CrossIcon onClick={() => arrayHelpers.remove(i)} />
                                        </Box>

                                        <Box sx={{ mt: 4 }}>
                                            {certification.file
                                                ?
                                                <Typography variant="t3s" className={styles.file_input} sx={{mt: 4}}>
                                                    <div>{intl.formatMessage({id: "cabinet.profile.doctor.certifications.document"})}</div>

                                                    <a target='_blank' title="Open" rel="noopener noreferrer" href={(axios.defaults.baseURL + options?.certifications?.[i]?.file?.contentUrl)}>
                                                        {certification.title}
                                                    </a>

                                                    <CrossIcon title="Remove file" onClick={() => deleteFile(certification.file)} />
                                                </Typography>
                                                :
                                                <div className={(certification.title.trim().length === 0 ? styles.file_input_upload_disabled : '')}>
                                                    <Field
                                                        name={`certifications.[${i}].file`}
                                                        children={(fieldProps: any) => {
                                                            return (
                                                                <FileInput
                                                                    onChange={(e) => uploadFile(e, i)}
                                                                    label={intl.formatMessage({ id: "cabinet.profile.doctor.certifications.document" })}
                                                                    placeholder={intl.formatMessage({ id: "cabinet.profile.doctor.certifications.document" })}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>

                            <Box sx={{ mt: 5 }}>
                                <div role="button" className={styles.button_add}
                                    onClick={() => {
                                        arrayHelpers.push(initialValues);
                                    }}
                                >
                                    <PlusIcon />

                                    <span>{intl.formatMessage({ id: "cabinet.profile.doctor.certifications.addCertification" })}</span>
                                </div>
                            </Box>
                        </>
                    )}
                />

                <UserSaveButtons setSaveAndNext={setSaveAndNext} />
            </Box>
            {/*<pre style={{color: "saddlebrown", overflow: 'hidden', width: '100%'}}>{JSON.stringify(changeForm.values, 0, 2)}</pre>*/}
        </FormikProvider>
    );
};

export default observer(Certifications);

import AuthRepository from "domain/repository/auth/AuthRepository";
import ClientRepository from "domain/repository/client/ClientRepository";

import authModel from "domain/entity/auth/AuthModel";
import SocialApi from "data/auth/SocialApi";
import errorParse from "../../../presentation/views/helpers/errorParse";

export default class LoginUseCase {
    private authRepository: AuthRepository;
    private clientRepository: ClientRepository;
    private authModel: authModel;

    public constructor(authRepository: AuthRepository, clientRepository: ClientRepository, authModel: authModel) {
        this.authRepository = authRepository;
        this.clientRepository = clientRepository;
        this.authModel = authModel;
    }

    public async loginUser(email: string, password: string): Promise<void> {
        return this.authRepository
            .login(email, password)
            .then(({ accessToken, refreshToken, roles }) => {
                this.clientRepository.authorizeUser(accessToken, refreshToken);
                this.authModel.onSignedIn(roles);
            })
            .catch(error => Promise.reject(errorParse(error)));
    }

    public async facebookLogin(facebookToken: string, userType: string | null, referralCode: string | null): Promise<void> {
        return SocialApi
            .facebookLogin(facebookToken, userType, referralCode)
            .then(({ accessToken, refreshToken, roles }) => {
                this.clientRepository.authorizeUser(accessToken, refreshToken);
                this.authModel.onSignedIn(roles);
            })
            .catch((error) => {
                console.log(error);
                // return error;
                return Promise.reject(error);
                // return Promise.reject(new SubmissionError(error.validationErrors, error.data.message));
            });
    }

    public async googleLogin(googleToken: string, userType: string | null, referralCode: string | null): Promise<void> {
        return SocialApi
            .googleLogin(googleToken, userType, referralCode)
            .then(({ accessToken, refreshToken, roles }) => {
                this.clientRepository.authorizeUser(accessToken, refreshToken);
                this.authModel.onSignedIn(roles);
            })
            .catch((error) => {
                console.log(error);
                // return error;
                return Promise.reject(error);
                // return Promise.reject(new SubmissionError(error.validationErrors, error.data.message));
            });
    }
}

import SubscriptionRepository, {SubscriptionResult} from "../../repository/subscription/SubscriptionRepository";
import {SubmissionError} from "../CustomErrors";

export default class ManageSubscriptionUseCase {
    private subscriptionRepository: SubscriptionRepository;

    constructor(subscriptionRepository: SubscriptionRepository) {
        this.subscriptionRepository = subscriptionRepository;
    }

    postSubscription(name: string, email: string): Promise<SubscriptionResult> {
        return this.subscriptionRepository
            .postSubscription(name, email)
            .then((object) => {
                return Promise.resolve(object);
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error.validationErrors, (error?.data?.message ? error.data.message : (error?.message ? error.message : 'Unknown error'))))
            });
    }
}

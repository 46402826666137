import axios from "axios";
import { extractRoleFromToken } from "./services";

class SocialApi {
    facebookLogin(facebookToken: string, userType: string | null, referralCode: string | null): Promise<any> {
        const data: any = {
            accessToken: facebookToken,
        };

        if (referralCode !== null) {
            data.referralCode = referralCode;
        }

        if (userType && userType.length > 0) {
            // @ts-ignore
            data.userType = userType;
        }

        return axios({
            method: "POST",
            url: axios.defaults.baseURL + "auth/facebook",
            data: data
        })
            .then((serverResponse: any) => {
                console.log(serverResponse)
                const loginResult = serverResponse.data;
                return {
                    accessToken: loginResult["token"],
                    refreshToken: loginResult["refresh_token"],
                    roles: extractRoleFromToken(loginResult["token"]),
                };
            })
            .catch(({ response }) => {
                return Promise.reject(response);
            });
    };

    googleLogin(googleToken: string, userType: string | null, referralCode: string | null): Promise<any> {
        const data: any = {
            accessToken: googleToken,
        };

        if (referralCode !== null) {
            data.referralCode = referralCode;
        }

        if (userType && userType.length > 0) {
            // @ts-ignore
            data.userType = userType;
        }

        return axios({
            method: "POST",
            url: axios.defaults.baseURL + "auth/google",
            data: data
        })
            .then((serverResponse: any) => {
                console.log(serverResponse)
                const loginResult = serverResponse.data;
                return {
                    accessToken: loginResult["token"],
                    refreshToken: loginResult["refresh_token"],
                    roles: extractRoleFromToken(loginResult["token"]),
                };
            })
            .catch(({ response }) => {
                return Promise.reject(response);
            });
    };
}

export default new SocialApi();

// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import st from "./appBar.module.scss";
import { Link, NavLink } from "react-router-dom";
import classNames from "classnames";
import { AuthRoutes, NonAuthRoutes } from "presentation/routes/routes";
import { useViewModels } from "../../../view-model/ViewModelProvider";
import { observer } from 'mobx-react';
import axios from 'axios';
import { toJS } from 'mobx';

import MenuItem from "@mui/material/MenuItem";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Avatar from '@mui/material/Avatar';
import Button from "@mui/material/Button";
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as LogoSmall } from "../../assets/images/logo-small.svg";
import { ReactComponent as AngleBracket } from "../../assets/images/icons/angle-bracket.svg";
import { ReactComponent as UserProfileIcon } from "../../assets/images/icons/user_profile_icon.svg";
import { ReactComponent as EnIcon } from "../../assets/images/icons/en.svg";
import { ReactComponent as DeIcon } from "../../assets/images/icons/de.svg";
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/arrow.svg";

interface appBarProps {
    bgColor?: string;
    className?: string;
    staticBarIsTransparent?: boolean;
    accentBar?: boolean;
    accentWhiteBar?: boolean;
    userMobileMenu?: boolean;
}


export const HeaderBar: React.FC<appBarProps> =  observer(({
    bgColor,
    className,
    staticBarIsTransparent,
    accentBar,
    accentWhiteBar,
    userMobileMenu,
}) => {
    const intl = useIntl();
    const {
        uIViewModel: {
            mobileMenuIsOpen,
            mobileMenuUserIsOpen,
            openMobileMenu,
            closeMobileMenu,
            openMobileUserMenu,
            mobileIs,
        },
        authViewModel: { isDoctor, isUserAuthorized, onClickSignOut },
        userViewModel: { identifier, locale },
        doctorProfileViewModel,
        patientProfileViewModel,
        userViewModel,
    } = useViewModels();
    const [selectLocale, setSelectLocale] = useState(false);
    const [showFixedBar, setShowFixedBar] = useState(false);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setSelectLocale(false);
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event?.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        let isMounted = true;
        if (prevOpen.current === true && open === false) {
            if (isMounted) {
                anchorRef.current.focus();
            }
        }

        if (isMounted) {
            prevOpen.current = open;
        }

        return () => { isMounted = false };
    }, [open]);

    const userAvatar = isDoctor ? toJS(doctorProfileViewModel.model.options.avatar) : toJS(patientProfileViewModel.patientProfile.avatar);
    const user = isDoctor ? doctorProfileViewModel.options : patientProfileViewModel.patientProfile;

    useEffect(() => {
        let lastScrollTop = 0;

        const onScroll = () => {
            const scrollTop = window.scrollY;

            setShowFixedBar(scrollTop <= lastScrollTop);

            const appBarElement = document.querySelector('.app-bar');

            if (scrollTop <= lastScrollTop) {
                setShowFixedBar(true);

                if (appBarElement) {
                    // @ts-ignore
                    document.querySelector('.app-bar').style.position = 'fixed';
                }
            } else {
                setShowFixedBar(false);

                if (appBarElement) {
                    setTimeout(function () {
                        // @ts-ignore
                        appBarElement.style.position = 'absolute';
                    }, 100);
                }
            }

            lastScrollTop = (scrollTop <= 0) ? 0 : scrollTop;

            if (lastScrollTop === 0) {
                setShowFixedBar(false);
            }
        };

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <AppBar
            className={classNames(
                'app-bar',
                st.app_bar,
                showFixedBar ? st.fixed_bar : '',
                window.scrollY < 1 ? st.static_bar : '',
                accentBar ? st.app_bar_accent : '',
                accentWhiteBar ? st.app_bar_accent_white : '',
                mobileMenuIsOpen ? st.app_bar_active : '',
                mobileMenuUserIsOpen ? st.app_bar_active : '',
                className
            )}
            position="fixed"
            style={{
                backgroundColor: showFixedBar || !staticBarIsTransparent ? bgColor : 'transparent',
            }}
        >
            <Toolbar className={st.app_bar_toolBar}>
                <div className={st.container}>
                    <div className={st.logo}>
                        <Link to={"/"}>
                            {!mobileIs ? (
                                <Logo />
                            ) : (
                                <LogoSmall />
                            )}
                        </Link>
                    </div>

                    <div className={st.menu}>
                        {['about', 'doctors', 'news', 'contacts'].map((value, index) => (
                            <NavLink key={`menuLink_${index}`} activeClassName={st.link_active}
                                to={(userViewModel.locale && userViewModel.locale !== 'de' ? '/' + userViewModel.locale : '') + NonAuthRoutes[value]}
                            >
                                { intl.formatMessage({ id: `app.header.menu.${value}` }) }
                            </NavLink>
                        ))}
                    </div>

                    <div className={st.settings}>
                        {!mobileIs && (
                            <div className={`${st.locale} ${selectLocale ? (st.locale_active) : ""}`}>
                                <div
                                    className={st.locale__switcher}
                                    onClick={() => setSelectLocale(!selectLocale)}
                                >
                                    <div>{userViewModel.locale}</div>
                                    <AngleBracket />
                                </div>

                                <div className={st.locale__list}>
                                    <div
                                        className={st.locale__list_item}
                                        onClick={() => {
                                            setSelectLocale(false);
                                            userViewModel.changeLocale(userViewModel.locale === "de" ? "en" : "de");
                                        }}
                                    >
                                        {/* <div className={st.select_locale__local_item}> */}
                                            {userViewModel.locale === "de" ?
                                                <><EnIcon /><div>En</div></>
                                                :
                                                <><DeIcon /><div>De</div></>
                                            }
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* <div className={`${st.locale} ${selectLocale ? (st.locale_active) : ""}`}
                            onClick={() => (!selectLocale ? setSelectLocale(true) : "")}>
                            <div>
                                <div>{userViewModel.locale}</div>

                                <div className={st.select_locale}>
                                    <div onClick={() => setSelectLocale(false)}>{userViewModel.locale}</div>
                                    <div className={st.not_active_locale} onClick={() => {
                                        setSelectLocale(false);
                                        userViewModel.changeLocale(userViewModel.locale === "de" ? "en" : "de");
                                    }}>
                                        <div className={st.select_locale__local_item}>
                                            {userViewModel.locale === "de" ?
                                                <><EnIcon /><div>En</div></>
                                                :
                                                <><DeIcon /><div>De</div></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <AngleBracket onClick={() => setSelectLocale(false)} />
                        </div> */}

                        <div className={st.profile}>
                            {isUserAuthorized ? (
                                <div className={st.profile_icon}
                                    ref={anchorRef}
                                    id="composition-button"
                                    aria-controls={open ? 'composition-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                >
                                    {userAvatar ? (
                                        <Avatar
                                            src={axios.defaults.baseURL + userAvatar}
                                            className={st.avatar}
                                            alt="photo"
                                        />
                                    ) : (
                                        <UserProfileIcon className={st.avatar} />
                                    )}

                                    <Popper
                                        open={open}
                                        anchorEl={anchorRef.current}
                                        role={undefined}
                                        placement="bottom-end"
                                        transition
                                        disablePortal
                                    >
                                        {({ TransitionProps, placement }) => (
                                            <Grow {...TransitionProps}>
                                                <Paper>
                                                    <ClickAwayListener onClickAway={handleClose}>
                                                        <MenuList
                                                            autoFocusItem={open}
                                                            id="composition-menu"
                                                            aria-labelledby="composition-button"
                                                            onKeyDown={handleListKeyDown}
                                                            className={st.profile_menu}
                                                        >
                                                            <Link to={AuthRoutes.cabinet}>
                                                                <MenuItem onClick={handleClose}>{intl.formatMessage({ id: "app.header.account" })}</MenuItem>
                                                            </Link>

                                                            <Link  to={`${locale && locale !== 'de' ? '/' + locale : ''}/${isDoctor ? "doctor" : "patient"}s/${identifier}${user.active ? "" : "/edit"}`}>
                                                                <MenuItem onClick={handleClose}>{intl.formatMessage({ id: "cabinet.menu.profile" })}</MenuItem>
                                                            </Link>

                                                            <Link to={NonAuthRoutes.contacts}>
                                                                <MenuItem onClick={handleClose}>{intl.formatMessage({ id: "app.header.feedback" })}</MenuItem>
                                                            </Link>

                                                            <Link to={AuthRoutes.referral}>
                                                                <MenuItem onClick={handleClose}>{intl.formatMessage({ id: "app.header.tell" })}</MenuItem>
                                                            </Link>

                                                            <Link to={AuthRoutes.bookingAppointments}>
                                                                <MenuItem onClick={handleClose}>{intl.formatMessage({ id: "cabinet.appointments.title" })}</MenuItem>
                                                            </Link>

                                                            <MenuItem
                                                                onClick={() => {
                                                                    onClickSignOut();
                                                                    handleClose();
                                                                }}
                                                            >
                                                                <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.header.logOut" }) }} />
                                                            </MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </div>
                            ) : (
                                <>
                                    <Link to={`${userViewModel.locale && userViewModel.locale !== 'de' ? "/" + userViewModel.locale : ''}${NonAuthRoutes.signin}`} className={st.signIn_button}>
                                        <Button className="btn btn-login" variant="contained">
                                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.header.signIn" }) }}></span>
                                        </Button>
                                    </Link>

                                    <Link to={`${userViewModel.locale && userViewModel.locale !== 'de' ? "/" + userViewModel.locale : ''}${NonAuthRoutes.signup}`} className={st.signIn_button}>
                                        <Button className="btn btn-login" variant="contained">
                                            {intl.formatMessage({ id: "auth.signup.button" })}
                                        </Button>
                                    </Link>
                                </>
                            )}
                        </div>

                        {userMobileMenu ? (
                            <div className={st.burger} onClick={openMobileUserMenu}>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        ) : (
                            <div className={st.burger} onClick={openMobileMenu}>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        )}
                    </div>

                    <div className={`${st.mobile_menu} ${mobileMenuIsOpen ? (st.mobile_menu_active) : ""}`}>
                        <header className={st.mobile_menu__header}>
                            <div className={classNames(st.logo, st.mobile_menu__logo)}>
                                <Link onClick={closeMobileMenu} to={"/"}>
                                    {!mobileIs ? (
                                        <Logo />
                                    ) : (
                                        <LogoSmall />
                                    )}
                                </Link>
                            </div>

                            <div className={st.mobile_menu__close} onClick={closeMobileMenu}>
                                <div></div>
                                <div></div>
                            </div>
                        </header>


                        {userMobileMenu && (
                            <div className={st.toUserMenu} onClick={() => {closeMobileMenu(); openMobileUserMenu()}}>
                                <ArrowIcon />
                                {intl.formatMessage({ id: "cabinet.menu.userMenu" })}
                            </div>
                        )}

                        <div className={st.locale_mobile__list}>
                            {['De', 'En'].map((item, i) => {
                                return (<div
                                    key={item + i}
                                    className={classNames(st.locale_mobile__item, userViewModel.locale === item.toLowerCase() ? st.locale_mobile__item_active : "")}
                                    onClick={() => {
                                        userViewModel.changeLocale(item.toLowerCase());
                                    }}
                                >
                                    {item.toLowerCase() === "en" ? <EnIcon /> : <DeIcon />}
                                    <div>{item}</div>
                                </div>
                            )})}
                        </div>

                        <div className={st.mobile_menu__main}>
                            <Link onClick={closeMobileMenu} to={NonAuthRoutes.about}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.header.menu.about" }) }}></Link>
                            <Link onClick={closeMobileMenu} to={NonAuthRoutes.doctors}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.header.menu.doctors" }) }}></Link>
                            <Link onClick={closeMobileMenu} to={NonAuthRoutes.news}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.header.menu.news" }) }}></Link>
                            <Link onClick={closeMobileMenu} to={NonAuthRoutes.faq}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.header.menu.faq" }) }}></Link>
                            <Link onClick={closeMobileMenu} to={NonAuthRoutes.contacts}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.header.menu.contacts" }) }}></Link>

                            {isUserAuthorized ?
                                <div onClick={() => {
                                        onClickSignOut();
                                        handleClose();
                                        closeMobileMenu();
                                    }}
                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.header.logOut" }) }}></div>
                                :
                                <>
                                    <Link onClick={closeMobileMenu} to={`${userViewModel.locale && userViewModel.locale !== 'de' ? "/" + userViewModel.locale : ''}${NonAuthRoutes.signin}`}>
                                        {intl.formatMessage({ id: "app.header.signIn" })}
                                    </Link>
                                    <Link onClick={closeMobileMenu} to={`${userViewModel.locale && userViewModel.locale !== 'de' ? "/" + userViewModel.locale : ''}${NonAuthRoutes.signup}`}>
                                        {intl.formatMessage({ id: "auth.signup.title" })}
                                    </Link>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
});

import axios from "axios";


class LanguagesApi {
    getLanguages(param = undefined): Promise<[]> {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + `api/languages?perPage=200&${param ?? ''}`,
        }).then((result: any) => {
            return result.data;
        }).catch((error) => {
            console.log({error});
            return Promise.reject(error);
        });
    }
}

export default new LanguagesApi();

import classNames from "classnames/bind";
import React, { ReactNode } from "react";
import st from "./tooltipedCard.module.scss";

type TooltipedCardProps = {
    tooltip: ReactNode;
    variant?: "normal" | "bordered";
    buttons?: ReactNode;
};

const TooltipedCard: React.FC<TooltipedCardProps> = ({ children, tooltip, variant = "normal", buttons }) => {
    const classes = classNames(st.container, {
        [st[`variant_normal`]]: variant === "normal",
        [st[`variant_bordered`]]: variant === "bordered",
    });

    return (
        <div className={classes}>
            <div className={st.content}>{children}</div>
            {buttons && <div className={st.buttons}>{buttons}</div>}
            <div className={st.tooltip}>{tooltip}</div>
        </div>
    );
};

export default TooltipedCard;

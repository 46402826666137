import React from "react";
import st from "./specializationPreview.module.scss";

interface SpecializationPreviewProps {
    title: string;
    img: string;
}

export const SpecializationPreview: React.FC<SpecializationPreviewProps> = ({
   title,
   img,
}) => {
    return (
        <div className={st.specialization_preview}>
            <div className={st.img}>
                <img src={img} alt={title} />
            </div>

            <div className={st.title}>
                <div>{title}</div>
                <div className={st.plus}></div>
            </div>
        </div>
    );
};

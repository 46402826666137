import MediaRepository from "domain/repository/media/MediaRepository";
import MediaStructure from "domain/entity/media/structures/MediaStructure";
import {SubmissionError} from "../CustomErrors";

export default class ManageMediaUseCase {
    private mediaRepository: MediaRepository;

    constructor(mediaRepository: MediaRepository) {
        this.mediaRepository = mediaRepository;
    }

    getMediaCollections = () => {
        return this.mediaRepository
            .getMediaCollections()
            .then((result: [MediaStructure[], []]) => {
                return result;
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error.validationErrors, (error?.data?.message ? error.data.message : (error?.message ? error.message : 'Unknown error'))))
            })
    }

    addMedia = (data: any) => {
        return this.mediaRepository
            .addMedia(data)
            .then((result: MediaStructure) => {
                return result;
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error.validationErrors, (error?.data?.message ? error.data.message : (error?.message ? error.message : 'Unknown error'))))
            })
    }

    getMediaById = (id: any): Promise<void> => {
        return this.mediaRepository
            .getMediaById(id)
            .then((result: any) => {
                return result;
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error.validationErrors, (error?.data?.message ? error.data.message : (error?.message ? error.message : 'Unknown error'))))
            })
    }
}

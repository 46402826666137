// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import st from "./chooseDoctor.module.scss";
import classNames from "classnames";

import Swiper, { Autoplay, EffectFade, Pagination } from "swiper";
import { Button } from "@mui/material";
import { ReactComponent as AngleBracket } from "../../assets/images/icons/angle-bracket.svg";
import { ReactComponent as AngleBracketNext } from "../../assets/images/icons/angle-bracket-thin-next.svg";
import { Planet } from "../Planet";

export const ChooseDoctor: React.FC = () => {
    const intl = useIntl();
    const [swiper, setSwiper] = useState("");
    const [chooseDoctorSwiperActiveIndex, setChooseDoctorSwiperActiveIndex] = useState(0);

    useEffect(() => {
        const chooseDoctorSliderConfig = {
            modules: [EffectFade, Autoplay, Pagination],
            speed: 700,
            effect: "fade",
            fadeEffect: {
                crossFade: true,
            },
            allowTouchMove: false,
            onAny(eventName, ...args) {
                if (eventName === "afterInit" || eventName === "slideChange") {
                    setChooseDoctorSwiperActiveIndex(args[0].activeIndex);
                }
            },
        };

        setSwiper(new Swiper(".swiper-choose_doctor", chooseDoctorSliderConfig));
    }, []);

    const prevClick = () => {
        if (swiper.realIndex === 0) {
            swiper.slideTo(4);
        } else {
            swiper.slidePrev();
        }
    };
    const nextClick = () => {
        if (swiper.realIndex === 3) {
            swiper.slideTo(0);
        } else {
            swiper.slideNext();
        }
    };

    return (
        <div className={st.container}>
            <div className={st.description_part}>
                <div className={classNames("pagination", st.graphic_pagination)}>
                    {[1, 2, 3, 4].map((val, index) => (
                        <div
                            key={index}
                            className={classNames(st.paginate_circle, chooseDoctorSwiperActiveIndex === index && st.paginate_circle_active)}
                        >
                            <Planet title={<div className={st.graphic_pagination_title}>0{val}</div>} bgColor="#00B9FF" />
                        </div>
                    ))}
                </div>

                <div className={st.title}>
                    <div
                        className={classNames(st.slide_title, chooseDoctorSwiperActiveIndex === 1 && st.slide_title_active)}
                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.steps.chooseTitle" }) }}
                    ></div>
                    <div
                        className={classNames(st.slide_title, chooseDoctorSwiperActiveIndex === 2 && st.slide_title_active)}
                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.steps.bookTitle" }) }}
                    ></div>
                    <div
                        className={classNames(st.slide_title, chooseDoctorSwiperActiveIndex === 3 && st.slide_title_active)}
                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.steps.comeTitle" }) }}
                    ></div>
                    <div
                        className={classNames(st.slide_title, chooseDoctorSwiperActiveIndex === 0 && st.slide_title_active)}
                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.steps.registerTitle" }) }}
                    ></div>
                </div>

                <div className={st.nav}>
                    <div className={st.button_prev}>
                        <Button className="btn--trans" onClick={prevClick} size="large" color="primary" variant="circle">
                            <AngleBracket />
                        </Button>
                    </div>

                    <div className={st.button_next}>
                        <Button className="btn--trans" onClick={nextClick} size="large" color="primary" variant="circle">
                            <AngleBracketNext />
                        </Button>
                    </div>
                </div>

                <div className={classNames("swiper-choose_doctor swiper", st.description)}>

                    <div className="swiper-wrapper">
                        <div className={classNames("swiper-slide", st.slide)}>
                            <div
                                className={st.slide_title_mobile}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.steps.registerTitle" }) }}
                            ></div>
                            {/*<div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.steps.registerDescription" }) }}></div>*/}
                        </div>

                        <div className={classNames("swiper-slide", st.slide)}>
                            <div
                                className={st.slide_title_mobile}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.steps.chooseTitle" }) }}
                            ></div>
                            {/*<div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.steps.chooseDescription" }) }}></div>*/}
                        </div>

                        <div className={classNames("swiper-slide", st.slide)}>
                            <div
                                className={st.slide_title_mobile}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.steps.bookTitle" }) }}
                            ></div>
                            {/*<div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.steps.bookDescription" }) }}></div>*/}
                        </div>

                        <div className={classNames("swiper-slide", st.slide)}>
                            <div
                                className={st.slide_title_mobile}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.steps.comeTitle" }) }}
                            ></div>
                            {/*<div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.steps.comeDescription" }) }}></div>*/}
                        </div>

                    </div>
                </div>
            </div>

            <div className={st.decor_part}>
                <div className={st.decor_img_wrap}>
                    {[1, 2, 3, 4].map((val, i) => (
                        <div key={i} className={classNames(st.decor_img, chooseDoctorSwiperActiveIndex === i && st.decor_img_active)}>
                            <img src={`/images/choose-doctor/choose-doctor-${val}.jpg`} alt="For doctors" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

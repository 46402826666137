import React from "react";
import { useIntl } from "react-intl";
import st from "./askQuestion.module.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { NonAuthRoutes } from "../../../routes/routes";
import { Planet } from "../Planet";
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/arrow.svg";

interface plusProps {
    className?: string;
}

export const AskQuestion: React.FC<plusProps> = ({className}) => {
    const intl = useIntl();
    return (
        <section className={classNames(st.ask_question, className)}>
            <div className={st.ask_question__container}>
                <div className={st.ask_question__description_part}>
                    <div className={st.ask_question__title}
                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.askQuestions.title" }) }}></div>
                </div>

                <div className={st.ask_question__decor_part}>
                    <div className={st.ask_question__img}>
                        <img src="/images/ask-questions.jpg" alt="For doctors" />
                    </div>

                    <Link to={NonAuthRoutes.contacts} className={st.ask_question__planet}>
                        <Planet
                            title={<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.askQuestions.button" }) }}></span>}
                            icon={<div><ArrowIcon /></div>}
                            bgColor="#00B9FF"
                            contentColor="color_accent_dark"
                            gravity
                        />
                    </Link>
                </div>
            </div>
        </section>
    );
};

// ts-nocheck
import DoctorExperienceApi from "../../../data/doctorExperience/DoctorExperienceApi";
import {makeAutoObservable} from "mobx";

class DoctorExperienceViewModel {
    public doctorExperience = [];
    public pagination = {
        currentPage: 1,
        hasNextPage: false,
        hasPreviousPage: false,
        itemsPerPage: 500,
        totalItems: 0,
        totalPages: 0
    };

    constructor() {
        makeAutoObservable(this);
    }


    getDoctorExperiencePromise = (value: string) => {
        return DoctorExperienceApi.getDoctorExperience(value);
    }

    /*    getDoctorExperience = () => {
            DoctorExperienceApi.getDoctorExperience()
                .then(action((data) => {
                    this.doctorExperience = data.items;
                    this.pagination = data.pagination;
                }));
        }*/
}

export default new DoctorExperienceViewModel();

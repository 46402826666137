import SearchBySpecialtiesModel from "domain/entity/searchBySpecialties/SearchBySpecialtiesModel";
import LakeRepository from "domain/repository/lake/LakeRepository";

import { SubmissionError } from "../CustomErrors";

export default class LoadAvailableLettersUseCase {
    private lakeRepository: LakeRepository;
    private searchBySpecialtiesModel: SearchBySpecialtiesModel;

    constructor(lakeRepository: LakeRepository, searchBySpecialtiesModel: SearchBySpecialtiesModel) {
        this.lakeRepository = lakeRepository;
        this.searchBySpecialtiesModel = searchBySpecialtiesModel;
    }

    start = (): Promise<void> => {
        return this.lakeRepository
            .loadSpecialtiesAvailableLetters()
            .then((letters) => {
                this.searchBySpecialtiesModel.updateAvailableLetters(letters);
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error?.validationErrors, error?.response?.data?.message));
            });
    };
}

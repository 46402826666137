// @ts-nocheck
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { TextField, Typography, Grid, Checkbox, FormControlLabel, Box, Button, Tooltip } from "@mui/material";
import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import { useViewModels } from "presentation/view-model/ViewModelProvider";
import classNames from "classnames";
import cleanDeep from "clean-deep";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import st from "./experiences.module.scss";
import styles from "../../doctorProfileEdit.module.scss";
import { ReactComponent as CrossIcon } from "../../../../../assets/images/icons/cross_14_2.svg";
import { ReactComponent as PlusIcon } from "../../../../../assets/images/icons/plus.svg";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocaleMap } from "../../../../../../../types";
import { UserSaveButtons } from "../../../../../components/UserSaveButtons";


const Experiences: React.FC = ({ changeDetector, setTab, congratulationsDetector }) => {
    const intl = useIntl();
    const {
        doctorProfileViewModel: { options, onSaveProfileButtonClick },
        uIViewModel: { setSuccessAlertContent, setErrorAlertContent, },
    } = useViewModels();
    const [saveAndNext, setSaveAndNext] = useState(false);
    const [notFind, setNotFind] = useState(false);

    const initialValues = {
        id: null,
        clinic: {
            id: null,
            title: "",
            location: "",
            lat: "",
            note: "",
        },
        startOfWork: "",
        endOfWork: "",
        currentWork: false,
        country: "",
        city: "",
        state: "",
        zipCode: "",
    };

    const validateExperiences = (values: any) => {
        const errors: any = {experiences: {}};

        values.experiences.forEach((item, index, array) => {
            if (!item.clinic.title.trim()) {
                errors.experiences[index] = {clinic: {title: "Required" }};
            }

            if (!item.currentWork) {
                if (array.findIndex(item => item.currentWork === true) === -1)
                errors.experiences[index] = {...errors.experiences[index], currentWork: intl.formatMessage({id: "userCard.currentWork"})};
            }
        });

        if (Object.keys(cleanDeep(errors)).length > 0) {
            setSaveAndNext(false);

            const errorsArray = Object.values(cleanDeep(errors.experiences)).map(item => Object.keys(item));
            if (errorsArray.findIndex(item => item.includes('currentWork')) !== -1) {
                setErrorAlertContent(intl.formatMessage({ id: "userCard.currentWork" }), true);
            }
        }
        return cleanDeep(errors);
    }

    const changeForm = useFormik({
        validate: validateExperiences,
        initialValues: {
            experiences: (() => {
                if (options.experiences.length === 0) {
                    return [{ ...initialValues, currentWork: true }];
                } else {
                    return (options.experiences.map(value => {
                        return {
                            id: `api/doctor_experiences/${value.id}`,
                            clinic: {
                                id: `api/clinics/${value.clinic.id}`,
                                title: value.clinic.title ?? '',
                                location: value.clinic.location ?? '',
                                lat: value.clinic.lat ?? '',
                                note: value.clinic.note ?? '',
                            },
                            startOfWork: value.startOfWork ?? '',
                            endOfWork: value.endOfWork ?? '',
                            currentWork: value.currentWork,
                            country: value.country ?? '',
                            city: value.city ?? '',
                            state: value.state ?? '',
                            zipCode: value.zipCode ?? '',
                        }
                    }));
                }
            })(),
        },

        onSubmit: (values, { setErrors }) => {
            setSuccessAlertContent('', true);
            setErrorAlertContent('', true);

            onSaveProfileButtonClick(values)
                .then((result) => {
                    if (!options.active && result.active) {
                        congratulationsDetector();
                    }

                    if (saveAndNext) {
                        setSaveAndNext(false);
                        setTab('saveAndNext', '', false, true);
                    }
                    changeDetector(false);
                    setSuccessAlertContent(intl.formatMessage({id: "successSave"}));
                })
                .catch((error) => {
                    console.log(error);
                    setErrorAlertContent(intl.formatMessage({id: "errorSave"}));
                    if (error.errors) {
                        setErrors(error.errors);
                    }
                });
        },
    });

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP,
    });

    const [defaultLocation, setDefaultLocation] = useState({lat: 48.2, lng: 16.3});
    const [zoom, setZoom] = useState(5);
    const [showMarker, setShowMarker] = useState(false);
    const mapContainerStyle = {height: '400px', width: '100%',};

    const getClinicLocation = (index, clinic, directFind = false) => {
        if (directFind) {
            let geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ address: clinic.title}, function (results, status) {
                if (status === 'OK') {
                    const mapData = results[0];
                    console.log('mapData', mapData);
                    setZoom(15);
                    setDefaultLocation(mapData.geometry.location);
                    const countryObject = mapData.address_components.find(item => item.types.includes('country'));
                    let cityObject = mapData.address_components.find(item => item.types.includes('locality'));
                    if (!cityObject) {
                        cityObject = mapData.address_components.find(item => item.types.includes('postal_town'));
                    }
                    const stateObject = mapData.address_components.find(item => item.types.includes('administrative_area_level_1'));
                    const zipObject = mapData.address_components.find(item => item.types.includes('postal_code'));
                    const streetNumberObject = mapData.address_components.find(item => item.types.includes('street_number'));
                    const streetRouteObject = mapData.address_components.find(item => item.types.includes('route'));

                    // changeForm.setFieldValue(`experiences[${index}].clinic.location`, mapData.formatted_address);
                    if (mapData.geometry.location) {
                        changeForm.setFieldValue(`experiences[${index}].clinic.lat`, JSON.stringify(mapData.geometry.location));
                    }
                    if (countryObject?.long_name) {
                        changeForm.setFieldValue(`experiences[${index}].country`, countryObject.long_name);
                    }
                    if (cityObject?.long_name) {
                        changeForm.setFieldValue(`experiences[${index}].city`, cityObject.long_name);
                    }
                    if (stateObject?.long_name) {
                        changeForm.setFieldValue(`experiences[${index}].state`, stateObject.long_name);
                    }
                    if (zipObject?.long_name) {
                        changeForm.setFieldValue(`experiences[${index}].zipCode`, zipObject.long_name);
                    }
                    if (streetNumberObject?.long_name || streetRouteObject?.long_name) {
                        changeForm.setFieldValue(`experiences[${index}].clinic.location`, (streetRouteObject?.long_name ?? '') + ' ' + (streetNumberObject?.long_name ?? ''));
                    }

                    setShowMarker(true);
                    setNotFind(false);
                } else {
                    console.log('Geocode was not successful for the following reason: ' + status);
                    setNotFind(true);
                }
            });
        } else if (clinic.lat) {
            setZoom(15);
            setDefaultLocation(clinic.lat);
            setShowMarker(true);
        }
    };

    return (
        <FormikProvider value={changeForm}>
            <Box component="form" noValidate onSubmit={changeForm.handleSubmit}>
                <Typography
                    variant="h5"
                    sx={{ mb: 5 }}
                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.profile.doctor.experience.title" }) }}
                />
                {/* Experience and Clinics loop beg */}
                <FieldArray
                    name="experiences"
                    render={(arrayHelpers) => (
                        <Grid container spacing={4} sx={{ mb: {xs: 4, sm: 6,} }}>
                            {changeForm.values.experiences?.map((experience, i) => (
                                <Grid key={i} item xs={12} className={classNames(styles.container_universal, st.container_universal)}>
                                    <div className={styles.main_block_universal}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} className={classNames(styles.mini_button_box, st.first_field)}>
                                                <Field
                                                    name={`experiences.[${i}].clinic.title`}
                                                    children={(fieldProps: any) => (
                                                        <TextField
                                                            {...fieldProps.field}
                                                            onChange={(e) => {
                                                                changeForm.handleChange(e);
                                                                changeDetector(true);
                                                            }}
                                                            onKeyDown={e => {
                                                                if (e.key === 'Enter') {
                                                                    e.preventDefault();
                                                                    if (experience.clinic.title && experience.currentWork) {
                                                                        getClinicLocation(i, experience.clinic, true);
                                                                    }
                                                                }
                                                            }}
                                                            fullWidth
                                                            label={intl.formatMessage({id: "clinic_address"})}
                                                            type="text"
                                                            variant="standard"
                                                            error={changeForm.touched.experiences?.[i]?.clinic?.title && !changeForm.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                            helperText={changeForm.touched.experiences?.[i]?.clinic?.title && !changeForm.isValid && fieldProps.meta.error}
                                                        />
                                                    )}
                                                />

                                                {experience.clinic.title &&
                                                    <Tooltip title={experience.clinic.title && experience.currentWork ? '' : intl.formatMessage({'id': 'buttonFindTitle'})}>
                                                        <div className={styles.mini_button}>
                                                            <Button type="button" variant="contained" size="small" color="secondary"  className={styles.mini_button}
                                                                    disabled={!(experience.currentWork && experience.clinic.title)}
                                                                    onClick={() => getClinicLocation(i, experience.clinic, true)}
                                                            >
                                                                {intl.formatMessage({id: "find"})}
                                                            </Button>
                                                        </div>
                                                    </Tooltip>
                                                }
                                            </Grid>

                                            <Grid item xs={6} lg={6}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={LocaleMap[intl.locale as keyof typeof LocaleMap]}>
                                                    <MobileDatePicker
                                                        name={`experiences.[${i}].startOfWork`}
                                                        disableCloseOnSelect={false}
                                                        views={['year', 'month']}
                                                        label={intl.formatMessage({id: 'cabinet.profile.doctor.experience.startOfWork'})}
                                                        minDate={new Date('1970-01-01')}
                                                        maxDate={new Date()}
                                                        value={experience.startOfWork ? experience.startOfWork : null}
                                                        onChange={newValue => {
                                                            changeForm.setFieldValue(`experiences.[${i}].startOfWork`, newValue);
                                                            changeDetector(true);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} helperText={null} />}
                                                        className={styles.buttons_years}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid item xs={6} lg={6} className={`${experience.currentWork ? 'disabled' : ''}`}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={LocaleMap[intl.locale as keyof typeof LocaleMap]}>
                                                    <MobileDatePicker
                                                        name={`experiences.[${i}].endOfWork`}
                                                        disableCloseOnSelect={false}
                                                        views={['year', 'month']}
                                                        label={intl.formatMessage({id: 'cabinet.profile.doctor.experience.endOfWork'})}
                                                        minDate={new Date('1970-01-01')}
                                                        maxDate={new Date()}
                                                        value={experience.endOfWork ? experience.endOfWork : null}
                                                        onChange={newValue => {
                                                            changeForm.setFieldValue(`experiences.[${i}].endOfWork`, newValue);
                                                            changeDetector(true);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} helperText={null} />}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>

                                            {experience.currentWork && experience.clinic.title && (
                                                <Grid item xs={12} display={"flex"} flexDirection={"column"}>
                                                    {notFind ?
                                                        <div className="error">{intl.formatMessage({"id": "notFind"})}</div>
                                                        :
                                                        (isLoaded ?
                                                            <GoogleMap
                                                                mapContainerStyle={mapContainerStyle}
                                                                center={experience.clinic.lat ? JSON.parse(experience.clinic.lat) : defaultLocation}
                                                                zoom={experience.clinic.lat ? 15 : zoom}
                                                                // onClick={(e: MapMouseEvent) => console.log('map click e', e, e.latLng)}
                                                            >
                                                                {(showMarker || experience.clinic.lat) &&
                                                                    <Marker icon={{ url: "/images/mapMarker.svg" }} position={experience.clinic.lat ? JSON.parse(experience.clinic.lat) : defaultLocation} />
                                                                }
                                                            </GoogleMap>
                                                            :
                                                            <>{intl.formatMessage({"id": "loadMap"})}</>)
                                                    }
                                                </Grid>
                                            )}

                                            <Grid item xs={12} lg={6}>
                                                <Field
                                                    name={`experiences.[${i}].country`}
                                                    children={(fieldProps: any) => (
                                                        <TextField
                                                            {...fieldProps.field}
                                                            onChange={(e) => {
                                                                changeForm.handleChange(e);
                                                                changeDetector(true);
                                                            }}
                                                            fullWidth
                                                            label={intl.formatMessage({id: "country"})}
                                                            type="text"
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12} lg={6}>
                                                <Field
                                                    name={`experiences.[${i}].city`}
                                                    children={(fieldProps: any) => (
                                                        <TextField
                                                            {...fieldProps.field}
                                                            onChange={(e) => {
                                                                changeForm.handleChange(e);
                                                                changeDetector(true);
                                                            }}
                                                            fullWidth
                                                            label={intl.formatMessage({id: "doctor.experience.city"})}
                                                            type="text"
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12} lg={6}>
                                                <Field
                                                    name={`experiences.[${i}].state`}
                                                    children={(fieldProps: any) => (
                                                        <TextField
                                                            {...fieldProps.field}
                                                            onChange={(e) => {
                                                                changeForm.handleChange(e);
                                                                changeDetector(true);
                                                            }}
                                                            fullWidth
                                                            label={intl.formatMessage({id: "doctor.experience.state"})}
                                                            type="text"
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12} lg={6}>
                                                <Field
                                                    name={`experiences.[${i}].zipCode`}
                                                    children={(fieldProps: any) => (
                                                        <TextField
                                                            {...fieldProps.field}
                                                            onChange={(e) => {
                                                                changeForm.handleChange(e);
                                                                changeDetector(true);
                                                            }}
                                                            fullWidth
                                                            label={intl.formatMessage({id: "doctor.experience.zipCode"})}
                                                            type="text"
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Field
                                                    name={`experiences.[${i}].clinic.location`}
                                                    children={(fieldProps: any) => (
                                                        <TextField
                                                            {...fieldProps.field}
                                                            onChange={(e) => {
                                                                changeForm.handleChange(e);
                                                                changeDetector(true);
                                                            }}
                                                            fullWidth
                                                            label={intl.formatMessage({id: "cabinet.dashboard.doctor.card.location"})}
                                                            type="text"
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Field
                                                    name={`experiences.[${i}].clinic.note`}
                                                    children={(fieldProps: any) => (
                                                        <TextField
                                                            {...fieldProps.field}
                                                            onChange={(e) => {
                                                                changeForm.handleChange(e);
                                                                changeDetector(true);
                                                            }}
                                                            fullWidth
                                                            className={st.note}
                                                            label={intl.formatMessage({id: "doctor.card.note"})}
                                                            type="text"
                                                            variant="standard"
                                                            multiline
                                                            maxRows={3}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <div className={classNames(styles.button_delete_universal, st.delete_block)}>
                                        <div className={styles.button_delete} role="button"
                                             onClick={() => {
                                                 arrayHelpers.remove(i);
                                             }}
                                        >
                                            <CrossIcon />

                                            <span>{intl.formatMessage({ id: "cabinet.profile.doctor.education.delete" })}</span>
                                        </div>

                                        <FormControlLabel
                                            className={`
                                                    ${!experience.currentWork &&
                                            changeForm.values.experiences.length > 1 &&
                                            (changeForm.values.experiences.findIndex(item => item.currentWork === true) !== -1)
                                                ?
                                                'disabled'
                                                :
                                                ''
                                            }
                                                `}
                                            control={
                                                <Field
                                                    name={`experiences.[${i}].currentWork`}
                                                    children={(fieldProps: any) => (
                                                        <Checkbox {...fieldProps.field}
                                                                  checkedIcon={<span />}
                                                                  icon={<span />}
                                                                  checked={experience.currentWork}
                                                                  onChange={(e) => {
                                                                      changeForm.handleChange(e);
                                                                      changeForm.setFieldValue(`experiences[${i}].endOfWork`, "");
                                                                      changeDetector(true);
                                                                      if (e.target.checked) {
                                                                          console.log('e.target.checked', e.target.checked);
                                                                          setErrorAlertContent('', true);
                                                                      }
                                                                  }}
                                                        />
                                                    )}
                                                />
                                            }
                                            label={intl.formatMessage({id: "cabinet.profile.doctor.experience.currentWork"})}
                                        />
                                    </div>
                                </Grid>
                            ))}

                            <Box sx={{ mt: 6, ml: 4 }}>
                                <div role="button"
                                    onClick={() => {
                                        arrayHelpers.push(initialValues);
                                    }}
                                    className={styles.button_add}
                                >
                                    <PlusIcon />

                                    <span>{intl.formatMessage({ id: "cabinet.profile.doctor.experience.addClinic" })}</span>
                                </div>
                            </Box>
                        </Grid>
                    )}
                />

                <UserSaveButtons setSaveAndNext={setSaveAndNext} />
            </Box>
            {/*<pre style={{color: "saddlebrown", overflow: 'hidden', width: '100%'}}>{JSON.stringify(changeForm.values, 0, 2)}</pre>*/}
        </FormikProvider>
    );
};

export default Experiences;

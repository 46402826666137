// @ts-nocheck
import React, { useState } from 'react';
import st from './appBar.module.scss';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useViewModels } from '../../../view-model/ViewModelProvider';
import { observer } from 'mobx-react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { ReactComponent as Logo } from '../../assets/images/logo-2.svg';
import { ReactComponent as AngleBracket } from '../../assets/images/icons/angle-bracket.svg';
import { ReactComponent as EnIcon } from '../../assets/images/icons/en.svg';
import { ReactComponent as DeIcon } from '../../assets/images/icons/de.svg';

interface appBarProps {
    bgColor?: string;
    className?: string;
    staticBarIsTransparent?: boolean;
    accentBar?: boolean;
}

export const HeaderBarSmall: React.FC<appBarProps> = observer(({ bgColor, className, staticBarIsTransparent, accentBar }) => {
    const [selectLocale, setSelectLocale] = useState(false);

    // return focus to the button when we transitioned from !open -> open
    const { userViewModel } = useViewModels();

    return (
        <AppBar
            className={classNames(
                'app-bar',
                st.app_bar,
                st.app_bar_small,
                st.static_bar,
                className
            )}
        >
            <Toolbar className={st.app_bar_toolBar}>
                <div className={st.container}>
                    <div className={st.container_inner}>
                        <div className={st.logo}>
                            <Link to={'/'}>
                                <Logo />
                            </Link>
                        </div>

                        <div className={st.settings}>
                            <div className={`${st.locale} ${selectLocale ? st.locale_active : ''}`}>
                                <div className={st.locale__switcher} onClick={() => setSelectLocale(!selectLocale)}>
                                    <div>{userViewModel.locale}</div>
                                    <AngleBracket />
                                </div>

                                <div className={st.locale__list}>
                                    <div
                                        className={st.locale__list_item}
                                        onClick={() => {
                                            setSelectLocale(false);
                                            userViewModel.changeLocale(userViewModel.locale === 'de' ? 'en' : 'de');
                                        }}
                                    >
                                        {/* <div className={st.select_locale__local_item}> */}
                                        {userViewModel.locale === 'de' ? (
                                            <>
                                                <EnIcon />
                                                <div>En</div>
                                            </>
                                        ) : (
                                            <>
                                                <DeIcon />
                                                <div>De</div>
                                            </>
                                        )}
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
});

import ConnectionRepository from "domain/repository/connection/ConnectionRepository";
import axios from "axios";
import { Connection } from "../../domain/entity/connections/structures/ConnectionStructure";

export default class ConnectionApi implements ConnectionRepository {

    askToApproveSkills(doctorId: string): Promise<any> {
        return axios({
            method: "POST",
            url: axios.defaults.baseURL + `api/ask-to-approve-skills`,
            data: {
                doctor: `/api/doctors/${doctorId}`
            },
        });
    }

    getMyConnections = async (): Promise<[Connection[], []]> => {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + 'api/connections?stateValue=my_connections',
        }).then((connections: any) => {
            return connections.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    getConnectionsRequests(): Promise<[Connection[], []]> {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + 'api/connections?stateValue=connection_requests',
        }).then((connections: any) => {
            return connections.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    getOutgoingConnectionsRequests(): Promise<[Connection[], []]> {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + 'api/connections?stateValue=outgoing_requests',
        }).then((connections: any) => {
            return connections.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    postConnection(recipientIri: string): Promise<any> {
        return axios({
            method: "POST",
            url: axios.defaults.baseURL + "api/connections",
            data: {
                recipient: recipientIri,
            },
        });
    }

    changeConnectionStatus = (connectionId: string, status: 'cancel' | 'reject' | 'accept') : any => {
        return axios({
            method: "PUT",
            url: axios.defaults.baseURL + `api/connections/${connectionId}/${status}`,
            data: {},
        });
    }
}

// @ts-nocheck
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { TextField, Typography, Box, Grid } from "@mui/material";
import { FormikProvider, Field, useFormik } from "formik";
import isEmail from "validator/lib/isEmail";
// import isMobilePhone from "validator/lib/isMobilePhone";
import { useViewModels } from "presentation/view-model/ViewModelProvider";
import { UserSaveButtons } from "../../../../components/UserSaveButtons";
import ReactPhoneInput from 'react-phone-input-material-ui';
import st from "./contactsUser.module.scss";
import classNames from "classnames";


const ContactsUser: React.FC = ({ changeDetector, setTab }) => {
    const intl = useIntl();
    const {
        patientProfileViewModel: {patientProfile, updatePatient},
        userViewModel,
        uIViewModel: { setSuccessAlertContent, setErrorAlertContent, },
    } = useViewModels();
    const [saveAndNext, setSaveAndNext] = useState(false);

    const validate = (values: any) => {
        const errors: any = {};

        /*if (values.phone && !isMobilePhone(values.phone)) {
            errors.phone = intl.formatMessage({id: "errors.phone"});
        }*/

        if (values.workEmail && !isEmail(values.workEmail)) {
            errors.workEmail = intl.formatMessage({id: "errors.email"});
        }

        return errors;
    }

    const changeForm = useFormik({
        validate: validate,
        initialValues: {
            phone: patientProfile.phone ?? '',
            workEmail: patientProfile.workEmail ?? '',
            address: patientProfile.address ?? '',
        },
        onSubmit: (values, { setErrors }) => {
            setSuccessAlertContent('', true);
            setErrorAlertContent('', true);

            updatePatient(values)
                .then(() => {
                    if (saveAndNext) {
                        setSaveAndNext(false);
                        setTab('saveAndNext', '', false, true);
                    }
                    changeDetector(false);
                    setSuccessAlertContent(intl.formatMessage({id: "successSave"}));
                })
                .catch((error) => {
                    console.log(error);
                    setErrorAlertContent(intl.formatMessage({id: "errorSave"}));
                    if (error.errors) {
                        setErrors(error.errors);
                    }
                });
        },
    });
    console.log(changeForm)

    const phoneChange = (phone: number) => {
        changeForm.setFieldValue("phone", phone);
        changeDetector(true);
    }

    const phoneFocusChange = (e: any, phone: number) => {
        if (changeForm.values.phone?.length <= 1) {
            changeForm.setFieldValue("phone", phone.dialCode);
        }
    }

    return (
        <FormikProvider value={changeForm}>
            <Box component="form" noValidate onSubmit={changeForm.handleSubmit}>
                <Typography
                    variant="h5"
                    sx={{ mb: 5 }}
                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "userPage.myContacts" }) }}
                />

                <Grid item xs={12} md={9}>
                    <Grid container spacing={4} sx={{ mb: 2.5 }}>
                        <Grid item xs={12} lg={6}>
                            <Field
                                name="phone"
                                children={(fieldProps: any) => (
                                    <ReactPhoneInput
                                        {...fieldProps.field}
                                        value={changeForm.values.phone}
                                        className={classNames(st.phone_container, changeForm.values.phone && changeForm.values.phone.length >= 1 ? st.filled : "")}
                                        onChange={phoneChange}
                                        onFocus={phoneFocusChange}
                                        disableDropdown
                                        country={userViewModel.locale || "de"}
                                        preserveOrder={['country', 'preferredCountries']}
                                        disableCountryGuess={true}
                                        inputProps={{
                                            id: "phone",
                                            margin: "normal",
                                            inputMode: 'numeric',
                                            variant: "standard",
                                            autoComplete: "phone",
                                            error: !changeForm.isValid && Boolean(fieldProps.meta.error),
                                            helperText: !changeForm.isValid && fieldProps.meta.error,
                                            label: `${intl.formatMessage({ id: "cabinet.account.phoneNumber" }) }`,
                                        }}
                                        prefix=''
                                        defaultErrorMessage={changeForm.touched.phone && !changeForm.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        error={changeForm.touched.phone && !changeForm.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={changeForm.touched.phone && !changeForm.isValid && fieldProps.meta.error}
                                        component={TextField}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <Field
                                name="workEmail"
                                children={(fieldProps: any) => (
                                    <TextField
                                        {...fieldProps.field}
                                        onChange={(e) => {
                                            changeForm.handleChange(e);
                                            changeDetector(true);
                                        }}
                                        fullWidth
                                        label={intl.formatMessage({id: "cabinet.account.form.email"})}
                                        type="text"
                                        variant="standard"
                                        error={changeForm.touched.workEmail && !changeForm.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={changeForm.touched.workEmail && !changeForm.isValid && fieldProps.meta.error}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <Field
                                name="address"
                                children={(fieldProps: any) => (
                                    <TextField
                                        {...fieldProps.field}
                                        onChange={(e) => {
                                            changeForm.handleChange(e);
                                            changeDetector(true);
                                        }}
                                        fullWidth
                                        label={intl.formatMessage({id: "cabinet.account.form.address"})}
                                        type="text"
                                        variant="standard"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <UserSaveButtons setSaveAndNext={setSaveAndNext} />
            </Box>
            {/*<pre style={{color: "saddlebrown", }}>{JSON.stringify(changeForm.values, 0, 2)}</pre>*/}
        </FormikProvider>
    );
};

export default ContactsUser;

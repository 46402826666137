import { makeAutoObservable, action } from "mobx";
import PatientProfileApi from "../../../../data/profile/patient/PatientProfileApi"
import { Profile } from "domain/entity/profile/patient/structures/PatientProfileStructure";
import UserModel from "domain/entity/user/UserModel";

export default class PatientProfileViewModel {
    private patientProfileApi: PatientProfileApi;
    private userModel: UserModel;
    public patientProfile: Profile | {};
    public isSync: boolean;

    constructor(patientProfileApi: PatientProfileApi, userModel: UserModel) {
        makeAutoObservable(this);
        this.patientProfileApi = patientProfileApi;
        this.userModel = userModel;
        this.patientProfile = {};
        this.isSync = false;
    }

    @action loadPatientProfile = () => {
        this.isSync = true;
        this.patientProfileApi.loadPatientProfile(this.userModel.id)
            .then(action((profile) => {
                this.patientProfile = profile;
                this.isSync = false;
        }));
    };

    public updatePatient = (data: any) => {
        // this.isSync = true;
        return this.patientProfileApi.updatePatient(data, this.userModel.id)
            .then(action((profile) => {
                this.patientProfile = profile;
                return profile;
                // this.isSync = false;
            }));
    }

    @action updatePatientFromJson(data: any) {
        this.patientProfile = data;
    }

}

// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useViewModels } from 'presentation/view-model/ViewModelProvider';
import LandingLayout from 'presentation/views/layouts/landing/LandingLayout';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import { NonAuthRoutes } from '../../../routes/routes';
import { Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import DoctorsViewModel from '../../../view-model/doctors/DoctorsViewModel';
import axios from 'axios';
import { DoctorPreview } from '../../components/DoctorPreview';
import EntityInfo from 'presentation/views/components/EntityInfo';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Divider from '@mui/material/Divider';
import { StepIconProps } from '@mui/material/StepIcon';
import { Button, Dialog, IconButton } from '@mui/material';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import Fade from '@mui/material/Fade';
import { ReactComponent as LocationIcon } from '../../assets/images/icons/location.svg';
import { ReactComponent as CrossIcon } from '../../assets/images/icons/cross_14_2.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg';
import { ReactComponent as CheckIcon } from '../../assets/images/icons/check.svg';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrow.svg';
import { ReactComponent as FileIcon } from '../../assets/images/icons/file2.svg';
import LinkIcon from '@mui/icons-material/Link';
import st from './doctorId.module.scss';
import stylePopup from '../../components/Popup/popup.module.scss';
import ErrorPage from '../ErrorPage';

const SpecialtiesPopup: React.FC = ({ speciality, count, locale }) => {
    const intl = useIntl();
    const [openSpeciality, setOpenSpeciality] = React.useState(false);

    const handleCloseSpeciality = () => {
        setOpenSpeciality(false);
    };

    const handleOpenSpeciality = () => {
        setOpenSpeciality(true);
    };

    return (
        <>
            <button className={st.specialties__counter} type="button" onClick={handleOpenSpeciality}>
                {intl.formatMessage({ id: 'cabinet.profile.doctor.specialties.approvements' }, { count: count })}
            </button>

            {speciality?.doctors && (
                <Dialog TransitionComponent={Fade} onClose={handleCloseSpeciality} maxWidth={false} scroll="body" open={openSpeciality} className={stylePopup.popup}>
                    <div className={stylePopup.container}>
                        <IconButton onClick={handleCloseSpeciality} className={stylePopup.close}>
                            <CrossIcon />
                        </IconButton>

                        <Typography variant="h5" className={stylePopup.title}>
                            {speciality?.speciality.name} ({speciality?.doctors.length})
                        </Typography>

                        <Grid container spacing={3}>
                            {speciality?.doctors.map((item, i) => {
                                return (
                                    <React.Fragment  key={i + 'popupSpeciality'}>
                                        <Grid item xs={12}>
                                            <Divider light />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Link to={(locale && locale !== 'de' ? '/' + locale : '') + NonAuthRoutes.doctors + '/' + (item.id)} style={{ color: "inherit" }}>
                                                <EntityInfo
                                                    avatarSize={64}
                                                    fio={item.profile.firstName?.slice(0, 1) + item.profile.lastName?.slice(0, 1)}
                                                    pictureUrl={item.profile.avatar?.contentUrl ? (axios.defaults.baseURL + item.profile.avatar.contentUrl) : ''}
                                                >
                                                    <Typography variant="t3">
                                                        {item.label}
                                                    </Typography>
                                                </EntityInfo>
                                            </Link>
                                        </Grid>
                                    </React.Fragment>
                                );
                            })}
                        </Grid>
                    </div>
                </Dialog>
            )}
        </>
    );
};

const MapGoogle: React.FC = ({ clinic, intl }) => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP,
    });

    const zoom = 12;
    const mapContainerStyle = {
        height: '400px',
        width: '100%',
    };

    const renderMap = () => {
        return (
            <>
                <Button
                    target="_blank"
                    href={`https://www.google.com/maps/dir//${Object.values(JSON.parse(clinic.lat)).join(',')}/@${Object.values(
                        JSON.parse(clinic.lat)
                    ).join(',')}z`}
                    rel="noreferrer"
                    color="secondary"
                    variant="contained"
                    sx={{ mb: 3 }}
                >
                    {intl.formatMessage({ id: 'cabinet.profile.doctor.location.button' })}
                </Button>

                <GoogleMap mapContainerStyle={mapContainerStyle} center={JSON.parse(clinic.lat)} zoom={zoom}>
                    <Marker icon={{ url: '/images/mapMarker.svg' }} position={JSON.parse(clinic.lat)} />
                </GoogleMap>
            </>
        );
    };

    return isLoaded ? renderMap() : 'Load Map…';
};

const DoctorId: React.FC = () => {
    let mapCount = 0;
    const intl = useIntl();
    const { id } = useParams();
    const { hash } = useLocation();
    const location = useLocation();
    const history = useHistory();
    const {
        authViewModel: { isDoctor },
        userViewModel: { identifier, locale },
        doctorProfileViewModel: { options: authorizedDoctor },
        uIViewModel: { setSuccessAlertContent, },
    } = useViewModels();
    const isMyPage = id === identifier;
    const [viewAsPatient, setViewAsPatient] = useState(isMyPage);
    const doctorProfile = DoctorsViewModel?.currentDoctor;
    const doctorSpecialities = DoctorsViewModel?.specialities;
    const [openStatutoryInsurances, setOpenStatutoryInsurances] = React.useState(false);
    // const [openPrivateInsurances, setOpenPrivateInsurances] = React.useState(false);
    const [errorBlock, setErrorBlock] = React.useState(false);

    const handleCloseStatutoryInsurances = () => {
        setOpenStatutoryInsurances(false);
    };

    const handleOpenStatutoryInsurances = () => {
        setOpenStatutoryInsurances(true);
    };

/*    const handleClosePrivateInsurances = () => {
        setOpenPrivateInsurances(false);
    };

    const handleOpenPrivateInsurances = () => {
        setOpenPrivateInsurances(true);
    };*/

    const handleViewAsPatient = () => {
        setViewAsPatient((prevState) => !prevState);
    };

    const removeHash = () => {
        if (hash) {
            history.push(location.pathname);
        }
    }

    const handleSendApprove = (speciality, doctor, i) => {
        removeHash();
        setSuccessAlertContent('', true);
        DoctorsViewModel.approveSkill(`api/specialities/${speciality}`, `api/doctors/${doctor}`, i, authorizedDoctor)
            .then(response => {
                setSuccessAlertContent(intl.formatMessage({id: "approveSkill"}));
            });
    };

    const handleRemoveApprove = (speciality, doctor, i) => {
        removeHash();
        setSuccessAlertContent('', true);
        DoctorsViewModel.removeApproveSkill(`api/specialities/${speciality}`, `api/doctors/${doctor}`, i, authorizedDoctor)
            .then(response => {
                setSuccessAlertContent(intl.formatMessage({id: "removeApproveSkill"}));
            });
    };

    useEffect(() => {
        if (hash !== '') {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    if (hash === '#connect') {
                        window.scrollTo(0, 100);
                    } else {
                        element.scrollIntoView();
                    }
                }
            }, 0);
        }
    });

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            DoctorsViewModel.getDoctorSpecialities(id).catch((e) => {
                setErrorBlock(true);
            });
            DoctorsViewModel.getDoctorWithSchedule(id).catch((e) => {
                setErrorBlock(true);
            });
        }

        return () => { isMounted = false };
    }, [id]);

    function StepIcon(props: StepIconProps) {
        const { active } = props;

        return <div className={classNames(st.step__icon, active ? st.step__icon_active : '')}></div>;
    }

    function StepIconDot(props: StepIconProps) {
        const { active } = props;

        return <div className={classNames(st.step__iconDot, active ? st.step__iconDot_active : '')}></div>;
    }


    return (
        (errorBlock || (doctorProfile?.id && !doctorProfile?.active)) ?
            <ErrorPage />
            :
            <LandingLayout>
                {doctorProfile?.profile &&
                    <div className={classNames(st.main, isMyPage ? st.is_my_page : '')}>
                        {!viewAsPatient && isMyPage && (
                            <div onClick={handleViewAsPatient} className={st.back}>
                                <ArrowIcon />
                                {intl.formatMessage({ id: 'back' })}
                            </div>
                        )}

                        <section className={st.top}>
                            <div className={st.breadcrumbs}>
                                <Link className={st.breadCrumb_link} to={(locale && locale !== 'de' ? '/' + locale : '') + NonAuthRoutes.home}>
                                    {intl.formatMessage({ id: 'homepage' })}
                                </Link>
                                <span className={classNames(st.breadCrumb_link, st.breadCrumb_separator)}>/</span>
                                <Link className={st.breadCrumb_link} to={(locale && locale !== 'de' ? '/' + locale : '') + NonAuthRoutes.doctors}>
                                    {intl.formatMessage({ id: 'app.header.menu.doctors' })}
                                </Link>
                                <span className={classNames(st.breadCrumb_link, st.breadCrumb_separator)}>/</span>
                                <Typography
                                    variant="span"
                                    className={classNames(st.breadCrumb_link, st.breadCrumb_link_active)}
                                >{`${doctorProfile?.label}`}</Typography>
                            </div>

                            {isDoctor && viewAsPatient && (
                                <div className={st.view_as_doctor}>
                                    <Button color="secondary" variant="outlined" onClick={handleViewAsPatient}>
                                        {intl.formatMessage({ id: 'doctor.viewButton' })}
                                    </Button>
                                </div>
                            )}
                        </section>

                        <div className={st.main_info}>
                            <DoctorPreview
                                previewIndex={1}
                                doctor={DoctorsViewModel.currentDoctor}
                                isDoctorPage={true}
                                isMyPage={isMyPage}
                                viewAsPatient={viewAsPatient}
                            />
                        </div>

                        <div className={st.other_info}>
                            {(doctorProfile?.privateInsurances?.length > 0 || doctorProfile?.statutoryInsurances?.length > 0) && (
                                <div className={st.info_block}>
                                    <Typography variant="h6" mb={4}>
                                        {intl.formatMessage({ id: 'cabinet.profile.doctor.insurance.title' })}
                                        {viewAsPatient && (
                                            <Link
                                                to={`${locale && locale !== 'de' ? '/' + locale : ''}/doctors/${id}/edit?tab=insurance`}
                                                className={st.edit}
                                            >
                                                {intl.formatMessage({ id: 'edit' })}
                                            </Link>
                                        )}
                                    </Typography>

                                    {doctorProfile?.statutoryInsurances?.length > 0 && (
                                        <Grid container spacing={2} mb={doctorProfile?.privateInsurances?.length > 0 ? 4 : 0}>
                                            <Grid item xs={12} md={6}>
                                                <EntityInfo
                                                    noHover={true}
                                                    avatarSize={48}
                                                    fio="Statutory"
                                                    className={st.info_block__picture_insurance}
                                                    imgContain
                                                    pictureWithBg
                                                    bgcolor="#00B9FF"
                                                    pictureUrl="/images/insurance-1.svg"
                                                >
                                                    <Typography variant="p5">
                                                        {intl.formatMessage({ id: 'gesetzlichType' })}
                                                    </Typography>
                                                </EntityInfo>
                                            </Grid>

                                            {doctorProfile?.statutoryInsurances.map((item, i) => {
                                                if (i < 4) {
                                                    return (
                                                        <Grid item xs={12} md={6} key={item.country + i + 'Statutory'}>
                                                            <EntityInfo
                                                                noHover={true}
                                                                avatarSize={48}
                                                                fio={item.country}
                                                                className={st.info_block__picture_border}
                                                                imgContain
                                                                bgcolor="#fff"
                                                                pictureUrl={axios.defaults.baseURL + item.logo?.contentUrl}
                                                            >
                                                                <Typography variant="p5">{item.title}</Typography>
                                                            </EntityInfo>
                                                        </Grid>
                                                    );
                                                }

                                                return <React.Fragment key={i + 'Statutory'}></React.Fragment>;
                                            })}

                                            {doctorProfile?.statutoryInsurances.length > 4 && (
                                                <>
                                                    <Grid item xs={12} md={6}>
                                                        <Typography variant="p5" mt={1.5}>
                                                            {intl.formatMessage(
                                                                { id: 'cabinet.profile.doctor.insurance.more' },
                                                                { count: doctorProfile?.statutoryInsurances.length - 5 }
                                                            )}
                                                            <button type="button" onClick={handleOpenStatutoryInsurances} className={st.more}>
                                                                {intl.formatMessage({ id: 'doctor.viewAll' })}
                                                            </button>
                                                        </Typography>
                                                    </Grid>

                                                    <Dialog TransitionComponent={Fade} onClose={handleCloseStatutoryInsurances} maxWidth={false}
                                                            open={openStatutoryInsurances} scroll="body" className={stylePopup.popup}>
                                                        <div className={stylePopup.container}>
                                                            <IconButton className={stylePopup.close} onClick={handleCloseStatutoryInsurances}>
                                                                <CrossIcon />
                                                            </IconButton>

                                                            <Typography variant="h5" className={stylePopup.title}>
                                                                {intl.formatMessage({ id: 'cabinet.profile.doctor.insurance.popup.statutory.title' })}
                                                            </Typography>
                                                            <Grid container spacing={2}>
                                                                {doctorProfile?.statutoryInsurances.map((item, i) => {
                                                                    return (
                                                                        <Grid item xs={12} key={item.country + i + 'popupStatutory'}>
                                                                            <EntityInfo
                                                                                noHover={true}
                                                                                avatarSize={48}
                                                                                fio={item.country}
                                                                                className={st.info_block__picture_border}
                                                                                bgcolor="#fff"
                                                                                imgContain
                                                                                pictureUrl={axios.defaults.baseURL + item.logo?.contentUrl}
                                                                            >
                                                                                <Typography variant="p5">{item.title}</Typography>
                                                                            </EntityInfo>
                                                                        </Grid>
                                                                    );
                                                                })}
                                                            </Grid>
                                                        </div>
                                                    </Dialog>
                                                </>
                                            )}
                                        </Grid>
                                    )}

                                    {doctorProfile?.enablePrivateInsurance && (
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} sx={{mt: 2}}>
                                                <EntityInfo
                                                    noHover={true}
                                                    avatarSize={48}
                                                    fio="Private"
                                                    className={st.info_block__picture_insurance}
                                                    imgContain
                                                    pictureWithBg
                                                    bgcolor="#27AE60"
                                                    pictureUrl="/images/insurance-2.svg"
                                                >
                                                    <Typography variant="p5">
                                                        {intl.formatMessage({ id: 'privatType' })}
                                                    </Typography>
                                                </EntityInfo>
                                            </Grid>

{/*                                            {doctorProfile?.privateInsurances.map((item, i) => {
                                                if (i < 4) {
                                                    return (
                                                        <Grid item xs={12} md={6} key={item.country + i + 'Private'}>
                                                            <EntityInfo
                                                                noHover={true}
                                                                avatarSize={48}
                                                                fio={item.country}
                                                                className={st.info_block__picture_border}
                                                                imgContain
                                                                bgcolor="#fff"
                                                                pictureUrl={axios.defaults.baseURL + item.logo?.contentUrl}
                                                            >
                                                                <Typography variant="p5">{item.title}</Typography>
                                                            </EntityInfo>
                                                        </Grid>
                                                    );
                                                }

                                                return <React.Fragment key={i + 'Private'}></React.Fragment>;
                                            })}
                                            {doctorProfile?.privateInsurances.length > 4 && (
                                                <>
                                                    <Grid item xs={12} md={6}>
                                                        <Typography variant="p5" mt={1.5}>
                                                            {intl.formatMessage(
                                                                { id: 'cabinet.profile.doctor.insurance.more' },
                                                                { count: doctorProfile?.privateInsurances.length - 5 }
                                                            )}
                                                            <button type="button" onClick={handleOpenPrivateInsurances} className={st.more}>
                                                                {intl.formatMessage({ id: 'doctor.viewAll' })}
                                                            </button>
                                                        </Typography>
                                                    </Grid>
                                                    <Dialog TransitionComponent={Fade} onClose={handleClosePrivateInsurances} maxWidth={false}
                                                            open={openPrivateInsurances} scroll="body" className={stylePopup.popup}>
                                                        <div className={stylePopup.container}>
                                                            <IconButton className={stylePopup.close} onClick={handleClosePrivateInsurances}>
                                                                <CrossIcon />
                                                            </IconButton>

                                                            <Typography variant="h5" className={stylePopup.title}>
                                                                {intl.formatMessage({ id: 'cabinet.profile.doctor.insurance.popup.private.title' })}
                                                            </Typography>
                                                            <Grid container spacing={2}>
                                                                {doctorProfile?.privateInsurances.map((item, i) => {
                                                                    return (
                                                                        <Grid item xs={12} key={item.country + i + 'popupPrivate'}>
                                                                            <EntityInfo
                                                                                noHover={true}
                                                                                avatarSize={48}
                                                                                fio={item.country}
                                                                                className={st.info_block__picture_border}
                                                                                bgcolor="#fff"
                                                                                imgContain
                                                                                pictureUrl={axios.defaults.baseURL + item.logo?.contentUrl}
                                                                            >
                                                                                <Typography variant="p5">{item.title}</Typography>
                                                                            </EntityInfo>
                                                                        </Grid>
                                                                    );
                                                                })}
                                                            </Grid>
                                                        </div>
                                                    </Dialog>
                                                </>
                                            )}*/}
                                        </Grid>
                                    )}
                                </div>
                            )}

                            {doctorProfile?.experiences?.length > 0 && doctorProfile?.experiences[0].clinic.lat && (
                                <div className={st.location_block}>
                                    <Typography variant="h6" mb={4}>
                                        {intl.formatMessage({ id: 'cabinet.profile.doctor.location.title' })}
                                        {viewAsPatient && (
                                            <Link
                                                to={`${locale && locale !== 'de' ? '/' + locale : ''}/doctors/${id}/edit?tab=experience`}
                                                className={st.edit}
                                            >
                                                {intl.formatMessage({ id: 'edit' })}
                                            </Link>
                                        )}
                                    </Typography>

                                    {doctorProfile.experiences.map((item, key) => {
                                        if (!item.currentWork || mapCount > 0) {
                                            return "";
                                        }

                                        mapCount = 1;
                                        return item.clinic?.lat && (
                                            <React.Fragment key={"map" + key}>
                                                <div className={st.location}>
                                                    <LocationIcon />
                                                    <span className={st.location_title}>{item.clinic?.title}</span>
                                                    <span className={st.location_place}>
                                                        {
                                                            (item.country ? item.country + ', ' : '') +
                                                            (item.city ? item.city + ', ' : '') +
                                                            (item.clinic?.location ? item.clinic?.location + ', ' : '') +
                                                            (item.zipCode ? item.zipCode : '')
                                                        }
                                                    </span>
                                                </div>

                                                <MapGoogle clinic={item.clinic} intl={intl} />
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            )}

                            {(doctorProfile?.educations?.length > 0 ||
                                doctorProfile?.experiences?.length > 0 ||
                                doctorSpecialities?.length > 0 ||
                                doctorProfile?.certifications?.length > 0 ||
                                doctorProfile?.awards?.length > 0 ||
                                doctorProfile?.publications?.length > 0 ||
                                doctorProfile?.languages?.length > 0) && (
                                <div className={st.info_block}>
                                    <Typography variant="h6" mb={4}>
                                        {intl.formatMessage({ id: 'cabinet.profile.doctor.detailed.title' })}
                                    </Typography>

                                    <Grid container spacing={7}>
                                        {doctorProfile?.experiences?.length > 0 && (
                                            <Grid item xs={12}>
                                                <Typography variant="t2" mb={3} display="block">
                                                    {intl.formatMessage({ id: 'cabinet.profile.doctor.experience.title' })}
                                                    {viewAsPatient && (
                                                        <Link
                                                            to={`${locale && locale !== 'de' ? '/' + locale : ''}/doctors/${id}/edit?tab=experience`}
                                                            className={st.edit}
                                                        >
                                                            {intl.formatMessage({ id: 'edit' })}
                                                        </Link>
                                                    )}
                                                </Typography>
                                                <div className={st.experiences_grid}>
                                                    <Stepper
                                                        connector={
                                                            <div className={st.step__connector}>
                                                                <div className={st.step__line} />
                                                            </div>
                                                        }
                                                        orientation="vertical"
                                                        className={st.step}
                                                    >
                                                        {doctorProfile?.experiences?.map((item, i) => {
                                                            const dateStart = new Date(item.startOfWork);
                                                            const dateEnd = new Date(item.endOfWork);

                                                            return (
                                                                <Step
                                                                    key={item.city + i}
                                                                    active={Boolean(item.currentWork)}
                                                                    className={st.step__item}
                                                                >
                                                                    <StepLabel StepIconComponent={StepIcon} className={st.step__label}>
                                                                        <Typography variant="span" className={st.step__title}>
                                                                            {`${dateStart.toLocaleString(locale, {
                                                                                month: 'long',
                                                                            })} ${dateStart.getFullYear()} - ${
                                                                                item.currentWork
                                                                                    ? intl.formatMessage({ id: "cabinet.profile.doctor.specialties.present" })
                                                                                    : `${dateEnd.toLocaleString(locale, {
                                                                                        month: 'long',
                                                                                    })} ${dateEnd.getFullYear()}`
                                                                            }`}
                                                                        </Typography>
                                                                        <Typography variant="span" className={st.step__text}>
                                                                            {item.city}, {item.clinic.title}
                                                                        </Typography>
                                                                    </StepLabel>
                                                                </Step>
                                                            );
                                                        })}
                                                    </Stepper>
                                                </div>
                                            </Grid>
                                        )}

                                        {doctorSpecialities?.length > 0 && (
                                            <Grid id="skills" item xs={12} sx={{ position: "relative" }}>
                                                <Typography variant="t2" mb={3} display="block" className={st.info_block__subtitle}>
                                                    {intl.formatMessage({ id: 'cabinet.profile.doctor.specialties.title' })}
                                                    {viewAsPatient && (
                                                        <Link
                                                            to={`${locale && locale !== 'de' ? '/' + locale : ''}/doctors/${id}/edit?tab=specialties`}
                                                            className={st.edit}
                                                        >
                                                            {intl.formatMessage({ id: 'edit' })}
                                                        </Link>
                                                    )}
                                                </Typography>

                                                {isDoctor && !isMyPage && !['pending', 'existing'].includes(doctorProfile?.connectionStatus) &&
                                                <Typography variant="d2s" className={st.tooltip}>
                                                    <button type="button" onClick={() => {
                                                        window.scrollTo(0, 0)
                                                    }}>{intl.formatMessage({ id: 'connect' })}</button>
                                                    {' '}
                                                    {intl.formatMessage({ id: 'cabinet.profile.doctor.specialties.approve.tooltip' }, { label: doctorProfile?.label })}
                                                </Typography>
                                                }

                                                <div className={st.certification_grid}>
                                                    <Stepper
                                                        connector={<div className={st.step__connectorDot}></div>}
                                                        orientation="vertical"
                                                        className={st.step}
                                                    >
                                                        {doctorSpecialities?.map((item, i) => {
                                                            return (
                                                                <Step
                                                                    key={item.speciality.name + i}
                                                                    active={item.approvesCount > 0}
                                                                    className={st.step__item}
                                                                >
                                                                    <StepLabel StepIconComponent={StepIconDot} className={st.step__label}>
                                                                        <Typography variant="span" className={st.step__text}>
                                                                            <b>
                                                                                {item.speciality.name}
                                                                                {item.approvesCount > 0 ?
                                                                                    <span className={st.step__divider}>•</span> : ""}
                                                                                <Typography variant="span" className={st.specialties__counter}>
                                                                                    {item.approvesCount > 0 ? (
                                                                                        <SpecialtiesPopup speciality={item} count={item.approvesCount}
                                                                                                          locale={locale} />
                                                                                    ) : ""}
                                                                                </Typography>
                                                                                {item.approvesCount > 0 && (
                                                                                    <Typography variant="d1s" className={st.specialties__text}>
                                                                                        {item.approvesCount > 1
                                                                                            ? intl.formatMessage(
                                                                                                { id: 'cabinet.profile.doctor.specialties.text' },
                                                                                                {
                                                                                                    name: item.lastDoctorWhoApproved?.label,
                                                                                                    count: item.approvesCount - 1,
                                                                                                }
                                                                                            )
                                                                                            :
                                                                                            intl.formatMessage({ id: 'approveText' }, { name: item.lastDoctorWhoApproved?.label })
                                                                                        }
                                                                                    </Typography>
                                                                                )}
                                                                            </b>

                                                                            {!isMyPage && doctorProfile?.connectionStatus === 'existing' && (
                                                                                <Button
                                                                                    className={classNames(
                                                                                        st.specialties__button,
                                                                                        item.approvedByYou
                                                                                            ? st.specialties__button_approved
                                                                                            : st.specialties__button_approve
                                                                                    )}
                                                                                    variant={item.approvedByYou ? 'contained' : 'outlined'}
                                                                                    color="secondary"
                                                                                    title={item.approvedByYou ?
                                                                                        intl.formatMessage({id: 'disApproveSkillTitle'})
                                                                                        :
                                                                                        intl.formatMessage({id: 'approveSkillTitle'})
                                                                                    }
                                                                                    onClick={
                                                                                        item.approvedByYou
                                                                                            ? () => handleRemoveApprove(item.speciality.id, doctorProfile.id, i)
                                                                                            : () => handleSendApprove(item.speciality.id, doctorProfile.id, i)
                                                                                    }
                                                                                >
                                                                                    {item.approvedByYou ? <CheckIcon height="12" /> :
                                                                                        <PlusIcon height="12" />}

                                                                                    {intl.formatMessage({
                                                                                        id: item.approvedByYou
                                                                                            ? 'cabinet.profile.doctor.specialties.approved'
                                                                                            : 'cabinet.profile.doctor.specialties.approve',
                                                                                    })}
                                                                                </Button>
                                                                            )}
                                                                        </Typography>
                                                                    </StepLabel>
                                                                </Step>
                                                            );
                                                        })}
                                                    </Stepper>
                                                </div>
                                            </Grid>
                                        )}

                                        {doctorProfile?.educations?.length > 0 && (
                                            <Grid item xs={12}>
                                                <Typography variant="t2" mb={3} display="block">
                                                    {intl.formatMessage({ id: 'cabinet.profile.doctor.education.title' })}
                                                    {viewAsPatient && (
                                                        <Link to={`${locale && locale !== 'de' ? '/' + locale : ''}/doctors/${id}/edit?tab=education`}
                                                              className={st.edit}>
                                                            {intl.formatMessage({ id: 'edit' })}
                                                        </Link>
                                                    )}
                                                </Typography>

                                                <div className={st.educations_grid}>
                                                    <Stepper
                                                        connector={
                                                            <div className={st.step__connector}>
                                                                <div className={st.step__line} />
                                                            </div>
                                                        }
                                                        orientation="vertical"
                                                        className={st.step}
                                                    >
                                                        {doctorProfile?.educations?.map((item, i) => {
                                                            return (
                                                                <Step
                                                                    key={`graduationYear_${i}`}
                                                                    active={!item.stillStudying}
                                                                    className={classNames(st.step__item, st.step__item_year)}
                                                                >
                                                                    <StepLabel StepIconComponent={StepIcon} className={st.step__label}>
                                                                        <Typography variant="span" className={st.step__title}>
                                                                            {item.stillStudying ?
                                                                                intl.formatMessage({ id: "studying" })
                                                                                :
                                                                                item.graduationYear
                                                                            }
                                                                        </Typography>

                                                                        <Typography variant="span" className={st.step__text}>
                                                                            {`${item.university}
                                                                        ${item.faculty ? ` - ${item.faculty}` : ''}${
                                                                                item.speciality?.name ? `, ${item.speciality?.name}` : ''
                                                                            }`}
                                                                            {/*                                                                    {item.diploma && (
                                                                            <a className={st.confirmation_link} href={axios.defaults.baseURL + item.diploma.contentUrl} target="_blank" rel="noreferrer">
                                                                                {intl.formatMessage({id: 'cabinet.profile.doctor.education.confirmation',})}
                                                                            </a>
                                                                        )}*/}
                                                                        </Typography>
                                                                    </StepLabel>
                                                                </Step>
                                                            );
                                                        })}
                                                    </Stepper>
                                                </div>
                                            </Grid>
                                        )}

                                        {doctorProfile?.certifications?.length > 0 && (
                                            <Grid item xs={12}>
                                                <Typography variant="t2" mb={3} display="block">
                                                    {intl.formatMessage({ id: 'cabinet.profile.doctor.certifications.title' })}
                                                    {viewAsPatient && (
                                                        <Link
                                                            to={`${locale && locale !== 'de' ? '/' + locale : ''}/doctors/${id}/edit?tab=certifications`}
                                                            className={st.edit}
                                                        >
                                                            {intl.formatMessage({ id: 'edit' })}
                                                        </Link>
                                                    )}
                                                </Typography>
                                                <div className={st.certification_grid}>
                                                    <Stepper
                                                        connector={<div className={st.step__connectorDot}></div>}
                                                        orientation="vertical"
                                                        className={st.step}
                                                    >
                                                        {doctorProfile?.certifications?.map((item, i) => {
                                                            return (
                                                                <Step
                                                                    key={item.title + i}
                                                                    active={Boolean(item.file)}
                                                                    className={classNames(st.step__item, st.step__item_certificate)}
                                                                >
                                                                    <StepLabel StepIconComponent={StepIconDot} className={st.step__label}>
                                                                        <Typography variant="span" className={st.step__text}>
                                                                            {item.title}
                                                                            {item.file && (
                                                                                <>
                                                                                    <a
                                                                                        href={axios.defaults.baseURL + item.file?.contentUrl}
                                                                                        target="_blank"
                                                                                        rel="noreferrer"
                                                                                    >
                                                                                        <IconButton
                                                                                            className={st.step__iconFile}><FileIcon /></IconButton>
                                                                                    </a>
                                                                                </>
                                                                            )}
                                                                        </Typography>
                                                                    </StepLabel>
                                                                </Step>
                                                            );
                                                        })}
                                                    </Stepper>
                                                </div>
                                            </Grid>
                                        )}

                                        {doctorProfile?.awards?.length > 0 && (
                                            <Grid item xs={12}>
                                                <Typography variant="t2" mb={3} display="block">
                                                    {intl.formatMessage({ id: 'cabinet.profile.doctor.awards.title' })}
                                                    {viewAsPatient && (
                                                        <Link
                                                            to={`${locale && locale !== 'de' ? '/' + locale : ''}/doctors/${id}/edit?tab=awards`}
                                                            className={st.edit}
                                                        >
                                                            {intl.formatMessage({ id: 'edit' })}
                                                        </Link>
                                                    )}
                                                </Typography>
                                                <div className={st.award_grid}>
                                                    <Stepper
                                                        connector={
                                                            <div className={st.step__connector}>
                                                                <div className={st.step__line} />
                                                            </div>
                                                        }
                                                        orientation="vertical"
                                                        className={st.step}
                                                    >
                                                        {doctorProfile?.awards?.map((item, i) => {
                                                            return (
                                                                <Step
                                                                    key={item.title + i + 1}
                                                                    active={Boolean(item.link)}
                                                                    className={classNames(st.step__item, st.step__item_year)}
                                                                >
                                                                    <StepLabel StepIconComponent={StepIcon} className={st.step__label}>
                                                                        <Typography variant="span" className={st.step__title}>
                                                                            {item.year}
                                                                        </Typography>

                                                                        <Typography variant="span" className={st.step__text}>
                                                                            {item.title}
                                                                            {item.link && (
                                                                                <a
                                                                                    className={st.step__link}
                                                                                    href={item.link}
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    <IconButton className={st.step__iconLink}><LinkIcon /></IconButton>
                                                                                </a>
                                                                            )}
                                                                        </Typography>
                                                                    </StepLabel>
                                                                </Step>
                                                            );
                                                        })}
                                                    </Stepper>
                                                </div>
                                            </Grid>
                                        )}

                                        {doctorProfile?.publications?.length > 0 && (
                                            <Grid item xs={12}>
                                                <Typography variant="t2" mb={3} display="block">
                                                    {intl.formatMessage({ id: 'cabinet.profile.doctor.publications.title' })}
                                                    {viewAsPatient && (
                                                        <Link
                                                            to={`${locale && locale !== 'de' ? '/' + locale : ''}/doctors/${id}/edit?tab=publications`}
                                                            className={st.edit}
                                                        >
                                                            {intl.formatMessage({ id: 'edit' })}
                                                        </Link>
                                                    )}
                                                </Typography>
                                                <div className={st.award_grid}>
                                                    <Stepper
                                                        connector={
                                                            <div className={st.step__connector}>
                                                                <div className={st.step__line} />
                                                            </div>
                                                        }
                                                        orientation="vertical"
                                                        className={st.step}
                                                    >
                                                        {doctorProfile?.publications?.map((item, i) => {
                                                            return (
                                                                <Step
                                                                    key={item.title + i + 'awward'}
                                                                    active={Boolean(item.link)}
                                                                    className={classNames(st.step__item, st.step__item_year)}
                                                                >
                                                                    <StepLabel StepIconComponent={StepIcon} className={st.step__label}>
                                                                        <Typography variant="span" className={st.step__title}>
                                                                            {item.year}
                                                                        </Typography>

                                                                        <Typography variant="span" className={st.step__text}>
                                                                            {item.title}
                                                                            {item.link && (
                                                                                <a
                                                                                    className={st.step__link}
                                                                                    href={item.link}
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    <IconButton className={st.step__iconLink}><LinkIcon /></IconButton>
                                                                                </a>
                                                                            )}
                                                                        </Typography>
                                                                    </StepLabel>
                                                                </Step>
                                                            );
                                                        })}
                                                    </Stepper>
                                                </div>
                                            </Grid>
                                        )}

                                        {doctorProfile?.spokenLanguages?.length > 0 && (
                                            <Grid item xs={12}>
                                                <Typography variant="t2" mb={3} display="block">
                                                    {intl.formatMessage({ id: 'cabinet.profile.doctor.languages.title' })}
                                                    {viewAsPatient && (
                                                        <Link
                                                            to={`${locale && locale !== 'de' ? '/' + locale : ''}/doctors/${id}/edit?tab=languages`}
                                                            className={st.edit}
                                                        >
                                                            {intl.formatMessage({ id: 'edit' })}
                                                        </Link>
                                                    )}
                                                </Typography>
                                                <div className={st.languages_grid}>
                                                    {doctorProfile?.spokenLanguages?.map((item, i) => {
                                                        return (
                                                            <div key={item.title + i} className={st.languages_item}>
                                                                {/*{item.flag}*/}
                                                                <Typography variant="p5">{item.title}</Typography>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Grid>
                                        )}
                                    </Grid>
                                </div>
                            )}
                        </div>
                    </div>
                }
            </LandingLayout>
    );
};

export default observer(DoctorId);

import React from "react";

// Repository Imps
import AuthApi from "data/auth/AuthApi";
import LocalStorageApi from "data/client/LocalStorageApi";
import UserApi from "data/user/UserApi";
import LakeApi from "data/lake/LakeApi";
import FaqApi from "data/faq/FaqApi";
import LandingApi from "data/landing/LandingApi";
import AppointmentApi from "data/appointment/AppointmentApi";
import NewsApi from "data/news/NewsApi";
import DoctorProfileApi from "data/profile/doctor/DoctorProfileApi";
import SubscriptionApi from "../../data/subscription/SubscriptionApi";
import ScheduleApi from "../../data/schedule/ScheduleApi";

// Models
import AuthModel from "domain/entity/auth/AuthModel";
import UserModel from "domain/entity/user/UserModel";
import SearchBySpecialtiesModel from "domain/entity/searchBySpecialties/SearchBySpecialtiesModel";
import FaqModel from "domain/entity/faq/FaqModel";
import NewsModel from "domain/entity/news/NewsModel";
import DoctorProfileModel from "domain/entity/profile/doctor/DoctorProfileModel";

// UseCases
import LoginUseCase from "domain/interactors/auth/LoginUseCase";
import RegisterUseCase from "domain/interactors/auth/RegisterUseCase";
import LogoutUseCase from "domain/interactors/auth/LogoutUseCase";
import GetUserAppAccessUseCase from "domain/interactors/auth/GetUserAppAccessUseCase";
import SearchBySpecialtiesUseCase from "domain/interactors/common/SearchBySpecialtiesUseCase";
import LoadAvailableLettersUseCase from "domain/interactors/common/LoadAvailableLettersUseCase";
import ManageFaqUseCase from "domain/interactors/faq/ManageFaqUseCase";
import ChangeUserPasswordUseCase from "domain/interactors/auth/ChangePasswordUseCase";
import DeleteAccountUseCase from "domain/interactors/auth/DeleteAccountUseCase";
import ForgotPasswordUseCase from "domain/interactors/auth/ForgotPasswordUseCase";
import NewPasswordUseCase from "domain/interactors/auth/NewPasswordUseCase";
import VerifyTokenUseCase from "domain/interactors/auth/VerifyTokenUseCase";
import ManageFormsUseCase from "domain/interactors/landing/ManageFormsUseCase";
import ManageAppointmentUseCase from "domain/interactors/appointment/ManageAppointmentUseCase";
import ManageNewsUseCase from "domain/interactors/news/ManageNewsUseCase";
import ManageDoctorProfileUseCase from "domain/interactors/profile/doctor/ManageDoctorProfileUseCase";
import ManageScheduleUseCase from "../../domain/interactors/schedule/ManageScheduleUseCase";

// ViewModels
import UIViewModel from "presentation/view-model/ui/UIViewModel";
import AuthViewModel from "./auth/AuthViewModel";
import UserViewModel from "./user/UserViewModel";
import LoadUserUseCase from "domain/interactors/user/LoadUserUseCase";
import LakeViewModel from "./lake/SearchBySpecialtiesViewModel";
import FaqViewModel from "./faq/FaqViewModel";
import LandingViewModel from "./landing/LandingViewModel";
import AppointmentViewModel from "./appointment/AppointmentViewModel";
import NewsViewModel from "./news/NewsViewModel";
import DoctorProfileViewModel from "./profile/doctor/DoctorProfileViewModel";
import ConnectionViewModel from "./connection/ConnectionViewModel";
import MediaViewModel from "./media/MediaViewModel";
import ConnectionApi from "../../data/connection/ConnectionApi";
import MediaApi from "../../data/media/MediaApi";
import ManageConnectionUseCase from "../../domain/interactors/connection/ManageConnectionUseCase";
import ManageMediaUseCase from "../../domain/interactors/media/ManageMediaUseCase";
import PatientProfileViewModel from "./profile/patient/PatientProfileViewModel";
import PatientProfileApi from "../../data/profile/patient/PatientProfileApi";
import ManageSubscriptionUseCase from "../../domain/interactors/subscription/ManageSubscriptionUseCase";
import SubscriptionViewModel from "./subscription/SubscriptionViewModel";
import ScheduleViewModel from "./schedule/ScheduleViewModel";
import MedicalCredentialsViewModel from "./medical_credentials/MedicalCredentialsViewModel";
import MedicalCredentialApi from "../../data/medical_credential/MedicalCredentialApi";
import LoadMedicalCredentialsUseCase from "../../domain/interactors/medical_credentials/LoadMedicalCredentialsUseCase";
import InsuranceViewModel from "./insurance/InsuranceViewModel";
import InsuranceApi from "../../data/insurance/InsuranceApi";
import LoadInsuranceUseCase from "../../domain/interactors/insurance/LoadInsuranceUseCase";
import ManageAccountUseCase from "../../domain/interactors/user/ManageAccountUseCase";

type ViewModelsContextValue = {
    uIViewModel: UIViewModel;
    authViewModel: AuthViewModel;
    userViewModel: UserViewModel;
    lakeViewModel: LakeViewModel;
    faqViewModel: FaqViewModel;
    landingViewModel: LandingViewModel;
    appointmentViewModel: AppointmentViewModel;
    connectionViewModel: ConnectionViewModel;
    mediaViewModel: MediaViewModel;
    newsViewModel: NewsViewModel;
    doctorProfileViewModel: DoctorProfileViewModel;
    patientProfileViewModel: PatientProfileViewModel;
    subscriptionViewModel: SubscriptionViewModel;
    scheduleViewModel: ScheduleViewModel;
    medicalCredentialsViewModel: MedicalCredentialsViewModel;
    insuranceViewModel: InsuranceViewModel;
};

// dataLayer init
const authRepository = new AuthApi();
const clientRepository = new LocalStorageApi();
const userRepository = new UserApi();
const lakeRepository = new LakeApi();
const faqRepository = new FaqApi();
const landingRepository = new LandingApi();
const appointmentRepository = new AppointmentApi();
const connectionRepository = new ConnectionApi();
const mediaRepository = new MediaApi();
const newsRepository = new NewsApi();
const doctorProfileRepository = new DoctorProfileApi();
const patientProfileRepository = new PatientProfileApi();
const subscriptionRepository = new SubscriptionApi();
const scheduleRepository = new ScheduleApi();
const medicalCredentialsRepository = new MedicalCredentialApi();
const insuranceRepository = new InsuranceApi();

// models init
const uIViewModel = new UIViewModel();
const userModel = new UserModel();
const authModel = new AuthModel(userModel);
const searchBySpecialtiesModel = new SearchBySpecialtiesModel();
const faqModel = new FaqModel();
const newsModel = new NewsModel();
const doctorProfileModel = new DoctorProfileModel();

// useCases init
const loginUseCase = new LoginUseCase(authRepository, clientRepository, authModel);
const registerUseCase = new RegisterUseCase(authRepository);
const logoutUseCase = new LogoutUseCase(clientRepository, authModel);
const getUserAppAccessUseCase = new GetUserAppAccessUseCase(authRepository, clientRepository, userRepository, authModel, userModel);
const changeUserPasswordUseCase = new ChangeUserPasswordUseCase(authRepository);
const deleteAccountUseCase = new DeleteAccountUseCase(authRepository, clientRepository);
const forgotPasswordUseCase = new ForgotPasswordUseCase(authRepository);
const newPasswordUseCase = new NewPasswordUseCase(authRepository);
const verifyTokenUseCase = new VerifyTokenUseCase(authRepository);
const loadUserUseCase = new LoadUserUseCase(userRepository, userModel);
const loadAvailableLettersUseCase = new LoadAvailableLettersUseCase(lakeRepository, searchBySpecialtiesModel);
const searchBySpecialtiesUseCase = new SearchBySpecialtiesUseCase(lakeRepository, searchBySpecialtiesModel);
const manageFaqUseCase = new ManageFaqUseCase(faqRepository, faqModel);
const manageFormsUseCase = new ManageFormsUseCase(landingRepository);
const manageAppointmentUseCase = new ManageAppointmentUseCase(appointmentRepository);
const manageConnectionUseCase = new ManageConnectionUseCase(connectionRepository);
const manageMediaUseCase = new ManageMediaUseCase(mediaRepository);
const manageNewsUseCase = new ManageNewsUseCase(newsModel, newsRepository);
const manageDoctorProfileCase = new ManageDoctorProfileUseCase(doctorProfileModel, doctorProfileRepository, userModel);
const manageSubscriptionUseCase = new ManageSubscriptionUseCase(subscriptionRepository);
const manageScheduleUseCase = new ManageScheduleUseCase(scheduleRepository);
const loadMedicalCredentialsUseCase = new LoadMedicalCredentialsUseCase(medicalCredentialsRepository);
const loadInsuranceUseCase = new LoadInsuranceUseCase(insuranceRepository);
const manageAccountUseCase = new ManageAccountUseCase(userRepository);

// viewModel init
const authViewModel = new AuthViewModel(
    loginUseCase,
    registerUseCase,
    logoutUseCase,
    getUserAppAccessUseCase,
    changeUserPasswordUseCase,
    deleteAccountUseCase,
    forgotPasswordUseCase,
    newPasswordUseCase,
    verifyTokenUseCase,
    manageAccountUseCase,
    authModel
);
const userViewModel = new UserViewModel(userModel, loadUserUseCase);
const lakeViewModel = new LakeViewModel(searchBySpecialtiesModel, searchBySpecialtiesUseCase, loadAvailableLettersUseCase);
const faqViewModel = new FaqViewModel(faqModel, manageFaqUseCase);
const landingViewModel = new LandingViewModel(manageFormsUseCase);
const appointmentViewModel = new AppointmentViewModel(manageAppointmentUseCase);
const connectionViewModel = new ConnectionViewModel(manageConnectionUseCase, connectionRepository);
const mediaViewModel = new MediaViewModel(manageMediaUseCase, mediaRepository);
const newsViewModel = new NewsViewModel(newsModel, manageNewsUseCase);
const doctorProfileViewModel = new DoctorProfileViewModel(doctorProfileModel, manageDoctorProfileCase);
const patientProfileViewModel = new PatientProfileViewModel(patientProfileRepository, userModel);
const subscriptionViewModel = new SubscriptionViewModel(manageSubscriptionUseCase);
const scheduleViewModel = new ScheduleViewModel(manageScheduleUseCase, doctorProfileModel, doctorProfileViewModel);
const medicalCredentialsViewModel = new MedicalCredentialsViewModel(loadMedicalCredentialsUseCase);
const insuranceViewModel = new InsuranceViewModel(loadInsuranceUseCase);

// ViewModelsContext
const ViewModelsContext = React.createContext<ViewModelsContextValue>({} as ViewModelsContextValue);

export const ViewModelsProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    return (
        <ViewModelsContext.Provider
            value={{
                uIViewModel,
                authViewModel,
                userViewModel,
                lakeViewModel,
                faqViewModel,
                landingViewModel,
                appointmentViewModel,
                connectionViewModel,
                mediaViewModel,
                newsViewModel,
                doctorProfileViewModel,
                patientProfileViewModel,
                subscriptionViewModel,
                scheduleViewModel,
                medicalCredentialsViewModel,
                insuranceViewModel
            }}
        >
            {children}
        </ViewModelsContext.Provider>
    );
};

export const useViewModels = () => React.useContext(ViewModelsContext);

import axios from "axios";
import UserRepository from "domain/repository/user/UserRepository";
import UserStructure from "domain/entity/user/structures/UserStructure";

class UserApi implements UserRepository {
    getUser = (): Promise<UserStructure> => {
        return axios({
            method: "GET",
            url: "api/user-by-token",
        })
            .then((result: any): UserStructure => {
                return {
                    id: result.data["id"],
                    email: result.data["email"],
                    active: result.data["active"],
                    profileId: result.data["id"],
                    defaultLocale: result.data["defaultLocale"],
                    referralCode: result.data["referralCode"],
                    balance: result.data["balance"],
                    referralsCount: result.data["referralsCount"],
                    referralsDoctorCount: result.data["referralsDoctorCount"],
                    referralsPatientCount: result.data["referralsPatientCount"],
                };
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    };

    editUserAccount = (id: string, userAccount: any): Promise<UserStructure> => {
        return axios({
            method: "PUT",
            url: axios.defaults.baseURL + `api/users/${id}`,
            data: userAccount,
        })
            .then((result: any) => {
                return {
                    id: result.data['id'],
                    email: result.data['email'],
                    active: result.data["active"],
                    profileId: result.data['profileId'],
                    defaultLocale: result.data['defaultLocale'],
                    referralCode: result.data["referralCode"],
                    balance: result.data["balance"],
                    referralsCount: result.data["referralsCount"],
                    referralsDoctorCount: result.data["referralsDoctorCount"],
                    referralsPatientCount: result.data["referralsPatientCount"],
                };
            })
            .catch((e) => {
                return Promise.reject(e.response);
            });
    }

    getUserAccount = (id: string) => {
        return axios({
            method: "GET",
            url: `api/users/${id}`,
        })
            .then((result: any): UserStructure => {
                return result.data;
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    }
}

export default UserApi;

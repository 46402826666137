import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Typography } from "@mui/material";
import TooltipedCard from "presentation/views/components/TooltipedCard";
import EntityInfo from "presentation/views/components/EntityInfo";
import { useViewModels } from "presentation/view-model/ViewModelProvider";

import cn from "classnames/bind";
import st from "../Dashboard/dashboard.module.scss";
import style from "./patients.module.scss";
import { useIntl } from "react-intl";
import axios from "axios";
import getAge from "../../../../helpers/getAge";
import ReactPhoneInput from 'react-phone-input-material-ui';
import TextField from "@mui/material/TextField";


const Patients = () => {
    const intl = useIntl();
    const { doctorProfileViewModel, userViewModel: {locale},
    } = useViewModels();

    useEffect(() => {
        doctorProfileViewModel.loadOwnPatients()
    }, [doctorProfileViewModel])

    return (
        <section className={st.section}>
            <Typography variant="h5" className={cn(st.section__title, st.section__title_isLg)}>
                <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cabinet.dashboard.patients.header' }) }} />
            </Typography>

                {doctorProfileViewModel.patients.length > 0 ?
                    doctorProfileViewModel.patients?.map((item: any) => (
                        <TooltipedCard key={item.id} variant="bordered"
                                       tooltip={
                                           <Typography variant="t2news" sx={{ display: "flex" }}>
                                               Tel: {item.phone ? (
                                                <ReactPhoneInput
                                                    value={item.phone}
                                                    // @ts-ignore
                                                    className={style.phone}
                                                    disableDropdown
                                                    country={locale || "de"}
                                                    preserveOrder={['country', 'preferredCountries']}
                                                    inputProps={{
                                                        readOnly: true,
                                                        size: "small",
                                                        id: "phoneNumber",
                                                        margin: "normal",
                                                        inputMode: 'numeric',
                                                        variant: "standard",
                                                        autoComplete: "phoneNumber",
                                                        label: "",
                                                    }}
                                                    name="phoneNumber"
                                                    prefix=''
                                                    component={TextField}
                                                />
                                            ) : 'no information'
                                            }
                                           </Typography>
                                       }
                        >
                            <div style={{cursor: "pointer"}}>
                                <EntityInfo
                                    fio={item.profile?.firstName.charAt(0) + item.profile?.lastName.charAt(0)}
                                    avatarSize={64}
                                    pictureUrl={
                                        item.profile?.avatar?.contentUrl ? (axios.defaults.baseURL + item.profile?.avatar?.contentUrl) : ''
                                    }>
                                    <Typography variant="t3">
                                        {item.profile?.firstName + " " + item.profile?.lastName}
                                    </Typography>

                                    <Typography variant="t2news">
                                         {
                                             item.profile?.birthDate ?
                                              `${getAge(new Date(item.profile?.birthDate))} ${intl.formatMessage({id: "cabinet.patients.y.o"})} ` : ""
                                         }
                                         {item.profile?.gender}
                                    </Typography>
                                </EntityInfo>
                            </div>
                        </TooltipedCard>
                    ))
                    :
                    <div className={st.empty_dashboard}>
                        <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cabinet.dashboard.patients.nopatients' }) }} />
                    </div>
                }

        </section>
    );
};

export default observer(Patients);

// @ts-nocheck
import ManageScheduleUseCase from "../../../domain/interactors/schedule/ManageScheduleUseCase";
import {
    AppointmentDayItem,
    ScheduleDay,
    ScheduleItem,
    TimeRow,
} from "../../../domain/entity/schedule/structures/ScheduleDayStructure";
import { action, makeAutoObservable } from "mobx";
import {
    ScheduleSettings,
} from "../../../domain/entity/schedule/structures/ScheduleSettingsStructure";
import DoctorProfileModel from "../../../domain/entity/profile/doctor/DoctorProfileModel";
import DoctorProfileViewModel from "../profile/doctor/DoctorProfileViewModel";


export default class ScheduleViewModel {
    public manageScheduleUseCase: ManageScheduleUseCase;
    public scheduleDays: Array<ScheduleDay>;
    public scheduleSettings: ScheduleSettings;
    public doctorProfileModel: DoctorProfileModel;
    public doctorProfileViewModel: DoctorProfileViewModel;
    public timeRows: Array<TimeRow>;

    constructor(manageScheduleUseCase: ManageScheduleUseCase, doctorProfileModel: DoctorProfileModel, doctorProfileViewModel: DoctorProfileViewModel) {
        makeAutoObservable(this);
        this.manageScheduleUseCase = manageScheduleUseCase;
        this.scheduleDays = [];
        this.scheduleSettings = this.defaultScheduleSettings;
        this.doctorProfileModel = doctorProfileModel;
        this.doctorProfileViewModel = doctorProfileViewModel;
        this.timeRows = [];
    }

    get defaultScheduleSettings() {
        return {
            workOnHoliday: false,
            workingDays: [1, 2, 3, 4, 5],
            workingTimeSettingsCommon: {
                breaks: [
                    {
                        timeFrom: '12:00',
                        timeTill: '13:00'
                    }
                ],
                appointmentInterval: 30,
                workingTimeStart: '10:00',
                workingTimeEnd: '21:00',
            },
            workingTimeType: 'common',
            appointmentByCall: false,
            noOrdination: false,
        };
    }

    changeAppointmentByCall = (appointmentByCall: boolean) => {
        this.manageScheduleUseCase.changeAppointmentByCall(appointmentByCall)
            .then(action((scheduleSettings) => {
            }))
    }

    getDoctorSchedule = (id: string, from: string, till: string) => {
        this.manageScheduleUseCase.getDoctorSchedule(id, from, till)
            .then(action((scheduleDays) => {
                // @ts-ignore
                this.scheduleDays = scheduleDays;
                this.buildTimeRows();
            }))
            .catch((error) => {
                console.log({ error });
            })
    }

    getDoctorScheduleSettings = (id: string) => {
        this.manageScheduleUseCase.getDoctorScheduleSettings(id)
            .then(action((scheduleSettings) => {
                this.scheduleSettings = scheduleSettings;
            }))
            .catch((error) => {
                console.log({ error });
            })
    }

    saveDoctorScheduleSettings = () => {
        let scheduleSettings = {} as any;
        Object.assign(scheduleSettings, this.scheduleSettings);
        delete scheduleSettings['id'];
        delete scheduleSettings.workingTimeSettingsCommon['id'];
        for (let i = 0; i < scheduleSettings.workingTimeSettingsCommon.breaks.length; i++) {
            delete scheduleSettings.workingTimeSettingsCommon.breaks[i]['id'];
        }

        if (
            scheduleSettings.workingDays.includes(6) ||
            scheduleSettings.workingDays.includes(7)
        ) {
            scheduleSettings.workOnHoliday = true;
        }

        return this.manageScheduleUseCase.saveDoctorScheduleSettings(scheduleSettings)
            .then(action((scheduleSettings) => {
                this.scheduleSettings = scheduleSettings;
                const currentDoctor = this.doctorProfileModel.options

                let userIsActive = true;
                if (!currentDoctor.active) {
                    userIsActive = !!(currentDoctor.firstName &&
                        currentDoctor.insurances.length &&
                        currentDoctor.experiences.length &&
                        currentDoctor.specialities.length
                    );
                }

                this.doctorProfileModel.updateFromJson({ ...this.doctorProfileModel.asJson, scheduleSettings: scheduleSettings, active: userIsActive});

                return this.doctorProfileModel;
            }))
    }

    isWorkingDay = (day: number): boolean => {
        return this.scheduleSettings.workingDays.includes(day);
    }

    private buildTimeRows = () => {
        let timesRange = ScheduleViewModel.generateTimesRange();
        this.timeRows = timesRange.map((time, index) => {
            const timeRow: TimeRow = {time: time, days: []};
            for (let i = 0; i < this.scheduleDays.length; i++) {
                const o = {
                    isWorking: this.scheduleDays[i].workingDay,
                    appointments: index + 1 === timesRange.length ? [] : this.determineAppointmentsBetweenTimes(
                        time,
                        timesRange[index + 1],
                        this.scheduleDays[i].scheduleItems
                    )
                }
                timeRow.days.push(o)
            }

            return timeRow;
        });
    }

    private determineAppointmentsBetweenTimes = (
        start: string,
        end: string,
        appointments: ScheduleItem[]
    ): AppointmentDayItem[] => {
        const appointmentDayItems = [];
        for (let i = 0; i < appointments.length; i++) {
            if (appointments[i].timeFrom >= start && appointments[i].timeTill <= end) {
                appointmentDayItems.push({
                    date: appointments[i].date,
                    start: appointments[i].timeFrom,
                    end: appointments[i].timeTill,
                    patientName: `${appointments[i].appointment.patient.profile?.firstName} ${appointments[i].appointment.patient.profile?.lastName}`,
                    background: 'blue',
                    reason: appointments[i].appointment.reason,
                    patientAvatarUrl: appointments[i].appointment.patient?.profile?.avatar?.contentUrl ?? ''
                })
            }
        }

        appointmentDayItems.sort((a, b) => {
            return a.start.localeCompare(b.start);
        })

        return appointmentDayItems;
    }

    private static generateTimesRange = (start: string = '08:00', end: string = '23:00') => {
        let timeFrom = Number(start.split(':')[0]);
        let timeTill = Number(end.split(':')[0]);
        let timesRange = [];
        for (let i = timeFrom; i <= timeTill; i++) {
            if (i < 10) {
                timesRange.push('0' + i + ':00');
            } else {
                timesRange.push(i + ':00');
            }
        }

        return timesRange;
    }
}

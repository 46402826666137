import { useIntl } from "react-intl";
import { observer } from "mobx-react";

import { Typography } from "@mui/material";
import { HeaderBar } from "../../components/AppBar";

import { Link } from "react-router-dom";

// styles
import st from "./ErrorPage.module.scss";
import { NonAuthRoutes } from "presentation/routes/routes";
import { useViewModels } from "presentation/view-model/ViewModelProvider";
import { useEffect } from "react";

const ErrorPage: React.FC = () => {
    const intl = useIntl();
    const {
        uIViewModel: {
            mediaQuery,
            setMobileIs,
        },
    } = useViewModels();

    useEffect(() => {
        let isMounted = true;
        window.matchMedia(mediaQuery).addEventListener("change", () => {
            if (isMounted) {
                setMobileIs(window.matchMedia(mediaQuery).matches);
            }
        });
        return () => { isMounted = false };
    });

    return (
        <>
            <HeaderBar
                bgColor='#9146FF'
                staticBarIsTransparent
                accentBar
                accentWhiteBar
            />

            <section className={st.error_section}>
                <div className={st.error}>
                    <Typography variant="h1" className={st.error__title}>
                        {intl.formatMessage({ id: "app.errorPage.title" })}
                    </Typography>
                    <Link to={NonAuthRoutes.home} className={st.error__button}>
                        {intl.formatMessage({ id: "app.errorPage.button" })}
                    </Link>
                </div>
            </section>
        </>
    );
};

export default observer(ErrorPage);

import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { useViewModels } from "presentation/view-model/ViewModelProvider";
import { observer } from "mobx-react";
import { Link, useParams } from "react-router-dom";

import stTags from "../../components/WithStyledTags/withStyledTags.module.scss"
import stHero from "../../components/HeroHeader/heroHeader.module.scss";
import st from './faqId.module.scss';
import './faqIdAccordion.scss';
import LandingLayout from "../../layouts/landing/LandingLayout";
import { Plus } from "../../components/Plus";
import { AskQuestion } from "../../components/AskQuestion";
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/arrow.svg";
import { NonAuthRoutes } from "../../../routes/routes";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ReactComponent as ExpandIcon } from "../../assets/images/icons/expand.svg";
import classNames from "classnames";

const FaqId: React.FC = () => {
    const intl = useIntl();
    // @ts-ignore
    const categoryId = useParams().id;
    const { faqViewModel } = useViewModels();
    const [expanded, setExpanded] = useState("");
    const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : "");
    };

    useEffect(() => {
        faqViewModel.loadQuestionsByCategory(categoryId);

    }, [faqViewModel, categoryId]);

    return (
        <LandingLayout>
            <div className={st.faq_id}>
                <section className={stHero.hero_header}>
                    <div className={st.hero_container}>
                        <div className={st.back_to_topic}>
                            <Link to={NonAuthRoutes.faq}>
                                <ArrowIcon />
                                <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "faqId.back" }) }}></span>
                            </Link>
                        </div>

                        <div className={st.title}>
                            {faqViewModel.faqModel.categorizedQuestions.length ? faqViewModel.faqModel.categorizedQuestions[0]['category'] : ''}
                        </div>
                    </div>
                </section>

                <main className={classNames(stTags.with_styled_tags, st.main)}>
                    <div className={st.question_list}>
                        {faqViewModel.faqModel.categorizedQuestions?.map((item, index) => (
                        <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} className="faq_accordion"
                                   key={index}>
                            <AccordionSummary expandIcon={<ExpandIcon />} aria-controls={`panel${index}bh-content`} id={`panel${index}bh-header`}>
                                <div className={st.accordion_title}>{item.title}</div>
                                <Plus className={st.accordion_icon} />
                            </AccordionSummary>

                            <AccordionDetails>
                                {/*<div className={st.accordion_answer}>{item.answer}</div>*/}
                                <div className={st.accordion_answer} dangerouslySetInnerHTML={{__html: item.answer}}></div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    </div>
                </main>

                <AskQuestion />
            </div>

        </LandingLayout>
    );
};

export default observer(FaqId);

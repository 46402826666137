import * as React from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormikProvider, useFormik } from 'formik';
import { useViewModels } from 'presentation/view-model/ViewModelProvider';
import { NonAuthRoutes } from 'presentation/routes/routes';
import { Link } from 'react-router-dom';
import st from '../Auth.module.scss';
import { ReactComponent as ArrowIcon } from '../../../assets/images/icons/arrow.svg';
import validator from "validator";
import cleanDeep from "clean-deep";

const ForgotPassword = () => {
    const intl = useIntl();

    const {
        authViewModel: {
            onClickForgotPassword,
            successForgotPassIsActive,
            hideSuccessForgotPass,
        },
        uIViewModel: {
            setErrorAlertContent,
            setErrorAlertContentTime,
            alertContentTime,
        },
        userViewModel,
    } = useViewModels();

    const validateForgotPass = (values: any): object => {
        const errors: any = {
            email: (() => {
                if (!values.email) return intl.formatMessage({id: 'required'});
                if (!validator.isEmail(values.email)) return intl.formatMessage({id: 'errors.email'});
            })(),
        };

        return cleanDeep(errors);
    };

    const form = useFormik({
        initialValues: {
            email: '',
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: validateForgotPass,
        onSubmit: (values) => {
            setErrorAlertContent('', true);
            onClickForgotPassword(values.email)
                .catch((errorMessage) => {
                    setErrorAlertContentTime(errorMessage, alertContentTime);
                }
            );
        },
    });

    return (
        <Grid container component="main" sx={{ minHeight: '100vh' }}>
            <Grid item xs={12} md={5} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                    sx={[
                        {
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            maxWidth: 480,
                        },
                        (theme) => ({
                            [theme.breakpoints.down('md')]: {
                                maxWidth: 'none',
                            },
                        }),
                    ]}
                >
                    {!successForgotPassIsActive ? (
                        <FormikProvider value={form}>
                            <Box
                                component="form"
                                noValidate
                                onSubmit={form.handleSubmit}
                                onChange={(event: any) => {
                                    if (form.errors) {
                                        form.setFieldError(event.target.name, '');
                                    }
                                }}
                                onKeyDown={(event: any) => {
                                    if (event.key === 'Enter') {
                                        form.handleSubmit();
                                    }
                                }}
                            >
                                <Grid container spacing={6}>
                                    <Grid item xs={12}>
                                        <Link to={`${userViewModel.locale && userViewModel.locale !== 'de' ? "/" + userViewModel.locale : ''}${NonAuthRoutes.signin}`}>
                                            <div className={st.back}>
                                                <ArrowIcon />
                                                {intl.formatMessage({ id: 'auth.forgotPassword.back' })}
                                            </div>
                                        </Link>
                                        <Typography
                                            component="h1"
                                            variant="h4"
                                            sx={{ m: 0 }}
                                            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.forgotPassword.title' }) }}
                                            gutterBottom
                                        ></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            component="p"
                                            variant="body1"
                                            dangerouslySetInnerHTML={{
                                                __html: intl.formatMessage({ id: 'auth.forgotPassword.description' }),
                                            }}
                                        ></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            label={intl.formatMessage({ id: 'auth.forgotPassword.email' })}
                                            name="email"
                                            variant="standard"
                                            autoComplete="email"
                                            value={form.values.email}
                                            onChange={form.handleChange}
                                            error={!form.isValid && Boolean(form.errors.email)}
                                            helperText={!form.isValid && form.errors.email}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button type="submit" fullWidth color="secondary" variant="contained" sx={{ width: '100%' }}>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: intl.formatMessage({ id: 'auth.forgotPassword.button' }),
                                                }}
                                            ></span>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </FormikProvider>
                    ) : (
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    sx={{ m: 0 }}
                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.forgotPassword.success.title' }) }}
                                    gutterBottom
                                ></Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    component="p"
                                    variant="body1"
                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.forgotPassword.success.description' }) }}
                                ></Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Link to={`${userViewModel.locale && userViewModel.locale !== 'de' ? "/" + userViewModel.locale : ''}${NonAuthRoutes.signin}`}>
                                    <Button variant="contained" color="secondary" onClick={hideSuccessForgotPass} autoFocus sx={{ width: '100%' }}>
                                        {intl.formatMessage({ id: 'auth.forgotPassword.success.button' })}
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </Grid>
            <Grid item xs={false} md={6} className={st.media}>
                <svg className={st.media_circle_1} viewBox="0 0 577 577" xmlns="http://www.w3.org/2000/svg">
                    <circle opacity=".32" cx="288.293" cy="288.294" r="287.5" transform="rotate(45 288.293 288.294)" stroke="#fff" fill="none" />
                </svg>
                <svg className={st.media_circle_2} viewBox="0 0 468 545" xmlns="http://www.w3.org/2000/svg">
                    <circle opacity="0.32" cx="180" cy="288.294" r="287.5" transform="rotate(45 180 288.294)" stroke="#fff" fill="none" />
                </svg>
                <div className={st.media__picture}>
                    <img src={`/images/Auth/forgot.png`} alt="forgot password" />
                </div>
            </Grid>
        </Grid>
    );
};

export default observer(ForgotPassword);

// @ts-nocheck
import React, { useState } from "react";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import LandingLayout from "presentation/views/layouts/landing/LandingLayout";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import st from "./contacts.module.scss";
import { useViewModels } from "presentation/view-model/ViewModelProvider";
import { NonAuthRoutes } from "../../../routes/routes";
// components
import HeroHeader from "presentation/views/components/HeroHeader";
import { Planet } from "presentation/views/components/Planet";
import { Popup } from "../../components/Popup";
import classNames from "classnames";

import PhoneInput from 'react-phone-input-material-ui';
import stylePopup from "presentation/views/components/Popup/popup.module.scss";
import isEmail from "validator/lib/isEmail";
// import isMobilePhone from "validator/lib/isMobilePhone";


const Contacts: React.FC = () => {
    const intl = useIntl();
    const { landingViewModel,
        authViewModel: { isDoctor },
        doctorProfileViewModel,
        patientProfileViewModel,
        uIViewModel: { openPopup, closePopup, setErrorAlertContent },
    } = useViewModels();

    const user = isDoctor ? doctorProfileViewModel.model.options : patientProfileViewModel.patientProfile;
    const [canSubmit, setCanSubmit] = useState(true);

    const validate = (values: any) => {
        const errors: any = {};

        if (values.firstName && values.firstName.length <= 1) {
            errors.firstName = intl.formatMessage({id: 'required'});
        }

        if (values.message && values.message.length <= 1) {
            errors.message = intl.formatMessage({id: 'required'});
        }

        /*if (values.phone && !isMobilePhone(values.phone)) {
            errors.phone = intl.formatMessage({id: "errors.phone"});
        }*/

        if (values.email && !isEmail(values.email)) {
            errors.email = intl.formatMessage({id: "errors.email"});
        }

        return errors;
    }
    const form = useFormik({
        validate: validate,
        initialValues: {
            email: user.workEmail ?? "",
            phone: user.phone ?? "",
            firstName: user.firstName ? user.firstName + ' ' + user.lastName  : "",
            message: ""
        } as any,
        onSubmit: async (values, { setErrors }) => {
            landingViewModel
                .sendGetInTouchForm(values.firstName, values.email, values.phone, values.message)
                .then((e) => {
                    openPopup();
                })
                .catch((errors) => {
                    setErrorAlertContent(intl.formatMessage({id: "errorSave"}));
                    setErrors(errors);
                });
        },
    });

    const phoneChange = (phone: number) => {
        form.setFieldValue("phone", phone);
    }

    const phoneFocusChange = (e: any, phone: number) => {
        if (form.values.phone?.length <= 1) {
            form.setFieldValue("phone", phone.dialCode);
        }
    }

    return (
        <LandingLayout>
            <Popup>
                <Typography variant="h5" className={stylePopup.title}>{intl.formatMessage({ id: "contacts.form.succes.header" })}</Typography>

                <Typography variant="p5" className={stylePopup.text}>{intl.formatMessage({id: "contacts.form.success"})}</Typography>

                <Button type="button" color="secondary" variant="contained" onClick={closePopup}  sx={{ mt: {xs: 2, sm: 4, md: 6}, width: "100%" }}>
                    {intl.formatMessage({ id: "ok" })}
                </Button>
            </Popup>

            <HeroHeader
                caption="contacts.title"
                desc="contacts.description"
                className={st.header}
            />

            <div className={st.container}>
                <div className={st.email_wrapper}>
                    <Planet
                        className={st.email__planet}
                        bgColor="rgba(0, 185, 255, 0.12)"
                        contentColor="color_light"
                        title={
                            <div className={st.email}>
                                info<span>@deinarzt.org</span>
                            </div>
                        }
                        onClickFaq={() => window.location.href = "mailto:info@deinarzt.org"}
                        gravity
                    />
                </div>
                <div className={st.form}>
                    <Box component="form" noValidate onSubmit={form.handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            className={st.field}
                            margin="normal"
                            required
                            fullWidth
                            id="firstName"
                            label={<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "contacts.form.name" }) }}></span>}
                            name="firstName"
                            variant="standard"
                            autoComplete="firstName"
                            value={form.values.firstName}
                            onChange={form.handleChange}
                            error={!form.isValid && Boolean(form.errors?.firstName)}
                            helperText={!form.isValid && form.errors?.firstName}
                        />
                        <PhoneInput
                            value={form.values.phone}
                            className={classNames(st.field, st.phone, form.values.phone && form.values.phone.length >= 1 ? st.filled : "")}
                            onChange={phoneChange}
                            onFocus={phoneFocusChange}
                            inputProps={{
                                id: "phone",
                                margin: "normal",
                                inputMode: 'numeric',
                                variant: "standard",
                                autoComplete: "phone",
                                error: !form.isValid && Boolean(form.errors?.phone),
                                helperText: !form.isValid && form.errors?.phone,
                                label: `${intl.formatMessage({ id: "contacts.form.phone" }) }`,
                            }}
                            name="phone"
                            prefix=''
                            preserveOrder={['country', 'preferredCountries']}
                            defaultErrorMessage={!form.isValid && Boolean(form.errors?.phone)}
                            error={!form.isValid && Boolean(form.errors?.phone)}
                            helperText={!form.isValid && form.errors?.phone}
                            component={TextField}
                        />
                        <TextField
                            className={st.field}
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "contacts.form.email" }) }}></span>}
                            name="email"
                            type="email"
                            variant="standard"
                            autoComplete="email"
                            value={form.values.email}
                            onChange={form.handleChange}
                            error={!form.isValid && Boolean(form.errors?.email)}
                            helperText={!form.isValid && form.errors?.email}
                        />
                        <TextField
                            className={st.field}
                            margin="normal"
                            required
                            fullWidth
                            name="message"
                            label={<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "contacts.form.message" }) }}></span>}
                            variant="standard"
                            multiline
                            id="message"
                            autoComplete="current-message"
                            value={form.values.message}
                            onChange={form.handleChange}
                            error={!form.isValid && Boolean(form.errors?.message)}
                            helperText={!form.isValid && form.errors?.message}
                        />

                        <Grid container columnSpacing={4} className={st.form_footer}>
                            <Grid item md={8} sm={7} xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checkedIcon={<span />}
                                            icon={<span />}
                                            defaultChecked
                                            name="privacy"
                                            id="privacy"
                                            value={form.values.privacy}
                                            onChange={(value: any) => {
                                                form.handleChange(value);
                                                setCanSubmit(value.target.checked);
                                            }}
                                        />
                                    }
                                    label={
                                        <span className={st.terms}>
                                            <span dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "contacts.form.terms.read" })} ` }} />

                                            <Link
                                                to={NonAuthRoutes.termsOfUse}
                                                className={st.terms__link}
                                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "contacts.form.terms.terms" }) }}
                                            />

                                            <span dangerouslySetInnerHTML={{ __html: ` ${intl.formatMessage({ id: "contacts.form.terms.and" })} ` }} />

                                            <Link
                                                to={NonAuthRoutes.privacyPolicy}
                                                className={st.terms__link}
                                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "contacts.form.terms.privacy" }) }}
                                            />.
                                        </span>
                                    }
                                />
                            </Grid>

                            <Grid item md={4} sm={5} xs={12} className={st.submit_wrapper}>
                                <Button
                                    type="submit"
                                    disabled={!canSubmit}
                                    variant="contained"
                                    className={st.submit}
                                    color="secondary"
                                    fullWidth
                                >
                                    <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "contacts.form.button" }) }} />
                                </Button>
                            </Grid>
                        </Grid>
                        {/* <pre style={{color: "saddlebrown", overflow: 'hidden', width: '100%'}}>{JSON.stringify(form.values, 0, 2)}</pre> */}
                    </Box>
                </div>
            </div>
        </LandingLayout>
    );
};

export default Contacts;

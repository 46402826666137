import InsuranceRepository from "../../repository/insurance/InsuranceRepository";
import {Insurance} from "../../entity/insurance/structures/InsuranceStructure";

export default class LoadInsuranceUseCase {
    private insuranceRepository: InsuranceRepository;

    constructor(insuranceRepository: InsuranceRepository) {
        this.insuranceRepository = insuranceRepository;
    }

    getInsurances = () => {
        return this.insuranceRepository
            .getInsurances()
            .then((insurances: [Insurance[], []]) => {
                return insurances;
            })
    }
}

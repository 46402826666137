// @ts-nocheck
import * as React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, Radio, RadioGroup } from '@mui/material';
import { NonAuthRoutes } from 'presentation/routes/routes';
import { Link, useLocation } from 'react-router-dom';
import { useViewModels } from 'presentation/view-model/ViewModelProvider';
import { Field, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { LoginSocialGoogle, LoginSocialFacebook, IResolveParams, TypeCrossFunction } from 'reactjs-social-login';

import st from '../Auth.module.scss';
import { ReactComponent as EyeIcon } from '../../../assets/images/icons/eye.svg';
import { ReactComponent as EyeIconOff } from '../../../assets/images/icons/eye-off.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/images/icons/checked.svg';
import { ReactComponent as FbIcon } from '../../../assets/images/icons/social_fb.svg';
import { ReactComponent as GoogleIcon } from '../../../assets/images/icons/social_google.svg';
import { ReactComponent as MailIcon } from '../../../assets/images/icons/mail.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/images/icons/arrow.svg';
import { observer } from 'mobx-react';
import validator from "validator";
import cleanDeep from "clean-deep";

const SignUp = () => {
    const routLocation = useLocation();
    const intl = useIntl();
    const {
        authViewModel: {
            onClickSignUp,
            onClickFaceBookLogin,
            onClickGoogleLogin,
            successSignUpIsActive,
            hideSuccessSignUp,
            saveReferralCode,
        },
        uIViewModel: {
            setErrorAlertContent,
            setErrorAlertContentTime,
            alertContentTime,
        },
        userViewModel,
    } = useViewModels();
    const [withSocial, setWithSocial] = useState<string | null>(null);
    const [userType, setUserType] = useState<string | null>(null);
    const [token, setToken] = useState('');
    const [withMail, setWithMail] = useState(false);
    const query = new URLSearchParams(routLocation.search);
    const handleCheckboxChange = (value: any) => {
        setCanSubmit(value.target.checked);
    };
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    const onClickMail = () => {
        setWithMail(true);
        setUserType(null);
    };

    const onClickBack = () => {
        setWithSocial(null);
        setWithMail(false);
        setUserType(null);
    };

    const [canSubmit, setCanSubmit] = useState(true);

    useEffect(() => {
        if (routLocation?.state) {
            setToken(routLocation.state.token);
            setWithSocial(routLocation.state.social);
        }
    }, [routLocation]);

    useEffect(() => {
        if (query.get('type') && ['patient', 'doctor'].includes(query.get('type') as string)) {
            form.setFieldValue('type', query.get('type'));
            setUserType(query.get('type'));
        }

        if (query.get('referralCode') !== null) {
            saveReferralCode(query.get('referralCode'));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const validate = (values: any): object => {
        const errors: any = {
            email: (() => {
                if (!values.email) return intl.formatMessage({id: 'required'});
                if (!validator.isEmail(values.email)) return intl.formatMessage({id: 'errors.email'});
            })(),
            password: (() => {
                if (!values.password) return intl.formatMessage({id: 'required'});
                if (values.password.length < 6) return intl.formatMessage({id: 'errors.passwordMinLength'});
                if (values.confirm_password && values.confirm_password !== values.password) {
                    return intl.formatMessage({id: 'errors.passwordNotMatch'});
                }
            })(),
            confirm_password: (() => {
                if (!values.confirm_password) return intl.formatMessage({id: 'required'});
                if (values.confirm_password !== values.password) {
                    return intl.formatMessage({id: 'errors.passwordNotMatch'});
                }
            })(),
            type: (() => {
                if (!values.type) return intl.formatMessage({id: 'required'});
                if (!["patient", "doctor"].includes(values.type)) return intl.formatMessage({id: 'errors.userRole'});
            })(),
        };

        // убираем пустые поля
        return cleanDeep(errors);
    };

    const form = useFormik({
        initialValues: {
            type: '',
            email: '',
            password: '',
            confirm_password: '',
        },
        validate: validate,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values, { setErrors }) => {
            setErrorAlertContent('', true);
            onClickSignUp(values.type, values.email, values.password, values.confirm_password)
                .catch((errorMessage) => {
                    setErrorAlertContentTime(errorMessage, alertContentTime);
                }
            );
        },
    });

    const googleRef = React.useRef<TypeCrossFunction>(null!);
    const facebookRef = React.useRef<TypeCrossFunction>(null!);

    const handleRegister = () => {
        if (withSocial === 'Google') {
            onClickGoogleLogin(token, userType).then((response) => {
                console.log(response);
            });
        } else {
            onClickFaceBookLogin(token, userType).then((response) => {
                console.log(response);
            });
        }
    }

    const onLoginStart = React.useCallback((data: any) => {
        console.log('login start', data);
    }, []);

    const onLoginSuccess = ({ provider, data }: IResolveParams) => {
        if (provider === 'google') {
            setToken(data?.access_token);
            onClickGoogleLogin(data?.access_token, null).then((response) => {
                if (response === 'need type') {
                    setWithSocial('Google');
                }
            });
        } else if (provider === 'facebook') {
            setToken(data?.accessToken);
            onClickFaceBookLogin(data?.accessToken, null).then((response) => {
                if (response === 'need type') {
                    setWithSocial('Facebook');
                }
            });
        }
    };

    const onLoginFailure = (response: any) => {
        console.log(129, response);
    };

    return (
        <Grid container component="main" sx={{ minHeight: '100vh' }}>
            <Grid item xs={12} md={5} sx={{ display: 'flex', alignItems: 'center' }}>
                {!successSignUpIsActive ? (
                    <>
                        {!withSocial && !withMail && (
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography
                                        component="h1"
                                        variant="h4"
                                        sx={{ mb: 2 }}
                                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signup.title' }) }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LoginSocialFacebook
                                        ref={facebookRef}
                                        appId={process.env.REACT_APP_FACEBOOK_ID || ''}
                                        fieldsProfile={
                                            'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                                        }
                                        onLoginStart={() => {onLoginStart('first')}}
                                        onResolve={onLoginSuccess}
                                        onReject={onLoginFailure}
                                    >
                                        <Button
                                            className={st.social_signup_button}
                                            color="secondary"
                                            variant="outlined"
                                            sx={{ width: '100%' }}
                                        >
                                            <FbIcon width="24" />
                                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signup.withFB' }) }}/>
                                        </Button>
                                    </LoginSocialFacebook>
                                </Grid>
                                <Grid item xs={12}>
                                    <LoginSocialGoogle
                                        ref={googleRef}
                                        client_id={process.env.REACT_APP_GOOGLE_ID || ''}
                                        onLoginStart={() => {onLoginStart('first', 'second')}}
                                        onResolve={onLoginSuccess}
                                        onReject={onLoginFailure}
                                    >
                                        <Button
                                            className={st.social_signup_button}
                                            color="secondary"
                                            variant="outlined"
                                            sx={{ width: '100%' }}
                                        >
                                            <GoogleIcon width="24" />
                                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signup.withGoogle' }) }}/>
                                        </Button>
                                    </LoginSocialGoogle>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        className={classNames(st.social_signup_button, st.social_signup_button_mail)}
                                        color="secondary"
                                        variant="outlined"
                                        sx={{ width: '100%' }}
                                        onClick={onClickMail}
                                    >
                                        <MailIcon width="24" />
                                        <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signup.withMail' }) }}/>
                                    </Button>
                                    <Grid item textAlign="center" sx={{ mt: 4 }}>
                                        <Link
                                            className={st.create_link}
                                            to={`${userViewModel.locale && userViewModel.locale !== 'de' ? "/" + userViewModel.locale : ''}${NonAuthRoutes.signin}`}
                                            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signup.registered' }) }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {withSocial && (
                            <Box
                                sx={[
                                    {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        maxWidth: 480,
                                    },
                                    (theme) => ({
                                        [theme.breakpoints.down('md')]: {
                                            maxWidth: 'none',
                                        },
                                    }),
                                ]}
                            >
                                <FormikProvider value={form}>
                                    <Box
                                        component="form"
                                        noValidate
                                        sx={{ mt: 3 }}
                                        onSubmit={form.handleSubmit}
                                        onChange={(event: any) => {
                                            if (form.errors) {
                                                form.setFieldError(event.target.name, '');
                                            }
                                        }}
                                    >
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} sx={{ mt: {xs: -5, md: 0} }}>
                                                <div className={st.back} onClick={onClickBack}>
                                                    <ArrowIcon />
                                                    {intl.formatMessage({ id: 'auth.signup.back' })}
                                                </div>
                                                <Typography
                                                    component="h1"
                                                    variant="h4"
                                                    sx={{ mb: 1 }}
                                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signup.withSocial' }, {social: withSocial}) }}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                {console.log(userType)}
                                                <RadioGroup
                                                    sx={{ width: '100%' }}
                                                    row={true}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setUserType((event.target as HTMLInputElement).value);
                                                    }}
                                                    aria-labelledby="user type by socials"
                                                    name="socials-type"
                                                >
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <FormControlLabel
                                                                className={st.signup_radio}
                                                                value="patient"
                                                                control={
                                                                    <Radio
                                                                        checked={userType === "patient"}
                                                                        className={st.signup_radio_radio}
                                                                        disableRipple
                                                                        checkedIcon={
                                                                            <div className={st.signup_radio_wrap}>
                                                                                <CheckedIcon className={st.signup_radio_icon} />
                                                                            </div>
                                                                        }
                                                                        icon={
                                                                            <div className={st.signup_radio_wrap}>
                                                                                <span className={st.signup_radio_span} />
                                                                            </div>
                                                                        }
                                                                    />
                                                                }
                                                                label={intl.formatMessage({ id: 'auth.signup.patient' })}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <FormControlLabel
                                                                className={st.signup_radio}
                                                                value="doctor"
                                                                control={
                                                                    <Radio
                                                                        checked={userType === "doctor"}
                                                                        className={st.signup_radio_radio}
                                                                        disableRipple
                                                                        checkedIcon={
                                                                            <div className={st.signup_radio_wrap}>
                                                                                <CheckedIcon className={st.signup_radio_icon} />
                                                                            </div>
                                                                        }
                                                                        icon={
                                                                            <div className={st.signup_radio_wrap}>
                                                                                <span className={st.signup_radio_span} />
                                                                            </div>
                                                                        }
                                                                    />
                                                                }
                                                                label={intl.formatMessage({ id: 'auth.signup.doctor' })}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </RadioGroup>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            defaultChecked
                                                            name="privacy"
                                                            onChange={handleCheckboxChange}
                                                            checkedIcon={<span />}
                                                            icon={<span />}
                                                        />
                                                    }
                                                    label={
                                                        <span className={st.terms}>
                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: `${intl.formatMessage({ id: 'contacts.form.terms.read' })} `,
                                                                }}
                                                            />
                                                            <Link
                                                                to={NonAuthRoutes.termsOfUse}
                                                                className={st.terms__link}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: intl.formatMessage({ id: 'contacts.form.terms.terms' }),
                                                                }}
                                                            />
                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: ` ${intl.formatMessage({ id: 'contacts.form.terms.and' })} `,
                                                                }}
                                                            />
                                                            <Link
                                                                to={NonAuthRoutes.privacyPolicy}
                                                                className={st.terms__link}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: intl.formatMessage({ id: 'contacts.form.terms.privacy' }),
                                                                }}
                                                            />
                                                            .
                                                        </span>
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button
                                                    type="submit"
                                                    disabled={!canSubmit || !userType}
                                                    color="secondary"
                                                    variant="contained"
                                                    sx={{ width: '100%' }}
                                                    onClick={handleRegister}
                                                >
                                                <span
                                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signup.button' }) }}
                                                />
                                                </Button>

                                                <Grid item textAlign="center" sx={{ mt: 4 }}>
                                                    <Link
                                                        className={st.create_link}
                                                        to={`${userViewModel.locale && userViewModel.locale !== 'de' ? "/" + userViewModel.locale : ''}${NonAuthRoutes.signin}`}
                                                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signup.registered' }) }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </FormikProvider>
                            </Box>
                        )}
                        {withMail && (
                            <Box
                                sx={[
                                    {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        maxWidth: 480,
                                    },
                                    (theme) => ({
                                        [theme.breakpoints.down('md')]: {
                                            maxWidth: 'none',
                                        },
                                    }),
                                ]}
                            >
                                <FormikProvider value={form}>
                                    <Box
                                        component="form"
                                        noValidate
                                        sx={{ mt: 3 }}
                                        onSubmit={form.handleSubmit}
                                        onChange={(event: any) => {
                                            if (form.errors) {
                                                form.setFieldError(event.target.name, '');
                                            }
                                        }}
                                    >
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} sx={{ mt: {xs: -5, md: 0} }}>
                                                <div className={st.back} onClick={onClickBack}>
                                                    <ArrowIcon />
                                                    {intl.formatMessage({ id: 'auth.signup.back' })}
                                                </div>
                                                <Typography
                                                    component="h1"
                                                    variant="h4"
                                                    sx={{ mb: 1 }}
                                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signup.withMail.title' }) }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Field
                                                    name="type"
                                                    children={(fieldProps: any) => {
                                                        return (
                                                            <RadioGroup
                                                                sx={{ width: '100%' }}
                                                                {...fieldProps.field}
                                                                row={true}
                                                                aria-labelledby="user type by mail"
                                                            >
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={6}>
                                                                        <FormControlLabel
                                                                            className={st.signup_radio}
                                                                            value="patient"
                                                                            control={
                                                                                <Radio
                                                                                    className={st.signup_radio_radio}
                                                                                    disableRipple
                                                                                    checkedIcon={
                                                                                        <div className={st.signup_radio_wrap}>
                                                                                            <CheckedIcon className={st.signup_radio_icon} />
                                                                                        </div>
                                                                                    }
                                                                                    icon={
                                                                                        <div className={st.signup_radio_wrap}>
                                                                                            <span className={st.signup_radio_span} />
                                                                                        </div>
                                                                                    }
                                                                                />
                                                                            }
                                                                            label={intl.formatMessage({ id: 'auth.signup.patient' })}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <FormControlLabel
                                                                            className={st.signup_radio}
                                                                            value="doctor"
                                                                            control={
                                                                                <Radio
                                                                                    className={st.signup_radio_radio}
                                                                                    disableRipple
                                                                                    checkedIcon={
                                                                                        <div className={st.signup_radio_wrap}>
                                                                                            <CheckedIcon className={st.signup_radio_icon} />
                                                                                        </div>
                                                                                    }
                                                                                    icon={
                                                                                        <div className={st.signup_radio_wrap}>
                                                                                            <span className={st.signup_radio_span} />
                                                                                        </div>
                                                                                    }
                                                                                />
                                                                            }
                                                                            label={intl.formatMessage({ id: 'auth.signup.doctor' })}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </RadioGroup>
                                                        );
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Field
                                                    name="email"
                                                    children={(fieldProps: any) => (
                                                        <TextField
                                                            {...fieldProps.field}
                                                            required
                                                            fullWidth
                                                            label={
                                                                <span
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: intl.formatMessage({ id: 'auth.signup.email' }),
                                                                    }}
                                                                />
                                                            }
                                                            variant="standard"
                                                            error={!form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                            helperText={!form.isValid && fieldProps.meta.error}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Field
                                                    type={showPassword ? 'text' : 'password'}
                                                    name="password"
                                                    children={(fieldProps: any) => (
                                                        <TextField
                                                            {...fieldProps.field}
                                                            required
                                                            fullWidth
                                                            label={
                                                                <span
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: intl.formatMessage({ id: 'auth.signup.password' }),
                                                                    }}
                                                                />
                                                            }
                                                            type={showPassword ? 'text' : 'password'}
                                                            variant="standard"
                                                            error={!form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                            helperText={!form.isValid && fieldProps.meta.error}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end" sx={{ tabIndex: "-1" }}>
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            edge="end"
                                                                            className={st.button_eye}
                                                                            tabIndex={-1}
                                                                        >
                                                                            {!showPassword ? <EyeIconOff /> : <EyeIcon />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Field
                                                    type={showPassword ? 'text' : 'password'}
                                                    name="confirm_password"
                                                    children={(fieldProps: any) => (
                                                        <TextField
                                                            {...fieldProps.field}
                                                            required
                                                            fullWidth
                                                            label={
                                                                <span
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: intl.formatMessage({ id: 'auth.signup.confirmPassword' }),
                                                                    }}
                                                                />
                                                            }
                                                            type={showPassword ? 'text' : 'password'}
                                                            variant="standard"
                                                            error={!form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                            helperText={!form.isValid && fieldProps.meta.error}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end" sx={{ tabIndex: "-1" }}>
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            edge="end"
                                                                            className={st.button_eye}
                                                                            tabIndex={-1}
                                                                        >
                                                                            {!showPassword ? <EyeIconOff /> : <EyeIcon />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <Grid item xs={12} mt={3}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                defaultChecked
                                                                name="privacy"
                                                                onChange={handleCheckboxChange}
                                                                checkedIcon={<span />}
                                                                icon={<span />}
                                                            />
                                                        }
                                                        label={
                                                            <span className={st.terms}>
                                                                <span
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: `${intl.formatMessage({ id: 'contacts.form.terms.read' })} `,
                                                                    }}
                                                                />
                                                                <Link
                                                                    to={NonAuthRoutes.termsOfUse}
                                                                    className={st.terms__link}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: intl.formatMessage({ id: 'contacts.form.terms.terms' }),
                                                                    }}
                                                                />
                                                                <span
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: ` ${intl.formatMessage({ id: 'contacts.form.terms.and' })} `,
                                                                    }}
                                                                />
                                                                <Link
                                                                    to={NonAuthRoutes.privacyPolicy}
                                                                    className={st.terms__link}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: intl.formatMessage({ id: 'contacts.form.terms.privacy' }),
                                                                    }}
                                                                />
                                                                .
                                                            </span>
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sx={{ mt: 1 }}>
                                                <Button
                                                    type="submit"
                                                    disabled={!canSubmit || !form.values.type}
                                                    color="secondary"
                                                    variant="contained"
                                                    sx={{ width: '100%', mb: 4 }}
                                                >
                                                    <span
                                                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signup.button' }) }}
                                                    />
                                                </Button>

                                                <Grid item textAlign="center">
                                                    <Link
                                                        className={st.create_link}
                                                        to={`${userViewModel.locale && userViewModel.locale !== 'de' ? "/" + userViewModel.locale : ''}${NonAuthRoutes.signin}`}
                                                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signup.registered' }) }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
{/*                                    <pre style={{color: "saddlebrown", }}>{JSON.stringify(form.values, 0, 2)}</pre>*/}
                                </FormikProvider>
                            </Box>
                        )}
                    </>
                ) : (
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Typography
                                component="h1"
                                variant="h4"
                                sx={{ m: 0 }}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signup.success.title' }) }}
                                gutterBottom
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                component="p"
                                variant="body1"
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signup.success.description' }) }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Link to={`${userViewModel.locale && userViewModel.locale !== 'de' ? "/" + userViewModel.locale : ''}${NonAuthRoutes.signin}`} className={st.linkFull} >
                                <Button variant="contained"
                               fullWidth color="secondary" onClick={hideSuccessSignUp} autoFocus>
                                    {intl.formatMessage({ id: 'auth.signup.success.button' })}
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={false} md={6} className={st.media}>
                <svg className={st.media_circle_1} viewBox="0 0 577 577" xmlns="http://www.w3.org/2000/svg">
                    <circle opacity=".32" cx="288.293" cy="288.294" r="287.5" transform="rotate(45 288.293 288.294)" stroke="#fff" fill="none" />
                </svg>
                <svg className={st.media_circle_2} viewBox="0 0 468 545" xmlns="http://www.w3.org/2000/svg">
                    <circle opacity="0.32" cx="180" cy="288.294" r="287.5" transform="rotate(45 180 288.294)" stroke="#fff" fill="none" />
                </svg>
                <div className={st.media__picture}>
                    <img src={`/images/Auth/signup.png`} alt="sign up" />
                </div>
            </Grid>
        </Grid>
    );
};

export default observer(SignUp);

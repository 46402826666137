import LoadMedicalCredentialsUseCase from "../../../domain/interactors/medical_credentials/LoadMedicalCredentialsUseCase";
import { action, computed, makeAutoObservable, toJS } from "mobx";
import {MedicalCredential} from "../../../domain/entity/medical_credentials/structures/MedicalCredentialStructure";

export default class MedicalCredentialsViewModel {
    private loadMedicalCredentialsUseCase: LoadMedicalCredentialsUseCase;
    public medicalCredentials: Array<MedicalCredential>;

    constructor(loadMedicalCredentialsUseCase: LoadMedicalCredentialsUseCase) {
        makeAutoObservable(this);
        this.loadMedicalCredentialsUseCase = loadMedicalCredentialsUseCase;
        this.medicalCredentials = [];
    }

    getMedicalCredentials = () => {
        this.loadMedicalCredentialsUseCase.getMedicalCredentials()
            .then(action((medicalCredentials) => {
                // @ts-ignore
                this.medicalCredentials = medicalCredentials.items;
            }));
    }

    getMedicalCredentialsPromise = () => {
        return this.loadMedicalCredentialsUseCase.getMedicalCredentials();
    }

    @computed get medicalCredentialsOptions(): Array<MedicalCredential> {
        return toJS(this.medicalCredentials);
    }
}

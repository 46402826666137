import { action, computed, makeAutoObservable } from "mobx";
import DoctorProfileStructure, { DoctorProfileOptions } from "./structures/DoctorProfileStructure";

export default class DoctorProfileModel implements DoctorProfileStructure {
    public options: DoctorProfileOptions;

    constructor(options?: DoctorProfileOptions) {
        makeAutoObservable(this);
        this.options = options || this.defaultOptions;
    }

    get defaultOptions(): DoctorProfileOptions {
        return {
            profileId: "",
            firstName: "",
            lastName: "",
            gender: "",
            birthDate: "",
            avatar: "",
            avatarId: "",
            phoneNumber: "",
            workEmail: "",
            address: "",
            about: "",
            enablePrivateInsurance: false,
            scheduleSettings: {},
            insurances: [],
            specialities: [],
            educations: [],
            experiences: [],
            certifications: [],
            awards: [],
            publications: [],
            spokenLanguages: [],
        };
    }

    @computed get asJson(): DoctorProfileOptions {
        return this.options;
    }

    @action public updateFromJson(doctorProfileJson: DoctorProfileOptions) {
        this.options = doctorProfileJson;
    }

    @action resetOptions = () => {
        this.options = this.defaultOptions;
    };
}

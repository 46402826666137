import { action, computed, makeAutoObservable, toJS } from "mobx";
import UserStructure from "./structures/UserStructure";

export default class UserModel {
    public id: string;
    public email: string;
    public active: boolean;
    public profileId: string;
    public defaultLocale: string;
    public referralCode: string;
    public balance: number;
    public referralsCount: number;
    public referralsDoctorCount: number;
    public referralsPatientCount: number;
    public fullOptions: Array<any>;

    public constructor() {
        makeAutoObservable(this);
        this.id = "";
        this.email = "";
        this.active = false;
        this.profileId = "";
        this.defaultLocale = "";
        this.referralCode = "";
        this.balance = 0;
        this.referralsCount = 0;
        this.referralsDoctorCount = 0;
        this.referralsPatientCount = 0;
        this.fullOptions = [];
    }

    @action update = (user: UserStructure) => {
        // @ts-ignore
        this.fullOptions = user;
        this.email = user.email;
        this.active = user.active;
        this.id = user.id;
        this.profileId = user.profileId;
        this.defaultLocale = user.defaultLocale;
        this.referralCode = user.referralCode;
        this.balance = user.balance;
        this.referralsCount = user.referralsCount;
        this.referralsDoctorCount = user.referralsDoctorCount;
        this.referralsPatientCount = user.referralsPatientCount;
    };

    @computed get get(): UserStructure {
        return toJS(this);
    }
}

import UserRepository from "../../repository/user/UserRepository";
import {SubmissionError} from "../CustomErrors";

export default class ManageAccountUseCase {
    private userRepository: UserRepository;

    constructor(userRepository: UserRepository) {
        this.userRepository = userRepository;
    }

    getUserAccount = async (id: string) => {
        return this.userRepository
            .getUserAccount(id)
            .then((response) => {
                return response;
            })
            .catch((errors) => {
                return Promise.reject(new SubmissionError(errors, errors));
            });
    }

    editUserAccount = (id: string, userAccount: any) => {
        return this.userRepository
            .editUserAccount(id, userAccount)
            .then((response) => {
                console.log(response);
            })
            .catch((errors) => {
                return Promise.reject(new SubmissionError(errors, errors));
            });
    }
}

import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { NonAuthRoutes } from "../../../routes/routes";
import { Link } from "react-router-dom";

import classNames from "classnames";
import 'swiper/swiper.min.css';
import st from './home.module.scss';

import {ReactComponent as ArrowIcon } from "../../assets/images/icons/arrow.svg";
import {ReactComponent as LogoContour } from "../../assets/images/logo-contour.svg";

import { SpecializationPreview } from "../../components/SpecializationPreview";
import { SimplePopup } from "../../components/SimplePopup";
import { Button } from "@mui/material";
import { NewsPreview } from "../../components/NewsPreview";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close.svg";
import LandingLayout from "../../layouts/landing/LandingLayout";
import { Plus } from "../../components/Plus";
import { Planet } from "../../components/Planet";
import { useViewModels } from "presentation/view-model/ViewModelProvider";
import { observer } from "mobx-react";
import { HeaderBar } from "../../components/AppBar";
import { ChooseDoctor } from "../../components/ChooseDoctor";
import { DoctorsSearch } from "../../components/DoctorsSearch";
import { SpecialtiesList } from "../../components/SpecialtiesList";

interface topSearchesSpecialization {
    title: string;
    img: string;
}

interface Question {
    title: string;
    answer: string;
}


const Home: React.FC = () => {
    const [popupTitle, setPopupTitle] = useState('');
    const [popupContent, setPopupContent] = useState('');
    const [popupFaqIsVisible, setPopupFaqIsVisible] = useState(false);
    const [popupFaqIsOpen, setPopupFaqIsOpen] = useState(false);
    const [popupFaqActive, setPopupFaqActive] = useState<HTMLElement | null>(null);
    const intl = useIntl();
    const [letterContentHeight, setLetterContentHeight] = useState('0');
    const specialtiesContent = useRef<HTMLDivElement>(null);
    const {
        lakeViewModel,
        faqViewModel,
        newsViewModel,
        uIViewModel,
        uIViewModel: {mobileIs},
        userViewModel: {locale},
    } = useViewModels();
    const topSearches: Array<topSearchesSpecialization> = [
        {"title": intl.formatMessage({id: 'topSearches1'}), "img": "/images/top-searches/1.jpg"},
        {"title": intl.formatMessage({id: 'topSearches2'}), "img": "/images/top-searches/2.jpg"},
        {"title": intl.formatMessage({id: 'topSearches3'}), "img": "/images/top-searches/3.jpg"},
        {"title": intl.formatMessage({id: 'topSearches4'}), "img": "/images/top-searches/4.jpg"},
    ];

    function showAnswer(e: React.SyntheticEvent, content: Question, index: number) {
        const currentTarget: any = e.currentTarget;
        const currentTargetCoordinates = currentTarget.getBoundingClientRect();
        const clone = currentTarget.cloneNode(false);

        const ratioTop = (currentTargetCoordinates.top + currentTargetCoordinates.height) / currentTargetCoordinates.height;
        const ratioBottom = (window.innerHeight - currentTargetCoordinates.bottom + currentTargetCoordinates.height) / currentTargetCoordinates.height;
        const ratioLeft = (currentTargetCoordinates.left + currentTargetCoordinates.width) / currentTargetCoordinates.width;
        const ratioRight = (window.innerWidth - currentTargetCoordinates.right  + currentTargetCoordinates.width) / currentTargetCoordinates.width;

        const expansionCoefficient = Math.max(ratioTop, ratioBottom, ratioLeft, ratioRight) * 2.5;

        clone.style.cssText = `
            position: fixed;
            background-color: #00B9FF;
            width: ${currentTargetCoordinates.width}px;
            height: ${currentTargetCoordinates.height}px;
            left: ${currentTargetCoordinates.left}px;
            top: ${currentTargetCoordinates.top}px;
            transition: 0.2s;
            opacity: 0;
            z-index: 1101;`;

        document.body.appendChild(clone);

        setTimeout(() => {
            clone.style.opacity = 1;
        }, 50);

        setTimeout(() => {
            clone.style.transition = '0.6s ease-in';
            clone.style.transform= `scale(${expansionCoefficient})`;
        }, 150);

        setTimeout(() => {
            setPopupTitle(content.title);
            setPopupContent(content.answer);
            setPopupFaqIsOpen(true);
            setPopupFaqIsVisible(true);
            clone.style.visibility = 'hidden';
            setPopupFaqActive(clone);
            document.body.style.overflow = 'hidden';
        }, 650);
    }

    useEffect(() => {
        lakeViewModel.loadLetters();
        faqViewModel.loadPopularQustions();
    }, [lakeViewModel, faqViewModel]);

    useEffect(() => {
        newsViewModel.updateSearchParams({ page: 1, perPage: 4 });
        newsViewModel.loadNews();
    }, [newsViewModel]);

    useEffect(() => {
        setLetterContentHeight("0px");

        if (lakeViewModel.activeLetter) {
            lakeViewModel.loadDoctorSpecialtiesByLetter(lakeViewModel.activeLetter, locale);
        }

        const activeSpecialties = setInterval(() => {
            if (lakeViewModel.activeSpecialties) {
                clearInterval(activeSpecialties);

                setTimeout(() => {
                    if (specialtiesContent?.current) {
                        setLetterContentHeight(`${specialtiesContent.current.scrollHeight}px`);
                    }
                }, 300);
            }
        }, 50);

        setTimeout(() => { clearInterval(activeSpecialties);}, 5000);
    }, [lakeViewModel.activeLetter, lakeViewModel, mobileIs, locale]);

    return (
        <LandingLayout hasNavBar={false}>
            <HeaderBar bgColor='#9146FF'
                    staticBarIsTransparent
                    className={classNames(st.home__appbar_logo, st.home__appbar_border)}
                    accentBar />

            <section className={st.home__header}>
                <div className={`${st.header__main } ${uIViewModel.mobileDoctorsSearchIsOpen ? st.header__main_active : '' }`}>
                    <div className={st.header__main_container}>
                        <div className={st.header__search_container}>
                            <DoctorsSearch isHomePage={true} />
                        </div>

                        <div className={st.header__main_title}
                             dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.header.title" }) }}></div>
                    </div>
                </div>

                <div className={st.header__features}>
                    <div className={st.header__features_container}>
                        <Link to={NonAuthRoutes.doctors} className={st.header__feature}>
                            <div className={st.header__feature_photo}>
                                <img src="/images/best-doctors.jpg" alt="Feature" />
                            </div>

                            <div className={st.header__feature_title}>
                                <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.feature.doctors" }) }}></div>
                                <div className={st.header__feature_plus}></div>
                            </div>
                        </Link>

                        <Link to={NonAuthRoutes.forDoctors} className={st.header__feature}>
                            <div className={st.header__feature_photo}>
                                <img src="/images/reviews.jpg" alt="Feature" />
                            </div>

                            <div className={st.header__feature_title}>
                                <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.feature.reviews" }) }}></div>
                                <div className={st.header__feature_plus}></div>
                            </div>
                        </Link>
                    </div>
                </div>
            </section>

            <section className={st.home__top_search}>
                <div className={st.top_search__container}>
                    <div className={st.top_search__side}>
                        <div className={st.top_search__logo_contour}><LogoContour/></div>
                        <div className={st.top_search__title}
                             dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.topSearch.title" }) }}></div>
                        {/*<div className={st.top_search__desc}
                             dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.topSearch.description" }) }}></div>*/}
                    </div>

                    <div className={st.top_search__main}>
                        {topSearches.map((item, index) => (
                            <SpecializationPreview
                                title={item.title}
                                img={item.img}
                                key={index}>
                            </SpecializationPreview>
                        ))}
                    </div>
                </div>
            </section>

            <section className={st.home__book_appointment}>
                <div className={st.book_appointment__container}>
                    <div className={st.book_appointment__picture}>
                        <img src="/images/doctor-appointment.jpg" alt="Book appointment" />
                    </div>

                    <div className={st.book_appointment__planet}>
                        <Planet
                            icon={<ArrowIcon />}
                            bgColor="#9146FF"
                            contentColor="color_light"
                        />
                    </div>

                    <div className={st.book_appointment__main}>
                        <div className={st.book_appointment__title}
                             dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.bookAppointment.title" }) }}></div>

                        {/*<div className={st.book_appointment__description}
                             dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.bookAppointment.description" }) }}></div>*/}

                        <div className={st.book_appointment__button}>
                            <Link to={`${locale && locale !== 'de' ? "/" + locale : ''}${NonAuthRoutes.doctors}`}>
                                <Button
                                    component="div"
                                    fullWidth
                                    size="large"
                                    color="secondary"
                                    sx={{ width: "100%" }}
                                    variant="contained">
                                    <span style={{ color: "#fff" }}>{intl.formatMessage({ id: "home.bookAppointment.more" })}</span>
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <ChooseDoctor />

            <section className={st.home__faq}>
                <SimplePopup
                    popupIsOpen={popupFaqIsOpen}
                    popupIsVisible={popupFaqIsVisible}
                >
                    <div className={st.faq_popup__container}>
                        <div className={st.faq_popup__close}>
                            <CloseIcon onClick={() => {
                                setPopupFaqIsVisible(false);

                                setTimeout(() => {
                                    setPopupFaqIsOpen(false);
                                    document.body.style.overflow = 'visible';
                                }, 200);

                                if (popupFaqActive !== null) {
                                    popupFaqActive.style.visibility = 'visible';
                                    popupFaqActive.style.transform = 'scale(1)';

                                    setTimeout(() => {
                                        popupFaqActive.remove();
                                    }, 600);
                                    setPopupFaqActive(null);
                                };
                            }} />
                        </div>

                        <div className={st.faq_popup__title}>{popupTitle}</div>
                        <div className={st.faq_popup__content}>{popupContent}</div>
                    </div>
                </SimplePopup>

                <div className={st.faq__container}>
                    <div className={st.faq__text}>
                        <div className={st.faq__title}
                             dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.faq.title" }) }}></div>

                        {/*<div className={st.faq__description}>
                            <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.faq.description" }) }}></div>
                        </div>*/}
                    </div>

                    <div className={st.faq__questions}>
                        {faqViewModel.faqModel.popularQuestions.map((item, index) => (
                            <div key={index} className={st.faq__question}>
                                <Planet
                                    onClickFaq={(e: React.SyntheticEvent) => showAnswer(e, item, index)}
                                    title={item.title}
                                    bgColor="#00B9FF"
                                    alignContent="center"
                                    contentSize="lg"
                                    setWidth={true}
                                    gravity
                                />
                            </div>
                        ))}

                        <Link to={NonAuthRoutes.faq} className={st.faq__question_all}>
                            <Planet
                                title={<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.faq.viewAll" }) }}></span>}
                                icon={<ArrowIcon />}
                                contentColor="#00B9FF"
                                bgColor="#09299C"
                                border="1px solid #00B9FF"
                                gravity
                            />
                        </Link>
                    </div>
                </div>
            </section>

            <section className={st.home__news}>
                <div className={st.news__container}>
                    <div className={st.news__side}>
                        <div className={st.news__header} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.news.title" }) }}></div>
                    </div>

                    <Link to={NonAuthRoutes.news} className={st.news__view_all}>
                        <Button
                            className="btn"
                            // sx={ { borderRadius: 40, width: 253 } }
                            size="large"
                            color="secondary"
                            variant="contained">
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.news.viewAll" }) }}></span>
                        </Button>
                    </Link>

                    <div className={st.news__main}>
                        {newsViewModel.foundNews.map((post) => (
                            <Link to={`${NonAuthRoutes.news}/${post.id}`} key={post.id}>
                                <NewsPreview date={post.date} title={post.title}></NewsPreview>
                            </Link>
                        ))}
                    </div>
                </div>
            </section>

            <section className={st.home__for_doctors}>
                <div className={st.for_doctors__container}>
                    <div className={st.for_doctors__description_part}>
                        <div className={st.for_doctors__label}
                             dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.forDoctors.label" }) }} />

                        <div className={classNames(st.for_doctors__title, (locale === 'de' ? st.for_doctors__title_de : ''))}
                             dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.forDoctors.title" }) }} />

                        {/*<div className={st.for_doctors__desc}
                             dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.forDoctors.description" }) }} />*/}

                    </div>

                    <div className={st.for_doctors__decor_part}>
                        <div className={st.for_doctors__img}>
                            <img src="/images/for-doctors.jpg" alt="For doctors" />
                        </div>

                        <Link to={`${locale && locale !== 'de' ? "/" + locale : ''}${NonAuthRoutes.signup}?type=doctor`} className={st.for_doctors__planet}>
                            <Planet
                                title={<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.forDoctors.join" }) }}></span>}
                                icon={<div><ArrowIcon /></div>}
                                bgColor="#00B9FF"
                                contentColor="color_accent_dark"
                                gravity
                            />
                        </Link>
                    </div>
                </div>
            </section>

            <section className={st.home__features}>
                <div className={st.features__container}>
                    <div className={st.features__description_part}>

                        <div className={st.features__title}
                             dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.features.title" }) }} />

                        {/*<div className={st.features__desc}
                             dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.features.description" }) }} />*/}

                    </div>

                    <div className={st.features__section_part}>
                        <Link to={NonAuthRoutes.home}>
                            <Planet
                                title={<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.features.time" }) }}></span>}
                                icon={<Plus />}
                                bgColor="#09299C"
                                gravity
                            />
                        </Link>

                        <Link to={NonAuthRoutes.home}>
                            <Planet
                                title={<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.features.profesionals" }) }}></span>}
                                icon={<Plus />}
                                bgColor="#00B9FF"
                                gravity
                            />
                        </Link>

                        <Link to={NonAuthRoutes.home}>
                            <Planet
                                title={<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.features.reviews" }) }}></span>}
                                icon={<Plus />}
                                bgColor="#9146FF"
                                gravity
                            />
                        </Link>

                        <Link to={NonAuthRoutes.about}>
                            <Planet
                                title={<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.features.more" }) }}></span>}
                                icon={<ArrowIcon />}
                                contentColor="#00B9FF"
                                border="1px solid #00B9FF"
                                bgColor="white"
                                gravity
                            />
                        </Link>
                    </div>
                </div>
            </section>

            <section className={st.home__search_specialties}>
                <div className={st.search_specialties__container}>
                    <div className={st.search_specialties__title}
                         dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.searchSpecialties.title" }) }}></div>

                    <div className={st.search_specialties__alphabet}>
                        {lakeViewModel.availableLetters.map((value, index) => {
                            return (<button className={`${st.search_specialties__letter}
                                        ${value === lakeViewModel.activeLetter ? st.search_specialties__letter_active : ''}`}
                                        onClick={() => setTimeout(() => lakeViewModel.updateActiveLetter(value), 100)}
                                        key={index}
                                    >
                                        <div>{value}</div>
                                    </button>);
                        })}
                        <div className={st.search_specialties__letter_all}>
                            <Link to={NonAuthRoutes.specialities}>{intl.formatMessage({ id: "home.searchSpecialties.all" })}</Link>
                        </div>
                    </div>

                    <div className={st.search_specialties__alphabet_content}>
                        <div className={st.search_specialties__section}>
                            <div className={st.search_specialties__letter_title}>{lakeViewModel.activeLetter}</div>

                            <div ref={specialtiesContent} className={classNames(st.search_specialties__letter_content, 'letter-content')} style={{ minHeight: letterContentHeight, maxHeight: letterContentHeight }}>
                                <SpecialtiesList data={lakeViewModel?.activeSpecialties} limit={10} className={st.search_specialties__specialization} />
                            </div>
                        </div>
                    </div>

                    <div className={st.search_specialties__all_specialties}>
                        <Link to={NonAuthRoutes.specialities}
                              dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.searchSpecialties.all" }) }}></Link>
                    </div>
                </div>
            </section>

        </LandingLayout>
    );
};

export default observer(Home);

import React, { useEffect } from "react";
import { useIntl } from "react-intl";

import { NonAuthRoutes } from "../../../routes/routes";
import { Link } from "react-router-dom";
import Swiper from "swiper";
import classNames from "classnames";
import 'swiper/swiper.min.css';

import st from './faq.module.scss';
import LandingLayout from "../../layouts/landing/LandingLayout";
import { Plus } from "../../components/Plus";
import { Planet } from "../../components/Planet";
import { useViewModels } from "presentation/view-model/ViewModelProvider";
import { observer } from "mobx-react";
import HeroHeader from "../../components/HeroHeader";
import { AskQuestion } from "../../components/AskQuestion";

const Faq: React.FC = () => {
    const intl = useIntl();
    const { faqViewModel } = useViewModels();

    const categoryBgColors = ['#09299C', '#00B9FF', '#9146FF', 'white'];

    useEffect(() => {
        faqViewModel.loadCategories();
    }, [faqViewModel]);

    useEffect(() => {
        new Swiper(".swiper-category", {
            breakpoints: {
                320: {
                    slidesPerView: 1.3,
                    spaceBetween: 16,
                },
                390: {
                    slidesPerView: 1.4,
                    spaceBetween: 18,
                },
                450: {
                    slidesPerView: 1.8,
                    spaceBetween: 20,
                },
                560: {
                    slidesPerView: 2.2,
                    spaceBetween: 22,
                },
                720: {
                    slidesPerView: 2.8,
                    spaceBetween: 24,
                },
                920: {
                    slidesPerView: 3.8,
                    spaceBetween: 24,
                },
                1440: {
                    slidesPerView: 4.2,
                    spaceBetween: 24,
                },

            },
        });
    });

    return (
        <LandingLayout>
            <HeroHeader
                caption="faq.title"
                desc="faq.description"
                className={st.header}
            />

            <section className={st.faq__topics}>
                <div className={st.topics__container}>
                    <div className={st.topics__title}
                         dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "faq.topics" }) }}></div>

                    <div className={st.topics__planets}>
                        <div className={classNames('swiper-category swiper', st.topics__swiper_container)}>
                            <div className="swiper-wrapper">
                                {faqViewModel.faqModel.categories?.map((item, index) => (
                                    <Link to={`${NonAuthRoutes.faq}/${item.id}`} className={classNames('swiper-slide', st.topics__category)} key={index}>
                                        <Planet
                                            title={item.name.toLowerCase()}
                                            bgColor={categoryBgColors[index % 4]}
                                            icon={<Plus className={index % 4 === 3 ? st.custom_plus : ''} />}
                                            setWidth={true}
                                            border={index % 4 === 3 ? "1px solid #00B9FF" : 'none'}
                                            contentColor={index % 4 === 3 ? "#00B9FF" : ''}
                                            gravity
                                        />
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <AskQuestion />

        </LandingLayout>
    );
};

export default observer(Faq);

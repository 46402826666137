import AppointmentRepository from "domain/repository/appointment/AppointmentRepository";
import { Appointment } from "../../entity/appointment/structures/AppointmentStructure";
import { SubmissionError } from "../CustomErrors";

export default class ManageAppointmentUseCase {
    private appointmentRepository: AppointmentRepository;

    constructor(appointmentRepository: AppointmentRepository) {
        this.appointmentRepository = appointmentRepository;
    }

    getUpcomingAppointments = () => {
        return this.appointmentRepository
            .getUpcomingAppointments()
            .then((appointments: [Appointment[], []]) => {
                return appointments;
            })
    };

    getPastAppointments = () => {
        return this.appointmentRepository
            .getPastAppointments()
            .then((appointments: [Appointment[], []]) => {
                return appointments;
            })
    };

    postBookingAppointment = (doctorIri: string, date: string, time: string, reason: string): Promise<void> => {
        return this.appointmentRepository
            .postBookingAppointment(doctorIri, date, time, reason)
            .then((object) => {
                return Promise.resolve(object);
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error.validationErrors, (error?.data?.message ? error.data.message : (error?.message ? error.message : 'Unknown error'))));
            });
    }

    postBookingAppointmentByCall = (doctorIri: string, phoneNumber: string, reason: string): Promise<void> => {
        return this.appointmentRepository
            .postBookingAppointmentByCall(doctorIri, phoneNumber, reason)
            .then((object) => {
                return Promise.resolve(object);
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error.validationErrors, (error?.data?.message ? error.data.message : (error?.message ? error.message : 'Unknown error'))));
            });
    }

    putStatusChangeAppointment = (appointmentId: string, urlStatus: string, status: string, cancellationReason: string|undefined): Promise<void> => {
        return this.appointmentRepository
            .putStatusChangeAppointment(appointmentId, urlStatus, status, cancellationReason)
            .then((object) => {
                return Promise.resolve(object);
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error.validationErrors, (error?.data?.message ? error.data.message : (error?.message ? error.message : 'Unknown error'))));
            });
    };
}

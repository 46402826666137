import AuthRepository from "domain/repository/auth/AuthRepository";

import { SubmissionError } from "../CustomErrors";

export default class NewPasswordUseCase {
    private authRepository: AuthRepository;

    constructor(authRepository: AuthRepository) {
        this.authRepository = authRepository;
    }

    start = (newPassword: string, token: string | null) => {
        return this.authRepository
            .newPassword(newPassword, token)
            .then((response) => {
                console.log("password updated", { response });
            })
            .catch((errors) => {
                return Promise.reject(new SubmissionError(errors, errors));
            });
    };
}

import { createTheme } from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        neutral: Palette['primary'];
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        neutral?: PaletteOptions['primary'];
    }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        neutral: true;
    }
}

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: "rgba(9, 41, 156, 1)",
            dark: "rgba(5, 20, 75)",
            light: "rgba(5, 20, 75, 0.64)",
            light1: "rgba(5, 20, 75, 0.56)",
            light2: "rgba(225, 227, 233, 1)",
            contrastText: "rgba(255, 255, 255, 1)",
            error: "#F44336",
        },
        secondary: {
            main: "rgba(0, 185, 255, 1)",
            light2: "rgba(0, 185, 255, 0.08)",
        },
        action: {
            disabled: "rgba(175, 180, 197, 1)",
        },
        error: {
            main: "rgba(244, 67, 54, 1)",
        },
        neutral: {
            main: 'rgba(255, 255, 255, 1)',
            light: "rgba(255, 255, 255, 0.4)",
            dark: 'rgba(100 116 139, 1)',
            contrastText: 'rgba(225, 227, 233, 1)',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 668,
            md: 1040,
            lg: 1440,
            xl: 1920,
        },
    },
});

const primaryMuiTheme = createTheme({
    palette: {
        neutral: {
            main: defaultTheme.palette.neutral.main,
            light: defaultTheme.palette.neutral.light,
            dark: defaultTheme.palette.neutral.dark,
            contrastText: defaultTheme.palette.neutral.contrastText,
        },
    },
    typography: {
        fontFamily: "Codec Pro",

        h1: {
            fontWeight: "700",
            fontSize: "64px",
            lineHeight: "60px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "48px",
                lineHeight: "44px",
            },
        },
        h2: {
            fontWeight: "700",
            fontSize: "56px",
            lineHeight: "52px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "40px",
                lineHeight: "36px",
            },
        },
        h3: {
            fontWeight: "700",
            fontSize: "48px",
            lineHeight: "44px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "32px",
                lineHeight: "28px",
            },
        },
        h4: {
            fontWeight: "700",
            fontSize: "40px",
            lineHeight: "36px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "24px",
                lineHeight: "20px",
            },
        },
        h5: {
            fontWeight: "700",
            fontSize: "32px",
            lineHeight: "28px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "20px",
                lineHeight: "16px",
            },
        },
        h6: {
            fontWeight: "700",
            fontSize: "24px",
            lineHeight: "20px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "18px",
                lineHeight: "14px",
            },
        },
        t2: {
            fontWeight: "700" as "bold",
            fontSize: "20px",
            lineHeight: "20px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "18px",
                lineHeight: "18px",
            },
        },
        t2news: {
            fontWeight: "300" as "bold",
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "13px",
                lineHeight: "14px",
            },
        },
        t3: {
            fontWeight: "700" as "bold",
            fontSize: "18px",
            lineHeight: "18px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "16px",
                lineHeight: "16px",
            },
        },
        t3s: {
            fontWeight: "700" as "bold",
            fontSize: "16px",
            lineHeight: "16px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "14px",
                lineHeight: "14px",
            },
        },
        t4: {
            fontWeight: "700" as "bold",
            fontSize: "14px",
            lineHeight: "14px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "14px",
                lineHeight: "14px",
            },
        },
        d1: {
            fontWeight: "300" as "bold",
            fontSize: "18px",
            lineHeight: "18px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "14px",
                lineHeight: "14px",
            },
        },
        d1s: {
            fontWeight: "300" as "bold",
            fontSize: "16px",
            lineHeight: "16px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "14px",
                lineHeight: "14px",
            },
        },
        d2: {
            fontWeight: "300" as "bold",
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "16px",
                lineHeight: "14px",
            },
        },
        d3: {
            fontWeight: "300" as "bold",
            fontSize: "13px",
            lineHeight: "13px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "16px",
                lineHeight: "14px",
            },
        },
        p5: {
            display: "block",
            fontWeight: "300" as "bold",
            fontSize: "18px",
            lineHeight: "26px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "16px",
                lineHeight: "18px",
            },
        },
        button: { fontWeight: "600" as "bold", fontSize: "16px", lineHeight: "16px", letterSpacing: "-0.02em", textTransform: "none" },
        inputText: { fontWeight: "700" as "bold", fontSize: "18px", lineHeight: "20px", letterSpacing: "-0.02em" },
        helpertext: { fontWeight: "300" as "bold", fontSize: "12px", lineHeight: "20px", letterSpacing: "-0.02em" },
        label: { fontWeight: "700" as "bold", fontSize: "14px", lineHeight: "14px", letterSpacing: "-0.02em" },
        staticLabel: { fontWeight: "700" as "bold", fontSize: "18px", lineHeight: "20px", letterSpacing: "-0.02em" },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    boxShadow: "none",
                    backgroundColor: "transparent",
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "40px",
                    width: "253px",
                    height: "64px",
                    fontSize: "16px",
                    fontFamily: "$font-family-base",
                    fontWeight: "600" as "bold", // тут 600, но mui не позволяет тип
                    lineHeight: "1em",
                    letterSpacing: "-0.02em",

                    color: "#fff",
                    textTransform: "none",
                    boxShadow: "none",
                    transition: ".2s ease",
                    "& svg, & path": {
                        transition: ".2s ease",
                    },
                    ":disabled": {
                        // display: 'none'
                        background: "transparent",
                        borderColor: "#AFB4C5",
                        color: "#AFB4C5",
                        cursor: "not-allowed",
                    },
                    ":active": {
                        transform: "scale(0.94)",
                    },
                    [defaultTheme.breakpoints.down("sm")]: {
                        width: "100%",
                        height: "48px",
                    },
                },
            },
            variants: [
                {
                    props: { variant: "outlined", color: "primary" },
                    style: {
                        textTransform: "none",
                        backgroundColor: "transparent",
                        border: `1px solid ${defaultTheme.palette.primary.main}`,
                        color: defaultTheme.palette.primary.main,
                        ":hover": {
                            border: `1px solid ${defaultTheme.palette.primary.main}`,
                            backgroundColor: defaultTheme.palette.primary.main,
                            boxShadow: "none",
                            color: "white",
                        },
                    },
                },
                {
                    props: { variant: "outlined", color: "secondary" },
                    style: {
                        textTransform: "none",
                        backgroundColor: "transparent",
                        border: `1px solid ${defaultTheme.palette.secondary.main}`,
                        color: defaultTheme.palette.secondary.main,
                        ":hover": {
                            border: `1px solid ${defaultTheme.palette.secondary.main}`,
                            backgroundColor: defaultTheme.palette.secondary.main,
                            boxShadow: "none",
                            color: "white",
                        },
                    },
                },
                {
                    props: { variant: "contained", color: "primary" },
                    style: {
                        textTransform: "none",
                        backgroundColor: defaultTheme.palette.primary.main,
                        border: `1px solid ${defaultTheme.palette.primary.main}`,
                        ":hover": {
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            color: defaultTheme.palette.primary.main,
                        },
                    },
                },
                {
                    props: { variant: "contained", color: "secondary" },
                    style: {
                        textTransform: "none",
                        backgroundColor: defaultTheme.palette.secondary.main,
                        border: `1px solid ${defaultTheme.palette.secondary.main}`,
                        ":hover": {
                            backgroundColor: defaultTheme.palette.primary.main,
                            borderColor: defaultTheme.palette.primary.main,
                            boxShadow: "none",
                            color: "#fff",
                        },
                    },
                },
                {
                    props: { variant: "contained", color: "error" },
                    style: {
                        textTransform: "none",
                        backgroundColor: defaultTheme.palette.primary.error,
                        border: `1px solid ${defaultTheme.palette.primary.error}`,
                        ":hover": {
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            color: defaultTheme.palette.primary.error,
                        },
                    },
                },
                {
                    props: { variant: "circle", color: "primary" },
                    style: {
                        textTransform: "none",
                        backgroundColor: defaultTheme.palette.primary.main,
                        border: `1px solid ${defaultTheme.palette.primary.main}`,
                        ":hover": {
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            color: defaultTheme.palette.primary.main,
                        },
                    },
                },
                {
                    props: { variant: "circle", color: "secondary" },
                    style: {
                        textTransform: "none",
                        backgroundColor: defaultTheme.palette.secondary.main,
                        border: `1px solid ${defaultTheme.palette.secondary.main}`,
                        ":hover": {
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            color: defaultTheme.palette.secondary.main,
                        },
                    },
                },

                // types
                {
                    props: { variant: "circle", size: "large" },
                    style: {
                        padding: "0px !important",
                        width: "80px !important",
                        height: "80px !important",
                    },
                },
                {
                    props: { variant: "circle", size: "medium" },
                    style: {
                        padding: "0 !important",
                        width: "64px !important",
                        height: "64px !important",
                    },
                },
                {
                    props: { variant: "circle", size: "small" },
                    style: {
                        padding: "0 !important",
                        width: "32px !important",
                        height: "32px !important",
                    },
                },

                // sizes
                {
                    props: { size: "large" },
                    style: {
                        padding: "24px 56px",
                        height: "64px",
                    },
                },
                {
                    props: { size: "medium" },
                    style: {
                        padding: "20px 40px",
                        height: "56px",
                    },
                },
                {
                    props: { size: "small" },
                    style: {
                        padding: "16px 40px",
                        height: "48px",
                    },
                },
            ],
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    "& input": {
                        height: "22px",
                    },
                    "& input::placeholder": {
                        paddingBottom: "2px"
                    },
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "& input": {
                        height: "22px",
                    },
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    lineHeight: "unset",
                    "&.MuiInputLabel-standard.MuiInputLabel-shrink": {
                        transform: "translate(0, -1.5px) scale(0.8)",
                    },
                    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
                        transform: "translate(14px, -9px) scale(0.7)",
                    },
                    "&.MuiInputLabel-filled.MuiInputLabel-shrink": {
                        transform: "translate(12px, 7px) scale(0.8)",
                    },
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    textAlign: "center",
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& input, label, textarea": {
                        fontWeight: "700",
                        fontFamily: "Codec Pro",
                        fontSize: "18px",
                        letterSpacing: "-0.02em",
                        color: "#05144b",
                        ":placeholder": {
                            fontSize: "18px",
                            letterSpacing: "-0.02em",
                            color: "#05144b",
                        },
                    },
                    "& input, textarea": {
                        paddingBottom: "14px",
                    },
                    "& textarea": {
                        fontWeight: "300",
                        lineHeight: "1.44em",

                        '@media (max-width: 668px)': {
                            fontSize: "14px",
                        },
                    },
                    "& .MuiInput-underline:before": {
                        borderBottomColor: "#05144b",
                        opacity: ".12",
                    },
                    "& .MuiInput-underline:after": {
                        borderBottomColor: "#05144b",
                    },
                    "& label.MuiFormLabel-filled, label.Mui-focused": {
                        opacity: "0.56",
                        fontSize: "18px",
                        letterSpacing: "-0.02em",
                        color: "#05144B",
                    },
                    "& label.MuiInputLabel-outlined": {
                        fontSize: "18px",
                    },
                    "& .MuiOutlinedInput-root fieldset legend": {
                        fontSize: "13px",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: '#00b9ff',
                    },
                    "& label": {
                        pointerEvents: 'none',
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    "& .MuiInputBase-formControl": {
                        paddingBottom: "3px",
                    },
                    "label": {
                        fontWeight: "700",
                        fontFamily: "Codec Pro",
                        fontSize: "18px",
                        letterSpacing: "-0.02em",
                        color: "#05144b",
                        // opacity: "0.56",
                    },
                    "& .MuiAutocomplete-endAdornment": {
                        top: "calc(50% - 21px)",
                    }
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    padding: "24px 40px",

                    ":hover": {
                        background: defaultTheme.palette.secondary.main,
                        "& .MuiListItemText-primary": {
                            color: "#fff",
                        },
                    },
                    "& .MuiListItemText-primary": {
                        fontFamily: "Codec Pro",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        fontSize: "18px",
                        lineHeight: "18px",
                        background: "transparent",
                        color: defaultTheme.palette.primary.main,
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontFamily: "Codec Pro",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "20px",
                    lineHeight: "20px",
                    color: "rgba(175, 180, 197, 1)",
                    "&.Mui-selected": {
                        color: defaultTheme.palette.secondary.main,
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    marginBottom: "24px",
                    "& .MuiTabs-indicator": {
                        display: "none",
                    },
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: 44,
                    height: 20,
                    padding: 0,
                    display: 'flex',
                    '&:active': {
                        '& .MuiSwitch-thumb': {
                            width: 16,
                            backgroundColor: '#FAFAFA',
                        },
                    },
                    '& .MuiSwitch-switchBase': {
                        padding: 2,
                        '&.Mui-checked': {
                            transform: 'translateX(24px)',
                            '& + .MuiSwitch-thumb': {
                                    backgroundColor: '#FAFAFA',
                            },
                            '& + .MuiSwitch-track': {
                                    opacity: 1,
                                    backgroundColor: defaultTheme.palette.secondary.main,
                            },
                        },
                    },
                    '& .MuiSwitch-thumb': {
                        width: 16,
                        height: 16,
                        borderRadius: '50%',
                        bosShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
                        backgroundColor: '#FAFAFA',
                    },
                    '& .MuiSwitch-track': {
                        borderRadius: 10,
                        opacity: 0.24,
                        backgroundColor: defaultTheme.palette.primary.main,
                        boxSizing: 'border-box',
                    },
                    '& .MuiTouchRipple-root': {
                        display: 'none',
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    padding: "5px 10px",
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                disableRipple: true,
            },

            styleOverrides: {
                root: {
                    "&:hover, &.Mui-focusVisible": {
                        backgroundColor: "transparent",
                    },
                    "& input + *": {
                        position: "relative",
                        border: "1px solid #AFB4C5",
                        borderRadius: 4,
                        width: 20,
                        height: 20,
                    },
                    "& input:not(:checked):hover + *": {
                        borderColor: "#00B9FF",
                    },

                    "& input:checked + *": {
                        backgroundColor: "#00B9FF",
                        borderColor: "#00B9FF",

                        "&:before": {
                            content: "''",
                            position: "absolute",
                            display: "block",
                            left: 3.5,
                            top: 5,
                            width: 12,
                            height: 8,
                            backgroundImage:
                                `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 8' %3E%3Cpath` +
                                " d='M1.91666 4.58337L4.24999 6.91671L10.0833 1.08337'" +
                                ` stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E")`,
                            backgroundRepeat: "no-repeat",
                        },
                    },

                    /*                    "& input:checked:hover + *": {
                                            opacity: 0.8,
                                        },*/
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    "&.Mui-checked": {
                        color: "#00b9ff"
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    "& .MuiCheckbox-root ~ .MuiTypography-root": {
                        alignSelf: "flex-start",
                        lineHeight: "1em",
                        letterSpacing: "-0.02em",
                        transform: "translateY(12px)",
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    "&.Mui-focused:after": {
                        borderBottomColor: "#00b9ff"
                    }
                }
            }
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 668,
            md: 1040,
            lg: 1440,
            xl: 1920,
        },
    },
});

export default primaryMuiTheme;
export { defaultTheme };

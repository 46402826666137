// @ts-nocheck
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useIntl} from "react-intl";
import { TextField, Autocomplete, Typography, Grid, Box, FormControlLabel, Checkbox } from "@mui/material";
import { Field, FormikProvider, useFormik } from "formik";
import st from "./insurance.module.scss";
import {useViewModels} from "presentation/view-model/ViewModelProvider";
import { UserSaveButtons } from "../../../../../components/UserSaveButtons";
import { Spinner } from "../../../../../components/Spinner";
import { statutoryInsurances } from "../../../../../../../types";


const Insurance: React.FC = ({ changeDetector, setTab, congratulationsDetector }) => {
    const intl = useIntl();
    const {
        authViewModel: { isDoctor },
        doctorProfileViewModel: {options: doctorOptions, onSaveProfileButtonClick},
        patientProfileViewModel: { patientProfile, updatePatient },
        insuranceViewModel,
        uIViewModel: { setSuccessAlertContent, setErrorAlertContent, },
    } = useViewModels();
    const [saveAndNext, setSaveAndNext] = useState(false);
    const [insuranceOptions1, setInsuranceOptions1] = useState([]);
    const [insuranceOptions2, setInsuranceOptions2] = useState([]);
    const [spinnerShow, setSpinnerShow] = useState(false);
    const options = isDoctor ? doctorOptions : patientProfile;
    let [selectedValues1Doctors, setSelectedValues1Doctors] = useState(options.insurances.filter(item => statutoryInsurances.find(item2 => item2.id === item.id)));
    let [selectedValues1Patients, setSelectedValues1Patients] = useState(options.insurances.filter(item => new RegExp( insuranceViewModel.types[0], 'i' ).test(item.type)));
    let [selectedValues2, setSelectedValues2] = useState(options.insurances.filter(item => new RegExp( insuranceViewModel.types[1], 'i' ).test(item.type)));

    useEffect(() => {
        let isMounted = true;

        if (!isDoctor) {
            if (insuranceViewModel.insurances.length === 0) {
                insuranceViewModel.getInsurancesPromise()
                    .then(data => {
                        if (isMounted) {
                            insuranceViewModel.insurances = data.items;
                            setInsuranceOptions1(data.items.filter(item => new RegExp( insuranceViewModel.types[0], 'i' ).test(item.type)));
                            setInsuranceOptions2(data.items.filter(item => new RegExp( insuranceViewModel.types[1], 'i' ).test(item.type)));
                        }
                    });
            } else {
                if (isMounted) {
                    setInsuranceOptions1(insuranceViewModel.insurances.filter(item => new RegExp( insuranceViewModel.types[0], 'i' ).test(item.type)));
                    setInsuranceOptions2(insuranceViewModel.insurances.filter(item => new RegExp( insuranceViewModel.types[1], 'i' ).test(item.type)));
                }
            }
        }

        return () => { isMounted = false };
    }, [insuranceViewModel, isDoctor]);

    const input = (name, label, insuranceOptions, selectedValues, setSelectedValues) =>
        <Grid container spacing={4} sx={{mb: 4}}>
            <Grid item xs={12} lg={9}>
                <Autocomplete
                    name={name}
                    multiple
                    className={st.autocomplete_withButtons}
                    filterSelectedOptions
                    getOptionLabel={option => (option?.title ? option.title : "")}
                    isOptionEqualToValue={(option, value) => value.title === "" || option.title === value.title}
                    options={insuranceOptions}
                    value={selectedValues}
                    onChange={(e, newValue) => {
                        if (!newValue.length || (insuranceOptions.findIndex(item => item.title === newValue[newValue.length - 1].title) !== -1)) {
                            changeForm.setFieldValue(name, newValue.map(item => `api/insurances/${item.id}`));
                            setSelectedValues(newValue);
                            changeDetector(true);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            label={label}
                            type="text"
                            variant="standard"
                        />
                    )}
                />
            </Grid>
        </Grid>;

    let initialValues = {
        insurances1: ((isDoctor ? selectedValues1Doctors : selectedValues1Patients).length ? (isDoctor ? selectedValues1Doctors : selectedValues1Patients).map(item => `api/insurances/${item.id}`) : []),
        insurances2: (!isDoctor && selectedValues2.length) ? selectedValues2.map(item => `api/insurances/${item.id}`) : [],
    };

    if (isDoctor) {
        initialValues.enablePrivateInsurance = !!options.enablePrivateInsurance;
    }

    const changeForm = useFormik({
        initialValues: initialValues,

        onSubmit: (values, {setErrors}) => {
            let sendingValues = {insurances: [...values.insurances1, ...values.insurances2]};
            if (isDoctor) {
                sendingValues.enablePrivateInsurance = values.enablePrivateInsurance;
            }

            setSpinnerShow(true);
            setSuccessAlertContent('', true);
            setErrorAlertContent('', true);

            (isDoctor ? onSaveProfileButtonClick : updatePatient)(sendingValues)
                .then((result) => {
                    if (!options.active && result.active) {
                        congratulationsDetector();
                    }

                    if (saveAndNext) {
                        setSaveAndNext(false);
                        setTab('saveAndNext', '', false, true);
                    }
                    changeDetector(false);
                    setSuccessAlertContent(intl.formatMessage({id: "successSave"}));
                    setSpinnerShow(false);
                })
                .catch((error) => {
                    console.log(error);
                    setErrorAlertContent(intl.formatMessage({id: "errorSave"}));
                    setSpinnerShow(false);
                    if (error.errors) {
                        setErrors(error.errors);
                    }
                });
        },
    });

    return (
        <FormikProvider value={changeForm}>
            <Box component="form" noValidate onSubmit={changeForm.handleSubmit} className="form-loader">
                {spinnerShow && <Spinner />}

                <Typography
                    variant="h5"
                    sx={{ mb: 5 }}
                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.profile.doctor.insurance.title" }) }}
                />

                {input('insurances1',
                    intl.formatMessage({ id: "gesetzlichType" }),
                    (isDoctor ? statutoryInsurances : insuranceOptions1),
                    (isDoctor ? selectedValues1Doctors : selectedValues1Patients),
                    (isDoctor ? setSelectedValues1Doctors : setSelectedValues1Patients))
                }

                {isDoctor ?
                    <FormControlLabel
                        control={
                            <Field
                                name='enablePrivateInsurance'
                                children={(fieldProps: any) => (
                                    <Checkbox
                                        {...fieldProps.field}
                                        onChange={(e) => {
                                            changeForm.handleChange(e);
                                            changeDetector(true);
                                        }}
                                        checkedIcon={<span />}
                                        icon={<span />}
                                        checked={changeForm.values.enablePrivateInsurance}
                                    />
                                )}
                            />
                        }
                        label={intl.formatMessage({ id: "privateInsuranceTitle" })}
                    />
                    :
                    input('insurances2', intl.formatMessage({ id: "privatType" }), insuranceOptions2, selectedValues2, setSelectedValues2)
                }

                <UserSaveButtons setSaveAndNext={setSaveAndNext} />
            </Box>

            {/*<pre style={{color: "saddlebrown", overflow: 'hidden', width: '100%'}}>{JSON.stringify(changeForm.values, 0, 2)}</pre>*/}
        </FormikProvider>
    );
};

export default observer(Insurance);

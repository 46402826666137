import axios from "axios";
import AuthRepository, { RegistrationResult, AuthorizationResult } from "domain/repository/auth/AuthRepository";
import { extractRoleFromToken } from "./services";

class AuthApi implements AuthRepository {
    register = (type: string, email: string, password: string, referralCode: string | null): Promise<RegistrationResult> => {
        const data: any = {
            type: type,
            email: email,
            plainPassword: password,
        }

        if (referralCode !== null) {
            data.referralCode = referralCode;
        }

        return axios({
            method: "POST",
            url: "api/users",
            data: data,
        })
            .then((result: any) => {
                return {
                    userType: result["userType"],
                    email: result["email"],
                    plainPassword: result["plainPassword"],
                    facebookId: result["facebookId"],
                    googleId: result["googleId"],
                };
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    };

    login = (email: string, password: string): Promise<AuthorizationResult> => {
        return axios({
            method: "POST",
            url: axios.defaults.baseURL + "auth",
            data: {
                email: email,
                password: password,
            },
        })
            .then((serverResponse: any) => {
                const loginResult = serverResponse.data;
                return {
                    accessToken: loginResult["token"],
                    refreshToken: loginResult["refresh_token"],
                    roles: extractRoleFromToken(loginResult["token"]),
                };
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    };

    getUserAppAccess = () => {
        return axios({
            method: "GET",
            url: "api/user-by-token",
        })
            .then((result: any) => {
                return result.data;
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    };

    getFreshToken = (refreshToken: string): any => {
        return axios({
            method: "POST",
            url: axios.defaults.baseURL + "token/refresh",
            data: {
                refresh_token: refreshToken,
            },
        });
    };

    facebookLogin = (facebookToken: string, userType: string | null, referralCode: string | null): any => {
        const data: any = {
            accessToken: facebookToken,
        };

        if (referralCode !== null) {
            data.referralCode = referralCode;
        }

        if (userType && userType.length > 0) {
            // @ts-ignore
            data.userType = userType;
        }

        return axios({
            method: "POST",
            url: axios.defaults.baseURL + "auth/facebook",
            data: data
        })
            .then((serverResponse: any) => {
                const loginResult = serverResponse.data;
                return {
                    accessToken: loginResult["token"],
                    refreshToken: loginResult["refresh_token"],
                    roles: extractRoleFromToken(loginResult["token"]),
                };
            })
            .catch(({ response }) => {
                return Promise.reject(response);
            });
    };

    googleLogin = (googleToken: string, userType: string | null, referralCode: string | null): any => {
        const data: any = {
            accessToken: googleToken,
        };

        if (referralCode !== null) {
            data.referralCode = referralCode;
        }

        if (userType && userType.length > 0) {
            // @ts-ignore
            data.userType = userType;
        }

        return axios({
            method: "POST",
            url: axios.defaults.baseURL + "auth/google",
            data: data
        })
            .then((serverResponse: any) => {
                const loginResult = serverResponse.data;
                console.log(serverResponse)
                return {
                    accessToken: loginResult["token"],
                    refreshToken: loginResult["refresh_token"],
                    roles: extractRoleFromToken(loginResult["token"]),
                };
            })
            .catch(({ response }) => {
                return Promise.reject(response);
            });
    };

    changePassword = (currentPassword: string, newPassword: string): any => {
        return axios({
            method: "POST",
            url: axios.defaults.baseURL + "api/change-password",
            data: {
                newPassword: newPassword,
                currentPassword: currentPassword,
            },
        }).catch((e) => {
            return Promise.reject(e);
        });
    };

    deleteAccount = (currentPassword: string): any => {
        return axios({
            method: "POST",
            url: axios.defaults.baseURL + "api/delete-account",
            data: {
                currentPassword: currentPassword,
            },
        })
            .then(() => {
                this.logout();
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    };

    forgotPassword(email: string): Promise<any> {
        return axios({
            method: "POST",
            url: axios.defaults.baseURL + "forgot-password/reset-password",
            data: {
                email: email,
            },
        })
            .then(() => {
                console.log('Password forgoted')
            })
            .catch((e) => {
                console.log({ e });
                return Promise.reject(e.response.data.message);
            });
    };

    newPassword(newPassword: string, token: string | null): Promise<any> {
        return axios({
            method: "POST",
            url: axios.defaults.baseURL + "forgot-password/change-password",
            data: {
                newPassword: newPassword,
                token: token,
            },
        })
            .then(() => {
                console.log('Password updated')
            })
            .catch((e) => {
                console.log({ e });
                return Promise.reject(e.response.data.message);
            });
    };

    verifyToken(token: string | null): Promise<any> {
        return axios({
            method: "POST",
            url: axios.defaults.baseURL + "forgot-password/verify-reset-token",
            data: {
                token: token,
            },
        })
            .then(() => {
                console.log('token is verified')
            })
            .catch((e) => {
                console.log({ e });
                return Promise.reject(e.response.data.message);
            });
    };

    emailConfirmation(code: string | null): Promise<any> {
        return axios({
            method: "POST",
            url: axios.defaults.baseURL + "confirm-email",
            data: {
                emailConfirmationCode: code,
            },
        })
            .then(() => {
                console.log('email is confirmation!')
            })
            .catch((e) => {
                console.log({ e });
                return Promise.reject(e.response.data.message);
            });
    };

    logout = () => {
        localStorage.removeItem("dein_at");
        localStorage.removeItem("dein_rt");
    };

    updateAccessTokenStorage = (accessToken: string) => {
        window.localStorage.setItem("dein_at", accessToken);
    };
    updateRefreshTokenStorage = (refreshToken: string) => {
        window.localStorage.setItem("dein_rt", refreshToken);
    };
}

export default AuthApi;

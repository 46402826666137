import LoadInsuranceUseCase from "../../../domain/interactors/insurance/LoadInsuranceUseCase";
import {Insurance} from "../../../domain/entity/insurance/structures/InsuranceStructure";
import { action, computed, makeAutoObservable, toJS } from "mobx";

export default class InsuranceViewModel {
    private loadInsuranceUseCase: LoadInsuranceUseCase;
    public insurances: Array<Insurance>;
    public types: Array<string>;

    constructor(loadInsuranceUseCase: LoadInsuranceUseCase) {
        makeAutoObservable(this);
        this.loadInsuranceUseCase = loadInsuranceUseCase;
        this.insurances = [];
        this.types = ['gesetzlich', 'privat'];
    }

    getInsurances = () => {
        this.loadInsuranceUseCase.getInsurances()
            .then(action((insurances) => {
                console.log(insurances)
                // @ts-ignore
                this.insurances = insurances.items;
            }));
    }

    getInsurancesPromise = () => {
        return this.loadInsuranceUseCase.getInsurances();
    }

    @computed get insurancesOptions(): Array<Insurance> {
        return toJS(this.insurances);
    }
}

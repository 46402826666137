// @ts-nocheck
import * as React from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useHistory, useLocation } from "react-router-dom";
import st from '../Auth.module.scss';
import { HeaderBarSmall } from 'presentation/views/components/AppBar/AppBarSmall';
import { useViewModels } from 'presentation/view-model/ViewModelProvider';
import classNames from "classnames";
import { AuthRoutes } from "../../../../routes/routes";
import jwt_decode from "jwt-decode";


const AuthenticationEmulate = () => {
    const intl = useIntl();
    const query = new URLSearchParams(useLocation().search);
    const queryToken = query?.get('token');
    const history = useHistory();
    const [incorrectToken, setIncorrectToken] = React.useState(false);

    const {
        userViewModel: {localeUrl},
    } = useViewModels();

    React.useEffect(() => {
        if (queryToken) {
            try {
                jwt_decode(queryToken);
                window.localStorage.removeItem("dein_rt");
                window.localStorage.setItem("dein_at", queryToken);
                history.push(localeUrl(AuthRoutes.dashboard));
                history.go(0);
            } catch (e) {
                setIncorrectToken(true);
                console.log('error', e.message);
            }

        }
    }, [queryToken, history, localeUrl]);

    return (
        <div className={st.auth}>
            <HeaderBarSmall bgColor="#09299C" staticBarIsTransparent className={classNames(st.header, st.authentication_emulate)} />

            <div className={st.auth_container}>
                <Grid container component="main" sx={{ minHeight: '100vh' }}>
                    <Grid item xs={12} md={12} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                            sx={[{display: 'flex', flexDirection: 'column', width: '100%',},
                                (theme) => ({
                                    [theme.breakpoints.down('md')]: {
                                        maxWidth: 'none',
                                    },
                                }),
                            ]}
                        >
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                    <Typography
                                        component="h1"
                                        variant="h4"
                                        sx={{ m: 0 }}
                                        gutterBottom
                                    >
                                        {intl.formatMessage({id: 'authenticationEmulate.title'})}
                                    </Typography>
                                </Grid>

                                {(!queryToken || incorrectToken) &&
                                    <Grid item xs={12} className="error">
                                        {incorrectToken ?
                                            <>Incorrect Token!</>
                                            :
                                            <>Token not provided!</>
                                        }
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default observer(AuthenticationEmulate);

import ScheduleRepository from "../../domain/repository/schedule/ScheduleRepository";
import {ScheduleDay} from "../../domain/entity/schedule/structures/ScheduleDayStructure";
import axios from "axios";
import {ScheduleSettings} from "../../domain/entity/schedule/structures/ScheduleSettingsStructure";

export default class ScheduleApi implements ScheduleRepository {
    getDoctorSchedule = async (id: string, from: string, till: string): Promise<[ScheduleDay[], []]> => {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + `api/doctors/${id}/schedule?scheduleItemsExtended=1&from=${from}&till=${till}`,
        }).then((appointments: any) => {
            return appointments.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    changeAppointmentByCall(appointmentByCall: boolean): Promise<ScheduleSettings> {
        return axios({
            method: "POST",
            url: "api/schedule_settings?appointmentByCallRewrite=true",
            data: {appointmentByCall},
        })
            .then((result: any) => {
                return {
                    id: result.data['id'],
                    workOnHoliday: result.data['workOnHoliday'],
                    workingDays: result.data['workingDays'],
                    workingTimeSettingsCommon: result.data['workingTimeSettingsCommon'],
                    workingTimeSettingsByDays: result.data['workingTimeSettingsByDays'],
                    workingTimeType: result.data['workingTimeType'],
                    appointmentByCall: result.data['appointmentByCall'],
                    noOrdination: result.data['noOrdination']
                };
            })
            .catch((e) => {
                console.log({e});
                return Promise.reject(e);
            });
    }

    getDoctorScheduleSettings(id: string): Promise<ScheduleSettings> {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + 'api/doctors/' + id + '/schedule_settings',
        }).then((appointments: any) => {
            return appointments.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    saveDoctorScheduleSettings(scheduleSettings: any): Promise<ScheduleSettings> {
        return axios({
            method: "POST",
            url: "api/schedule_settings",
            data: scheduleSettings,
        })
            .then((result: any) => {
                return {
                    id: result.data['id'],
                    workOnHoliday: result.data['workOnHoliday'],
                    workingDays: result.data['workingDays'],
                    workingTimeSettingsCommon: result.data['workingTimeSettingsCommon'],
                    workingTimeSettingsByDays: result.data['workingTimeSettingsByDays'],
                    workingTimeType: result.data['workingTimeType'],
                    appointmentByCall: result.data['appointmentByCall'],
                    noOrdination: result.data['noOrdination']
                };
            })
            .catch((e) => {
                console.log({e});
                return Promise.reject(e);
            });
    }
}

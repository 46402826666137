import { computed, makeAutoObservable, action } from "mobx";
import model from "domain/entity/profile/doctor/DoctorProfileModel";
import ManageDoctorProfile, { ValidationParameters } from "domain/interactors/profile/doctor/ManageDoctorProfileUseCase";
import { DoctorProfileOptions } from "domain/entity/profile/doctor/structures/DoctorProfileStructure";
import {ApprovalRequest} from "../../../../domain/entity/approval_request/structures/ApprovalRequestStructure";

// (separate this to different stores)
export default class DoctorProfileViewModel {
    public model: model;
    private manageDoctorProfile: ManageDoctorProfile;
    public isSync: boolean;
    public patients: any;
    public myApprovalRequests: Array<ApprovalRequest>;

    constructor(model: model, manageDoctorProfile: ManageDoctorProfile) {
        makeAutoObservable(this);
        this.model = model;
        this.manageDoctorProfile = manageDoctorProfile;
        this.isSync = false;
        this.patients = [];
        this.myApprovalRequests = [];
    }

    @computed get fullName(): string {
        return this.model.options?.firstName + this.model.options?.lastName;
    }

    @computed get identifier(): string {
        return this.model.options.profileId;
    }

    @computed get options(): DoctorProfileOptions {
        return this.model.asJson;
    }

    @action loadProfile = () => {
        this.isSync = true;
        this.manageDoctorProfile.loadProfile().finally(
            action(() => {
                this.isSync = false;
            })
        );
    };

    public loadOwnPatients = () => {
        this.manageDoctorProfile.loadOwnPatients()
            .then(action((patients) => {
                this.patients = patients.items;
            }));
    }

    public loadMyApprovalRequests = () => {
        this.manageDoctorProfile.loadMyApprovalRequests()
            .then(action((approvalRequests) => {
                this.myApprovalRequests = approvalRequests.items;
            }));
    }

    public onSaveProfileButtonClick = async (options: any, validationParameters: ValidationParameters = {}): Promise<any> => {
        return await this.manageDoctorProfile.DoctorProfileOptionsUpdate(options, validationParameters);
    };

    public editAccount = async (options: any): Promise<any> => {
        return await this.manageDoctorProfile.editAccount(options);
    }

}

import axios from "axios";
import AppointmentRepository from "domain/repository/appointment/AppointmentRepository";
import { Appointment } from "../../domain/entity/appointment/structures/AppointmentStructure";

export default class AppointmentApi implements AppointmentRepository {
    getUpcomingAppointments = async (): Promise<[Appointment[], []]> => {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + 'api/appointments?stateValue=upcoming',
        }).then((appointments: any) => {
            return appointments.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    getPastAppointments = async (): Promise<[Appointment[], []]> => {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + 'api/appointments?stateValue=past',
        }).then((appointments: any) => {
            return appointments.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    postBookingAppointment = (doctorIri: string, date: string, time: string, reason: string) => {
        return axios({
            method: "POST",
            url: axios.defaults.baseURL + "api/create-appointment",
            data: {
                doctor: doctorIri,
                date: date,
                time: time,
                reason: reason,
            },
        });
    };

    postBookingAppointmentByCall = (doctorIri: string, phoneNumber: string, reason: string) => {
        return axios({
            method: "POST",
            url: axios.defaults.baseURL + "api/create-appointment/by-call",
            data: {
                doctor: doctorIri,
                phoneNumber: phoneNumber,
                reason: reason,
            },
        });
    };

    putStatusChangeAppointment = (appointmentId: string, urlStatus: string, status: string, cancellationReason: string|undefined) => {
        return axios({
            method: "PUT",
            url: axios.defaults.baseURL + `api/appointments/${appointmentId}/${urlStatus}`,
            data: {
                status: status,
                cancellationReason: cancellationReason,
            },
        });
    };
}

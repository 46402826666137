import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Button, ClickAwayListener, Grid, IconButton, Typography } from '@mui/material';
import TooltipedCard from 'presentation/views/components/TooltipedCard';
import EntityInfo from 'presentation/views/components/EntityInfo';
import { ReactComponent as MoreIcon } from 'presentation/views/assets/images/icons/more.svg';

import classNames from 'classnames';
import st from '../Dashboard/dashboard.module.scss';
import styles from './team.module.scss';
import { useIntl } from 'react-intl';
import axios from 'axios';
import getAge from '../../../../helpers/getAge';
import { Link } from 'react-router-dom';
import { useViewModels } from 'presentation/view-model/ViewModelProvider';
import { NonAuthRoutes } from 'presentation/routes/routes';
import SearchIcon from '@mui/icons-material/Search';
import { Popup } from 'presentation/views/components/Popup';
import DoctorsViewModel from "../../../../../view-model/doctors/DoctorsViewModel";
import stylePopup from "presentation/views/components/Popup/popup.module.scss";

const Team = () => {
    const intl = useIntl();
    const {
        uIViewModel: { openPopup, closePopup },
        userViewModel: { locale },
    } = useViewModels();
    const [cardMenuIndexActive, setCardMenuIndexActive] = useState<number | null>(null);

    useEffect(() => {
        DoctorsViewModel.getOwnDoctors();
    }, []);

    const handleCardMenuClickAway = (event: MouseEvent | TouchEvent) => {
        const activeCard: HTMLElement | null = document.querySelector(`.cardMenu_${cardMenuIndexActive}`);

        if (!activeCard?.contains(event.target as Node)) {
            setCardMenuIndexActive(null);
        }
    };

    return (
        <section className={st.section}>
            <Typography variant="h5" className={classNames(st.section__title, st.section__title_isLg)}>
                <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cabinet.dashboard.team.header' }) }} />
            </Typography>

            {DoctorsViewModel.ownDoctors.length > 0 ? (
                DoctorsViewModel.ownDoctors.map((item: any, index: number) => (
                    <TooltipedCard
                        key={item.id}
                        variant="bordered"
                        tooltip={
                            <ClickAwayListener onClickAway={(e: MouseEvent | TouchEvent) => handleCardMenuClickAway(e)}>
                                <div
                                    className={`cardMenu_${index} ${styles.card_menu}  ${
                                        index === cardMenuIndexActive ? styles.card_menu_active : ''
                                    }`}
                                    onClick={() => (index === cardMenuIndexActive ? setCardMenuIndexActive(null) : setCardMenuIndexActive(index))}
                                >
                                    <IconButton>
                                        <MoreIcon height="16px" />
                                    </IconButton>

                                    <div
                                        className={styles.cancel_tooltip}
                                        onClick={() => {
                                            openPopup();
                                        }}
                                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cabinet.dashboard.team.cancel' }) }}
                                    />
                                </div>
                            </ClickAwayListener>
                        }
                    >
                        <Link
                            to={(locale && locale !== 'de' ? '/' + locale : '') + NonAuthRoutes.doctors + '/' + item.id}
                            style={{ color: 'inherit' }}
                        >
                            <EntityInfo
                                fio={item.profile?.firstName.charAt(0) + item.profile?.lastName.charAt(0)}
                                avatarSize={64}
                                pictureUrl={item.profile?.avatar?.contentUrl ? axios.defaults.baseURL + item.profile?.avatar?.contentUrl : ''}
                            >
                                <Typography variant="t3">
                                    {item.label}
                                </Typography>

                                <Typography variant="t2news">
                                    {item.specialities?.length
                                        ? `${item.specialities[0].name} ${intl.formatMessage({ id: 'cabinet.dashboard.team.doctor' })}`
                                        : ''}
                                    {item.profile?.birthDate
                                        ? `${getAge(new Date(item.profile?.birthDate))} ${intl.formatMessage({ id: 'cabinet.patients.y.o' })} `
                                        : ''}
                                </Typography>
                            </EntityInfo>
                        </Link>
                    </TooltipedCard>
                ))
            ) : (
                <div className={st.empty_dashboard}>
                    <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cabinet.dashboard.team.nopatients' }) }} />
                </div>
            )}

            <Popup>
                <Typography
                    variant="h5"
                    className={stylePopup.title}
                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cabinet.dashboard.team.cancel.title' }) }}
                />

                <Box component="form">
                    <Grid container spacing={2} pt={{ xs: 2, sm: 4, md: 6 }}>
                        <Grid item xs={12} sm={6}>
                            <Button type="submit" color="secondary" variant="contained" sx={{ width: "100%" }}>
                                {intl.formatMessage({ id: 'cabinet.dashboard.team.cancel.button' })}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button type="button" color="secondary" variant="outlined" onClick={closePopup} sx={{ width: "100%" }}>
                                {intl.formatMessage({ id: 'cabinet.dashboard.team.cancel.cancel' })}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Popup>

            <Box mt={5}>
                <Link to={`${locale && locale !== 'de' ? '/' + locale : ''}${NonAuthRoutes.doctors}`} className={styles.team__bottom_link}>
                    <Button component="div" variant="contained" size="large" color="secondary">
                        <SearchIcon />
                        {intl.formatMessage({ id: 'cabinet.dashboard.team.find' })}
                    </Button>
                </Link>
            </Box>
        </section>
    );
};

export default observer(Team);

import classNames from "classnames/bind";
import React from "react";
import { useIntl } from "react-intl";
import st from "./heroHeader.module.scss";

type Props = {
    caption: string;
    desc?: string;
    className?: string;
};

const HeroHeader: React.FC<Props> = ({ caption, desc, className }) => {
    const intl = useIntl();
    return (
        <div className={classNames(st.hero_header, className)}>
            <div className={st.container}>
                <div className={st.caption_part}>
                    <h1 className={st.caption}
                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: caption }) }}></h1>
                </div>
                {desc &&
                    <div className={st.desc_part}>
                        <p className={st.paragraph}
                           dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: desc }) }}></p>
                    </div>
                }
            </div>
        </div>
    );
};

export default HeroHeader;

import ManageAppointmentUseCase from "domain/interactors/appointment/ManageAppointmentUseCase";
import { action, makeAutoObservable } from "mobx";
import { SubmissionError, ValidationError } from "domain/interactors/CustomErrors";
import { Appointment } from "domain/entity/appointment/structures/AppointmentStructure";

export default class AppointmentViewModel {
    private manageAppointmentUseCase: ManageAppointmentUseCase;
    public upcomingAppointments: Array<Appointment>;
    public pastAppointments: Array<Appointment>;

    constructor(manageAppointmentUseCase: ManageAppointmentUseCase) {
        makeAutoObservable(this);
        this.manageAppointmentUseCase = manageAppointmentUseCase;
        this.upcomingAppointments = [];
        this.pastAppointments = [];
    }

    public validatePutCancelAppointment = (values: any) => {
        const errors: any = {};

        if (!values.appointmentId.length) {
            errors.appointmentId = 'Required';
        }
        if (!values.cancellationReason.length) {
            errors.cancellationReason = 'Required';
        }

        return errors;
    }

    public validatePutCompleteAppointment = (values: any) => {
        const errors: any = {};

        if (!values.appointmentId.length) {
            errors.appointmentId = 'Required';
        }

        return errors;
    }

    getUpcomingAppointments = () => {
        this.manageAppointmentUseCase.getUpcomingAppointments()
            .then(action((appointments) => {
                // @ts-ignore
                this.upcomingAppointments = appointments.items;
            }));
    }

    getPastAppointments = () => {
        this.manageAppointmentUseCase.getPastAppointments()
            .then(action((appointments) => {
                // @ts-ignore
                this.pastAppointments = appointments.items;
            }));

    }

    public postBookingAppointment = (doctorIri: string, date: string, time: string, reason: string) => {
        return this.manageAppointmentUseCase
            .postBookingAppointment(doctorIri, date, time, reason)
            .then((serverResponse) => {
                return Promise.resolve(serverResponse);
            })
            .catch((error) => {
                if (error instanceof SubmissionError) {
                    const submissionError = error as SubmissionError;

                    return Promise.reject(submissionError.errors);
                }
                if (error instanceof ValidationError) {
                    const validationError = error as ValidationError;
                    return Promise.reject(validationError.errors);
                }

                return Promise.reject(error);
            });
    };

    public postBookingAppointmentByCall = (doctorIri: string, phoneNumber: string, reason: string) => {
        return this.manageAppointmentUseCase
            .postBookingAppointmentByCall(doctorIri, phoneNumber, reason)
            .then((serverResponse) => {
                return Promise.resolve(serverResponse);
            })
            .catch((error) => {
                if (error instanceof SubmissionError) {
                    const submissionError = error as SubmissionError;

                    return Promise.reject(submissionError.errors);
                }
                if (error instanceof ValidationError) {
                    const validationError = error as ValidationError;
                    return Promise.reject(validationError.errors);
                }

                return Promise.reject(error);
            });
    };

    putStatusChangeAppointment = (appointmentId: string, urlStatus: string, status: string, cancellationReason: string|undefined) => {
        return this.manageAppointmentUseCase
            .putStatusChangeAppointment(appointmentId, urlStatus, status, cancellationReason)
            .then(action((serverResponse) => {
                // Move Appointment from Upcoming to Past Appointments
                const appointmentIdIndex = this.upcomingAppointments.findIndex(item => item.id === appointmentId);
                const [pastAppointmentObject] = this.upcomingAppointments.splice(appointmentIdIndex, 1);
                pastAppointmentObject.status = status;
                this.getPastAppointments();

                return Promise.resolve(serverResponse);
            }))
            .catch((error) => {
                if (error instanceof SubmissionError) {
                    const submissionError = error as SubmissionError;

                    return Promise.reject(submissionError.errors);
                }
                if (error instanceof ValidationError) {
                    const validationError = error as ValidationError;
                    return Promise.reject(validationError.errors);
                }

                return Promise.reject(error);
            });
    };
}

import * as React from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Field, FormikProvider, useFormik } from 'formik';
import { useViewModels } from 'presentation/view-model/ViewModelProvider';
import { NonAuthRoutes } from 'presentation/routes/routes';
import { Link, useLocation } from 'react-router-dom';
import { InputAdornment, IconButton } from '@mui/material';
import st from '../Auth.module.scss';
import { ReactComponent as EyeIcon } from '../../../assets/images/icons/eye.svg';
import { ReactComponent as EyeIconOff } from '../../../assets/images/icons/eye-off.svg';
import cleanDeep from "clean-deep";

const NewPassword = () => {
    const intl = useIntl();

    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };
    const query = new URLSearchParams(useLocation().search);
    const queryToken = query?.get('resetToken');

    const {
        authViewModel: {
            verifyTokenSubmitError,
            isVerifyTokenSubmission,
            isVerifyTokenSubmissionIsSync,
            onClickNewPassword,
            successNewPassIsActive,
            onVerifyToken,
            hideSuccessNewPass,
        },
        uIViewModel: {
            setErrorAlertContent,
            setErrorAlertContentTime,
            alertContentTime,
        },
        userViewModel,
    } = useViewModels();

    React.useEffect(() => {
        onVerifyToken(queryToken);
    }, [isVerifyTokenSubmissionIsSync, onVerifyToken, queryToken]);

    const validateNewPass = (values: any): object => {
        const errors: any = {
            newPassword: (() => {
                if (!values.newPassword) return intl.formatMessage({id: 'required'});
                if (values.newPassword.length < 6) return intl.formatMessage({id: 'errors.passwordMinLength'});
                if (values.confirmNewPassword && values.confirmNewPassword !== values.newPassword) {
                    return intl.formatMessage({id: 'errors.passwordNotMatch'});
                }
            })(),
            confirmNewPassword: (() => {
                if (!values.confirmNewPassword) return intl.formatMessage({id: 'required'});
                if (values.confirmNewPassword.length < 6) return intl.formatMessage({id: 'errors.passwordMinLength'});
                if (values.confirmNewPassword !== values.newPassword) {
                    return intl.formatMessage({id: 'errors.passwordNotMatch'});
                }
            })(),
        };

        return cleanDeep(errors);
    };

    const form = useFormik({
        initialValues: {
            newPassword: '',
            confirmNewPassword: '',
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: validateNewPass,
        onSubmit: (values) => {
            setErrorAlertContent('', true);
            onClickNewPassword(values.newPassword, queryToken)
                .catch((errorMessage) => {
                    setErrorAlertContentTime(errorMessage, alertContentTime);
                }
            );
        },
    });

    return (
        <Grid container component="main" sx={{ minHeight: '100vh' }}>
            <Grid item xs={12} md={5} sx={{ display: 'flex', alignItems: 'center' }}>
                {isVerifyTokenSubmission ? (
                    <Grid container spacing={6}>
                        {isVerifyTokenSubmissionIsSync && (
                            <Grid item xs={12}>
                                <Typography variant="t3" mb={4} className="error">
                                    {verifyTokenSubmitError.length < 1
                                        ? intl.formatMessage({ id: 'auth.newPassword.notVerifyToken' })
                                        : verifyTokenSubmitError}
                                </Typography>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <Link to={`${userViewModel.locale && userViewModel.locale !== 'de' ? "/" + userViewModel.locale : ''}${NonAuthRoutes.signin}`}>
                                <Button variant="contained" color="secondary" autoFocus sx={{ width: '100%' }}>
                                    {intl.formatMessage({ id: 'ok' })}
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                ) : (
                    <Box
                        sx={[
                            {
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                maxWidth: 480,
                            },
                            (theme) => ({
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 'none',
                                },
                            }),
                        ]}
                    >
                        {!successNewPassIsActive ? (
                            <FormikProvider value={form}>
                                <Box
                                    component="form"
                                    noValidate
                                    onSubmit={form.handleSubmit}
                                    onChange={(event: any) => {
                                        if (form.errors) {
                                            form.setFieldError(event.target.name, '');
                                        }
                                    }}
                                    onKeyDown={(event: any) => {
                                        if (event.key === 'Enter') {
                                            form.handleSubmit();
                                        }
                                    }}
                                >
                                    <Grid container spacing={6}>
                                        <Grid item xs={12}>
                                            <Typography
                                                component="h1"
                                                variant="h4"
                                                sx={{ m: 0 }}
                                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.newPassword.title' }) }}
                                                gutterBottom
                                            ></Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                type={showPassword ? 'text' : 'password'}
                                                name="newPassword"
                                                children={(fieldProps: any) => (
                                                    <TextField
                                                        {...fieldProps.field}
                                                        required
                                                        fullWidth
                                                        label={
                                                            <span>{intl.formatMessage({ id: 'auth.newPassword.password' })}</span>
                                                        }
                                                        type={showPassword ? 'text' : 'password'}
                                                        variant="standard"
                                                        error={!form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                        helperText={!form.isValid && fieldProps.meta.error}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        edge="end"
                                                                        className={st.button_eye}
                                                                        // @ts-ignore
                                                                        tabIndex={-1}
                                                                    >
                                                                        {!showPassword ? <EyeIconOff /> : <EyeIcon />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                type={showPassword ? 'text' : 'password'}
                                                name="confirmNewPassword"
                                                children={(fieldProps: any) => (
                                                    <TextField
                                                        {...fieldProps.field}
                                                        required
                                                        fullWidth
                                                        label={
                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: intl.formatMessage({ id: 'auth.newPassword.confirmPassword' }),
                                                                }}
                                                            ></span>
                                                        }
                                                        type={showPassword ? 'text' : 'password'}
                                                        variant="standard"
                                                        error={!form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                        helperText={!form.isValid && fieldProps.meta.error}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        edge="end"
                                                                        className={st.button_eye}
                                                                        // @ts-ignore
                                                                        tabIndex={-1}
                                                                    >
                                                                        {!showPassword ? <EyeIconOff /> : <EyeIcon />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Button type="submit" fullWidth color="secondary" variant="contained" sx={{ width: '100%' }}>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: intl.formatMessage({ id: 'auth.newPassword.button' }),
                                                    }}
                                                ></span>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </FormikProvider>
                        ) : (
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                    <Typography
                                        component="h1"
                                        variant="h4"
                                        sx={{ m: 0 }}
                                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.newPassword.success.title' }) }}
                                        gutterBottom
                                    ></Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography
                                        component="p"
                                        variant="body1"
                                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.newPassword.success.description' }) }}
                                    ></Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Link to={`${userViewModel.locale && userViewModel.locale !== 'de' ? "/" + userViewModel.locale : ''}${NonAuthRoutes.signin}`}>
                                        <Button variant="contained" color="secondary" onClick={hideSuccessNewPass} autoFocus sx={{ width: '100%' }}>
                                            {intl.formatMessage({ id: 'auth.newPassword.success.button' })}
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                )}
            </Grid>

            <Grid item xs={12} md={6} className={st.media}>
                <svg className={st.media_circle_1} viewBox="0 0 577 577" xmlns="http://www.w3.org/2000/svg">
                    <circle opacity=".32" cx="288.293" cy="288.294" r="287.5" transform="rotate(45 288.293 288.294)" stroke="#fff" fill="none" />
                </svg>
                <svg className={st.media_circle_2} viewBox="0 0 468 545" xmlns="http://www.w3.org/2000/svg">
                    <circle opacity="0.32" cx="180" cy="288.294" r="287.5" transform="rotate(45 180 288.294)" stroke="#fff" fill="none" />
                </svg>
                <div className={st.media__picture}>
                    <img src={`/images/Auth/forgot.png`} alt="forgot password" />
                </div>
            </Grid>
        </Grid>
    );
};

export default observer(NewPassword);

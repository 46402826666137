import ManageConnectionUseCase from "../../../domain/interactors/connection/ManageConnectionUseCase";
import ConnectionRepository from "../../../domain/repository/connection/ConnectionRepository";
import { Connection } from "../../../domain/entity/connections/structures/ConnectionStructure";
import {action, makeAutoObservable} from "mobx";
import errorParse from "../../views/helpers/errorParse";
import { SubmissionError, ValidationError } from "domain/interactors/CustomErrors";

export default class ConnectionViewModel {
    private manageConnectionUseCase: ManageConnectionUseCase;
    private connectionRepository: ConnectionRepository;
    public myConnections: Array<Connection>;
    public connectionsRequests: Array<Connection>;
    public outgoingConnectionsRequests: Array<Connection>;
    public errors = {changeConnectionStatus: ''};
    public complete = {changeConnectionStatus: false};

    constructor(manageConnectionUseCase: ManageConnectionUseCase, connectionRepository: ConnectionRepository) {
        makeAutoObservable(this);
        this.manageConnectionUseCase = manageConnectionUseCase;
        this.connectionRepository = connectionRepository;
        this.myConnections = [];
        this.connectionsRequests = [];
        this.outgoingConnectionsRequests = [];
    }

    askToApproveSkills = (connection: Connection, doctorId: string) => {
        return this.manageConnectionUseCase
            .askToApproveSkills(doctorId)
            .then(action((object) => {
                connection.askedToApproveSkills = true;
            }));
    }

    getAskToApproveSkillsPromise = (doctorId: string) => {
        return this.manageConnectionUseCase.askToApproveSkills(doctorId);
    }

    postConnection = (recipientIri: string) => {
        return this.manageConnectionUseCase
            .postConnection(recipientIri)
            .then(action((serverResponse) => {
                // @ts-ignore
                return Promise.resolve(serverResponse.data);
            }))
            .catch((error) => {
                if (error instanceof SubmissionError) {
                    const submissionError = error as SubmissionError;

                    return Promise.reject(submissionError.errors);
                }
                if (error instanceof ValidationError) {
                    const validationError = error as ValidationError;
                    return Promise.reject(validationError.errors);
                }

                return Promise.reject(error);
            });
    }

    getMyConnections = () => {
        this.manageConnectionUseCase.getMyConnections()
            .then(action((connections) => {
                // @ts-ignore
                this.myConnections = connections.items;
            }));
    }

    getConnectionsRequests = () => {
        this.manageConnectionUseCase.getConnectionsRequests()
            .then(action((connections) => {
                // @ts-ignore
                this.connectionsRequests = connections.items;
            }));
    }

    getOutgoingConnectionsRequests = () => {
        this.manageConnectionUseCase.getOutgoingConnectionsRequests()
            .then(action((connections) => {
                // @ts-ignore
                this.outgoingConnectionsRequests = connections.items;
            }));
    }

    changeConnectionStatus = (connectionId: string, status: string) => {
        return this.connectionRepository
            .changeConnectionStatus(connectionId, status)
            .then(action((object) => {
                this.errors.changeConnectionStatus = '';
                this.complete.changeConnectionStatus = true;

                if (status === 'cancel') {
                    this.getOutgoingConnectionsRequests();
                } else if (status === 'accept') {
                    this.getConnectionsRequests();
                    this.getMyConnections();
                } else {
                    this.getConnectionsRequests();
                }
            }))
            .catch((error) => {
                this.errors.changeConnectionStatus = errorParse(error);
            });
    }
}

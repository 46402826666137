// @ts-nocheck
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useViewModels } from 'presentation/view-model/ViewModelProvider';
import axios from 'axios';
import classNames from 'classnames';

import st from './doctorPreviewProfile.module.scss';
import { ReactComponent as LocationIcon } from '../../assets/images/icons/location.svg';
import { ReactComponent as InsurancePlusIcon } from '../../assets/images/icons/insurance-1.svg';
import { ReactComponent as InsuranceSchieldIcon } from '../../assets/images/icons/insurance-2.svg';
import { ReactComponent as CheckIcon } from '../../assets/images/icons/check.svg';
import primaryMuiTheme from '../../layouts/themes/primaryMuiTheme';
import { Avatar, Typography, Dialog, IconButton, Grid } from '@mui/material';
import { stringAvatar } from 'presentation/views/helpers/tools';
import { ReactComponent as CrossIcon } from '../../assets/images/icons/cross_14_2.svg';
import EntityInfo from 'presentation/views/components/EntityInfo';
import Fade from '@mui/material/Fade';
import stylePopup from 'presentation/views/components/Popup/popup.module.scss';

interface doctorPreviewProfileProps {
    doctor: {};
    isDoctorPage: boolean;
    viewAsPatient?: boolean;
    children?: React.ReactNode;
}

const DoctorContainer: React.FC<doctorPreviewProfileProps> = ({ doctor, isDoctorPage, userViewModel, children }) => {
    if (!isDoctorPage) {
        return (
            <Link
                to={`${userViewModel.locale && userViewModel.locale !== 'de' ? '/' + userViewModel.locale : ''}/doctors/${doctor.id}`}
                className={classNames(st.doctor_info, st.doctor_info_link)}
            >
                {children}
            </Link>
        );
    }

    return <div className={st.doctor_info}>{children}</div>;
};

export const DoctorPreviewProfile: React.FC<doctorPreviewProfileProps> = ({ doctor, isDoctorPage, viewAsPatient }) => {
    let mapCount = 0;
    const intl = useIntl();
    const { userViewModel } = useViewModels();
    const editProfile = doctor.id === userViewModel.identifier;

    function ProfilePreviewInsurance() {
        const [openInsurances, setOpenInsurances] = React.useState(false);

        const handleCloseInsurances = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpenInsurances(false);
        };

        const handleOpenInsurances = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpenInsurances(true);
        };

        return (
            <>
                <div className={st.insurance_status} onClick={handleOpenInsurances}>
                    {intl.formatMessage({id: 'doctors.previewProfile.plans'})}
                </div>
                <Dialog TransitionComponent={Fade} onClose={handleCloseInsurances} maxWidth={false} open={openInsurances} scroll="body" className={stylePopup.popup}>
                    <div className={stylePopup.container}>
                        <IconButton className={stylePopup.close} onClick={handleCloseInsurances}>
                            <CrossIcon />
                        </IconButton>

                        <Typography variant="h5" className={stylePopup.title}>
                            {intl.formatMessage({ id: 'cabinet.profile.doctor.insurance.popup.title' })}
                        </Typography>
                        <Grid container spacing={2}>
                            {doctor?.insurances.map((item, i) => {
                                return (
                                    <Grid item xs={12} key={item.country + i + 'popup'}>
                                        <EntityInfo
                                            noHover={true}
                                            avatarSize={48}
                                            fio={item.country}
                                            className={st.info_block__picture_border}
                                            bgcolor="#fff"
                                            imgContain
                                            pictureUrl={axios.defaults.baseURL + item.logo?.contentUrl}
                                        >
                                            <Typography variant="p5">{item.title}</Typography>
                                        </EntityInfo>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </div>
                </Dialog>
            </>
        );
    }

    return (
        <DoctorContainer doctor={doctor} isDoctorPage={isDoctorPage} userViewModel={userViewModel}>
            <div className={classNames(st.avatar, isDoctorPage ? st.avatar_big : '')}>
                <div className={st.photo}>
                    {doctor.profile?.avatar?.contentUrl ? (
                        <Avatar
                            src={axios.defaults.baseURL + doctor.profile.avatar.contentUrl}
                            sx={{
                                ...primaryMuiTheme.typography.h3,
                                bgcolor: 'rgba(0, 185, 255, 1)',
                                width: '100%',
                                height: '100%',
                            }}
                            {...stringAvatar((doctor?.profile?.firstName || doctor?.profile?.lastName) ? `${doctor?.profile?.firstName} ${doctor?.profile?.lastName}` : '')}
                        />
                    ) : (
                        <Avatar
                            sx={{
                                ...primaryMuiTheme.typography.h3,
                                bgcolor: 'rgba(0, 185, 255, 1)',
                                width: '100%',
                                height: '100%',
                            }}
                            {...stringAvatar((doctor?.profile?.firstName || doctor?.profile?.lastName) ? `${doctor?.profile?.firstName} ${doctor?.profile?.lastName}` : '')}
                        />
                    )}
                </div>

                {!isDoctorPage && doctor.countSkillsApproved ?
                    <div className={st.skills_approve}>
                        {intl.formatMessage({ id: 'doctors.previewProfile.skills' }, { count: `${doctor.countSkillsApproved}` })}
                    </div>
                    :
                    ''
                }
            </div>

            <div className={classNames(st.profile, isDoctorPage ? st.profile_doctor : "")}>
                {isDoctorPage && editProfile && viewAsPatient && (
                    <Link
                        to={`${userViewModel.locale && userViewModel.locale !== 'de' ? '/' + userViewModel.locale : ''}/doctors/${
                            doctor.id
                        }/edit?tab=personal`}
                        className={st.edit}
                    >
                        {intl.formatMessage({ id: 'edit' })}
                    </Link>
                )}
                <div className={st.name}>
                    {doctor.label}
                    <CheckIcon />
                </div>

                <div className={st.specialty}>{doctor?.specialities?.length ? doctor.specialities[0].name : ''}</div>

                {doctor.countSkillsApproved && doctor.countSkillsApproved > 0 ? (
                    <div className={st.skills_approve}>
                        {intl.formatMessage({ id: 'doctors.previewProfile.skills' }, { count: `${doctor.countSkillsApproved}` })}
                    </div>
                ) : (
                    ''
                )}

                {doctor?.experiences?.length > 0 &&
                    doctor.experiences.map((item, key) => {
                        if (!item.currentWork || mapCount > 0) {
                            return '';
                        }

                        mapCount = 1;
                        return (
                            item.clinic?.lat && (
                                <React.Fragment key={'map' + key}>
                                    <div className={st.clinic}>{item.clinic?.title}</div>
                                    <div className={st.location}>
                                        <div>
                                            <LocationIcon />
                                        </div>
                                        <div className={st.location_place}>
                                            {
                                                (item.country ? item.country + ', ' : '') +
                                                (item.city ? item.city + ', ' : '') +
                                                (item.clinic?.location ? item.clinic?.location + ', ' : '') +
                                                (item.zipCode ? item.zipCode : '')
                                            }
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        );
                    })}

                {!isDoctorPage && (
                    <div className={st.additional_data}>
                        {doctor?.hourCost && (
                            <div className={st.price}>
                                {intl.formatMessage({ id: 'doctors.previewProfile.rate' }, { rate: `${doctor?.hourCost}` })}
                            </div>
                        )}

                        {doctor?.statutoryInsurances?.length > 0 || doctor?.privateInsurances?.length > 0 ?
                            <div className={st.insurance_data}>
                                {doctor?.statutoryInsurances?.length > 0 && (
                                    <div className={classNames(st.statuses, st.statuses_gesetzlich)}>
                                        <InsurancePlusIcon />
                                        <Typography variant="t4" color="white" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "gesetzlichType" }) }} />
                                    </div>
                                )}
                                {doctor?.enablePrivateInsurance && (
                                    <div className={classNames(st.statuses, st.statuses_privat)}>
                                        <InsuranceSchieldIcon />
                                        <Typography variant="t4" color="white" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "privatType" }) }} />
                                    </div>
                                )}
                                <div className={classNames(st.statuses)}>
                                    <ProfilePreviewInsurance />
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </div>
                )}
            </div>
        </DoctorContainer>
    );
};

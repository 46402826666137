import AuthRepository from "../../repository/auth/AuthRepository";
import errorParse from "../../../presentation/views/helpers/errorParse";

export default class LoginUseCase {
    private authRepository: AuthRepository;

    public constructor(authRepository: AuthRepository) {
        this.authRepository = authRepository;
    }

    public async register(type: string, email: string, password: string, confirm_password: string, referralCode: string | null) {
        return await this.authRepository.register(type, email, password, referralCode)
            .catch(error => Promise.reject(errorParse(error)));
    }
}

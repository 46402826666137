// @ts-nocheck
import React from "react";
import st from "./skeleton.module.scss";
import { useViewModels } from "presentation/view-model/ViewModelProvider";

export const Skeleton: React.FC = ({ number = 1 }) => {
    const {uIViewModel: { tabletIs }} = useViewModels();

    return (
        <div className={st.item}>
            <div className={st.info}>
                <div className={st.avatar}></div>

                <div className={st.doctor_info}>
                    <div className={st.fill}></div>
                    <div className={st.fill}></div>
                    {!tabletIs && <div className={st.fill}></div>}
                </div>
            </div>

            <div className={st.schedule}></div>
        </div>
    );
};

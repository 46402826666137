// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {Link} from "react-router-dom";
import { styled } from '@mui/material/styles';
import { defaultTheme } from 'presentation/views/layouts/themes/primaryMuiTheme';

import st from './schedule.module.scss';
import { ReactComponent as AngleBracketPrev } from 'presentation/views/assets/images/icons/angle-bracket-prev.svg';
import { ReactComponent as AngleBracketNext } from 'presentation/views/assets/images/icons/angle-bracket-next.svg';
import { ReactComponent as CrossIcon } from 'presentation/views/assets/images/icons/cross_14_2.svg';
import { ReactComponent as ExpandIcon } from 'presentation/views/assets/images/icons/expand.svg';
import { ReactComponent as ScheduleIcon } from 'presentation/views/assets/images/icons/schedule.svg';

import EntityInfo from 'presentation/views/components/EntityInfo';
import {
    IconButton,
    Typography,
    Button,
    Select,
    MenuItem,
    InputLabel,
    Fade,
    Modal,
    Box,
} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useViewModels } from '../../../../../view-model/ViewModelProvider';
import { observer } from 'mobx-react';
import getCurrentDate from '../../../../helpers/getCurrentDate';
import getDatePlusDays from '../../../../helpers/getDatePlusDays';
import axios from 'axios';
import classNames from 'classnames';
import { NonAuthRoutes } from "../../../../../routes/routes";


const Schedule = () => {
    const intl = useIntl();
    const {
        scheduleViewModel,
        scheduleViewModel: {scheduleDays, getDoctorSchedule},
        userViewModel: { identifier: doctorId, locale },
        uIViewModel: { isTouchDevice, mobileIs },
        doctorProfileViewModel: { options: user, },
    } = useViewModels();

    const isProfileCreated = true;
    const [month, setMonth] = useState(intl.formatDate(new Date(), { month: "long" }));

    const months = Array.from({length: 12}, (item, i) => {
        return intl.formatDate(new Date(0, i), { month: 'long' });
    });

    const [monthIndex, setMonthIndex] = useState(months.indexOf(month));

    useEffect(() => {
        setMonth(months[monthIndex]);
    }, [locale, mobileIs, months, monthIndex]);

    useEffect(() => {
        getDoctorSchedule(doctorId, getCurrentDate(), getDatePlusDays(new Date(), mobileIs ? 0 : 6));
    }, [getDoctorSchedule, doctorId, mobileIs]);

    const handleMonthChange = (e: any) => {
        setMonth(e.target.value);

        const currentMonth = months.indexOf(e.target.value);
        setMonthIndex(currentMonth);

        getDoctorSchedule(
            doctorId,
            getDatePlusDays(new Date(new Date().getFullYear(), currentMonth, 1), mobileIs ? 1 : 1),
            getDatePlusDays(new Date(new Date().getFullYear(), currentMonth, 1), mobileIs ? 1 : 7)
        );
    };

    const previousWeekLoad = () => {
        if (scheduleDays.length) {
            const from = getDatePlusDays(new Date(scheduleDays[0].date), mobileIs ? -1 : -7);

            getDoctorSchedule(doctorId, from, getDatePlusDays(new Date(scheduleDays[0].date), -1));

            const currentScheduleMonthIndex = new Date(from).getMonth();

            if (monthIndex !== currentScheduleMonthIndex) {
                setMonthIndex(currentScheduleMonthIndex);
                setMonth(months[currentScheduleMonthIndex]);
            }
        }
    };

    const nextWeekLoad = () => {
        if (scheduleDays.length) {
            const from = getDatePlusDays(new Date(scheduleDays[scheduleDays.length - 1].date), 1);

            getDoctorSchedule(
                doctorId,
                from,
                getDatePlusDays(new Date(scheduleDays[scheduleDays.length - 1].date), mobileIs ? 1 : 7)
            );

            const currentScheduleMonthIndex = new Date(from).getMonth();

            if (monthIndex !== currentScheduleMonthIndex) {
                setMonthIndex(currentScheduleMonthIndex);
                setMonth(months[currentScheduleMonthIndex]);
            }
        }
    };

    const inlineStyles = {
        calendarGrid: {
            gridTemplateColumns: `auto repeat(${scheduleViewModel?.timeRows?.length ? scheduleViewModel.timeRows[0].days?.length : 0}, 1fr)`,
        },
    };

    function formatDateTime(date: string) {
        const dateHuman = new Date(date);

        const monthDayUtc = dateHuman.getUTCDate();
        dateHuman.setUTCDate(monthDayUtc);

        const weekday = intl.formatDate(dateHuman, { weekday: 'short' });
        const monthDay = intl.formatDate(dateHuman, { month: 'long', day: 'numeric' });

        const dateFormatted = `${weekday}, ${monthDay}`;

        return intl.formatMessage({ id: 'cabinet.appointments.dateTime' }, { date: dateFormatted, time: '' });
    }

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 'unset',
            padding: '24px 144px 24px 24px',
            borderRadius: '4px',
            color: '#05144B',
            backgroundColor: '#fff',
            boxShadow: '0px 5px 5px -3px rgba(5, 20, 75, 0.2), 0px 8px 10px 1px rgba(5, 20, 75, 0.08), 0px 3px 14px 2px rgba(5, 20, 75, 0.04)',
        },
    }));

    function ControlledTooltips({ appointment, children }: any) {
        const [open, setOpen] = React.useState(false);

        const handleClose = () => {
            setOpen(false);
        };

        const handleOpen = () => {
            setOpen(true);
        };

        if (isTouchDevice) {
            return (
                <>
                    <div onClick={handleOpen}>{children}</div>
                    <Modal open={open} onClose={handleClose}>
                        <Box className={st.tooltip__modal}>
                            <div className={st.tooltip__date}>
                                <Typography className={st.tooltip__date} sx={{ marginBottom: 16 }} variant="t3s">
                                    {`${formatDateTime(appointment.date)} ${appointment.start} - ${appointment.end}`}
                                </Typography>
                            </div>

                            <EntityInfo
                                avatarSize={34}
                                pictureUrl={appointment.patientAvatarUrl ? axios.defaults.baseURL + appointment.patientAvatarUrl : ''}
                                fio={appointment.patientName}
                                size="sm"
                                noHover
                            >
                                <Typography variant="t4">{appointment.patientName}</Typography>

                                <Typography
                                    color={defaultTheme.palette.primary.light}
                                    variant="d2"
                                    dangerouslySetInnerHTML={{
                                        __html: intl.formatMessage({ id: 'cabinet.schedule.reason' }, { reason: appointment.reason }),
                                    }}
                                />
                            </EntityInfo>

                            <div className={st.tooltip__buttons}>
                                <IconButton onClick={handleClose}>
                                    <CrossIcon width="14px" height="14px" />
                                </IconButton>
                            </div>
                        </Box>
                    </Modal>
                </>
            );
        }

        return (
            <HtmlTooltip
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                TransitionComponent={Fade}
                title={
                    <>
                        <div className={st.tooltip__date}>
                            <Typography className={st.tooltip__date} sx={{ marginBottom: 16 }} variant="t3s">
                                {`${formatDateTime(appointment.date)} ${appointment.start} - ${appointment.end}`}
                            </Typography>
                        </div>

                        <EntityInfo
                            avatarSize={34}
                            pictureUrl={appointment.patientAvatarUrl ? axios.defaults.baseURL + appointment.patientAvatarUrl : ''}
                            fio={appointment.patientName}
                            size="sm"
                            noHover
                        >
                            <Typography variant="t4">{appointment.patientName}</Typography>

                            <Typography
                                color={defaultTheme.palette.primary.light}
                                variant="d2"
                                dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({ id: 'cabinet.schedule.reason' }, { reason: appointment.reason }),
                                }}
                            />
                        </EntityInfo>

                        <div className={st.tooltip__buttons}>
                            <IconButton onClick={handleClose}>
                                <CrossIcon width="14px" height="14px" />
                            </IconButton>
                        </div>
                    </>
                }
                placement="right-end"
            >
                {children}
            </HtmlTooltip>
        );
    }

    return (
        <section className={st.section}>
            <div className={st.section__top}>
                <Typography variant="h5" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cabinet.schedule.title' }) }} />

                {user.scheduleSettings &&
                    <div className={st.abc_option}>
                        <div className={classNames(st.abc_option__title, (user.scheduleSettings.appointmentByCall ? st.abc_option__title_active : ''))}>
                            {intl.formatMessage({ id: "cabinet.abcOption" },
                                { state: intl.formatMessage({ id: (user.scheduleSettings.appointmentByCall ? "active" : "notActive") }) },
                            )}
                        </div>

                        <Link className={st.abc_option__button} to={`${locale && locale !== "de" ? "/" + locale : ""}${NonAuthRoutes.doctors}/${user.id}/edit?tab=schedule-settings`}>
                            <Button variant="contained" color="secondary" size="small">
                                {intl.formatMessage({ id: "needScheduleSettings" })}
                            </Button>
                        </Link>
                    </div>
                }
            </div>

            {user.scheduleSettings ?
                isProfileCreated ? (
                    <div className={classNames(st.tabs_wrapper,
                            user.scheduleSettings.appointmentByCall ? st.tabs_wrapper_inactive : '')}
                    >
                        <div className={classNames(st.by_call_overlay,
                            user.scheduleSettings.appointmentByCall ? '' : st.by_call_overlay_hidden)}
                        />

                        <div className={st.nav}>
                            <InputLabel className={st.label} sx={{ display: { sm: 'none' } }} htmlFor="month">
                                <span>{intl.formatMessage({ id: 'cabinet.schedule.month' })}</span>
                            </InputLabel>

                            <Select
                                value={month}
                                MenuProps={{ disableScrollLock: true }}
                                id="month"
                                onChange={handleMonthChange}
                                variant="outlined"
                                IconComponent={mobileIs ? ScheduleIcon : ExpandIcon}
                                className={st.month_select}
                            >
                                {months?.map((item, i) =>
                                    <MenuItem key={item + i} value={item}>
                                        {item}
                                    </MenuItem>
                                )}
                            </Select>

                            <div className={st.pagination}>
                                <Button
                                    className="btn--trans btn--circle--sm"
                                    size="small"
                                    color="primary"
                                    variant="circle"
                                    onClick={() => previousWeekLoad()}
                                >
                                    <AngleBracketPrev height={10} />
                                </Button>

                                <div className={classNames(st.pagination__name, (scheduleDays.length > 0 ? '' : 'hidden'))}>
                                    {scheduleDays.length > 0 &&
                                        (mobileIs ?
                                            intl.formatDate(scheduleDays[0].date, { month: "short" }) + ', ' +
                                            intl.formatDate(scheduleDays[0].date, { day: 'numeric' })
                                            :
                                            intl.formatDate(scheduleDays[0].date, { month: "short" }) + ' ' +
                                            intl.formatDate(scheduleDays[0].date, { day: 'numeric' }) + ' - ' +
                                            intl.formatDate(scheduleDays[scheduleDays.length - 1].date, { month: "short" }) + ' ' +
                                            intl.formatDate(scheduleDays[scheduleDays.length - 1].date, { day: 'numeric' }) + ', ' +
                                            intl.formatDate(scheduleDays[0].date, { year: "numeric" }) +
                                            (intl.formatDate(scheduleDays[0].date, { year: "numeric" }) !== intl.formatDate(scheduleDays[scheduleDays.length - 1].date, { year: "numeric" }) ?
                                                '/' + intl.formatDate(scheduleDays[scheduleDays.length - 1].date, { year: "numeric" })
                                                :
                                                ''
                                            )
                                        )
                                    }
                                </div>

                                <Button
                                    className="btn--trans btn--circle--sm"
                                    size="small"
                                    color="primary"
                                    variant="circle"
                                    onClick={() => nextWeekLoad()}
                                >
                                    <AngleBracketNext height={10} />
                                </Button>
                            </div>
                        </div>

                        <div className={st.calendar} style={inlineStyles.calendarGrid}>
                            <div className={classNames(st.calendar__day, st.calendar__day_empty)}></div>

                            {scheduleDays.map((day, dayIndex, array) => (
                                <div
                                    key={`${day.monthDay}_${dayIndex}`}
                                    className={classNames(st.calendar__day, (dayIndex === array - 1 && st.calendar__day_last))}
                                >
                                    {intl.formatDate(day.date, { day: "numeric"})}&nbsp;
                                    {intl.formatDate(day.date, { weekday: "short"})}
                                </div>
                            ))}

                            {scheduleViewModel.timeRows?.map((interval, intervalIndex) => (
                                <React.Fragment key={intervalIndex}>
                                    <div className={st.calendar__time}>{interval.time}</div>
                                    {interval.days?.map((slot, slotIndex) =>
                                        slot.isWorking ? (
                                            <div
                                                key={slotIndex + 'inner'}
                                                className={classNames(
                                                    st.calendar__slot,
                                                    slotIndex === interval.days.length - 1 && st.calendar__slot_last,
                                                    intervalIndex === scheduleViewModel.timeRows.length - 1 && st.calendar__slot_last_row
                                                )}
                                            >
                                                {slot.appointments?.map((appointment, appointmentIndex) => (
                                                    <ControlledTooltips appointment={appointment} key={appointmentIndex + 'inner2'}>
                                                        <div
                                                            className={classNames(
                                                                st.calendar__appointment,
                                                                st[`calendar__appointment_${appointment.background}`]
                                                            )}
                                                        >
                                                            {slot.appointments.length < 2 ? (
                                                                <>
                                                                    <span>{appointment.patientName}</span>
                                                                    <span>{`${appointment.start} - ${appointment.end}`}</span>
                                                                </>
                                                            ) : (
                                                                <span>{`${appointment.start} - ${appointment.end}`}</span>
                                                            )}
                                                        </div>
                                                    </ControlledTooltips>
                                                ))}
                                            </div>
                                        ) : (
                                            <div
                                                key={slotIndex + 'inner'}
                                                className={classNames(
                                                    st.calendar__slot,
                                                    st.calendar__slot_break,
                                                    slotIndex === interval.days.length - 1 && st.calendar__slot_last,
                                                    intervalIndex === scheduleViewModel.timeRows.length - 1 && st.calendar__slot_last_row,
                                                    interval.days.indexOf(slotIndex + 1) !== -1 &&
                                                        interval.days?.[slotIndex + 1]?.isWorking &&
                                                        st.calendar__slot_next_working
                                                )}
                                            />
                                        )
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className={st.create_profile}>
                        <Typography
                            variant="p5"
                            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cabinet.schedule.createProfile.desc' }) }}
                        />

                        <Button variant="contained" size="medium" color="secondary" className={st.create_profile__btn}>
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cabinet.schedule.createProfile.button' }) }} />
                        </Button>
                    </div>
                )
            :
                <Link to={`${locale && locale !== 'de' ? '/' + locale : ''}${NonAuthRoutes.doctors}/${user.id}/edit?tab=schedule-settings`}>
                    <Button variant="contained" color="secondary">
                        {intl.formatMessage({id: "needScheduleSettings"})}
                    </Button>
                </Link>
            }
        </section>
    );
};

export default observer(Schedule);

// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { TextField, Checkbox, Typography, FormControlLabel, Grid, Box, Autocomplete } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import { useViewModels } from "presentation/view-model/ViewModelProvider";
import FileInput from "presentation/views/components/FileInput";
import classNames from "classnames";
import styles from "../../doctorProfileEdit.module.scss";
import st from "../Experiences/experiences.module.scss";
import { ReactComponent as CrossIcon } from "../../../../../assets/images/icons/cross_14_2.svg";
import { ReactComponent as PlusIcon } from "../../../../../assets/images/icons/plus.svg";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import SpecialitiesViewModel from "../../../../../../view-model/specialities/SpecialitiesViewModel";
import mediaObjectApi from "../../../../../../../data/mediaObject/mediaObjectApi";
import axios from "axios";
import { observer } from "mobx-react";
import cleanDeep from "clean-deep";
import { UserSaveButtons } from "../../../../../components/UserSaveButtons";


const Education: React.FC = ({ changeDetector, setTab }) => {
    const intl = useIntl();
    const {
        doctorProfileViewModel: { options, onSaveProfileButtonClick, model },
        uIViewModel: { setSuccessAlertContent, setErrorAlertContent, },
    } = useViewModels();
    const [saveAndNext, setSaveAndNext] = useState(false);
    const [specialitiesOptions, setSpecialitiesOptions] = useState([]);

    const validate = (values: any) => {
        const errors: any = {educations: {}};

        values.educations.forEach((item, index) => {
            errors.educations[index] = {};
            if (!item.university.trim()) {
                errors.educations[index].university = "Required";
            }

            if (!item.graduationYear && !item.stillStudying) {
                errors.educations[index].graduationYear = "Required";
            }
        });

        if (Object.keys(cleanDeep(errors)).length > 0) {
            setSaveAndNext(false);
        }

        return cleanDeep(errors);
    }


    const initialValues = {
        id: null,
        university: "",
        degree: "",
        graduationYear: '',
        faculty: "",
        speciality: null,
        diploma: null,
        stillStudying: false,
    };

    const changeForm = useFormik({
        validate: validate,
        initialValues: {
            educations: (() => {
                if (options.educations.length === 0) {
                    return [initialValues];
                } else {
                    return (options.educations.map(value => {
                        return {
                            id: `api/doctor_educations/${value.id}`,
                            university: value.university ?? '',
                            degree: value.degree ?? '',
                            graduationYear: value.graduationYear ?? '',
                            faculty: value.faculty ?? '',
                            speciality: value.speciality ? value.speciality : null,
                            diploma: (value.diploma?.id && !value.stillStudying  ? `api/media_objects/${value.diploma.id}` : null),
                            stillStudying: value.stillStudying ?? false,
                        }
                    }));
                }
            })(),
        },

        onSubmit: (values, { setErrors }) => {
            setSuccessAlertContent('', true);
            setErrorAlertContent('', true);

            onSaveProfileButtonClick(values)
                .then(() => {
                    if (saveAndNext) {
                        setSaveAndNext(false);
                        setTab('saveAndNext', '', false, true);
                    }
                    changeDetector(false);
                    setSuccessAlertContent(intl.formatMessage({id: "successSave"}));
                })
                .catch((error) => {
                    console.log(error);
                    setErrorAlertContent(intl.formatMessage({id: "errorSave"}));
                    if (error.errors) {
                        setErrors(error.errors);
                    }
                });
        },
    });

    const uploadDiploma = (e, index) => {
        mediaObjectApi.loadMedia(e.target.files[0])
            .then((response) => {
                if (response.status === 201 && response.data.contentUrl) {
                    changeForm.values.educations[index].diploma = `api/media_objects/${response.data.id}`;

                    changeForm.submitForm()
                        .then(() => {
                            setSuccessAlertContent(intl.formatMessage({ id: "successSave" }));
                    })
                        .catch((error) => {
                            console.log(error);
                            setErrorAlertContent(intl.formatMessage({ id: "errorSave" }));
                            if (error.errors) {
                                setErrors(error.errors);
                            }
                        });
                } else {
                    console.log(response);
                }
            })
            .catch(error => {
                console.log(error);
                setErrorAlertContent(intl.formatMessage({ id: "errorSave" }));
            });
    }

    const deleteFile = (mediaObjectIri) => {
        mediaObjectApi.deleteMedia(mediaObjectIri)
            .then((response) => {
                if (response.status === 204) {
                    const deleteDiplomaIndex = options.educations.findIndex(item => item.diploma?.id === mediaObjectIri.replace('api/media_objects/', ''));

                    options.educations[deleteDiplomaIndex].diploma = null;
                    changeForm.setFieldValue(`educations[${deleteDiplomaIndex}].diploma`, null);
                    model.updateFromJson(options);
                    setSuccessAlertContent(intl.formatMessage({ id: "successSave" }));
                } else {
                    console.log(response);
                    setErrorAlertContent(intl.formatMessage({ id: "errorSave" }));
                }
            })
            .catch(error => {
                console.log(error);
                setErrorAlertContent(intl.formatMessage({ id: "errorSave" }));
            });
    }

    useEffect(() => {
        let isMounted = true;

        if (SpecialitiesViewModel.specialities.length === 0) {
            SpecialitiesViewModel.getSpecialitiesPromise()
                .then(data => {
                    if (isMounted) {
                        SpecialitiesViewModel.specialities = data.items;
                        setSpecialitiesOptions(data.items);
                    }
                });
        } else {
            if (isMounted) {
                setSpecialitiesOptions(SpecialitiesViewModel.specialities);
            }
        }

        return () => { isMounted = false };
    }, []);

    return (
        <FormikProvider value={changeForm}>
            <Box component="form" noValidate onSubmit={changeForm.handleSubmit}>
                <Typography
                    variant="h5"
                    sx={{ mb: 5 }}
                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.profile.doctor.education.title" }) }}
                />

                <FieldArray
                    name="educations"
                    render={(arrayHelpers) => (
                        <Grid container spacing={4} sx={{ mb: {xs: 4, sm: 6,} }}>
                            {changeForm.values.educations?.map((education, i) => (
                                <Grid key={i} item xs={12} className={styles.container_universal}>
                                    <div className={styles.main_block_universal}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <Field
                                                    name={`educations.[${i}].university`}
                                                    children={(fieldProps: any) => (
                                                        <TextField
                                                            {...fieldProps.field}
                                                            onChange={(e) => {
                                                                changeForm.handleChange(e);
                                                                changeDetector(true);
                                                            }}
                                                            fullWidth
                                                            label={intl.formatMessage({id: "cabinet.profile.doctor.education.university"})}
                                                            type="text"
                                                            variant="standard"
                                                            error={changeForm.touched.educations?.[i]?.university && !changeForm.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                            helperText={changeForm.touched.educations?.[i]?.university && !changeForm.isValid && fieldProps.meta.error}
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12} lg={8}>
                                                <Field
                                                    name={`educations.[${i}].degree`}
                                                    children={(fieldProps: any) => (
                                                        <TextField
                                                            {...fieldProps.field}
                                                            onChange={(e) => {
                                                                changeForm.handleChange(e);
                                                                changeDetector(true);
                                                            }}
                                                            fullWidth
                                                            label={intl.formatMessage({id: "cabinet.profile.doctor.education.degree"})}
                                                            type="text"
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12} lg={4} className={`${education.stillStudying ? 'disabled' : ''}`} sx={{ "> div": { width: "100%" } }}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        name={`educations.[${i}].graduationYear`}
                                                        className="error"
                                                        views={['year']}
                                                        label={intl.formatMessage({id: 'cabinet.profile.doctor.education.graduationYear'})}
                                                        minDate={new Date('1950-01-01')}
                                                        maxDate={new Date()}
                                                        value={education.graduationYear ? education.graduationYear : null}
                                                        disableCloseOnSelect={false}
                                                        onChange={newValue => {
                                                            changeForm.setFieldValue(`educations[${i}].graduationYear`, newValue);
                                                            changeDetector(true);
                                                        }}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                error={changeForm.touched.educations?.[i]?.graduationYear && changeForm.errors?.educations?.[i]?.graduationYear ? true : undefined}
                                                                helperText={changeForm.touched.educations?.[i]?.graduationYear && changeForm.errors?.educations?.[i]?.graduationYear}
                                                            />}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Field
                                                    name={`educations.[${i}].faculty`}
                                                    children={(fieldProps: any) => (
                                                        <TextField
                                                            {...fieldProps.field}
                                                            onChange={(e) => {
                                                                changeForm.handleChange(e);
                                                                changeDetector(true);
                                                            }}
                                                            fullWidth
                                                            label={intl.formatMessage({id: "cabinet.profile.doctor.education.faculty"})}
                                                            type="text"
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    name={`educations.[${i}].speciality`}
                                                    blurOnSelect
                                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                                    isOptionEqualToValue={(option, value) => value.name === "" || option.name === value.name}
                                                    options={specialitiesOptions}
                                                    value={education?.speciality ?? null}
                                                    onChange={(e, newValue) => {
                                                            changeForm.setFieldValue(`educations[${i}].speciality`, (newValue ?? null));
                                                            changeDetector(true);
                                                        }
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            label={intl.formatMessage({ id: "cabinet.profile.doctor.education.speciality" })}
                                                            type="text"
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid className={`${education.stillStudying ? 'disabled' : ''}`} item xs={12}>
                                                {education.diploma
                                                    ?
                                                    <Typography variant="t3s" className={styles.file_input}>
                                                        <div>{intl.formatMessage({id: "cabinet.profile.doctor.education.diploma"})}</div>

                                                        <a target='_blank' rel="noopener noreferrer" href={(axios.defaults.baseURL + options?.educations?.[i]?.diploma?.contentUrl)}>
                                                            {options?.educations?.[i]?.diploma?.contentUrl}
                                                        </a>

                                                        <CrossIcon onClick={() => deleteFile(education.diploma)} />
                                                    </Typography>
                                                    :
                                                    <div className={(education.university.trim().length === 0 ? styles.file_input_upload_disabled : '')}>
                                                        <Field
                                                            name={`educations.[${i}].diploma`}
                                                            children={(fieldProps: any) => {
                                                                return (
                                                                    <FileInput
                                                                        onChange={(e) => uploadDiploma(e, i)}
                                                                        label={intl.formatMessage({ id: "cabinet.profile.doctor.education.diploma" })}
                                                                        placeholder={intl.formatMessage({
                                                                            id: "cabinet.profile.doctor.education.diploma",
                                                                        })}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <div className={classNames(styles.button_delete_universal, st.delete_block)}>
                                        <div className={styles.button_delete} role="button"
                                             onClick={() => {
                                                 arrayHelpers.remove(i);
                                             }}
                                        >
                                            <CrossIcon />
                                            <span>{intl.formatMessage({ id: "cabinet.profile.doctor.education.delete" })}</span>
                                        </div>

                                        <FormControlLabel
                                            control={
                                                <Field
                                                    name={`educations.[${i}].stillStudying`}
                                                    children={(fieldProps: any) => (
                                                        <Checkbox
                                                            {...fieldProps.field}
                                                            onChange={(e) => {
                                                                changeForm.handleChange(e);
                                                                changeForm.setFieldValue(`educations[${i}].graduationYear`, '');
                                                                changeForm.setFieldValue(`educations[${i}].diploma`, null);
                                                                changeDetector(true);
                                                            }}
                                                            checkedIcon={<span />}
                                                            icon={<span />}
                                                            checked={education.stillStudying}
                                                        />
                                                    )}
                                                />
                                            }
                                            label={intl.formatMessage({id: "cabinet.profile.doctor.education.stillStudying"})}
                                        />
                                    </div>
                                </Grid>
                            ))}

                            <Box sx={{ mt: 5.5, ml: 4 }}>
                                <div
                                    role="button"
                                    onClick={() => {
                                        arrayHelpers.push(initialValues);
                                    }}
                                    className={styles.button_add}
                                >
                                    <PlusIcon />

                                    <span>{intl.formatMessage({ id: "cabinet.profile.doctor.education.addUniversity" })}</span>
                                </div>
                            </Box>
                        </Grid>
                    )}
                />

                <UserSaveButtons setSaveAndNext={setSaveAndNext} />
            </Box>
            {/*<pre style={{color: "saddlebrown", overflow: 'hidden', width: '100%'}}>{JSON.stringify(changeForm.values, 0, 2)}</pre>*/}
        </FormikProvider>
    );
};

export default observer(Education);

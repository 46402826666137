import axios from "axios";


class DoctorExperienceApi {
    getDoctorExperience(value: string): Promise<[]> {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + `api/doctor_experiences?perPage=500&doctorExperienceInput=${value}`,
        }).then((doctorExperience: any) => {
            return doctorExperience.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }
}

export default new DoctorExperienceApi();

// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { observer } from "mobx-react";

import { Tab, Typography, Grid, Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { defaultTheme } from "presentation/views/layouts/themes/primaryMuiTheme";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import PersonalInformation from "./Common/PersonalInformation";
import Education from "./Common/Education";
import Insurance from "./Common/Insurance";
import Experiences from "./Common/Experiences";
import Specialities from "./Common/Specialities";
import Certifications from "./Common/Certifications";
import Awards from "./Common/Awards";
import Publications from "./Common/Publications/Publications";
import ScheduleSettings from "./Common/ScheduleSettings/ScheduleSettings";
import { Popup } from "../../../components/Popup";

// styles
import stDashboard from "../../Cabinet/Common/Dashboard/dashboard.module.scss"
import st from "./doctorProfileEdit.module.scss";
import classNames from "classnames";
import { ReactComponent as ArrowIcon } from "../../../assets/images/icons/arrow.svg";
import { ReactComponent as CheckedIcon2 } from "../../../assets/images/icons/checked_2.svg";
import { useViewModels } from "../../../../view-model/ViewModelProvider";
import { NonAuthRoutes } from "../../../../routes/routes";
import stylePopup from "presentation/views/components/Popup/popup.module.scss";


const DoctorProfileEdit = () => {
    const intl = useIntl();
    const query = new URLSearchParams(useLocation().search);
    const {
        userViewModel: {identifier, locale},
        uIViewModel: { tabletIs, openPopup, closePopup, },
        doctorProfileViewModel: {options: user},
    } = useViewModels();
    const [tab, setTab] = useState('personal');
    const [pendingTab, setPendingTab] = useState('');
    const [changeDetect, setChangeDetect] = useState(false);
    const [congratulationsDetect, setCongratulationsDetect] = useState(false);

    const handleChange = (event: (React.SyntheticEvent | string), newValue: string, unconditionalRedirect = false, setNextTab = false) => {
        if (setNextTab) {
            const nextTabIndex = tabsLinks.findIndex(item => item.value === tab) + 1;
            newValue = tabsLinks[nextTabIndex].value;
        }

        if (!unconditionalRedirect && !setNextTab) {
            setPendingTab(newValue);
        }

        if (!setNextTab && changeDetect && !unconditionalRedirect) {
            openPopup();
        } else {
            window.history.pushState({}, '', `${window.location.pathname}?tab=${newValue}`);
            setTab(newValue);
        }
    };

    const handleCongratulationsDetect = () => {
        setCongratulationsDetect(true);
        openPopup();
    }

    const resetCongratulations = () => {
        setCongratulationsDetect(false);
    }

    useEffect(() => {
        let isMounted = true;

        let queryTab = query.get("tab");

        if ((typeof queryTab === 'string')) {
            if (!tabsLinks.find(item => item.value === queryTab)) {
                queryTab = 'personal';
            }

            if (isMounted && tab !== queryTab) {
                setTab(queryTab);
            }
        }

        return () => { isMounted = false };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const tabsLinks = [
        {
            id: "id-0",
            name: intl.formatMessage({ id: "cabinet.profile.doctor.personalInfo.title" }),
            value: "personal",
            complete: !!user.firstName,
            required: true,
        },
        {
            id: "id-1",
            name: intl.formatMessage({ id: "cabinet.profile.doctor.insurance.title" }),
            value: "insurance",
            complete: !!user.insurances.length,
            required: true,
        },
        {
            id: "id-2",
            name: intl.formatMessage({ id: "cabinet.profile.doctor.experience.title" }),
            value: "experience",
            complete: !!user.experiences.length,
            required: true,
        },
        {
            id: "id-3",
            name: intl.formatMessage({ id: "cabinet.profile.doctor.specialties.title" }),
            value: "specialties",
            complete: !!user.specialities.length,
            required: true,
        },
        {
            id: "id-4",
            name: intl.formatMessage({ id: "cabinet.schedule.settings" }),
            value: "schedule-settings",
            complete: !!user.scheduleSettings,
            required: true,
        },
        {
            id: "id-5",
            name: intl.formatMessage({ id: "cabinet.profile.doctor.education.title" }),
            value: "education",
            complete: !!user.educations.length,
            required: false,
        },
        {
            id: "id-6",
            name: intl.formatMessage({ id: "cabinet.profile.doctor.certifications.title" }),
            value: "certifications",
            complete: !!user.certifications.length,
            required: false,
        },
        {
            id: "id-7",
            name: intl.formatMessage({ id: "cabinet.profile.doctor.awards.title" }),
            value: "awards",
            complete: !!user.awards.length,
            required: false,
        },
        {
            id: "id-8",
            name: intl.formatMessage({ id: "cabinet.profile.doctor.publications.title" }),
            value: "publications",
            complete: !!user.publications.length,
            required: false,
        },
    ];

    return (
        <>
            <Popup callback={resetCongratulations}>
                {congratulationsDetect ?
                    <>
                        <Typography variant="h5" className={stylePopup.title}>{intl.formatMessage({ id: "congratulationsTitle" })}</Typography>

                        <Typography variant="p5" className={stylePopup.text}>{intl.formatMessage({ id: "congratulationsTextDoctor" })}</Typography>

                        <Grid container spacing={2} pt={{ xs: 2, sm: 4, md: 6 }}>
                            <Grid item xs={12} sm={6}>
                                <Link to={`${locale && locale !== 'de' ? '/' + locale : ''}${NonAuthRoutes.doctors}/${identifier}`} sx={{ width: "100%" }}>
                                    <Button type="button" color="secondary" variant="contained"
                                        onClick={(e) => {
                                            closePopup();
                                            setCongratulationsDetect(false);
                                        }}
                                    >
                                        {intl.formatMessage({ id: "congratulationsOkDoctor" })}
                                    </Button>
                                </Link>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Button type="button" color="secondary" variant="outlined" sx={{ width: "100%" }}
                                        onClick={() => {
                                            closePopup();
                                            setCongratulationsDetect(false);
                                        }}
                                >
                                    {intl.formatMessage({ id: "close" })}
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                    :
                    <>
                        <Typography variant="h5" mb={4} className={stylePopup.title}>{intl.formatMessage({ id: "leavingAlertTitle" })}</Typography>

                        <Typography variant="p5" className={stylePopup.text}>{intl.formatMessage({ id: "leavingAlert" })}</Typography>

                        <Grid container spacing={2} pt={{ xs: 2, sm: 4, md: 6 }}>
                            <Grid item xs={12} sm={6}>
                                <Button type="button" color="secondary" variant="contained" sx={{ width: "100%" }}
                                        onClick={(e) => {
                                            setChangeDetect(false);
                                            closePopup();
                                            handleChange(e, pendingTab, true);
                                        }}
                                >
                                    {intl.formatMessage({ id: "ok" })}
                                </Button>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Button type="button" color="secondary" variant="outlined" onClick={closePopup} sx={{ width: "100%" }}>
                                    {intl.formatMessage({ id: "cancel" })}
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }
            </Popup>

            <Link to={`${locale && locale !== 'de' ? '/' + locale : ''}${user.active ? '/doctors/' + identifier : '/cabinet/dashboard'}`} className={st.back_to_dashboard}>
                <ArrowIcon />
                {intl.formatMessage({id: "back"})}
            </Link>

            <Grid container>
                <TabContext value={tab}>
                    {tabletIs && (
                        <Grid item xs={12}>
                            <TabList
                                variant="scrollable"
                                textColor="secondary"
                                onChange={handleChange}
                                aria-label="Horizontal tabs menu"
                                classes={{ root: st.tabs_block }}
                                sx={{ background: defaultTheme.palette.secondary.light2 }}
                            >
                                {tabsLinks.map(({ id, name, value, complete, required }) => (
                                    <Tab
                                        key={id}
                                        classes={{
                                            root: st.tab_item,
                                            selected: st.tab_item_active,
                                        }}
                                        value={value}
                                        label={
                                            <div className={classNames(stDashboard.details_item, st.tab_item_label, (complete ? st.tab_item_label_complete : ''))}>
                                                <div className={classNames(stDashboard.details_item_check, st.tab_item_label_icon)}><CheckedIcon2/></div>
                                                <div className={st.tab_item_title}>{name}{required && '*'}</div>
                                            </div>
                                        }
                                    />
                                ))}
                            </TabList>
                        </Grid>
                    )}

                    <Grid item xs={12} md={8} lg={9}>
                        <TabPanel value="personal" sx={{ padding: 0 }}>
                            <PersonalInformation changeDetector={setChangeDetect} setTab={handleChange} congratulationsDetector={handleCongratulationsDetect} />
                        </TabPanel>

                        <TabPanel value="insurance" sx={{ padding: 0 }}>
                            <Insurance changeDetector={setChangeDetect} setTab={handleChange} congratulationsDetector={handleCongratulationsDetect} />
                        </TabPanel>

                        <TabPanel value="experience" sx={{ padding: 0 }}>
                            <Experiences changeDetector={setChangeDetect} setTab={handleChange} congratulationsDetector={handleCongratulationsDetect} />
                        </TabPanel>

                        <TabPanel value="specialties" sx={{ padding: 0 }}>
                            <Specialities changeDetector={setChangeDetect} setTab={handleChange} congratulationsDetector={handleCongratulationsDetect} />
                        </TabPanel>

                        <TabPanel value="schedule-settings" sx={{ padding: 0 }}>
                            <ScheduleSettings changeDetector={setChangeDetect} setTab={handleChange} congratulationsDetector={handleCongratulationsDetect} />
                        </TabPanel>

                        <TabPanel value="education" sx={{ padding: 0 }}>
                            <Education changeDetector={setChangeDetect} setTab={handleChange} />
                        </TabPanel>

                        <TabPanel value="certifications" sx={{ padding: 0 }}>
                            <Certifications changeDetector={setChangeDetect} setTab={handleChange} />
                        </TabPanel>

                        <TabPanel value="awards" sx={{ padding: 0 }}>
                            <Awards changeDetector={setChangeDetect} setTab={handleChange} />
                        </TabPanel>

                        <TabPanel value="publications" sx={{ padding: 0 }}>
                            <Publications changeDetector={setChangeDetect} setTab={handleChange} />
                        </TabPanel>
                    </Grid>

                    {!tabletIs && (
                        <Grid item className={st.tabs_container}>
                            <TabList
                                orientation="vertical"
                                variant="scrollable"
                                textColor="secondary"
                                onChange={handleChange}
                                aria-label="Vertical tabs menu"
                                classes={{ root: st.tabs_block }}
                                sx={{ background: defaultTheme.palette.secondary.light2 }}
                            >
                                {tabsLinks.map(({ id, name, value, complete, required }) => (
                                    <Tab
                                        key={id}
                                        classes={{
                                            root: st.tab_item,
                                            selected: st.tab_item_active,
                                        }}
                                        sx={[
                                            { alignItems: "flex-start" },
                                            (theme) => ({
                                                ...theme.typography.t3,
                                                color: theme.palette.primary.main,
                                            }),
                                        ]}
                                        value={value}
                                        label={
                                            <div className={classNames(stDashboard.details_item, st.tab_item_label, (complete ? st.tab_item_label_complete : ''))}>
                                                <div className={classNames(stDashboard.details_item_check, st.tab_item_label_icon)}><CheckedIcon2/></div>
                                                <div className={st.tab_item_title}>{name}{required && '*'}</div>
                                            </div>
                                        }
                                    />
                                ))}
                            </TabList>
                        </Grid>
                    )}
                </TabContext>
            </Grid>
        </>
    );
};

export default observer(DoctorProfileEdit);

import NewsModel from "domain/entity/news/NewsModel";
import { action, computed, makeAutoObservable } from "mobx";
import ManageNewsUseCase from "domain/interactors/news/ManageNewsUseCase";

export default class NewsViewModel {
    public newsModel: NewsModel;
    public searchParams: any;
    public newsPerPage = 10;
    public isSync: boolean;
    private activePostId: string;
    private newsManager: ManageNewsUseCase;

    constructor(newsModel: NewsModel, newsManager: ManageNewsUseCase) {
        makeAutoObservable(this);
        this.newsModel = newsModel;
        this.newsManager = newsManager;
        // filters
        this.isSync = false;
        this.searchParams = {};
        this.activePostId = "";
    }

    @action loadNews = () => {
        this.isSync = true;
        this.newsManager
            .loadNewsByParams(this.searchParams)
            .then(
                action(() => {
                    this.isSync = false;
                })
            )
            .catch((error) => {
                console.log({ error });
            });
    };

    @action loadOtherNews = (id: string) => {
        this.isSync = true;
        this.newsManager
            .loadOtherNews(id)
            .then(() => {
                action(() => {
                    this.isSync = false;
                })
            })
            .catch((error) => {
                console.log({ error });
            });
    };


    @action loadSinglePost = (id: string) => {
        this.isSync = true;
        this.newsManager
            .loadPostById(id)
            .then(() => {
                this.updateActivePostId(id);
            })
            .finally(
                action(() => {
                    this.isSync = false;
                })
            );
    };

    @action updateSearchParams = (params: any) => {
        this.searchParams = { ...this.searchParams, ...params };
    };

    @action resetSearchParams = () => {
        this.searchParams = null;
        this.searchParams = { page: 1 };
    };

    @action loadFreshNews = () => {
        this.isSync = true;

        this.newsManager
            .loadFreshNews()
            .then(
                action(() => {
                    this.isSync = false;
                })
            )
            .catch((error) => {
                console.log({ error });
        });
    };

    @action updateActivePostId = (newId: string) => {
        this.activePostId = newId;
    };

    @computed get activeSinglePost() {
        return this.newsModel.getPostById(this.activePostId);
    }

    @computed get foundNews() {
        return this.newsModel.searchResults.map((currentPost) => {
            return {
                picture: currentPost["picture"],
                title: currentPost["title"],
                date: currentPost["createdAt"],
                id: currentPost["id"],
            };
        });
    }

    @computed get otherNews() {
        return this.newsModel.otherNews.map((currentPost) => {
            return {
                picture: currentPost["picture"],
                title: currentPost["title"],
                date: currentPost["createdAt"],
                id: currentPost["id"],
            };
        });
    }

    @computed get total() {
        return this.newsModel.searchResults.length;
    }

    @computed get availableYears() {
        return this.newsModel.availableYears;
    }
}

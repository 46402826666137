// @ts-nocheck
import React, { useEffect } from "react";
import AuthRoute from "presentation/routes/AuthRoute";
import { AuthRoutes } from "presentation/routes/routes";
import { Switch } from "react-router-dom";
import { UserRoles } from "types";
import DoctorProfileEdit from "./Doctor";
import PatientProfileEdit from "./Patient";
import { observer } from "mobx-react";
import { useViewModels } from "presentation/view-model/ViewModelProvider";
import { LinearProgress } from "@mui/material";
import LandingLayout from "presentation/views/layouts/landing/LandingLayout";
// styles
import st from "./userEdit.module.scss";


const UserEdit: React.FC = () => {
    const { authViewModel,
        doctorProfileViewModel,
        patientProfileViewModel,
        uIViewModel: { mediaQueryTablet, setTabletIs },
    } = useViewModels();

    useEffect(() => {
        let isMounted = true;
        window.matchMedia(mediaQueryTablet).addEventListener("change", () => {
            if (isMounted) {
                setTabletIs(window.matchMedia(mediaQueryTablet).matches);
            }
        });
        return () => { isMounted = false };
    });

    if (doctorProfileViewModel.isSync) return <LinearProgress />;

    return (
        <LandingLayout>
            <div className={st.user_edit}>
                <Switch>
                    {authViewModel.isDoctor && doctorProfileViewModel.options.id ?
                        <>
                            <AuthRoute exact Component={DoctorProfileEdit} path={AuthRoutes.doctorEdit} requiredRoles={[UserRoles.doctor]} />
                            <AuthRoute Component={DoctorProfileEdit} path={AuthRoutes.doctorEditLocale} requiredRoles={[UserRoles.doctor]} />
                        </>
                    :
                        authViewModel.userRoles[0] === UserRoles.patient && patientProfileViewModel?.patientProfile?.id &&
                            <>
                                <AuthRoute exact Component={PatientProfileEdit} path={AuthRoutes.patientEdit} requiredRoles={[UserRoles.patient]} />
                                <AuthRoute Component={PatientProfileEdit} path={AuthRoutes.patientEditLocale} requiredRoles={[UserRoles.patient]} />
                            </>
                    }
                </Switch>
            </div>
        </LandingLayout>
    );
};

export default observer(UserEdit);

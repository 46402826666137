// @ts-nocheck
import SearchBySpecialtiesModel from "domain/entity/searchBySpecialties/SearchBySpecialtiesModel";
import LoadAvailableLettersUseCase from "domain/interactors/common/LoadAvailableLettersUseCase";
import SearchBySpecialtiesUseCase from "domain/interactors/common/SearchBySpecialtiesUseCase";
import { action, computed, makeAutoObservable, toJS } from "mobx";

export default class LakeViewModel {
    public isSync: boolean;
    public activeLetter: string;
    private searchBySpecialtiesModel: SearchBySpecialtiesModel;

    private loadAvailableLettersUseCase: LoadAvailableLettersUseCase;
    private searchBySpecialtiesUseCase: SearchBySpecialtiesUseCase;
    public locale = localStorage.getItem('locale') ?? process.env.REACT_APP_DEFAULT_LOCALE;

    constructor(
        searchBySpecialtiesModel: SearchBySpecialtiesModel,
        searchBySpecialtiesUseCase: SearchBySpecialtiesUseCase,
        loadAvailableLettersUseCase: LoadAvailableLettersUseCase
    ) {
        makeAutoObservable(this);
        this.searchBySpecialtiesModel = searchBySpecialtiesModel;
        this.searchBySpecialtiesUseCase = searchBySpecialtiesUseCase;
        this.loadAvailableLettersUseCase = loadAvailableLettersUseCase;
        this.activeLetter = "";
        this.isSync = false;
    }

    @computed get activeSpecialties(): [any] {
        return toJS((this.searchBySpecialtiesModel.specialties as any)[this.activeLetter]);
    }
    @computed get availableLetters(): string[] {
        return this.searchBySpecialtiesModel.availableLetters;
    }
    public loadDoctorSpecialtiesByLetter = (letter: string, locale) => {
        if (locale !== this.locale) {
            this.searchBySpecialtiesModel.resetSpecialties();
        }

        if (Object.keys(this.searchBySpecialtiesModel.specialties).includes(letter)) {
            return;
        }

        this.searchBySpecialtiesUseCase.start(letter);
        this.locale = locale;
    };

    public loadLetters = () => {
        this.loadAvailableLettersUseCase.start()
            .then(action(() => {
                if (this.searchBySpecialtiesModel.availableLetters.length) {
                    this.activeLetter = this.searchBySpecialtiesModel.availableLetters[0];
                }
            }));
    };

    @action updateActiveLetter = (letter: string) => {
        this.activeLetter = letter;
    };
}

import axios from "axios";
import LandingRepository from "domain/repository/landing/LandingRepository";

export default class LandingApi implements LandingRepository {
    sendContactForm = (firstName: string, email: string, phoneNumber: string, message: string) => {
        return axios({
            method: "POST",
            url: axios.defaults.baseURL + "api/contact_forms",
            data: {
                firstName: firstName,
                email: email,
                phoneNumber: phoneNumber,
                message: message,
                lastName: "TEMP",
                subject: "TEMP",
            },
        });
    };
}

// @ts-nocheck
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useViewModels} from "presentation/view-model/ViewModelProvider";
import axios from "axios";
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";

import st from "./dashboard.module.scss";
import {Button, Typography} from "@mui/material";
import {defaultTheme} from "../../../../layouts/themes/primaryMuiTheme";
import cn from "classnames/bind";
import {ReactComponent as PenIcon} from "../../../../assets/images/icons/pen.svg";
import {ReactComponent as CheckedIcon2} from '../../../../assets/images/icons/checked_2.svg';
import {ReactComponent as CrossIcon} from "../../../../assets/images/icons/cross_14_2.svg";
import {ReactComponent as ListIcon} from "../../../../assets/images/icons/list.svg";

import TooltipedCard from "../../../../components/TooltipedCard";
import EntityInfo from "../../../../components/EntityInfo";
import AppointmentList from "../../../../components/AppointmentList/AppointmentList";
import {Appointment} from "../../../../../../domain/entity/appointment/structures/AppointmentStructure";
import getAge from "../../../../helpers/getAge";
import classNames from "classnames";
import { NonAuthRoutes} from "../../../../../routes/routes";
import {ConditionalWrapper} from "../../../../components/ConditionalWrapper";


const Dashboard: React.FC = () => {
    const intl = useIntl();
    const {authViewModel: { isDoctor }, doctorProfileViewModel, patientProfileViewModel,
        userViewModel: {identifier, locale},
        appointmentViewModel,
    } = useViewModels();
    const userType = isDoctor ? "doctor" : "patient";
    const [activeAppointmentCard, setActiveAppointmentCard] = useState<Appointment | null>(null);
    const user = isDoctor ? doctorProfileViewModel.options : patientProfileViewModel.patientProfile;
    const [typeActiveAppointmentCard, setTypeActiveAppointmentCard] = useState('upcomingAppointments');
    // User is active if requiring fields are set.
    // const isActiveUser = isDoctor ? (!!(user.firstName && user.experiences.length && user.specialities.length && user.insurances.length && (user.scheduleSettings !== null && Object.keys(user.scheduleSettings).length > 0))) : !!user.firstName;
    const [instructionIsOpenExpress, setInstructionIsOpenExpress] = useState(null);
    const [maxProgress, setMaxProgress] = useState(0);
    let isActiveUser = user.active ?? false;
    let instructionIsOpen = user.instructionIsOpen ?? true;

    const handleInstructionIsOpen = (value) => {
        setInstructionIsOpenExpress(value);

        const sendData = {instructionIsOpen: value};
        if (isDoctor) {
            doctorProfileViewModel.onSaveProfileButtonClick(sendData);
        } else {
            patientProfileViewModel.updatePatient(sendData);
        }
    }

    useEffect(() => {
        appointmentViewModel.getUpcomingAppointments();
        appointmentViewModel.getPastAppointments();
    }, [appointmentViewModel]);

    useEffect(() => {
        if (!isDoctor) {
            return;
        }

        doctorProfileViewModel.loadMyApprovalRequests();
    }, [doctorProfileViewModel, isDoctor]);

    useEffect(() => {
        let isMounted = true;
        // by default Account is create
        let countFilledFields = 1;
        let patientPercent = 30;
        let totalObservableFields = 1;

        for (let key in user) {
            switch (key) {
                case 'firstName':
                    totalObservableFields++;
                    if (user[key]) {
                        countFilledFields++;
                        if (!isDoctor) {
                            patientPercent += 30;
                        }
                    }
                    break;
                case 'phone':
                    if (!isDoctor) {
                        totalObservableFields++;
                        if (user[key]) {
                            countFilledFields++;
                            if (!isDoctor) {
                                patientPercent += 20;
                            }
                        }
                    }
                    break;
                case 'insurances':
                    totalObservableFields++;
                    if (user[key].length) {
                        countFilledFields++;
                        if (!isDoctor) {
                            patientPercent += 20;
                        }
                    }
                    break;
                case 'experiences':
                    if (isDoctor) {
                        totalObservableFields++;
                        user[key].length && countFilledFields++;
                    }
                    break;
                case 'specialities':
                    if (isDoctor) {
                        totalObservableFields++;
                        user[key].length && countFilledFields++;
                    }
                    break;
                case 'scheduleSettings':
                    if (isDoctor) {
                        totalObservableFields++;
                        user[key] !== null && Object.keys(user[key]) && countFilledFields++;
                    }
                    break;
                case 'educations':
                    if (isDoctor) {
                        totalObservableFields++;
                        user[key].length && countFilledFields++;
                    }
                    break;
                case 'certifications':
                    if (isDoctor) {
                        totalObservableFields++;
                        user[key].length && countFilledFields++;
                    }
                    break;
                case 'awards':
                    if (isDoctor) {
                        totalObservableFields++;
                        user[key].length && countFilledFields++;
                    }
                    break;
                case 'publications':
                    if (isDoctor) {
                        totalObservableFields++;
                        user[key].length && countFilledFields++;
                    }
                    break;
            }
        }

        if (isMounted) {
            setMaxProgress(isDoctor ? (countFilledFields / totalObservableFields) * 100 : patientPercent);
        }

        return () => {
            isMounted = false
        };
    }, [user, isDoctor, doctorProfileViewModel])

    return (
        <>
            <section className={st.section}>
                <Typography variant="h4" className={cn(st.section__title, st.section__title_isLg)}>
                    {user.firstName ?
                        <>
                            <span style={{ color: defaultTheme.palette.secondary.main }}>
                                {isDoctor ?
                                    user.label + ' '
                                    :
                                    intl.formatMessage({ id: `cabinet.dashboard.${[userType]}.welcome.name` }, { name: `${user.firstName ? (user.firstName + " " + user.lastName) : ""}` })
                                }
                            </span>

                            <span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "cabinet.dashboard.doctor.welcome.greeting"})}}/>
                        </>
                        :
                        <span>{intl.formatMessage({id: 'cabinet.welcome'})}</span>
                    }
                </Typography>

                {(isActiveUser && !(instructionIsOpenExpress ?? instructionIsOpen)) &&
                    <div className={st.instruction_open}>
                        <ListIcon onClick={() => handleInstructionIsOpen(true)}/>
                    </div>
                }

                {(instructionIsOpenExpress ?? (instructionIsOpenExpress ?? instructionIsOpen)) &&
                    <section className={st.instruction}>
                        <div className={st.details}>
                            <div className={classNames(st.details_item, st.details_item_active)}>
                                <div className={st.details_item_check}><CheckedIcon2/></div>
                                {intl.formatMessage({id: 'dashboard.createAccount'})}*&nbsp;
                                <span className={st.details_required}>({intl.formatMessage({id: 'required'})})</span>
                            </div>

                            <div className={classNames(st.details_item, (user.firstName ? st.details_item_active : ''))}>
                                <div className={st.details_item_check}><CheckedIcon2/></div>

                                <Link to={`${locale && locale !== 'de' ? '/' + locale : ''}/${userType + "s"}/${identifier}/edit?tab=personal`}>
                                    {intl.formatMessage({id: 'dashboard.addPersonal'})}*&nbsp;
                                    <span className={st.details_required}>({intl.formatMessage({id: 'required'})})</span>
                                </Link>


                            </div>

                            {isDoctor ?
                                <>
                                    <div className={classNames(st.details_item, (user.insurances.length ? st.details_item_active : ''))}>
                                        <div className={st.details_item_check}><CheckedIcon2/></div>

                                        <ConditionalWrapper
                                            condition={true}
                                            wrapper={children => <Link
                                                to={`${locale && locale !== 'de' ? '/' + locale : ''}/${userType + "s"}/${identifier}/edit?tab=insurance`}>{children}</Link>}
                                        >
                                            {intl.formatMessage({id: 'dashboard.addSupInsurance'})}*&nbsp;
                                            <span className={st.details_required}>({intl.formatMessage({id: 'required'})})</span>
                                        </ConditionalWrapper>


                                    </div>

                                    <div className={classNames(st.details_item, (user.experiences.length ? st.details_item_active : ''))}>
                                        <div className={st.details_item_check}><CheckedIcon2/></div>

                                        <ConditionalWrapper
                                            condition={true}
                                            wrapper={children => <Link
                                                to={`${locale && locale !== 'de' ? '/' + locale : ''}/${userType + "s"}/${identifier}/edit?tab=experience`}>{children}</Link>}
                                        >
                                            {intl.formatMessage({id: 'dashboard.addExperience'})}*&nbsp;
                                            <span className={st.details_required}>({intl.formatMessage({id: 'required'})})</span>
                                        </ConditionalWrapper>


                                    </div>

                                    <div className={classNames(st.details_item, (user.specialities.length ? st.details_item_active : ''))}>
                                        <div className={st.details_item_check}><CheckedIcon2/></div>

                                        <ConditionalWrapper
                                            condition={true}
                                            wrapper={children => <Link
                                                to={`${locale && locale !== 'de' ? '/' + locale : ''}/${userType + "s"}/${identifier}/edit?tab=specialties`}>{children}</Link>}
                                        >
                                            {intl.formatMessage({id: 'dashboard.addSpecialties'})}*&nbsp;
                                            <span className={st.details_required}>({intl.formatMessage({id: 'required'})})</span>
                                        </ConditionalWrapper>


                                    </div>

                                    <div
                                        className={classNames(st.details_item, (user.scheduleSettings !== null && Object.keys(user.scheduleSettings).length > 0 ? st.details_item_active : ''))}>
                                        <div className={st.details_item_check}><CheckedIcon2/></div>

                                        <ConditionalWrapper
                                            condition={true}
                                            wrapper={children => <Link
                                                to={`${locale && locale !== 'de' ? '/' + locale : ''}/${userType + "s"}/${identifier}/edit?tab=schedule-settings`}>{children}</Link>}
                                        >
                                            {intl.formatMessage({id: 'dashboard.addSchedule'})}*&nbsp;
                                            <span className={st.details_required}>({intl.formatMessage({id: 'required'})})</span>
                                        </ConditionalWrapper>


                                    </div>

                                    <div className={classNames(st.details_item, (user.educations.length ? st.details_item_active : ''))}>
                                        <div className={st.details_item_check}><CheckedIcon2/></div>

                                        <ConditionalWrapper
                                            condition={true}
                                            wrapper={children => <Link
                                                to={`${locale && locale !== 'de' ? '/' + locale : ''}/${userType + "s"}/${identifier}/edit?tab=education`}>{children}</Link>}
                                        >
                                            {intl.formatMessage({id: 'dashboard.addEducation'})}
                                        </ConditionalWrapper>
                                    </div>

                                    <div className={classNames(st.details_item, (user.certifications.length ? st.details_item_active : ''))}>
                                        <div className={st.details_item_check}><CheckedIcon2/></div>

                                        <ConditionalWrapper
                                            condition={true}
                                            wrapper={children => <Link
                                                to={`${locale && locale !== 'de' ? '/' + locale : ''}/${userType + "s"}/${identifier}/edit?tab=certifications`}>{children}</Link>}
                                        >
                                            {intl.formatMessage({id: 'dashboard.addCertifications'})}
                                        </ConditionalWrapper>
                                    </div>

                                    <div className={classNames(st.details_item, (user.awards.length ? st.details_item_active : ''))}>
                                        <div className={st.details_item_check}><CheckedIcon2/></div>

                                        <ConditionalWrapper
                                            condition={true}
                                            wrapper={children => <Link
                                                to={`${locale && locale !== 'de' ? '/' + locale : ''}/${userType + "s"}/${identifier}/edit?tab=awards`}>{children}</Link>}
                                        >
                                            {intl.formatMessage({id: 'dashboard.addAwards'})}
                                        </ConditionalWrapper>
                                    </div>

                                    <div className={classNames(st.details_item, (user.publications.length ? st.details_item_active : ''))}>
                                        <div className={st.details_item_check}><CheckedIcon2/></div>

                                        <ConditionalWrapper
                                            condition={true}
                                            wrapper={children => <Link
                                                to={`${locale && locale !== 'de' ? '/' + locale : ''}/${userType + "s"}/${identifier}/edit?tab=publications`}>{children}</Link>}
                                        >
                                            {intl.formatMessage({id: 'dashboard.addPublications'})}
                                        </ConditionalWrapper>
                                    </div>

                                    {isActiveUser &&
                                        <Link to={`${locale && locale !== 'de' ? '/' + locale : ''}${NonAuthRoutes.doctors}/${identifier}`}>
                                            <Button type="submit" color="secondary" sx={{mt: 3}} variant="contained">
                                                {intl.formatMessage({id: "cabinet.menu.profile"})}
                                            </Button>
                                        </Link>
                                    }
                                </>
                                :
                                <>
                                    <div className={classNames(st.details_item, (user.insurances?.length ? st.details_item_active : ''))}>
                                        <div className={st.details_item_check}><CheckedIcon2/></div>

                                        <ConditionalWrapper
                                            condition={true}
                                            wrapper={children => <Link
                                                to={`${locale && locale !== 'de' ? '/' + locale : ''}/${userType + "s"}/${identifier}/edit?tab=insurance`}>{children}</Link>}
                                        >
                                            {intl.formatMessage({id: 'dashboard.addInsurance'})}
                                        </ConditionalWrapper>
                                    </div>

                                    <div className={classNames(st.details_item, (user.phone ? st.details_item_active : ''))}>
                                        <div className={st.details_item_check}><CheckedIcon2/></div>

                                        <ConditionalWrapper
                                            condition={true}
                                            wrapper={children => <Link to={`${locale && locale !== 'de' ? '/' + locale : ''}/${userType + "s"}/${identifier}/edit?tab=contacts`}>{children}</Link>}
                                        >
                                            {intl.formatMessage({id: 'dashboard.addContact'})}
                                        </ConditionalWrapper>
                                    </div>

                                    {isActiveUser &&
                                        <Link to={(locale && locale !== 'de' ? '/' + locale : '') + NonAuthRoutes.doctors}>
                                            <Button type="submit" color="secondary" sx={{mt: 3}} variant="contained">
                                                {intl.formatMessage({id: "doctors.preview.confirmButton"})}
                                            </Button>
                                        </Link>
                                    }
                                </>
                            }

                        </div>

                        <div className={st.total}>
                            {(isActiveUser && (instructionIsOpenExpress ?? instructionIsOpen)) &&
                                <CrossIcon className={st.instruction_close} style={{marginBottom: '28px'}}
                                           onClick={() => handleInstructionIsOpen(false)}/>
                            }

                            <div className={st.total_title}>{intl.formatMessage({id: `instruction.doctor.text1.${isActiveUser}`})}</div>

                            <div className={st.total_text}>{intl.formatMessage({id: `instruction.${[userType]}.text2.${isActiveUser}`})}</div>

                            <div className={st.total_progress}>
                                <div className={st.progress_percent}>{maxProgress}%</div>

                                <div className={st.progress_bar}>
                                    <div className={st.progress_bar_static}></div>
                                    <div className={st.progress_bar_dynamic} style={{width: `${maxProgress}%`}}></div>
                                </div>
                            </div>

                        </div>
                    </section>
                }

                {isActiveUser &&
                    <div className={st.cards}>
                        <TooltipedCard
                            tooltip={
                                <Link to={`${locale && locale !== 'de' ? '/' + locale : ''}/${userType + "s"}/${identifier}/edit?tab=personal`}>
                                    <PenIcon style={{marginRight: "10px"}}/>
                                    <Typography
                                        variant="button"
                                        color={defaultTheme.palette.secondary.main}
                                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "cabinet.dashboard.doctor.cards.edit"})}}
                                    />
                                </Link>
                            }
                        >
                            <EntityInfo
                                pictureUrl={user?.avatar ? axios.defaults.baseURL + user.avatar : ""}
                                fio={user.firstName?.slice(0, 1) + user.lastName?.slice(0, 1)}
                                bgcolor="#00B9FF"
                                imgContain
                            >
                                <Typography variant="label" color={defaultTheme.palette.primary.light1}>
                                    {intl.formatMessage({id: `user.type.${[userType]}`})}
                                </Typography>

                                <Typography variant="t3">
                                    {isDoctor ?
                                        user.firstName ? user.label : ''
                                        :
                                        user.firstName + user.lastName
                                    }
                                </Typography>

                                <Typography variant="t3s" color={defaultTheme.palette.primary.light}>
                                    {isDoctor ?
                                        user.specialities?.length ? (user.specialities[0].name ?? "") : ''
                                        :
                                        `${user.birthDate ? (getAge(user.birthDate) + " " + intl.formatMessage({id: "age"})) : ""} ${intl.formatMessage({id: `cabinet.account.form.gender.${user.gender}`})}`
                                    }
                                </Typography>
                            </EntityInfo>
                        </TooltipedCard>

                        <TooltipedCard
                            tooltip={
                                <Link to={`${locale && locale !== 'de' ? '/' + locale : ''}/${userType + "s"}/${identifier}/edit?tab=${isDoctor ? 'experience' : 'insurance'}`}>
                                    <PenIcon style={{marginRight: "10px"}}/>
                                    <Typography
                                        variant="button"
                                        color={defaultTheme.palette.secondary.main}
                                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "cabinet.dashboard.doctor.cards.edit"})}}
                                    />
                                </Link>
                            }
                        >
                            <EntityInfo
                                pictureUrl={isDoctor ? '' : (user?.insurances?.length ? axios.defaults.baseURL + user.insurances[0].logo?.contentUrl : '')}
                                fio={user?.experiences?.length ? user.experiences[0].clinic?.title : ''}
                                bgcolor="#00B9FF"
                                imgContain
                            >
                                {isDoctor ?
                                    <>
                                        <Typography variant="label" color={defaultTheme.palette.primary.light1} sx={{display: 'block'}}>
                                            {intl.formatMessage({id: "cabinet.dashboard.doctor.card.location"})}
                                        </Typography>
                                        <Typography variant="t3">
                                            {user?.experiences?.length ? user.experiences[0].clinic?.title : ''}
                                        </Typography>
                                        <Typography variant="t3s" color={defaultTheme.palette.primary.light}>
                                            {user?.experiences?.length ?
                                                (user.experiences[0].country ? user.experiences[0].country + ', ' : '') +
                                                (user.experiences[0].city ? user.experiences[0].city + ', ' : '') +
                                                (user.experiences[0].clinic?.location ? user.experiences[0].clinic?.location + ', ' : '') +
                                                (user.experiences[0].zipCode ? user.experiences[0].zipCode : '')
                                                : ""
                                            }
                                        </Typography>
                                    </>
                                    :
                                    <>
                                        <Typography variant="label" color={defaultTheme.palette.primary.light1} sx={{display: 'block'}}>
                                            {intl.formatMessage({ "id": "insurancePlan" })}
                                        </Typography>

                                        <Typography variant="t3">
                                            {user?.insurances?.length ? user.insurances[0]?.title : ''}
                                        </Typography>
                                    </>
                                }
                            </EntityInfo>
                        </TooltipedCard>
                    </div>
                }
            </section>
            {isActiveUser &&
            <>
                {(activeAppointmentCard === null || typeActiveAppointmentCard === "upcomingAppointments") &&
                    <section className={st.section}>
                        <Typography
                            variant="h5"
                            className={st.section__title}
                            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.dashboard.doctor.upcomingVisits.title" }) }}
                        />

                        <AppointmentList
                            appointmentsType="upcomingAppointments"
                            pageType="dashboard"
                            activeAppointmentCard={activeAppointmentCard}
                            setActiveAppointmentCard={setActiveAppointmentCard}
                            setTypeActiveAppointmentCard={setTypeActiveAppointmentCard}
                        />
                    </section>
                }

                {(activeAppointmentCard === null || typeActiveAppointmentCard === "pastAppointments") &&
                    <section className={st.section}>
                        <Typography
                            variant="h5"
                            className={st.section__title}
                            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "cabinet.dashboard.doctor.latestVisits.title" }) }}
                        />

                        <AppointmentList
                            appointmentsType="pastAppointments"
                            pageType="dashboard"
                            activeAppointmentCard={activeAppointmentCard}
                            setActiveAppointmentCard={setActiveAppointmentCard}
                            setTypeActiveAppointmentCard={setTypeActiveAppointmentCard}
                        />
                    </section>
                }

                {(isDoctor && doctorProfileViewModel.myApprovalRequests.length > 0) &&
                    <section className={st.section}>
                        <Typography
                            variant="h5"
                            className={st.section__title}
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "cabinet.dashboard.doctor.skillsApproval.title"})}}
                        />

                        {doctorProfileViewModel.myApprovalRequests?.map((item, index) => (
                            <TooltipedCard
                                key={`approval_${index}`}
                                variant="bordered"
                                buttons={
                                    <Link to={{ pathname: `${locale && locale !== 'de' ? '/' + locale : ''}${NonAuthRoutes.doctors}/${item.whoRequested.id}`, hash: "#skills" }}>
                                        <Button variant="contained" color="secondary">
                                            {intl.formatMessage({id: "cabinet.dashboard.doctor.skillsApproval.approve"})}
                                        </Button>
                                    </Link>
                                }
                                tooltip={''}
                            >
                                <Link className={st.connection_item} to={(locale && locale !== 'de' ? '/' + locale : '') + NonAuthRoutes.doctors + '/' + (item.whoRequested?.id !== user.id ? item.whoRequested?.id : item.whom?.id)}>
                                    <EntityInfo
                                        pictureUrl={item.whoRequested.profile?.avatar?.contentUrl ?
                                            axios.defaults.baseURL + item.whoRequested.profile.avatar.contentUrl
                                            : ""
                                        }
                                        avatarSize={64}
                                        fio={item.whoRequested?.profile?.firstName?.slice(0, 1) + item.whoRequested?.profile?.lastName?.slice(0, 1)}
                                    >
                                        <Typography variant="t3">
                                            {item.whoRequested.label}
                                        </Typography>
                                    </EntityInfo>
                                </Link>
                            </TooltipedCard>
                        ))}
                    </section>
                }
            </>
          }
        </>
    );
};

export default observer(Dashboard);

import FaqModel from "domain/entity/faq/FaqModel";
import FaqRespository from "domain/repository/faq/FaqRepository";

export default class ManageFaqUseCase {
    private faqRepository: FaqRespository;
    private faqModel: FaqModel;

    constructor(faqRepository: FaqRespository, faqModel: FaqModel) {
        this.faqRepository = faqRepository;
        this.faqModel = faqModel;
    }

    public loadPopularQustions = () => {
        this.faqRepository.loadPopularQustions().then((questions) => {
            this.faqModel.updatePopularQuestions(questions);
        });
    };

    public loadCategories = () => {
        this.faqRepository.loadCategories().then((categories) => {
            this.faqModel.updateCategories(categories);
        });
    };

    public loadQuestionsByCategory = (category: string) => {
        this.faqRepository.loadQuestionsByCategory(category).then((questions) => {
            this.faqModel.updateCategorizedQuestions(questions);
        });
    };

    public loadQuestionsForDoctorsCategory = () => {
        this.faqRepository.loadQuestionsForDoctorsCategory().then((questions) => {
            this.faqModel.updateCategorizedQuestions(questions);
        });
    };
}

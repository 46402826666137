// @ts-nocheck
import * as React from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link, useHistory } from 'react-router-dom';
import { NonAuthRoutes } from 'presentation/routes/routes';
import { useViewModels } from 'presentation/view-model/ViewModelProvider';
import { Field, FormikProvider, useFormik } from 'formik';
import { InputAdornment, IconButton } from '@mui/material';

import { ReactComponent as GoogleIcon } from '../../../assets/images/icons/social_google.svg';
import { ReactComponent as FbIcon } from '../../../assets/images/icons/social_fb.svg';
import { ReactComponent as EyeIcon } from '../../../assets/images/icons/eye.svg';
import { ReactComponent as EyeIconOff } from '../../../assets/images/icons/eye-off.svg';
import st from '../Auth.module.scss';
import { defaultTheme } from 'presentation/views/layouts/themes/primaryMuiTheme';
import { LoginSocialGoogle, LoginSocialFacebook, IResolveParams, TypeCrossFunction } from 'reactjs-social-login';
import validator from "validator";
import cleanDeep from "clean-deep";

const SignIn = () => {
    const intl = useIntl();
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    const {
        authViewModel: {
            onClickSignIn,
            onClickGoogleLogin,
            onClickFaceBookLogin,
        },
        userViewModel: { locale },
        uIViewModel: {
            setErrorAlertContent,
            setErrorAlertContentTime,
            alertContentTime,
        }
    } = useViewModels();

    const validate = (values: any): object => {
        const errors: any = {
            email: (() => {
                if (!values.email) return intl.formatMessage({id: 'required'});
                if (!validator.isEmail(values.email)) return intl.formatMessage({id: 'errors.email'});
            })(),
            password: (() => {
                if (!values.password) return intl.formatMessage({id: 'required'});
            })(),
        };

        // убираем пустые поля
        return cleanDeep(errors);
    };

    const form = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: validate,
        onSubmit: (values, { setErrors }) => {
            setErrorAlertContent('', true);
            onClickSignIn(values.email, values.password).catch((errorMessage) => {
                setErrorAlertContentTime(errorMessage, alertContentTime);
            });
        },
    });

    const history = useHistory();
    const googleRef = React.useRef<TypeCrossFunction>(null!);
    const facebookRef = React.useRef<TypeCrossFunction>(null!);

    const onLoginStart = React.useCallback(() => {
        console.log('login start');
    }, []);

    const onLoginSuccess = ({ provider, data }: IResolveParams) => {
        if (provider === 'google') {
            onClickGoogleLogin(data?.access_token, null).then((response) => {
                if (response === 'need type') {
                    history.push({
                        pathname: (locale && locale !== 'de' ? '/' + locale : '') + NonAuthRoutes.signup,
                        state: {
                            token: data.access_token,
                            social: 'Google',
                        },
                    });
                }
            });
        } else if (provider === 'facebook') {
            onClickFaceBookLogin(data?.accessToken, null).then((response) => {
                console.log(response)
                if (response === 'need type') {

                    history.push({
                        pathname: (locale && locale !== 'de' ? '/' + locale : '') + NonAuthRoutes.signup,
                        state: {
                            token: data.accessToken,
                            social: 'Facebook',
                        },
                    });
                }
            });
        }
    };

    const onLoginFailure = (response: any) => {
        console.log(response);
    };

    return (
        <Grid container component='main' sx={{ minHeight: '100vh' }}>
            <Grid item xs={12} md={5} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                    sx={[
                        {
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            maxWidth: 480,
                        },
                        (theme) => ({
                            [theme.breakpoints.down('md')]: {
                                maxWidth: 'none',
                            },
                        }),
                    ]}
                >
                    <Typography
                        component='h2'
                        variant='h4'
                        sx={{ mb: { xs: 3, md: 6 } }}
                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signin.signIn' }) }}
                    />

                    <FormikProvider value={form}>
                        <Box
                            component='form'
                            noValidate
                            onSubmit={form.handleSubmit}
                            onChange={(event: any) => {
                                if (form.errors) {
                                    form.setFieldError(event.target.name, '');
                                }
                            }}
                            onKeyDown={(event: any) => {
                                if (event.key === 'Enter') {
                                    form.handleSubmit();
                                }
                            }}
                        >
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <Grid container sx={{ mt: -4, mb: { sm: 2, lg: 6 } }} spacing={3}>
                                        <Grid item xs={12} className={st.social_label}>
                                            <Typography variant='label' color={defaultTheme.palette.primary.light1}>
                                                {intl.formatMessage({ id: 'auth.signin.signInWith' })}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <LoginSocialFacebook
                                                ref={facebookRef}
                                                appId={process.env.REACT_APP_FACEBOOK_ID || ''}
                                                fieldsProfile={'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'}
                                                onLoginStart={onLoginStart}
                                                onResolve={onLoginSuccess}
                                                onReject={onLoginFailure}
                                            >
                                                <Button className={st.social_button} color='secondary' variant='outlined'>
                                                    <FbIcon />
                                                    <span
                                                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signin.facebook' }) }} />
                                                </Button>
                                            </LoginSocialFacebook>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <LoginSocialGoogle
                                                ref={googleRef}
                                                client_id={process.env.REACT_APP_GOOGLE_ID || ''}
                                                onLoginStart={onLoginStart}
                                                onResolve={onLoginSuccess}
                                                onReject={onLoginFailure}
                                            >
                                                <Button className={st.social_button} color='secondary' variant='outlined'>
                                                    <GoogleIcon />
                                                    <span
                                                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signin.google' }) }} />
                                                </Button>
                                            </LoginSocialGoogle>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        name='email'
                                        children={(fieldProps: any) => (
                                            <TextField
                                                {...fieldProps.field}
                                                required
                                                fullWidth
                                                label={<span
                                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signin.email' }) }} />}
                                                variant='standard'
                                                error={!form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                helperText={!form.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        type={showPassword ? 'text' : 'password'}
                                        name='password'
                                        children={(fieldProps: any) => (
                                            <TextField
                                                {...fieldProps.field}
                                                sx={{ mb: 2 }}
                                                required
                                                fullWidth
                                                name='password'
                                                label={<span
                                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signin.password' }) }} />}
                                                type={showPassword ? 'text' : 'password'}
                                                variant='standard'
                                                error={!form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                helperText={!form.isValid && fieldProps.meta.error}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <IconButton
                                                                aria-label='toggle password visibility'
                                                                onClick={handleClickShowPassword}
                                                                edge='end'
                                                                className={st.button_eye}
                                                                tabIndex={-1}
                                                            >
                                                                {!showPassword ? <EyeIconOff /> : <EyeIcon />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                    <Typography variant='label'>
                                        <Link
                                            to={(locale && locale !== 'de' ? '/' + locale : '') + NonAuthRoutes.forgotPassword}
                                            className={st.forgot_link}
                                            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signin.forgotPassword' }) }}
                                        />
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button
                                        onClick={() => {
                                            form.handleSubmit();
                                        }}
                                        fullWidth
                                        color='secondary'
                                        variant='contained'
                                        sx={{ width: '100%', mt: { md: 6 }, mb: 3 }}
                                    >
                                        <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'app.header.signIn' }) }} />
                                    </Button>

                                    <Grid item xs={12} textAlign='center'>
                                        <Link
                                            to={(locale && locale !== 'de' ? '/' + locale : '') + NonAuthRoutes.signup}
                                            className={st.create_link}
                                            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signin.createAccount' }) }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        {/*<pre style={{color: "saddlebrown", }}>{JSON.stringify(form.values, 0, 2)}</pre>*/}
                    </FormikProvider>
                </Box>
            </Grid>
            <Grid item xs={false} md={6} className={st.media}>
                <svg className={st.media_circle_1} viewBox='0 0 577 577' xmlns='http://www.w3.org/2000/svg'>
                    <circle opacity='.32' cx='288.293' cy='288.294' r='287.5' transform='rotate(45 288.293 288.294)' stroke='#fff' fill='none' />
                </svg>
                <svg className={st.media_circle_2} viewBox='0 0 468 545' xmlns='http://www.w3.org/2000/svg'>
                    <circle opacity='0.32' cx='180' cy='288.294' r='287.5' transform='rotate(45 180 288.294)' stroke='#fff' fill='none' />
                </svg>
                <div className={st.media__picture}>
                    <img src={`/images/Auth/login.png`} alt='log in' />
                </div>
            </Grid>
        </Grid>
    );
};

export default observer(SignIn);

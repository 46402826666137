// @ts-nocheck
import {observer} from "mobx-react";
import AuthRoute from "presentation/routes/AuthRoute";
import {AuthRoutes} from "presentation/routes/routes";
import React from "react";
import {UserRoles} from "types";
import LandingLayout from "presentation/views/layouts/landing/LandingLayout";
import {Redirect, Switch} from "react-router-dom";

// Pages/Common
import Security from "./Common/Security";
import Dashboard from "./Common/Dashboard";
// import Notifications from "./Common/Notifications";
import Appointments from "./Common/Appointments";
import Schedule from "./Common/Schedule";
import Referral from "./Common/Referral";

// styles
import st from "./cabinet.module.scss";
import CabinetLayout from "presentation/views/layouts/cabinet/CabinetLayout";
import Connections from "./Common/Connections";
import {useViewModels} from "../../../view-model/ViewModelProvider";
import Patients from "./Common/Patients";
import Team from "./Common/Team";

const Cabinet: React.FC = () => {
    const { userViewModel: {localeUrl} } = useViewModels();

    return (
        <LandingLayout userMobileMenu={true}>
            <div className={st.cabinet}>
                <CabinetLayout>
                    <Switch>
                        {/* <AuthRoute Component={Notifications} path={AuthRoutes.notifications} requiredRoles={[UserRoles.doctor, UserRoles.patient]} /> */}
                        <AuthRoute Component={Dashboard} path={AuthRoutes.dashboard} requiredRoles={[UserRoles.doctor, UserRoles.patient]} />
                        <AuthRoute Component={Dashboard} path={AuthRoutes.dashboardLocale} requiredRoles={[UserRoles.doctor, UserRoles.patient]} />

                        <AuthRoute Component={Appointments} path={AuthRoutes.bookingAppointments} requiredRoles={[UserRoles.doctor, UserRoles.patient]} />
                        <AuthRoute Component={Appointments} path={AuthRoutes.bookingAppointmentsLocale} requiredRoles={[UserRoles.doctor, UserRoles.patient]} />

                        <AuthRoute Component={Schedule} path={AuthRoutes.schedule} requiredRoles={[UserRoles.doctor, UserRoles.patient]} />
                        <AuthRoute Component={Schedule} path={AuthRoutes.scheduleLocale} requiredRoles={[UserRoles.doctor, UserRoles.patient]} />

                        <AuthRoute Component={Connections} path={AuthRoutes.connections} requiredRoles={[UserRoles.doctor]} />
                        <AuthRoute Component={Connections} path={AuthRoutes.connectionsLocale} requiredRoles={[UserRoles.doctor]} />

                        <AuthRoute Component={Patients} path={AuthRoutes.patients} requiredRoles={[UserRoles.doctor]} />
                        <AuthRoute Component={Patients} path={AuthRoutes.patientsLocale} requiredRoles={[UserRoles.doctor]} />

                        <AuthRoute Component={Team} path={AuthRoutes.team} requiredRoles={[UserRoles.patient]} />
                        <AuthRoute Component={Team} path={AuthRoutes.teamLocale} requiredRoles={[UserRoles.patient]} />

                        <AuthRoute Component={Referral} path={AuthRoutes.referral} requiredRoles={[UserRoles.doctor, UserRoles.patient]} />
                        <AuthRoute Component={Referral} path={AuthRoutes.referralLocale} requiredRoles={[UserRoles.doctor, UserRoles.patient]} />

                        <AuthRoute Component={Security} path={AuthRoutes.accountSettings} requiredRoles={[UserRoles.doctor, UserRoles.patient]} />
                        <AuthRoute Component={Security} path={AuthRoutes.accountSettingsLocale} requiredRoles={[UserRoles.doctor, UserRoles.patient]} />

                        <Redirect to={localeUrl(AuthRoutes.dashboard)} />
                    </Switch>
                </CabinetLayout>
            </div>
        </LandingLayout>
    );
};

export default observer(Cabinet);

import { Avatar } from '@mui/material';
import React from 'react';
import cn from 'classnames/bind';

import st from './entityInfo.module.scss';
import classNames from 'classnames';
import { stringAvatar } from 'presentation/views/helpers/tools';

type EntityInfoProps = {
    pictureUrl?: string;
    avatarSize?: number;
    fio?: string;
    size?: string;
    bgcolor?: string;
    className?: string;
    noHover?: boolean;
    imgContain?: boolean;
    pictureWithBg?: boolean;
};

const EntityInfo: React.FC<EntityInfoProps> = ({ children, pictureUrl, avatarSize, fio, size, bgcolor, className, noHover, imgContain, pictureWithBg }) => {
    return (
        <div className={classNames(st.container, noHover ? `no-hover ${st.no_hover}` : '', className)}>
            <Avatar
                src={pictureUrl}
                className={st.avatar}
                imgProps={{ style: { objectFit: imgContain ? 'contain' : "cover" } }}
                sx={{ bgcolor: (pictureUrl && !pictureWithBg) ? "transparent" : bgcolor ? bgcolor : "#00B9FF", width: { xs: '64px', md: `${avatarSize ?? 80}px` }, height: { xs: '64px', md: `${avatarSize ?? 80}px` } }}
                {...stringAvatar(fio ? fio : '')}
            />

            <div className={cn(st.content, size === 'sm' && st.content_small)}>{children}</div>
        </div>
    );
};

export default EntityInfo;

import ManageMediaUseCase from "domain/interactors/media/ManageMediaUseCase";
import MediaRepository from "domain/repository/media/MediaRepository";
import MediaStructure from "domain/entity/media/structures/MediaStructure";
import {action, makeAutoObservable} from "mobx";
import errorParse from "../../views/helpers/errorParse";

export default class MediaViewModel {
    private manageMediaUseCase: ManageMediaUseCase;
    private mediaRepository: MediaRepository;
    public mediaFile: MediaStructure | undefined;
    public mediaCollections: Array<MediaStructure>;
    public errors = {changeMedia: ''};
    public complete = {changeMedia: false};

    constructor(manageMediaUseCase: ManageMediaUseCase, mediaRepository: MediaRepository) {
        makeAutoObservable(this);
        this.manageMediaUseCase = manageMediaUseCase;
        this.mediaRepository = mediaRepository;
        this.mediaCollections = [];
    }

    getMediaCollections = () => {
        this.manageMediaUseCase.getMediaCollections()
            .then(action((files) => {
                // @ts-ignore
                this.mediaCollections = files.items;
            }))
            .catch((error) => {
                this.errors.changeMedia = errorParse(error);
            });
    }

    addMedia = (data: any) => {
        this.manageMediaUseCase.addMedia(data)
            .then(action((file) => {
                console.log(file)
                // @ts-ignore
                this.mediaFile = file;
            }))
            .catch((error) => {
                this.errors.changeMedia = errorParse(error);
            });
    }

    getMediaById = (id: string) => {
        return this.mediaRepository
            .getMediaById(id)
            .then((object) => {
                console.log(object)
                this.errors.changeMedia = '';
                this.complete.changeMedia = true;
            })
            .catch((error) => {
                this.errors.changeMedia = errorParse(error);
            });
    }
}

import InsuranceRepository from "../../domain/repository/insurance/InsuranceRepository";
import axios from "axios";
import {Insurance} from "../../domain/entity/insurance/structures/InsuranceStructure";

export default class InsuranceApi implements InsuranceRepository {
    getInsurances = async (): Promise<[Insurance[], []]> => {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + 'api/insurances?perPage=500',
        }).then((insurances: any) => {
            return insurances.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    getInsurancesPromise = async (): Promise<[]> => {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + 'api/insurances?perPage=500',
        }).then((insurances: any) => {
            return insurances.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }
}

import ConnectionRepository from "domain/repository/connection/ConnectionRepository";
import {Connection} from "../../entity/connections/structures/ConnectionStructure";
import {SubmissionError} from "../CustomErrors";

export default class ManageConnectionUseCase {
    private connectionRepository: ConnectionRepository;

    constructor(connectionRepository: ConnectionRepository) {
        this.connectionRepository = connectionRepository;
    }

    askToApproveSkills = (doctorId: string) => {
        return this.connectionRepository
            .askToApproveSkills(doctorId)
            .then((object) => {
                return Promise.resolve(object);
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error.validationErrors, (error?.data?.message ? error.data.message : (error?.message ? error.message : 'Unknown error'))))
            })
    }

    getMyConnections = () => {
        return this.connectionRepository
            .getMyConnections()
            .then((connections: [Connection[], []]) => {
                return connections;
            })
    }

    getConnectionsRequests = () => {
        return this.connectionRepository
            .getConnectionsRequests()
            .then((connections: [Connection[], []]) => {
                return connections;
            })
    }

    getOutgoingConnectionsRequests = () => {
        return this.connectionRepository
            .getOutgoingConnectionsRequests()
            .then((connections: [Connection[], []]) => {
                return connections;
            })
    }

    postConnection = (recipientIri: string): Promise<void> => {
        return this.connectionRepository
            .postConnection(recipientIri)
            .then((object) => {
                return Promise.resolve(object);
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error.validationErrors, (error?.data?.message ? error.data.message : (error?.message ? error.message : 'Unknown error'))))
            })
    }
}

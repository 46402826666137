// @ts-nocheck
import React from "react";
import { useIntl } from "react-intl";
import { Button } from "@mui/material";
import st from "../../Private/UserEdit/userEdit.module.scss";


export const UserSaveButtons = ({setSaveAndNext}) => {
    const intl = useIntl();

    return (
        <div className={st.save_buttons}>
            <Button type="submit" color="secondary" variant="contained">
                {intl.formatMessage({ id: "cabinet.profile.doctor.submit" })}
            </Button>

            <Button type="submit" color="secondary" variant="contained"
                    onClick={(e) => setSaveAndNext(true)}
            >
                {intl.formatMessage({ id: "saveAndNext" })}
            </Button>
        </div>
    );
};

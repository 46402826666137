import axios from "axios";
import MediaRepository from "domain/repository/media/MediaRepository";
import MediaStructure from "domain/entity/media/structures/MediaStructure";

class MediaApi implements MediaRepository {
    getMediaCollections = async (): Promise<[MediaStructure[], []]> => {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + 'api/media_objects',
        }).then((result: any) => {
            return result.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }
    addMedia = (data: any): Promise<MediaStructure> => {
        return axios({
            method: "POST",
            url: "api/media_objects",
            data: JSON.stringify({file: data}),
        })
            .then((result: any): MediaStructure => {
                return { id: result.data["id"], contentUrl: result.data["contentUrl"] };
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };
    getMediaById = (id: string): Promise<MediaStructure> => {
        return axios({
            method: "GET",
            url: `api/media_objects/${id}`
        })
            .then((result: any): MediaStructure => {
                return { id: result.data["id"], contentUrl: result.data["contentUrl"] };
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    };
}

export default MediaApi;

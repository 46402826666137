import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import LandingLayout from 'presentation/views/layouts/landing/LandingLayout';
import HeroHeader from 'presentation/views/components/HeroHeader';
import { useViewModels } from 'presentation/view-model/ViewModelProvider';
import st from './specialities.module.scss';
import { observer } from 'mobx-react';
import { SpecialtiesList } from '../../components/SpecialtiesList';

const Specialities: React.FC = () => {
    const [letterContentHeight, setLetterContentHeight] = useState('0');
    const specialtiesContent = useRef<HTMLDivElement>(null);
    const {
        uIViewModel: { mobileIs },
        lakeViewModel,
        userViewModel: {locale},
    } = useViewModels();

    useEffect(() => {
        lakeViewModel.loadLetters();
    }, [lakeViewModel]);

    useEffect(() => {
        setLetterContentHeight("0px");

        if (lakeViewModel.activeLetter) {
            lakeViewModel.loadDoctorSpecialtiesByLetter(lakeViewModel.activeLetter, locale);
        }

        const activeSpecialties = setInterval(() => {
            if (lakeViewModel.activeSpecialties) {
                clearInterval(activeSpecialties);

                setTimeout(() => {
                    if (specialtiesContent?.current) {
                        setLetterContentHeight(`${specialtiesContent.current.scrollHeight}px`);
                    }
                }, 300);
            }
        }, 50);

        setTimeout(() => { clearInterval(activeSpecialties);}, 5000);
    }, [lakeViewModel.activeLetter, lakeViewModel, mobileIs, locale]);

    return (
        <LandingLayout>
            <HeroHeader caption="specialties.title" desc="" className={st.header} />

            <div className={st.container}>
                <section className={st.alphabet}>
                    {lakeViewModel.availableLetters?.map((value, index) => {
                        return (
                            <button
                                className={`${st.letter}
                                    ${value === lakeViewModel.activeLetter ? st.letter_active : ''}`}
                                onClick={() => lakeViewModel.updateActiveLetter(value)}
                                key={index}
                            >
                                <div>{value}</div>
                            </button>
                        );
                    })}
                </section>

                <section className={st.alphabet_content}>
                    <div className={st.letter_title}>{lakeViewModel.activeLetter}</div>

                    <div ref={specialtiesContent} className={classNames(st.letter_content, 'letter-content')} style={{ minHeight: letterContentHeight, maxHeight: letterContentHeight }}>
                        <SpecialtiesList data={lakeViewModel?.activeSpecialties} className={st.specialization} />
                    </div>
                </section>
            </div>
        </LandingLayout>
    );
};

export default observer(Specialities);

import React, { useEffect } from "react";
import  { useIntl } from "react-intl";
import { observer } from "mobx-react";
import st from "./landingLayout.module.scss";
import "../../footerAccordion.scss";

import { ReactComponent as Logo } from "presentation/views/assets/images/logo.svg";
import { ReactComponent as FBIcon } from "presentation/views/assets/images/icons/fb.svg";
import { ReactComponent as InstagramIcon } from "presentation/views/assets/images/icons/instagram.svg";
import { ReactComponent as InIcon } from "presentation/views/assets/images/icons/in.svg";
import { ReactComponent as Basis33 } from "presentation/views/assets/images/icons/basis33.svg";
import { ReactComponent as AMP } from "presentation/views/assets/images/icons/amp.svg";
import { ReactComponent as ExpandIcon } from "presentation/views/assets/images/icons/expand.svg";
import { ReactComponent as InfoIcon } from "presentation/views/assets/images/icons/info.svg";
import { ReactComponent as CrossIcon } from "presentation/views/assets/images/icons/cross.svg";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Link } from "react-router-dom";
import { useViewModels } from "presentation/view-model/ViewModelProvider";
import { HeaderBar } from "../../components/AppBar";
import { NonAuthRoutes } from "../../../routes/routes";
import classNames from "classnames";

interface LandingLayoutProps {
    children?: any;
    hasNavBar?: boolean;
    userMobileMenu?: boolean;
    headerColor?: string;
}


const LandingLayout: React.FC<LandingLayoutProps> = ({
    children,
    hasNavBar,
    userMobileMenu,
    headerColor = '#09299C'
}) => {
    const intl = useIntl();
    const {
        uIViewModel: {
            mobileMenuIsOpen,
            mobileDoctorsSearchIsOpen,
            popupActive,
            mediaQuery,
            mediaQueryTablet,
            mobileIs,
            setMobileIs,
            setTabletIs,
            setDevices,
            successAlertContent,
            setSuccessAlertContent,
            errorAlertContent,
            setErrorAlertContent,
        },
        authViewModel: { isUserAuthorized },
        userViewModel: { locale },
    } = useViewModels();

    const [expanded, setExpanded] = React.useState("");

    const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : "");
    };

    useEffect(() => {
        const mediaMobile = window.matchMedia(mediaQuery);
        const mediaTablet = window.matchMedia(mediaQueryTablet);

        const handleMobile = (query: any) => {
            setMobileIs(query.matches);
        }

        const handleTablet = (query: any) => {
            setTabletIs(query.matches);
        }

        handleMobile(mediaMobile);
        handleTablet(mediaTablet);

        mediaMobile.addEventListener("change", handleMobile);
        mediaTablet.addEventListener("change", handleTablet);

        return () => {
          mediaMobile.removeEventListener("change", handleMobile);
          mediaTablet.removeEventListener("change", handleTablet);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        function handleResize() {
            setDevices(Boolean('ontouchstart' in window || navigator.maxTouchPoints > 0));
        }

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    });

    return (
        <div className={classNames(st.layout, (mobileMenuIsOpen || mobileDoctorsSearchIsOpen || popupActive) ? st.disabled : "")}>
            {hasNavBar !== false &&
                <HeaderBar userMobileMenu={userMobileMenu} bgColor={headerColor} />
            }

            {(successAlertContent || errorAlertContent) &&
                <div className={classNames('alert', (errorAlertContent ? 'alert_error' : ''))}>
                    <div className={'alert__info'}>
                        <InfoIcon />
                        <div>{successAlertContent || errorAlertContent}</div>
                    </div>

                    <CrossIcon className={classNames('hover-stroke hover-stroke-deep', 'alert__close')}
                               onClick={() => {successAlertContent ? setSuccessAlertContent('') : setErrorAlertContent('')}}
                    />
                </div>
            }

            {children}

            <footer className={st.footer}>
                <div className={st.footer__container}>
                    <div className={st.footer__logo}>
                        <Link to={"/"}>
                            <Logo />
                        </Link>
                    </div>

                    <div className={st.footer__main}>
                        {!mobileIs ? (
                            <>
                                <ul className={st.footer__section}>
                                    <li className={st.footer__section_title}>DeinArzt</li>
                                    <li className={st.footer__subsection_title}>
                                        <Link to={NonAuthRoutes.about}
                                              dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.about" }) }}></Link>
                                    </li>
                                    <li className={st.footer__subsection_title}>
                                        <Link to={NonAuthRoutes.faq}
                                              dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.faq" }) }}></Link>
                                    </li>
                                    <li className={st.footer__subsection_title}>
                                        <Link to={NonAuthRoutes.news}
                                              dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.news" }) }}></Link>
                                    </li>
                                    <li className={st.footer__subsection_title}>
                                        <Link to={NonAuthRoutes.contacts}
                                              dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.contacts" }) }}></Link>
                                    </li>
                                </ul>

                                <ul className={st.footer__section}>
                                    <li className={st.footer__section_title}
                                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.forPatients" }) }}></li>
                                    <li className={st.footer__subsection_title}>
                                        <Link to={NonAuthRoutes.doctors}
                                              dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.doctors" }) }}></Link>
                                    </li>
                                    <li className={st.footer__subsection_title}>
                                        <Link to={NonAuthRoutes.specialities}
                                              dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.specialties" }) }}></Link>
                                    </li>

                                    {
                                        (!isUserAuthorized) && (
                                            <li className={st.footer__subsection_title}>
                                                <Link to={`${locale && locale !== 'de' ? "/" + locale : ''}${NonAuthRoutes.signup}?type=patient`}
                                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.patientsSignUp" }) }}></Link>
                                            </li>
                                        )
                                    }
                                </ul>

                                <ul className={st.footer__section}>
                                    <li className={st.footer__section_title}
                                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.forDoctors" }) }}></li>
                                    <li className={st.footer__subsection_title}>
                                        <Link to={NonAuthRoutes.forDoctors}
                                              dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.information" }) }}></Link>
                                    </li>
                                    <li className={st.footer__subsection_title}>
                                        <Link to={NonAuthRoutes.home}
                                              dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.startWork" }) }}></Link>
                                    </li>
                                    <li className={st.footer__subsection_title}>
                                        <Link to={NonAuthRoutes.home}
                                              dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.partnership" }) }}></Link>
                                    </li>

                                    {
                                        (!isUserAuthorized) && (
                                            <li className={st.footer__subsection_title}>
                                                <Link to={`${locale && locale !== 'de' ? "/" + locale : ''}${NonAuthRoutes.signup}?type=doctor`}
                                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.doctorsSignUp" }) }}></Link>
                                            </li>
                                        )
                                    }
                                </ul>

                                <ul className={st.footer__section}>
                                    <li className={st.footer__section_title}
                                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.discover" }) }}></li>
                                    <li className={st.footer__subsection_title}>
                                        <Link to={NonAuthRoutes.privacyPolicy}
                                              dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.privacy" }) }}></Link>
                                    </li>
                                    <li className={st.footer__subsection_title}>
                                        <Link to={NonAuthRoutes.termsOfUse}
                                              dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.terms" }) }}></Link>
                                    </li>
                                </ul>
                            </>
                        ) : (
                            <>
                                <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")} className="footer-accordion">
                                    <AccordionSummary expandIcon={<ExpandIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                                        <div>DeinArzt</div>
                                        {/* <Typography>Nunc vitae orci ultricies.</Typography>*/}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ul className={st.footer__section}>
                                            <li className={st.footer__subsection_title}>
                                                <Link to={NonAuthRoutes.about}
                                                      dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.about" }) }}></Link>
                                            </li>
                                            <li className={st.footer__subsection_title}>
                                                <Link to={NonAuthRoutes.faq}
                                                      dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.faq" }) }}></Link>
                                            </li>
                                            <li className={st.footer__subsection_title}>
                                                <Link to={NonAuthRoutes.news}
                                                      dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.news" }) }}></Link>
                                            </li>
                                            <li className={st.footer__subsection_title}>
                                                <Link to={NonAuthRoutes.contacts}
                                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.contacts" }) }}></Link>
                                            </li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")} className="footer-accordion">
                                    <AccordionSummary expandIcon={<ExpandIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                                        <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.forPatients" }) }}></div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ul className={st.footer__section}>
                                            <li className={st.footer__subsection_title}>
                                                <Link to={NonAuthRoutes.doctors}
                                                      dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.doctors" }) }}></Link>
                                            </li>
                                            <li className={st.footer__subsection_title}>
                                                <Link to={NonAuthRoutes.specialities}
                                                      dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.specialties" }) }}></Link>
                                            </li>
                                            {
                                                (!isUserAuthorized) && (
                                                    <li className={st.footer__subsection_title}>
                                                        <Link to={`${locale && locale !== 'de' ? "/" + locale : ''}${NonAuthRoutes.signup}?type=patient`}
                                                            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.patientsSignUp" }) }}></Link>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")} className="footer-accordion">
                                    <AccordionSummary expandIcon={<ExpandIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
                                        <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.forDoctors" }) }}></div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ul className={st.footer__section}>
                                            <li className={st.footer__subsection_title}>
                                                <Link to={NonAuthRoutes.forDoctors}
                                                      dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.information" }) }}></Link>
                                            </li>
                                            <li className={st.footer__subsection_title}>
                                                <Link to={NonAuthRoutes.home}
                                                      dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.startWork" }) }}></Link>
                                            </li>
                                            <li className={st.footer__subsection_title}>
                                                <Link to={NonAuthRoutes.home}
                                                      dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.partnership" }) }}></Link>
                                            </li>
                                            {
                                                (!isUserAuthorized) && (
                                                    <li className={st.footer__subsection_title}>
                                                        <Link to={`${locale && locale !== 'de' ? "/" + locale : ''}${NonAuthRoutes.signup}?type=doctor`}
                                                            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.doctorsSignUp" }) }}></Link>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")} className="footer-accordion">
                                    <AccordionSummary expandIcon={<ExpandIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                                        <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.discover" }) }}></div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ul className={st.footer__section}>
                                            <li className={st.footer__subsection_title}>
                                                <Link to={NonAuthRoutes.privacyPolicy}
                                                      dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.privacy" }) }}></Link>
                                            </li>
                                            <li className={st.footer__subsection_title}>
                                                <Link to={NonAuthRoutes.termsOfUse}
                                                      dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.menu.terms" }) }}></Link>
                                            </li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        )}
                    </div>

                    <div className={st.footer__contacts}>
                        <div className={st.footer__email}><a href="mailto:info@deinarzt.org">info@deinarzt.org</a></div>
                        <div className={st.footer__social}>
                            <div className={st.footer__social_icon}>
                                <a href="https://www.facebook.com/deinarzt.org" target="_blank" rel="noreferrer"><FBIcon /></a>
                            </div>
                            <div className={st.footer__social_icon}>
                                <a href="https://www.instagram.com/deinarzt_org/" target="_blank" rel="noreferrer"><InstagramIcon /></a>
                            </div>
                            <div className={st.footer__social_icon}>
                                <a href="https://www.linkedin.com/company/deinarzt-org" target="_blank" rel="noreferrer"><InIcon /></a>
                            </div>
                        </div>
                    </div>
                </div>

                <footer className={st.footer__additional_data}>
                    <div className={st.footer__footer_container}>
                        <div className={st.footer__copyright}>© DeinArzt {new Date().getFullYear()}</div>
                        <div className={st.footer__developer}>
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "app.footer.websiteBy" }) }}></span>
                            <a href="https://basis33.com/"><Basis33 /></a>
                            <span>&</span>
                            <a href="https://alpmarketingpartners.com"><AMP /></a>
                        </div>
                    </div>
                </footer>
            </footer>
        </div>
    );
};

export default observer(LandingLayout);

import axios from "axios";
import PatientProfileRepository from "domain/repository/profile/patient/PatientProfileRepository";
import { Profile } from "domain/entity/profile/patient/structures/PatientProfileStructure";
import "data/settings/axios";
import { tools } from "../../../presentation/views/helpers/tools";

class PatientProfileApi implements PatientProfileRepository {
    loadPatientProfile = async (id: string): Promise<Profile> => {
        return axios({
            method: "get",
            url: `api/patients/${id}`,
        })
            .then(({ data }: any) => {
                return {
                    ...data,
                    about: data.profile?.['about'] ?? '',
                    firstName: data.profile?.['firstName'] ?? '',
                    lastName: data.profile?.['lastName'] ?? '',
                    gender: data.profile?.['gender'] ?? '',
                    birthDate: data.profile?.['birthDate'] ?? '',
                    avatar: data.profile?.['avatar']?.['contentUrl'] ?? '',
                    avatarId: data["profile"]?.["avatar"]?.["id"],
                    profileId: data.profile?.['id'] ?? '',
                };
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    updatePatient = (options: any, id: string): Promise<any> => {
        // options = {...options, profile: {id: 'error test'}};
        return axios({
            method: "put",
            url: `api/patients/${id}`,
            data: JSON.stringify(options),
        })
            .then(({ data }: any) => {
                return {
                    ...data,
                    about: data.profile?.['about'] ?? '',
                    firstName: data.profile?.['firstName'] ?? '',
                    lastName: data.profile?.['lastName'] ?? '',
                    gender: data.profile?.['gender'] ?? '',
                    birthDate: data.profile?.['birthDate'] ?? '',
                    avatar: data.profile?.['avatar']?.['contentUrl'] ?? '',
                    avatarId: data["profile"]?.["avatar"]?.["id"],
                    profileId: data.profile?.['id'] ?? '',
                    insurances: tools.objectToArray(data["insurances"]),
                    spokenLanguages: tools.objectToArray(data["spokenLanguages"]),
                };
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };
}

export default PatientProfileApi;

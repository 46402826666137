// @ts-nocheck
import DoctorsApi from "../../../data/doctors/DoctorsApi";
import { action, makeAutoObservable } from "mobx";
import qs from "qs";
import cleanDeep from "clean-deep";

class DoctorsViewModel {
    public doctors = [];
    public ownDoctors = [];
    public currentDoctor = {};
    public specialities = [];
    public pagination = {
        currentPage: 1,
        hasNextPage: false,
        hasPreviousPage: false,
        itemsPerPage: 8,
        totalItems: 0,
        totalPages: 0
    };
    public searchParameters = {
        spokenLanguages: '',
        'profile.gender': '',
        'order[profile.firstName]': 'ASC',
        'order[hourCost]': '',
        firstSmartInput: '',
        locationSmartInput: '',
        insuranceType: [],
    };
    public pageNumber = 1;
    public loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    getDoctorsWithSchedule = (pageNumber) => {
        this.loading = true;
        this.doctors = [];

        if (pageNumber) {
            this.pageNumber = pageNumber;
        }

        // If 2 types of insurance (all of them possible) are selected at the same time,
        // we consider it as a union, not an intersection.
        // Therefore, we reset the insurance type filters.
/*      Task 2254
        const withStringInsurance = {
            ...this.searchParameters,
            insuranceType: this.searchParameters.insuranceType.length === 1 ? this.searchParameters.insuranceType[0] : ''
        };*/

        const withStringInsurance = {
            ...this.searchParameters,
            insuranceType: this.searchParameters.insuranceType.includes('gesetzlich') ? (this.searchParameters.insuranceType.length === 1 ? 'gesetzlich' : '')  : '',
            enablePrivateInsurance: this.searchParameters.insuranceType.includes('privat') ? (this.searchParameters.insuranceType.length === 1 ? 1 : '')  : '',
        };

        DoctorsApi.getDoctorsWithSchedule(this.pageNumber, qs.stringify(cleanDeep(withStringInsurance), { allowDots: true, encode: false, }))
            .finally(() => this.loading = false)
            .then(action((data) => {
                this.doctors = data.items;
                this.pagination = data.pagination;
            }));
    }

    getOwnDoctors = () => {
        DoctorsApi.getOwnDoctors()
            .then(action((data) => {
                this.ownDoctors = data.items;
            }));
    }

    async getDoctorWithSchedule(doctorId = '', from = '', till = '', scheduleUpdate = false, isDoctorPage = false) {
        await DoctorsApi.getDoctorWithSchedule(doctorId, from, till)
            .then(action((data) => {
                if (scheduleUpdate) {
                    if (isDoctorPage) {
                        this.updateCurrentDoctor({
                            ...this.currentDoctor,
                            scheduleItems: [...this.currentDoctor.scheduleItems, ...data.scheduleItems],
                        });
                        // this.currentDoctor.scheduleItems = [...this.currentDoctor.scheduleItems, ...data.scheduleItems];
                    } else {
                        let currentDoctorIndex = this.doctors.findIndex(item => item.id === doctorId);
                        this.doctors[currentDoctorIndex].scheduleItems = [...this.doctors[currentDoctorIndex].scheduleItems, ...data.scheduleItems];
                    }
                } else {
                    this.currentDoctor = data;
                }
            }));
    }


    getMoreSchedule = (doctorId = '', from = '', till = '', scheduleUpdate = false, isDoctorPage = false) => {
        return DoctorsApi.getDoctorWithSchedule(doctorId, from, till);
    }

    async getDoctorSpecialities(doctorId) {
        await DoctorsApi.getDoctorSpecialities(doctorId)
            .then(action((data) => {
                this.specialities = data.specialityApproves;
                return data
            }))
            .catch((error) => {
                return Promise.reject(error);
            })
    }

    approveSkill = (speciality, doctor, i, whoApproved) => {
        return DoctorsApi.approveSkill(speciality, doctor)
            .then(action((data) => {
                const whoApprovedData = { id: whoApproved.id, profile: whoApproved.profile , label: whoApproved.label };
                this.specialities[i].doctors.push(whoApprovedData);
                this.specialities[i].lastDoctorWhoApproved = whoApprovedData;
                this.specialities[i].approvedByYou = true;
                this.specialities[i].approvesCount++;

                return 'success';
            }));
    }

    removeApproveSkill = (speciality, doctor, i, whoApproved) => {
        return DoctorsApi.removeApproveSkill(speciality, doctor)
            .then(action((data) => {
                this.specialities[i].doctors = this.specialities[i].doctors.filter(item => item.id !== whoApproved.id);

                if (this.specialities[i].lastDoctorWhoApproved?.id === whoApproved.id) {
                    if (this.specialities[i].doctors.length > 0) {
                        this.specialities[i].lastDoctorWhoApproved = this.specialities[i].doctors[0];
                    } else {
                        this.specialities[i].lastDoctorWhoApproved = {};
                    }
                }

                this.specialities[i].approvedByYou = false;
                this.specialities[i].approvesCount--;
                return 'success';
            }))

    }

    @action setFilterOrSort = (parameter, value, submit: boolean) => {
        if (parameter === 'reset') {
            this.searchParameters = {
                spokenLanguages: '',
                'profile.gender': '',
                'order[profile.firstName]': 'ASC',
                'order[hourCost]': '',
                firstSmartInput: '',
                locationSmartInput: '',
                insuranceType: [],
            }
        } else {
            this.searchParameters[parameter] = value;

            if (parameter === 'order[hourCost]') {
                this.searchParameters['order[profile.firstName]'] = '';
            }
        }

        if (submit) {
            this.getDoctorsWithSchedule(1);
        }
    };

    @action updateCurrentDoctor(data: any) {
        this.currentDoctor = data;
    }

}

export default new DoctorsViewModel();

// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import classNames from "classnames";
import LandingLayout from "presentation/views/layouts/landing/LandingLayout";
import st from "./doctors.module.scss";
import { observer } from "mobx-react";
import { Select, MenuItem } from "@mui/material";
import { DoctorPreview } from "../../components/DoctorPreview"
import { ReactComponent as ExpandIcon } from "../../assets/images/icons/expand.svg";
import { ReactComponent as AngleBracket } from "../../assets/images/icons/angle-bracket.svg";
import { DoctorsSearch } from "../../components/DoctorsSearch";
import DoctorsViewModel from "../../../view-model/doctors/DoctorsViewModel";
import { useViewModels } from "../../../view-model/ViewModelProvider";
import { Skeleton } from "../../components/Skeleton";

const Doctors: React.FC = () => {
    const intl = useIntl();
    const { uIViewModel: {mobileIs}, } = useViewModels();
    const pagination = DoctorsViewModel.pagination;
    const [sortBy, setSortBy] = useState('order[profile.firstName]');
    const handleChangeSortBy = (e: any) => {
        setSortBy(e.target.value);
    };

    const getDoctorsByPaginate = (pageNumber) => {
        DoctorsViewModel.getDoctorsWithSchedule(pageNumber);
    }

    useEffect(() => {
        DoctorsViewModel.getDoctorsWithSchedule();
    }, []);


    return (
        <LandingLayout>
            <section className={st.hero_header}>
                <div className={st.hero_header__container}>
                    <DoctorsSearch />
                </div>
            </section>

            <section className={st.main}>
                <div className={st.filters}>
                    <div className={st.filters__count}
                         dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "doctors.counter" }, { count: (pagination.totalItems ? pagination.totalItems : '') }) }}></div>

                    <div className={st.filters__sort}>
                        <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "doctors.sortBy" }) }}></span>
                        <Select
                            MenuProps={{ disableScrollLock: true }}
                            className={st.filters__sorting_by}
                            value={sortBy}
                            id="filters-sorting-by"
                            onChange={handleChangeSortBy}
                            variant="standard"
                            defaultValue="order[profile.firstName]"
                            IconComponent={ExpandIcon}
                        >
                            <MenuItem value="order[profile.firstName]" onClick={() => DoctorsViewModel.setFilterOrSort('order[profile.firstName]', 'ASC', true)}>
                                <span>{intl.formatMessage({ id: "doctors.sortOption.alphabet" })}</span>
                            </MenuItem>

                            <MenuItem value="order[profile.firstName.reverse]" onClick={() => DoctorsViewModel.setFilterOrSort('order[profile.firstName]', 'DESC', true)}>
                                <span>{intl.formatMessage({ id: "doctors.sortOption.alphabetReverse" })}</span>
                            </MenuItem>

                            <MenuItem value="order[hourCost]" onClick={() => DoctorsViewModel.setFilterOrSort('order[hourCost]', 'ASC', true)}>
                                <span>{intl.formatMessage({ id: "doctors.sortOption.price" })} &uarr;</span>
                            </MenuItem>

                            <MenuItem value="order[hourCost.reverse]" onClick={() => DoctorsViewModel.setFilterOrSort('order[hourCost]', 'DESC', true)}>
                                <span>{intl.formatMessage({ id: "doctors.sortOption.price" })} &darr;</span>
                            </MenuItem>
                        </Select>
                    </div>
                </div>

                <div className={st.list}>
                    {DoctorsViewModel.loading ?
                        [...Array(8)].map((x, i) =>
                            <Skeleton key={`skeleton_${i}`} />
                        )
                        :
                        DoctorsViewModel.doctors.length ?
                            DoctorsViewModel.doctors?.map((item, index) => (
                                <DoctorPreview key={item.id} previewIndex={index} mobileIs={mobileIs} doctor={item} />
                            ))
                            :
                            intl.formatMessage({id: 'tip.noResults'})
                    }
                </div>

                {pagination.totalPages > 1 &&
                    <div className={st.paginate}>
                        <div className={classNames(st.paginate__prev, (pagination.hasPreviousPage ? st.paginate__arrow_active : ''))}
                             {...(pagination.hasPreviousPage && { onClick: () => getDoctorsByPaginate(pagination.currentPage - 1) })}
                        >
                            <AngleBracket />
                        </div>

                        <div className={st.paginate__pages}>
                            {(pagination.currentPage > 3) &&
                                <>
                                    <div onClick={() => getDoctorsByPaginate(1)}>1</div>
                                    {(pagination.currentPage > 4) && <div className={st.paginate__ellipsis}>…</div>}
                                </>
                            }

                            {pagination.hasPreviousPage && pagination.currentPage > 2 &&
                                <div onClick={() => getDoctorsByPaginate(pagination.currentPage - 2)}>{pagination.currentPage - 2}</div>
                            }

                            {pagination.hasPreviousPage &&
                                <div onClick={() => getDoctorsByPaginate(pagination.currentPage - 1)}>{pagination.currentPage - 1}</div>
                            }

                            <div className={st.paginate__pages_active}>{pagination.currentPage}</div>

                            {pagination.hasNextPage &&
                                <div onClick={() => getDoctorsByPaginate(pagination.currentPage + 1)}>{pagination.currentPage + 1}</div>
                            }

                            {(pagination.totalPages - pagination.currentPage > 1) &&
                                <div onClick={() => getDoctorsByPaginate(pagination.currentPage + 2)}>{pagination.currentPage + 2}</div>
                            }

                            {(pagination.totalPages - pagination.currentPage > 2) &&
                                <>
                                    {(pagination.currentPage + 3 !== pagination.totalPages) && <div className={st.paginate__ellipsis}>…</div>}
                                    <div onClick={() => getDoctorsByPaginate(pagination.totalPages)}>{pagination.totalPages}</div>
                                </>
                            }
                        </div>

                        <div className={classNames(st.paginate__next, (pagination.hasNextPage ? st.paginate__arrow_active : ''))}
                             {...(pagination.hasNextPage && { onClick: () => getDoctorsByPaginate(pagination.currentPage + 1) })}
                        ><AngleBracket /></div>
                    </div>
                }

            </section>
        </LandingLayout>
    );
};

export default observer(Doctors);

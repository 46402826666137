import axios from "axios";
import DoctorProfileRepository from "domain/repository/profile/doctor/DoctorProfileRepository";
import { DoctorProfileOptions } from "domain/entity/profile/doctor/structures/DoctorProfileStructure";
import doctorProfileService from "./services/DoctorProfileService";

import "data/settings/axios";

class DoctorProfileApi implements DoctorProfileRepository {
    // loadDoctorSchedule = async (doctorId: string): Promise<DoctorScheduleStructure> => {
    //     return axios({
    //         method: "get",
    //         url: `api/doctor_availability_calendars?doctor=${doctorId}`,
    //         headers: {
    //             Authorization: "Bearer " + localStorage.getItem("accessToken"),
    //         },
    //     })
    //         .then((response) => {
    //             return Promise.reject(response)
    //         })
    //         .catch((error) => {
    //             return Promise.reject(error)
    //         });
    // }

    load = async (id: string): Promise<DoctorProfileOptions> => {
        return axios({
            method: "get",
            url: `api/doctors/${id}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
        })
            .then((response) => {
                return doctorProfileService.serialize(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    update = (doctorProfileData: DoctorProfileOptions, id: string): Promise<DoctorProfileOptions> => {
        // console.log(doctorProfileData);
        const prettifiedProfileData = doctorProfileService.deserialize(doctorProfileData);
        // console.log(prettifiedProfileData);
        return axios({
            method: "put",
            url: `api/doctors/${id}`,
            data: JSON.stringify(prettifiedProfileData),
        })
            .then((response) => {
                return doctorProfileService.serialize(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    loadOwnPatients(): Promise<any> {
        return axios({
            method: "get",
            url: `api/patients`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    loadMyApprovalRequests(): Promise<any> {
        return axios({
            method: "get",
            url: `api/approval_requests`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export default DoctorProfileApi;

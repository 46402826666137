import DoctorProfileModel from "domain/entity/profile/doctor/DoctorProfileModel";
import Respository from "domain/repository/profile/doctor/DoctorProfileRepository";
import { DoctorProfileOptions } from "domain/entity/profile/doctor/structures/DoctorProfileStructure";
import { SubmissionError, ValidationError } from "domain/interactors/CustomErrors";
import { ValidationResult } from "types";
import UserModel from "domain/entity/user/UserModel";

export interface ValidationParameters {
    profile?: boolean;
    educations?: boolean;
    experiences?: boolean;
}

export default class ManageDoctorProfileUseCase {
    private repository: Respository;
    private model: DoctorProfileModel;
    private userModel: UserModel;

    constructor(model: DoctorProfileModel, repository: Respository, userModel: UserModel) {
        this.repository = repository;
        this.model = model;
        this.userModel = userModel;
    }

    public loadProfile() {
        return this.repository.load(this.userModel.id).then((doctorProfileJSON) => {
            this.model.updateFromJson(doctorProfileJSON);
        });
    }

    public loadOwnPatients() {
        return this.repository.loadOwnPatients().then((patients) => {
            return patients;
        })
    }

    public loadMyApprovalRequests() {
        return this.repository.loadMyApprovalRequests().then((approvalRequests) => {
            return approvalRequests;
        })
    }

    private validateProfileUpdateFields = (options: DoctorProfileOptions, validationParameters: ValidationParameters = {}): ValidationResult => {
        const errors: any = {};

        if (validationParameters?.profile) {
            if (!options.firstName) {
                errors.firstName = "Required";
            }
            if (!options.lastName) {
                errors.lastName = "Required";
            }
        }

        if (validationParameters?.educations) {
            errors.university = [];
            options.educations.forEach((item, index) => {
                // @ts-ignore
                if (!item.university) {
                    errors.university[index] = "Required";
                }
            })

            if (errors.university.length === 0) {
                delete errors.university;
            }
        }

        if (validationParameters?.experiences) {
            errors.clinic = {title: []};
            options.experiences.forEach((item, index) => {
                if (!item.clinic.title) {
                    errors.clinic.title[index] = "Required";
                }
            });

            console.log('errors.clinic', errors.clinic);

            if (errors.clinic.title.length === 0) {
                delete errors.clinic.title;
            }
        }

        const isValid = Object.values(errors).filter((error) => !!error).length <= 0;

        return {
            errors: errors,
            isValid: isValid,
        };
    };

    public async DoctorProfileOptionsUpdate(profileOptions: DoctorProfileOptions, validationParameters: ValidationParameters = {}) {
        const validationResult = this.validateProfileUpdateFields(profileOptions, validationParameters);

        if (!validationResult.isValid) return Promise.reject(new ValidationError(validationResult.errors));

        return await this.repository
            .update(profileOptions, this.userModel.id)
            .then((doctorProfileJSON) => {
                this.model.updateFromJson(doctorProfileJSON);
                return doctorProfileJSON;
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error.validationErrors, (error?.data?.message ? error.data.message : (error?.message ? error.message : 'Unknown error'))))
            });
    }

    public async editAccount(options: any) {
        return await this.repository
            .update(options, this.userModel.id)
            .then((doctorProfileJSON) => {
                this.model.updateFromJson(doctorProfileJSON);
                return doctorProfileJSON;
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error.validationErrors, (error?.data?.message ? error.data.message : (error?.message ? error.message : 'Unknown error'))))
            });
    }
}

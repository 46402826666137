import ManageSubscriptionUseCase from "../../../domain/interactors/subscription/ManageSubscriptionUseCase";
import {action, makeAutoObservable} from "mobx";
import {SubmissionError, ValidationError} from "../../../domain/interactors/CustomErrors";

export default class SubscriptionViewModel {
    private manageSubscriptionUseCase: ManageSubscriptionUseCase;
    private subscriptionSubmitError: string;
    private subscriptionValidationError: string;
    private subscriptionSubmitErrorVisible: boolean;
    private subscriptionValidationErrorVisible: boolean;

    constructor(manageSubscriptionUseCase: ManageSubscriptionUseCase) {
        makeAutoObservable(this);
        this.manageSubscriptionUseCase = manageSubscriptionUseCase;

        this.subscriptionSubmitError = "";
        this.subscriptionValidationError = "";
        this.subscriptionSubmitErrorVisible = false;
        this.subscriptionValidationErrorVisible = false;
    }

    public postSubscription = (name: string, email: string) => {
        return this.manageSubscriptionUseCase
            .postSubscription(name, email)
            .then((serverResponse) => {
                return Promise.resolve(serverResponse);
            })
            .catch(action((error) => {
                if (error instanceof SubmissionError) {
                    const submissionError = error as SubmissionError;
                    this.subscriptionSubmitError = submissionError.errorDescription;
                    this.subscriptionSubmitErrorVisible = true;

                    return Promise.reject(error);
                }
                if (error instanceof ValidationError) {
                    return Promise.reject(error);
                }
            }))
    }
}

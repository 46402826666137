import AuthRepository from "domain/repository/auth/AuthRepository";

import { SubmissionError } from "../CustomErrors";

export default class VerifyTokenUseCase {
    private authRepository: AuthRepository;

    constructor(authRepository: AuthRepository) {
        this.authRepository = authRepository;
    }

    start = (token: string | null) => {
        return this.authRepository
            .verifyToken(token)
            .then((response) => {
                console.log("token is verified", { response });
            })
            .catch((errors) => {
                return Promise.reject(new SubmissionError(errors, errors));
            });
    };
}

// @ts-nocheck
import React from "react";
import { useIntl } from "react-intl";
import { Box, Grid, List, ListItemButton, ListItemText } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { AuthRoutes } from "presentation/routes/routes";
import { useViewModels } from "presentation/view-model/ViewModelProvider";
import { observer } from "mobx-react";
// styles
import st from "./cabinetLayout.module.scss";
import classNames from "classnames";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as LogoSmall } from "../../assets/images/logo-small.svg";
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/arrow.svg";


const CabinetLayout: React.FC = ({ children }) => {
    const {
        uIViewModel: { mobileIs, tabletIs, mobileMenuUserIsOpen, closeMobileUserMenu, openMobileMenu },
        authViewModel: { isDoctor },
        userViewModel: { identifier, locale },
        doctorProfileViewModel, patientProfileViewModel,
    } = useViewModels();
    const intl = useIntl();
    let location = useLocation().pathname;
    if (location.match(/\/[a-z]{2}\//i)) {
        location = location.slice(3);
    }
    const user = isDoctor ? doctorProfileViewModel.model.options : patientProfileViewModel.patientProfile;

    return (
        <Grid container>
            <Grid item xs={12} md={3}>
                <Box sx={{ width: "100%" }} className={classNames(st.nav, mobileMenuUserIsOpen ? st.nav_active : "")}>
                    <Box sx={{ width: "100%", height: "100%" }}>
                        <header className={st.mobile_menu__header}>
                            <div className={classNames(st.logo, st.mobile_menu__logo)}>
                                <Link onClick={closeMobileUserMenu} to={"/"}>
                                    {!mobileIs ? (
                                        <Logo />
                                    ) : (
                                        <LogoSmall />
                                    )}
                                </Link>
                            </div>

                            <div className={st.mobile_menu__close} onClick={closeMobileUserMenu}>
                                <div></div>
                                <div></div>
                            </div>
                        </header>
                        <List component="nav" aria-label="main mailbox folders" className={st.nav__list}>
                            <Link onClick={closeMobileUserMenu} className={st.nav__link}
                                to={`${(locale && locale !== "de") ? "/" + locale : ""}${AuthRoutes.dashboard}`}>
                                <ListItemButton className={location === AuthRoutes.dashboard ? st.active_menu_item : ""}>
                                    <ListItemText primary={intl.formatMessage({ id: "cabinet.menu.dashboard" })} />
                                </ListItemButton>
                            </Link>

                            <Link onClick={closeMobileUserMenu} className={st.nav__link}
                                to={`${(locale && locale !== "de") ? "/" + locale : ""}${AuthRoutes.bookingAppointments}`}>
                                <ListItemButton className={location === AuthRoutes.bookingAppointments ? st.active_menu_item : ""}>
                                    <ListItemText primary={intl.formatMessage({ id: "cabinet.menu.appointments" })} />
                                </ListItemButton>
                            </Link>

                            {isDoctor ? (
                                <>
                                    <Link onClick={closeMobileUserMenu} className={st.nav__link}
                                        to={`${(locale && locale !== "de") ? "/" + locale : ""}${AuthRoutes.schedule}`}>
                                        <ListItemButton className={location === AuthRoutes.schedule ? st.active_menu_item : ""}>
                                            <ListItemText primary={intl.formatMessage({ id: "cabinet.menu.schedule" })} />
                                        </ListItemButton>
                                    </Link>

                                    <Link onClick={closeMobileUserMenu} className={st.nav__link}
                                        to={`${(locale && locale !== "de") ? "/" + locale : ""}${AuthRoutes.connections}?type=1`}>
                                        <ListItemButton className={location === AuthRoutes.connections ? st.active_menu_item : ""}>
                                            <ListItemText primary={intl.formatMessage({ id: "cabinet.menu.connections" })} />
                                        </ListItemButton>
                                    </Link>
                                    <Link onClick={closeMobileUserMenu} className={st.nav__link}
                                        to={`${(locale && locale !== "de") ? "/" + locale : ""}${AuthRoutes.patients}`}>
                                        <ListItemButton className={location === AuthRoutes.patients ? st.active_menu_item : ""}>
                                            <ListItemText primary={intl.formatMessage({ id: "cabinet.menu.patients" })} />
                                        </ListItemButton>
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <Link onClick={closeMobileUserMenu} className={st.nav__link}
                                        to={`${(locale && locale !== "de") ? "/" + locale : ""}${AuthRoutes.team}`}>
                                        <ListItemButton className={location === AuthRoutes.team ? st.active_menu_item : ""}>
                                            <ListItemText primary={intl.formatMessage({ id: "cabinet.menu.team" })} />
                                        </ListItemButton>
                                    </Link>
                                </>
                            )}

                            <Link onClick={closeMobileUserMenu} className={st.nav__link}
                                to={`${(locale && locale !== "de") ? "/" + locale : ""}${AuthRoutes.referral}`}>
                                <ListItemButton className={location === AuthRoutes.referral ? st.active_menu_item : ""}>
                                    <ListItemText primary={intl.formatMessage({ id: "cabinet.menu.referral" })} />
                                </ListItemButton>
                            </Link>

                            <Link onClick={closeMobileUserMenu} className={st.nav__link}
                                to={`${(locale && locale !== "de") ? "/" + locale : ""}${AuthRoutes.accountSettings}`}>
                                <ListItemButton className={location === AuthRoutes.accountSettings ? st.active_menu_item : ""}>
                                    <ListItemText primary={intl.formatMessage({ id: "cabinet.menu.accountSettings" })} />
                                </ListItemButton>
                            </Link>

                            <Link onClick={closeMobileUserMenu} className={st.nav__link}
                                to={`${locale && locale !== 'de' ? '/' + locale : ''}/${isDoctor ? "doctor" : "patient"}s/${identifier}${user.active ? "" : "/edit"}`}>
                                <ListItemButton>
                                    <ListItemText primary={intl.formatMessage({ id: "cabinet.menu.profile" })} />
                                </ListItemButton>
                            </Link>

                            {tabletIs && (
                                <div className={st.toMenu} onClick={() => {
                                    closeMobileUserMenu();
                                    openMobileMenu();
                                }}>
                                    {intl.formatMessage({ id: "cabinet.menu.mainMenu" })}
                                    <ArrowIcon />
                                </div>
                            )}
                        </List>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={9} className={st.content}>
                {children}
            </Grid>
        </Grid>
    );
};

export default observer(CabinetLayout);

import MedicalCredentialsRepository from "../../domain/repository/medical_credentials/MedicalCredentialsRepository";
import axios from "axios";
import {MedicalCredential} from "../../domain/entity/medical_credentials/structures/MedicalCredentialStructure";

export default class MedicalCredentialApi implements MedicalCredentialsRepository {
    getMedicalCredentials = async (): Promise<[MedicalCredential[], []]> => {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + 'api/medical_credentials',
        }).then((medicalCredentials: any) => {
            return medicalCredentials.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }
}

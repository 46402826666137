import { makeAutoObservable, action } from "mobx";
import QuestionAnswer from "./structures/QuestionAnswer";
import CategorizedQuestion from "./structures/CategorizedQuestion";
import Category from "./structures/Category";

export default class FaqModel {
    public popularQuestions: QuestionAnswer[];
    public categorizedQuestions: CategorizedQuestion[];
    public categories: Category[];

    constructor() {
        makeAutoObservable(this);
        this.popularQuestions = [];
        this.categorizedQuestions = [];
        this.categories = [];
    }

    @action updateCategories = (categories: Category[]) => {
        this.categories = categories;
    };

    @action updatePopularQuestions = (popularQuestions: QuestionAnswer[]) => {
        this.popularQuestions = popularQuestions;
    };

    @action updateCategorizedQuestions = (categorizedQuestions: CategorizedQuestion[]) => {
        this.categorizedQuestions = categorizedQuestions;
    };
}

import ManageFormsUseCase from "domain/interactors/landing/ManageFormsUseCase";
import { makeAutoObservable } from "mobx";
import { SubmissionError, ValidationError } from "domain/interactors/CustomErrors";

export default class LandingViewModel {
    private manageFormsUseCase: ManageFormsUseCase;

    constructor(manageFormsUseCase: ManageFormsUseCase) {
        makeAutoObservable(this);
        this.manageFormsUseCase = manageFormsUseCase;
    }

    public sendGetInTouchForm = (firstName: string, email: string, phoneNumber: string, message: string) => {
        return this.manageFormsUseCase
            .sendContactForm(firstName, email, phoneNumber, message)
            .then((serverResponse) => {
                return Promise.resolve(serverResponse);
            })
            .catch((error) => {
                if (error instanceof SubmissionError) {
                    const submissionError = error as SubmissionError;

                    return Promise.reject(submissionError.errors);
                }
                if (error instanceof ValidationError) {
                    const validationError = error as ValidationError;
                    return Promise.reject(validationError.errors);
                }

                return Promise.reject(error);
            });
    };
}

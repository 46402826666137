// @ts-nocheck
import React from "react";
import { UserRoles } from "../../types";
import { RouteComponentProps, Route, Redirect } from "react-router-dom";
import { useViewModels } from "presentation/view-model/ViewModelProvider";
import { NonAuthRoutes } from "./routes";
import { observer } from "mobx-react";

interface Props {
    Component: React.FC<RouteComponentProps>;
    path: string;
    exact?: boolean;
    requiredRoles?: Array<UserRoles>;
}


const AuthRoute = observer(({ Component, path, exact = false, requiredRoles }: Props) => {
    const {
        authViewModel: { isUserAuthorized, userRoles },
        userViewModel: {locale},
    } = useViewModels();

    const hasRequestedRoles = requiredRoles?.length ? requiredRoles?.includes(userRoles[0]) : true;

    return (
        <Route
            exact={exact}
            path={path}
            render={(props: RouteComponentProps) =>
                isUserAuthorized && hasRequestedRoles ?
                    <Component {...props} />
                :
                    <Redirect
                        to={{
                            pathname: ((locale && locale !== "de") ? "/" + locale : '') + NonAuthRoutes.signin,
                            state: {
                                message: "Non authorized",
                                requestedPath: path,
                            },
                        }}
                    />
            }
        />
    );
});

export default AuthRoute;

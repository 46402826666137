import { action, makeAutoObservable } from "mobx";


export default class UIViewModel {
    mobileMenuIsOpen = false;
    mobileMenuUserIsOpen = false;
    preloaderIsVisible = true;
    mobileDoctorsSearchIsOpen = false;
    popupActive = false;
    successAlertContent = '';
    errorAlertContent = '';
    alertContentTime = 10000;
    timerSuccessAlert: any = null;
    timerErrorAlert: any = null;
    mediaQuery = "(max-width: 668px)";
    mediaQueryTablet = "(max-width: 1040px)";
    mobileIs: boolean = window.matchMedia(this.mediaQuery).matches;
    isTouchDevice: boolean = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
    tabletIs: boolean = window.matchMedia(this.mediaQueryTablet).matches;

    constructor() {
        makeAutoObservable(this);
    }

    // mobile menu
    @action openMobileMenu = () => {
        this.mobileMenuIsOpen = true;
        document.documentElement.style['overflow'] = 'hidden';
    }

    @action closeMobileMenu = () => {
        this.mobileMenuIsOpen = false;
        document.documentElement.style['overflow'] = '';
    }

    // mobile menu for doctor
    @action openMobileUserMenu = () => {
        this.mobileMenuUserIsOpen = true;
        document.documentElement.style['overflow'] = 'hidden';
    }

    @action closeMobileUserMenu = () => {
        this.mobileMenuUserIsOpen = false;
        document.documentElement.style['overflow'] = '';
    }

    // preloader
    @action showPreloader = () => {
        this.preloaderIsVisible = true;
    }

    @action hidePreloader = () => {
        this.preloaderIsVisible = false;
    }

    // mobile doctors search form
    @action openMobileDoctorsSearch = () => {
        this.mobileDoctorsSearchIsOpen = true;
    }

    @action closeMobileDoctorsSearch = () => {
        this.mobileDoctorsSearchIsOpen = false;
    }

    // popup
    @action openPopup = () => {
        this.popupActive = true;
    }

    @action closePopup = () => {
        this.popupActive = false;
    }

    @action setTabletIs = (value: boolean) => {
        this.tabletIs = value;
    }

    @action setMobileIs = (value: boolean) => {
        this.mobileIs = value;
    }

    @action setDevices = (value: boolean) => {
        this.isTouchDevice = value;
    }

    @action setSuccessAlertContent = (value: string, doNotClose = false) => {
        if (this.timerSuccessAlert) {
            clearTimeout(this.timerSuccessAlert);
        }

        this.successAlertContent = value;

        if (!doNotClose) {
            this.timerSuccessAlert = setTimeout(() => {
                this.setSuccessAlertContent('', true);
            }, 3000);
        }
    }

    @action setErrorAlertContent = (value: string, doNotClose = false) => {
        if (this.timerErrorAlert) {
            clearTimeout(this.timerErrorAlert);
        }

        this.errorAlertContent = value;

        if (!doNotClose) {
            this.timerErrorAlert = setTimeout(() => {
                this.setErrorAlertContent('', true);
            }, 3000);
        }
    }

    @action setErrorAlertContentTime = (value: string, time: number) => {
        if (this.timerErrorAlert) {
            clearTimeout(this.timerErrorAlert);
        }

        this.errorAlertContent = value;

        if (time) {
            this.timerErrorAlert = setTimeout(() => {
                this.setErrorAlertContentTime('', 0);
            }, time);
        }
    }

}


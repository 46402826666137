import React, { useEffect, useState } from "react";
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import cn from 'classnames/bind';
import st from '../Dashboard/dashboard.module.scss';
import { Appointment } from '../../../../../../domain/entity/appointment/structures/AppointmentStructure';
import AppointmentList from '../../../../components/AppointmentList/AppointmentList';
import { useViewModels } from "../../../../../view-model/ViewModelProvider";


const Appointments = () => {
    const intl = useIntl();
    const { appointmentViewModel, userViewModel: {locale},} = useViewModels();
    const [value, setValue] = useState('1');
    const [activeAppointmentCard, setActiveAppointmentCard] = useState<Appointment | null>(null);

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    useEffect(() => {
        appointmentViewModel.getUpcomingAppointments();
        appointmentViewModel.getPastAppointments();
    }, [appointmentViewModel, locale]);

    return (
        <section className={st.section}>
            <>
                <TabContext value={value}>
                    <TabPanel value="1" sx={{ padding: 0 }}>
                        <Typography variant="h5" className={cn(st.section__title, st.section__title_isLg)}>
                            {intl.formatMessage({ id: `cabinet.appointments.${activeAppointmentCard ? 'singleUpcoming.' : ''}title` })}
                        </Typography>
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: 0 }}>
                        <Typography variant="h5" className={cn(st.section__title, st.section__title_isLg)}>
                            {intl.formatMessage({ id: `cabinet.appointments.${activeAppointmentCard ? 'singlePast.' : ''}title` })}
                        </Typography>
                    </TabPanel>

                    {activeAppointmentCard === null && (
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab
                                label={<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cabinet.appointments.upcoming' }) }} />}
                                value="1"
                                sx={{ paddingLeft: 0 }}
                            />
                            <Tab
                                label={<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cabinet.appointments.past' }) }} />}
                                value="2"
                            />
                        </TabList>
                    )}

                    <TabPanel value="1" sx={{ padding: 0 }}>
                        <AppointmentList
                            appointmentsType="upcomingAppointments"
                            pageType="bookingAppointments"
                            activeAppointmentCard={activeAppointmentCard}
                            setActiveAppointmentCard={setActiveAppointmentCard}
                        />
                    </TabPanel>

                    <TabPanel value="2" sx={{ padding: 0 }}>
                        <AppointmentList
                            appointmentsType="pastAppointments"
                            pageType="bookingAppointments"
                            activeAppointmentCard={activeAppointmentCard}
                            setActiveAppointmentCard={setActiveAppointmentCard}
                        />
                    </TabPanel>
                </TabContext>
            </>
        </section>
    );
};

export default Appointments;

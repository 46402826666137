import enLocale from "date-fns/locale/en-US";
import deLocale from "date-fns/locale/de";

enum UserRoles {
    doctor = "ROLE_DOCTOR",
    owner = "ROLE_ADMIN",
    patient = "ROLE_PATIENT",
}

const LocaleMap = {
    en: enLocale,
    de: deLocale,
};

export const statutoryInsurances = [
    {id: '9fc67d09-3710-4fa0-84c4-c35c0d6ad038', logo: {id: '6b7496f3-d448-4925-882f-c4ab5df45a9c'}, country: 'Austria', title: 'ÖGK - Österreichische Gebietskrankenkassa', type: 'gesetzliche Krankenversicherung', },
    {id: '45e10f6d-775d-464d-b4b0-2898b907fbd3', logo: {id: '44f8c95a-81de-40c5-a526-8424f86000ef'}, country: 'Austria', title: 'KFA - Krankenfürsorgeanstalt der Bediensteten der Stadt Wien', type: 'gesetzliche Krankenversicherung', },
    {id: '130ffff5-91d7-44b6-b6f0-886552ab00d6', logo: {id: 'c111475a-6dae-4361-a16d-f20d4a9cb3c6'}, country: 'Austria', title: 'BVAEB - Versicherungsanstalt öffentlich Bediensteter, Eisenbahnen und Bergbau', type: 'gesetzliche Krankenversicherung', },
    {id: 'b5b5f937-b1dd-4497-9d94-01ffe3f7b784', logo: {id: '29273ad5-5072-49be-8153-6e533c258563'}, country: 'Austria', title: 'SVS - Sozialversicherung der Selbstständigen', type: 'gesetzliche Krankenversicherung', },
];

export const Languages = {
    de: { title: "German", shortName: "De", icon: "de.svg" },
    en: { title: "English", shortName: "En", icon: "en.svg" },
    es: { title: "Spanish", shortName: "es", icon: "" },
};

type ValidationResult = {
    errors: Object;
    isValid?: boolean;
};

type SubmissionFailedResult = {
    errors: Object;
    errorDescription: string | null;
    // isValid: Boolean;
};

declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        circle: true;
    }
}

declare module "@mui/material/styles" {
    interface PaletteColor {
        light1?: string;
        light2?: string;
        error?: string;
    }
    interface SimplePaletteColorOptions {
        light1?: string;
        light2?: string;
        error?: string;
    }
}

declare module "@mui/material/styles" {
    interface TypographyVariants {
        t2: React.CSSProperties;
        t2news: React.CSSProperties;
        t3: React.CSSProperties;
        t3s: React.CSSProperties;
        t4: React.CSSProperties;
        d1: React.CSSProperties;
        d1s: React.CSSProperties;
        d2: React.CSSProperties;
        d3: React.CSSProperties;
        p5: React.CSSProperties;
        button: React.CSSProperties;
        inputText: React.CSSProperties;
        helpertext: React.CSSProperties;
        label: React.CSSProperties;
        staticLabel: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        t2: React.CSSProperties;
        t2news: React.CSSProperties;
        t3: React.CSSProperties;
        t3s: React.CSSProperties;
        t4: React.CSSProperties;
        d1: React.CSSProperties;
        d1s: React.CSSProperties;
        d2: React.CSSProperties;
        d3: React.CSSProperties;
        p5: React.CSSProperties;
        button: React.CSSProperties;
        inputText: React.CSSProperties;
        helpertext: React.CSSProperties;
        label: React.CSSProperties;
        staticLabel: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        t2: true;
        t2news: true;
        t3: true;
        t3s: true;
        t4: true;
        d1: true;
        d1s: true;
        d2: true;
        d3: true;
        p5: true;
        button: true;
        inputText: true;
        helpertext: true;
        label: true;
        staticLabel: true;
    }
}

export { UserRoles };
export { LocaleMap };
export type { ValidationResult, SubmissionFailedResult };

import React from 'react';
import { FormControl, IconButton, InputAdornment, InputLabel, Input, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import cn from 'classnames/bind';

import st from './referral.module.scss';
import { ReactComponent as CopyIcon } from '../../../../assets/images/icons/copy.svg';
import {useViewModels} from "../../../../../view-model/ViewModelProvider";

const Referral: React.FC = () => {
    const intl = useIntl();
    const inputEl = React.useRef<any>(null);

    const { userViewModel } = useViewModels();

    const handleClickCopy = () => {
        navigator.clipboard.writeText(inputEl?.current?.value);
    };

    return (
        <section className={st.section}>
            <Typography variant="h5" className={cn(st.section__title, st.section__title_isLg)}>
                {intl.formatMessage({ id: 'cabinet.referral.title' })}
            </Typography>
            <Typography variant="p5" className={st.section__text}>
                {intl.formatMessage({ id: 'cabinet.referral.text' })}
            </Typography>

            <FormControl variant="standard" className={st.section__form}>
                <InputLabel htmlFor="copy-to">{intl.formatMessage({ id: 'cabinet.referral.input.label' })}</InputLabel>
                <Input
                    inputRef={inputEl}
                    id="copy-to"
                    type="text"
                    value={`${window.location.origin}/auth/signup?referralCode=${userViewModel.userModel.referralCode}`}
                    className={st.section__input}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton aria-label="copy to clipboard" onClick={handleClickCopy} edge="end" className={st.section__copy}>
                                <CopyIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>

            <div className={st.balance}>
                <div className={st.balance__head}>
                    <p className={st.balance__current}>{intl.formatMessage({ id: 'cabinet.referral.balance.label' })}</p>
                    <p className={st.balance__cost}>{userViewModel.userModel.balance} EUR</p>
                </div>
                <div className={st.balance__grid}>
                    <p className={st.balance__label}>{intl.formatMessage({ id: 'cabinet.referral.balance.names' })}</p>
                    <div className={st.balance__values}>
                        <p className={st.balance__value_big}>{userViewModel.userModel.referralsCount}</p>
                        <p className={st.balance__value}>{userViewModel.userModel.referralsDoctorCount} {intl.formatMessage({ id: 'cabinet.referral.balance.doctors' })}</p>
                        <p className={st.balance__value}>{userViewModel.userModel.referralsPatientCount} {intl.formatMessage({ id: 'cabinet.referral.balance.patients' })}</p>
                    </div>
                </div>
                <div className={st.balance__grid}>
                    <p className={st.balance__label}>{intl.formatMessage({ id: 'cabinet.referral.balance.total' })}</p>
                    <div className={st.balance__values}>
                        <p className={st.balance__value_big}>{userViewModel.userModel.balance} EUR</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Referral;

import React from "react";
import { useIntl } from "react-intl";

import { observer } from "mobx-react";

import stTags from "../../components/WithStyledTags/withStyledTags.module.scss"
import stHero from "../../components/HeroHeader/heroHeader.module.scss";
import st from './privacyPolicy.module.scss';

import LandingLayout from "../../layouts/landing/LandingLayout";
import classNames from "classnames";
import { useViewModels } from "../../../view-model/ViewModelProvider";


const PrivacyPolicy: React.FC = () => {
    const intl = useIntl();
    const { userViewModel: { locale }} = useViewModels();

    function formatHumanDate(date: Date) {
        const day = intl.formatDate(new Date(date), { day: 'numeric' });
        const month = intl.formatDate(new Date(date), { month: 'long' });
        const year = intl.formatDate(new Date(date), { year: 'numeric' });

        return `${month} ${day}, ${year}`;
    }

    return (
        <LandingLayout>
            <section className={stHero.hero_header}>
                <div className={st.hero_container}>
                    <h1 className={classNames(stHero.caption, locale === 'de' ? stHero.caption_de : "")}
                         dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "privacyPolicy.title" }) }}></h1>

                    <div className={st.date}>{formatHumanDate(new Date())}</div>
                </div>
            </section>

            <section className={classNames(stTags.with_styled_tags, st.main)}>
                <div className={st.main_container}>
                    <h2 className={classNames(locale === 'de' ? st.content__title_de : "")} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "privacyPolicy.content.title" }) }}></h2>
                    <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "privacyPolicy.content.description.1" }) }}></div>
                    <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "privacyPolicy.content.description.2" }) }}></div>
                </div>
            </section>
        </LandingLayout>
    );
};

export default observer(PrivacyPolicy);

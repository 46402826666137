import { observer } from "mobx-react";
import { NonAuthRoutes } from "presentation/routes/routes";
import { useViewModels } from "presentation/view-model/ViewModelProvider";
import HeroHeader from "presentation/views/components/HeroHeader";
import { NewsPreview } from "presentation/views/components/NewsPreview";
import LandingLayout from "presentation/views/layouts/landing/LandingLayout";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Box, Button } from "@mui/material";
import st from "./news.module.scss";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Helmet } from "react-helmet-async";


const News: React.FC = () => {
    const intl = useIntl();
    const { newsViewModel } = useViewModels();
    const [lastAvailableYear, setLastAvailableYear] = useState(new Date().getFullYear());

    useEffect(() => {
        newsViewModel.loadFreshNews();
    }, [newsViewModel]);

    useEffect(() => {
        if (newsViewModel.availableYears.length) {
            setLastAvailableYear(Math.max.apply(Math, newsViewModel.availableYears?.map((item) => item)));
        }
    }, [newsViewModel.availableYears]);

    function formatHumanDate(date: string,) {
        const dateHuman = new Date(date);

        const monthDayUtc = dateHuman.getUTCDate();
        dateHuman.setUTCDate(monthDayUtc);

        const month = intl.formatDate(dateHuman, { month: "long"});
        const day = intl.formatDate(dateHuman, { day: "numeric" });
        const year = intl.formatDate(dateHuman, { year: "numeric" });

        return `${month} ${day}, ${year}`;
    }

    return (
        <LandingLayout>
            <Helmet>
                <title>{intl.formatMessage({id: 'news.title'})}</title>
                <link rel="canonical" href={`${process.env.REACT_APP_API_ADDRESS}news`} />
            </Helmet>

            <div className={st.page}>
                <HeroHeader
                    caption="news.title"
                    desc="news.description"
                />
                <main className={st.main}>
                    <div className={st.timeline}>
                        <div className={st.timeline__points}>
                            {newsViewModel.availableYears.length ? (
                                newsViewModel.availableYears?.map((year) => (
                                    <div key={year}
                                        className={`${st.timeline__point} ${(Number(year) === lastAvailableYear) ? st.timeline__point_is_active : ''}`}
                                        onClick={() => {
                                            newsViewModel.updateSearchParams({ year: year });
                                            newsViewModel.loadNews();
                                            setLastAvailableYear(Number(year));
                                        }}
                                    >
                                        <div className={st.timeline__inner_point}>
                                            {year}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <Box sx={{ display: 'flex', alignItems: 'center', width: `${45 * 3 + 8 * 2}px`  }}>
                                    <Box>
                                        <Skeleton variant="rectangular" width={45} height={56} />
                                    </Box>
                                    <Box ml={2}>
                                        <Skeleton variant="rectangular" width={45} height={56} />
                                    </Box>
                                    <Box ml={2}>
                                        <Skeleton variant="rectangular" width={45} height={56} />
                                    </Box>
                                </Box>
                            )}
                        </div>
                    </div>

                    <div className={st.active_year}>{lastAvailableYear}</div>

                    <div className="relative">
                        {!newsViewModel.isSync && newsViewModel.foundNews.length ? (
                            newsViewModel.foundNews.map((post) => (
                                <Link to={`${NonAuthRoutes.news}/${post.id}`} key={post.id}>
                                    <NewsPreview date={formatHumanDate(post.date)} title={post.title}></NewsPreview>
                                </Link>
                            ))
                        ) : (
                            <Stack spacing={2}>
                                <Skeleton variant="rectangular" height={100} />
                                <Skeleton variant="rectangular" height={100} />
                                <Skeleton variant="rectangular" height={100} />
                            </Stack>
                        )}
                    </div>

                    <div className={st.more__wrapper}>
                        <Button
                            size="large"
                            color="secondary"
                            variant="outlined">
                            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "news.allNews" }) }}></span>
                        </Button>
                    </div>
                </main>
            </div>
        </LandingLayout>
    );
};

export default observer(News);

// @ts-nocheck
import React from "react";
import { HeaderBarSmall } from '../../components/AppBar/AppBarSmall';
import { observer } from 'mobx-react';
import { AuthRoutes, NonAuthRoutes } from 'presentation/routes/routes';
import { useViewModels } from 'presentation/view-model/ViewModelProvider';
import { Redirect, Route, Switch } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import NewPassword from './NewPassword';
import SignIn from './SignIn/SignIn';
import SignUp from './SignUp/SignUp';
import st from './Auth.module.scss';
import classNames from "classnames";
import { ReactComponent as InfoIcon } from "../../assets/images/icons/info.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/icons/cross.svg";


const Auth: React.FC = () => {
    const {
        authViewModel: { isUserAuthorized },
        userViewModel: { locale },
        uIViewModel: {
            successAlertContent,
            setSuccessAlertContent,
            errorAlertContent,
            setErrorAlertContent,
        },
    } = useViewModels();

    if (isUserAuthorized) return <Redirect to={AuthRoutes.cabinet} />;

    return (
        <div className={st.auth}>
            <HeaderBarSmall bgColor="#09299C" staticBarIsTransparent className={st.header} />

            <div className={st.auth_container}>
                {(successAlertContent || errorAlertContent) &&
                    <div className={classNames('alert', (errorAlertContent ? 'alert_error' : ''))}>
                        <div className={'alert__info'}>
                            <InfoIcon />
                            <div>{successAlertContent || errorAlertContent}</div>
                        </div>

                        <CrossIcon className={classNames('hover-stroke hover-stroke-deep', 'alert__close')}
                                   onClick={() => {successAlertContent ? setSuccessAlertContent('') : setErrorAlertContent('')}}
                        />
                    </div>
                }

                <Switch>
                    <Route path={NonAuthRoutes.signin} component={SignIn} exact />
                    <Route path={NonAuthRoutes.signinLocale} component={SignIn} exact />

                    <Route path={NonAuthRoutes.signup} component={SignUp} exact />
                    <Route path={NonAuthRoutes.signupLocale} component={SignUp} exact />

                    <Route path={NonAuthRoutes.forgotPassword} component={ForgotPassword} exact />
                    <Route path={NonAuthRoutes.forgotPasswordLocale} component={ForgotPassword} exact />

                    <Route path={NonAuthRoutes.newPassword} component={NewPassword} exact />
                    <Route path={NonAuthRoutes.newPasswordLocale} component={NewPassword} exact />

                    <Route component={() => <Redirect to={`${(locale && locale !== "de") ? "/" + locale : ""}${AuthRoutes.cabinet}`} />} />
                </Switch>
            </div>
        </div>
    );
};

export default observer(Auth);

// @ts-nocheck
import SpecialitiesApi from "../../../data/specialities/SpecialitiesApi";
import {makeAutoObservable, action} from "mobx";

class SpecialitiesViewModel {
    public specialities = [];
    public specialitiesForSearch = [];
    locale = localStorage.getItem('locale') ?? process.env.REACT_APP_DEFAULT_LOCALE;

    constructor() {
        makeAutoObservable(this);
    }

    getSpecialitiesForSearch = (locale) => {
        SpecialitiesApi.getSpecialitiesForSearch()
            .then(action((data) => {
                this.specialitiesForSearch = data.items;
                this.locale = locale;
            }));
    }

    getSpecialitiesPromise = () => {
        return SpecialitiesApi.getSpecialities();
    }

}

export default new SpecialitiesViewModel();

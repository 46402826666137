import React from 'react'
import { observer } from "mobx-react";
import { useViewModels } from "presentation/view-model/ViewModelProvider";
// styles
import styles from './Preloader.module.scss';
import cx from 'classnames';

import { ReactComponent as Logo } from "../../assets/images/logo.svg";


const Preloader = () => {
    const { uIViewModel: { preloaderIsVisible } } = useViewModels();
    console.log('preloaderIsVisible', preloaderIsVisible);

    return (
        <div className={cx(
            styles.preloader,
            {
                [styles.is_visible]: preloaderIsVisible,
                [styles.is_hidden]: !preloaderIsVisible,
            })}
        >
            <div className={styles.logo}>
                <Logo />
            </div>
        </div>
    );
}

export default observer(Preloader);

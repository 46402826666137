// @ts-nocheck
import { IntlProvider } from "react-intl";
import Germany from "lang/de.json";
import English from "lang/en.json";


const errorParse = (error: any) => {
    const intlProvider = new IntlProvider({ 'en': English, 'de': Germany });
    const locale = localStorage.getItem("locale") ?? process.env.REACT_APP_DEFAULT_LOCALE;

    if (error.response && (error.response.data.detail || error.response.status === 401 || error.response.data.violations || error.response.data.message || error.response.data.error)) {
        // Request made and server responded
        if (error.response.status === 401) {
            if (error?.response?.data?.error) {
                return error.response.data.error;
            } else {
                return intlProvider.props[locale]["parseError.401"];
            }
        } else if (error.response.data.violations) {
            console.log('error.response.data.violations',  error.response.data.violations);

            return error.response.data.violations;
        } else if (error.response.data.detail) {
            return error.response.data.detail;
        } else if (error.response.data.message) {
            return error.response.data.message;
        }
    } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }

    return intlProvider.props[locale]["parseError.errorDefault"];
}

export default errorParse;

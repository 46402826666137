import axios from "axios";
import LakeRepository from "domain/repository/lake/LakeRepository";

export default class LakeApi implements LakeRepository {
    loadSpecialtiesAvailableLetters = () => {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + "api/specialities/available-letters",
        }).then((result) => {
            const fromServer = (result.data as []).map(({ letter }) => {
                return letter;
            });

            return fromServer;
        });
    };

    loadSpecialtiesByLetter = (letter: string) => {
        return axios({
            method: "GET",
            url: axios.defaults.baseURL + `api/specialities?name=${letter}&doctorsCount=DESC`,
        }).then((result) => {
            // @ts-ignore
            return result.data.items;
        });
    };
}

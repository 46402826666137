import AuthRepository from "domain/repository/auth/AuthRepository";

import { SubmissionError } from "../CustomErrors";

export default class ForgotPasswordUseCase {
    private authRepository: AuthRepository;

    constructor(authRepository: AuthRepository) {
        this.authRepository = authRepository;
    }

    start = (email: string) => {
        return this.authRepository
            .forgotPassword(email)
            .then((response) => {
                console.log("password forgoted", { response });
            })
            .catch((errors) => {
                return Promise.reject(new SubmissionError(errors, errors));
            });
    };
}

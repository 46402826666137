import LandingRespository from "domain/repository/landing/LandingRepository";

import { ValidationResult } from "types";
import { SubmissionError, ValidationError } from "../CustomErrors";
import isEmail from "validator/lib/isEmail";
// import isMobilePhone from "validator/lib/isMobilePhone";

export default class ManageFormsUseCase {
    private landingRespository: LandingRespository;

    constructor(landingRespository: LandingRespository) {
        this.landingRespository = landingRespository;
    }

    validateContactForm = (firstName: string, email: string, phoneNumber: string, message: string): ValidationResult => {
        const errors: any = {
            firstName: (() => {
                if (!firstName) return "required";
            })(),
            email: (() => {
                if (!email) return "required";
                if (!isEmail(email)) return "Invalid email";
            })(),
            /*phoneNumber: (() => {
                if (phoneNumber && !isMobilePhone(phoneNumber)) return "Invalid phone number";
            })(),*/
            message: (() => {
                if (!message) return "required";
            })(),
        };

        const isValid = Object.values(errors).filter((error) => !!error).length <= 0;

        return {
            errors: errors,
            isValid: isValid,
        };
    };

    sendContactForm = (firstName: string, email: string, phoneNumber: string, message: string): Promise<void> => {
        const validationResult = this.validateContactForm(firstName, email, phoneNumber, message);

        if (!validationResult.isValid) return Promise.reject(new ValidationError(validationResult.errors));

        return this.landingRespository
            .sendContactForm(firstName, email, phoneNumber, message)
            .then((object) => {
                return Promise.resolve(object);
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error.validationErrors, error.data.message));
            });
    };
}

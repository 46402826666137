import SearchBySpecialtiesModel from "domain/entity/searchBySpecialties/SearchBySpecialtiesModel";
import LakeRepository from "domain/repository/lake/LakeRepository";

import { ValidationResult } from "types";
import { SubmissionError, ValidationError } from "../CustomErrors";

export default class SearchBySpecialtiesUseCase {
    private lakeRepository: LakeRepository;
    private searchBySpecialtiesModel: SearchBySpecialtiesModel;

    constructor(lakeRepository: LakeRepository, searchBySpecialtiesModel: SearchBySpecialtiesModel) {
        this.lakeRepository = lakeRepository;
        this.searchBySpecialtiesModel = searchBySpecialtiesModel;
    }

    validate = (letter: string): ValidationResult => {
        const errors: any = {
            letter: (() => {
                if (!letter) return "required";
            })(),
        };

        const isValid = Object.values(errors).filter((error) => !!error).length <= 0;

        return {
            errors: errors,
            isValid: isValid,
        };
    };

    start = (letter: string): Promise<void> => {
        const validationResult = this.validate(letter);
        if (!validationResult.isValid) return Promise.reject(new ValidationError(validationResult.errors));

        return this.lakeRepository
            .loadSpecialtiesByLetter(letter)
            .then((object) => {
                this.searchBySpecialtiesModel.updateSpecialtiesByletter(letter, object);
            })
            .catch((error) => {
                return Promise.reject(new SubmissionError(error.validationErrors, error.response.data.message));
            });
    };
}

import React, { useEffect, useState } from "react";
import st from "./planet.module.scss";
import classnames from 'classnames/bind';

const cx = classnames.bind(st);

interface PlanetProps {
    title?: any;
    icon?: any;
    inputRef?: React.Ref<any>;
    border?: string;
    bgColor?: string;
    contentColor?: string;
    alignContent?: string;
    contentSize?: string;
    setWidth?: boolean;
    gravity?: boolean;
    onClickFaq?: Function;
    className?: string
}

const gravityMediaQuery = '(min-width: 1025px)';

export const Planet: React.FC<PlanetProps> = ({
    title,
    icon,
    inputRef,
    border,
    bgColor,
    contentColor,
    alignContent,
    contentSize,
    setWidth,
    gravity,
    onClickFaq,
    className
}) => {
    const [gravityIs, setGravityIs] = useState(window.matchMedia(gravityMediaQuery).matches);

    function throttle (callback: Function, limit: number) {
        let waiting = false;
        return function () {
            if (!waiting) {
                // @ts-ignore
                callback.apply(this, arguments);
                waiting = true;
                setTimeout(function () {
                    waiting = false;
                }, limit);
            }
        }
    }

    function hoverGravity(e: React.MouseEvent) {
        const currentTarget: any = e.currentTarget;
        const currentTargetRect = currentTarget.getBoundingClientRect();
        const pointerX = e.pageX - currentTargetRect.left;
        const pointerY = e.clientY - currentTargetRect.top;
        const targetWidth = currentTargetRect.width;
        const targetHeight = currentTargetRect.height;

        const offsetX = (pointerX - targetWidth / 2) / 2 + 'px';
        const offsetY = (pointerY - targetHeight / 2) / 2 + 'px';

        currentTarget.style.transform = `translate(${offsetX}, ${offsetY}) scale(1.1)`;
    }

    let resetGravityIsRun = false;
    function resetGravity(e: React.MouseEvent) {
        if (resetGravityIsRun) {
            return;
        }

        resetGravityIsRun = true;
        const timeout = e.type === 'click' ? 200 : 0;

        let currentTarget: any = e.target;

        if (!currentTarget.classList.contains('planet-hover')) {
            currentTarget = currentTarget.closest('.planet-hover');
        }

        if (currentTarget) {
            setTimeout(() => {
                currentTarget.style.transform = 'translate(0, 0)';
                resetGravityIsRun = false;
            }, timeout);
        }
    }

    const classlist = cx({
        planet: true,
        color_light: contentColor === 'color_light',
        color_accent_dark: contentColor === 'color_accent_dark',
        color_accent: contentColor === 'color_accent',
        align_center: alignContent === 'center',
        content_lg: contentSize === 'lg',
        set_width: setWidth === true,

    }, className);

    useEffect(() => {
        let isMounted = true;
        window.matchMedia(gravityMediaQuery).addEventListener('change', () => {
            if (isMounted) {
                setGravityIs(!gravityIs);
            }
        })
        return () => { isMounted = false };
    });

    return (
        <div ref={inputRef} className={classnames('planet-hover', classlist)}
             onMouseMove={(gravity && gravityIs) ? throttle(hoverGravity, 20) : undefined}
             onMouseLeave={(gravity && gravityIs) ? resetGravity : undefined}
             onClick={onClickFaq ?
                     (event) => {
                     resetGravity(event);
                     onClickFaq(event);
                 }
                 : undefined
             }
             style={{backgroundColor: bgColor, border: border, color: contentColor}}
        >
            <div className={st.content}>
                {title && <div className={st.title}>{title}</div>}
                {icon}
            </div>
        </div>
    );
};

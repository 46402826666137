// @ts-nocheck
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useViewModels } from 'presentation/view-model/ViewModelProvider';
import { observer } from 'mobx-react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import {toJS} from 'mobx';

import stHero from '../../components/HeroHeader/heroHeader.module.scss';
import st from './newsId.module.scss';
import LandingLayout from '../../layouts/landing/LandingLayout';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrow.svg';
import { ReactComponent as TgIcon } from '../../assets/images/icons/tg.svg';
import { ReactComponent as TwitterIcon } from '../../assets/images/icons/twitter.svg';
import { ReactComponent as FbIcon } from '../../assets/images/icons/share_fb.svg';
import { ReactComponent as LinkIcon } from '../../assets/images/icons/link.svg';
import { NonAuthRoutes } from '../../../routes/routes';
import { Button, Skeleton, Grid } from '@mui/material';
import { NewsPreview } from '../../components/NewsPreview';
import { Planet } from '../../components/Planet';

const NewsId: React.FC = () => {
    const intl = useIntl();
    const { newsViewModel } = useViewModels();
    // @ts-ignore
    const newsId = useParams().id;

    useEffect(() => {
        newsViewModel.loadSinglePost(newsId);
        newsViewModel.loadOtherNews(newsId);
    }, [newsViewModel, newsId]);

    function formatHumanDate(date: string) {
        const day = intl.formatDate(new Date(date), { day: 'numeric' });
        const month = intl.formatDate(new Date(date), { month: 'long' });
        const year = intl.formatDate(new Date(date), { year: 'numeric' });

        return `${month} ${day}, ${year}`;
    }

    console.log(toJS(newsViewModel.activeSinglePost))
    return (
        <LandingLayout>
            <div className={st.news_id}>
                <section className={stHero.hero_header}>
                    <div className={st.hero_container}>
                        <div className={st.back_to_topic}>
                            {newsViewModel.activeSinglePost === undefined ? (
                                <Skeleton variant="text" height={15} sx={{ mt: 1, mr: 3, bgcolor: 'grey.300' }} />
                            ) : (
                                <Link to={NonAuthRoutes.news}>
                                    <ArrowIcon />
                                    <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'newsId.back' }) }}></span>
                                </Link>
                            )}
                        </div>

                        <div>
                            {newsViewModel.activeSinglePost === undefined ? (
                                <Skeleton className={st.title} variant="text" sx={{ bgcolor: 'grey.300' }} />
                            ) : (
                                <div className={st.title}>{newsViewModel.activeSinglePost.title}</div>
                            )}


                            {newsViewModel.activeSinglePost === undefined ? (
                                <Skeleton className={st.date} variant="text" sx={{ bgcolor: 'grey.300' }} />
                            ) : (
                                <div className={st.date}>{formatHumanDate(newsViewModel.activeSinglePost?.createdAt)}</div>
                            )}
                        </div>
                    </div>
                </section>

                {newsViewModel.activeSinglePost === undefined ? (
                    <Skeleton className={st.picture} variant="rectangular" sx={{ bgcolor: 'grey.300' }} />
                ) : (
                    newsViewModel.activeSinglePost.picture && (
                        <div
                            className={st.picture}
                            style={{ backgroundImage: `url(${axios.defaults.baseURL + newsViewModel.activeSinglePost.picture})` }}
                        />
                    )
                )}

                {newsViewModel.activeSinglePost === undefined ? (
                    <section className={st.main}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} md={4}>
                                <Grid container spacing={1}>
                                    <Grid item xs="auto">
                                        <Skeleton variant="circular" width={60} height={60} sx={{ bgcolor: 'grey.300' }} />
                                    </Grid>
                                    <Grid item xs>
                                        <Skeleton variant="text" width="100%" height={60} sx={{ bgcolor: 'grey.300' }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Skeleton variant="rectangular" width="100%" height={150} sx={{ bgcolor: 'grey.300' }} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <Skeleton variant="rectangular" width="100%" height={250} sx={{ bgcolor: 'grey.300' }} />
                            </Grid>
                        </Grid>
                    </section>
                ) : (
                    <>
                        <section className={st.main}>
                            <div className={st.additional_data}>
                                {newsViewModel.activeSinglePost?.authorFullName && (
                                    <div className={st.author}>
                                        {newsViewModel.activeSinglePost.authorAvatar?.contentUrl && (
                                            <div className={st.author_photo}>
                                                <img src={axios.defaults.baseURL + newsViewModel.activeSinglePost?.authorAvatar?.contentUrl} alt="Author" />
                                            </div>
                                        )}

                                        <div className={st.author_desc}>
                                            <div className={st.author_name}>{newsViewModel.activeSinglePost?.authorFullName}</div>

                                            {newsViewModel.activeSinglePost.authorSpeciality && (
                                                <div className={st.author_profession}>{newsViewModel.activeSinglePost.authorSpeciality.name}</div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                <div className={st.social_text} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'newsId.share' }) }}></div>

                                <div className={st.social_icons}>
                                    <Link to={NonAuthRoutes.news} className={st.social_icon}>
                                        <Planet
                                            icon={
                                                <div className={st.social_icon_content}>
                                                    <TgIcon />
                                                </div>
                                            }
                                            border="1px solid rgba(0, 185, 255, 0.24)"
                                        />
                                    </Link>
                                    <Link to={NonAuthRoutes.news} className={st.social_icon}>
                                        <Planet
                                            icon={
                                                <div className={st.social_icon_content}>
                                                    <TwitterIcon />
                                                </div>
                                            }
                                            border="1px solid rgba(0, 185, 255, 0.24)"
                                        />
                                    </Link>
                                    <Link to={NonAuthRoutes.news} className={st.social_icon}>
                                        <Planet
                                            icon={
                                                <div className={st.social_icon_content}>
                                                    <FbIcon />
                                                </div>
                                            }
                                            border="1px solid rgba(0, 185, 255, 0.24)"
                                        />
                                    </Link>
                                    <Link to={NonAuthRoutes.news} className={st.social_icon}>
                                        <Planet
                                            icon={
                                                <div className={st.social_icon_content}>
                                                    <LinkIcon />
                                                </div>
                                            }
                                            border="1px solid rgba(0, 185, 255, 0.24)"
                                        />
                                    </Link>
                                </div>
                            </div>

                            <div className={st.content} dangerouslySetInnerHTML={{ __html: newsViewModel.activeSinglePost.content }}></div>
                        </section>

                        <div className={st.border}>
                            <div></div>
                        </div>

                        <section className={st.other_news}>
                            <div className={st.other_news__container}>
                                <div className={st.other_news__side}>
                                    <div
                                        className={st.other_news__header}
                                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'newsId.other' }) }}
                                    ></div>
                                </div>

                                <Link to={NonAuthRoutes.news} className={st.other_news__view_all}>
                                    <Button className="btn" size="large" color="secondary" variant="contained">
                                        <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'newsId.allNews' }) }}></span>
                                    </Button>
                                </Link>

                                <div className={st.other_news__main}>
                                    {newsViewModel.otherNews.map((post) => (
                                        <Link to={`${NonAuthRoutes.news}/${post.id}`} key={post.id}>
                                            <NewsPreview date={post.date} title={post.title}></NewsPreview>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </section>
                    </>
                )}
            </div>
        </LandingLayout>
    );
};

export default observer(NewsId);

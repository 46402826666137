// @ts-nocheck
import React from "react";
import { withRouter } from "react-router-dom";
import DoctorsViewModel from "../../../view-model/doctors/DoctorsViewModel";
import { NonAuthRoutes } from "../../../routes/routes";
import { useViewModels } from "../../../view-model/ViewModelProvider";

interface SpecialtiesListProps {
    data?: Array<[]>;
    limit?: number;
    className?: string;
}

export const SpecialtiesList: React.FC<SpecialtiesListProps> = withRouter(({data, limit, className, history}) => {
    const { userViewModel: { locale } } = useViewModels();

    return (
        <>
            {data?.map((item, index) => {
                if (limit && index >= limit) {
                    return "";
                }

                return (
                    <div className={className} key={index} onClick={() => {
                        DoctorsViewModel.setFilterOrSort('firstSmartInput', item.name);
                        history.push({
                            pathname: (locale && locale !== 'de' ? '/' + locale : '') + NonAuthRoutes.doctors,
                            state: { search: 1 }
                        });
                    }}>
                        <div>{item.name}</div>
                        <div>{item.doctorsCount}</div>
                    </div>
                );
            })}
        </>
    );
});
